import React, {useEffect} from "react";
import {Button, Container, CssBaseline, IconButton, InputBase, makeStyles} from "@material-ui/core";
import PcHeader from "./PcHeader";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {shadow} from '@material-ui/system';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import RoundButton from "../component/RoundButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Axios from "axios";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { AiOutlinePaperClip } from "react-icons/ai";




export default function ImgDetail({history,match}) {

    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [imgDetail, setImgDetail] =  React.useState('');


    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

    useEffect(()=>{

        console.log('이미지',history.location.state.uri);
        // Axios({
        //     url: 'https://sbc.smartbc.kr/sb/mycardInfo.php' ,
        //     method: 'get',
        //     params: {
        //         u_id:u_id,
        //         b_id:b_id,
        //     },
        // })
        //     .then((result) => {
        //         console.log(result.data);
        //
        //     })
        //     .catch((err) => console.log(err.message));


    },[]);

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center'}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'이미지 상세보기'} back={1} history={history} match={match}/>

            {/*body*/}
            <div className={classes.paper}>
                    <img src={"https://sbc.smartbc.kr" + history.location.state.uri}
                          style={{width: 500, }}/>
            </div>

        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        flex: 1,
        marginTop: theme.spacing(8),
        // marginTop:250,
        overflow: 'hidden',
        // width: '100%',
        // maxHeight: 600,
        height:700,
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    }
}));

