import React, {useEffect, useState} from "react";
import {
    Container,
    CssBaseline,
    makeStyles,
    InputBase,
    IconButton,
    Button,
    Typography,
    Checkbox, CircularProgress
} from "@material-ui/core";
import PcHeader from "./PcHeader";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {shadow, width} from '@material-ui/system';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import RoundButton from "../component/RoundButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Axios from "axios";
import Radio from "@material-ui/core/Radio";
import { AiOutlinePaperClip } from "react-icons/ai";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import customAlert from "../component/customAlert";
import customConfirm from "../component/customConfirm";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

function AddHyphen(num) {
    let regexp;
    if(num.length==7){
         regexp = num.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{4})/, '$1-$2');
    }else if(num.length==8){
        regexp = num.replace(/[^0-9]/g, "").replace(/(\d{4})(\d{4})/, '$1-$2');
    } else{
         regexp = num.replace(/[^0-9]/g, "").replace(/(^02|^0505|^0504|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
    }

    return regexp;
}
// 이메일 체크 정규식
function isEmail(asValue) {
    var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    // console.log(regExp.test(asValue));
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

function MyCardDetail({history,match}) {

    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [tab, setTab] = React.useState(false);

    const [modal, setModal] = React.useState(false);

    const [user_name, setUser_name] =  React.useState('');
    const [phone, setPhone] =  React.useState('');
    const [email, setEmail] = React.useState('');
    const [tel, setTel] = React.useState('');
    const [fax, setFax] = React.useState('');
    const [fax2, setFax2] = React.useState('');
    const [fax3, setFax3] = React.useState('');
    const [company, setCompany] = React.useState('');//소속
    const [part, setPart] =  React.useState(''); //부서,파트
    const [position, setPosition] =  React.useState('');
    const [address, setAddress] = React.useState('');
    const [homepage, setHomepage] =  React.useState('');
    const [homepage2, setHomepage2] =  React.useState('');
    const [homepage3, setHomepage3] = React.useState('');
    const [homepage4, setHomepage4] =  React.useState('');
    const [homepage5, setHomepage5] =  React.useState('');
    const [homepage6, setHomepage6] =  React.useState('');
    const [card_open, setCard_open] =  React.useState('');
    const [file, setFile] = React.useState('');//파일이름
    const [upfile, setUpfile] = React.useState('');
    const [image, setImage] = React.useState('');
    const [fileFullName,setFileFullName] =  React.useState('');//파일이름포함한 경로 (다운받을 파일)

    const [position_type, setPosition_type] = React.useState('');
    const [location, setLocation] =  React.useState('');
    const [keyword, setKeyword] =  React.useState('');
    const [introduce, setIntroduce] = React.useState('');

    let mycard_data = {};
    let tmp_data = {};

    const [c_type_list, setC_type_list] =  React.useState([]);
    const [c_type1, setC_type1] =  React.useState('');
    const [c_type2, setC_type2] = React.useState('');
    // let item_c1 = [];
    let item_c2 = [];
    // const [opList_c1,setOpList_c1]= React.useState([]);
    const [opList_c2,setOpList_c2]= React.useState([]);

    const [p_type_list, setP_type_list] = React.useState([]);
    const [p_type1, setP_type1] =React.useState('');
    const [p_type2, setP_type2] =React.useState('');
    let item_p1 = [];
    let item_p2 = [];
    const [opList_p1,setOpList_p1]= React.useState([]);
    const [opList_p2,setOpList_p2]= React.useState([]);

    const [location_list,setLocation_list] = React.useState([]);
    const [position_list,setPosition_list] = React.useState([]);
    let item_position = [];
    let item_location = [];
    const [opList_position,setOpList_position] = React.useState([]);
    const [opList_location,setOpList_location] = React.useState([]);

    const formData = new FormData();
    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
   // console.log('환경',navigator.platform);

    const [loading, setLoding] = React.useState(true);

    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        window.open("https://info.smartbc.kr/b2a");

    };

    useEffect(()=>{

        if(history.location.state){
            console.log('tab : ',history.location.state.tab);
            setTab(true);
        }

        Axios({
            url: 'https://sbc.smartbc.kr/sb/mycardInfo.php' ,
            method: 'get',
            params: {
                u_id:u_id,
                b_id:b_id,
            },
        })
            .then((result) => {
                console.log(result.data);
                if (result.data.status == 9001) {
                    let fileName='' ;
                    if(result.data.upfile) {
                        fileName = result.data.upfile.split('/');
                        fileName = fileName[fileName.length - 1];
                    }
                    console.log('파일이름',fileName);
                    setUser_name(result.data.name);
                    setPhone(AddHyphen(result.data.phone));
                    setEmail(result.data.email);
                    setTel(AddHyphen(result.data.office_phone));
                    setFax(AddHyphen(result.data.fax));
                    setCompany(result.data.company);
                    setPart(result.data.part);
                    setPosition(result.data.position);
                    setAddress(result.data.address);
                    setHomepage(result.data.homepage);
                    setHomepage2(result.data.homepage2);
                    setHomepage3(result.data.homepage3);
                    setHomepage4(result.data.homepage4);
                    setHomepage5(result.data.homepage5);
                    setHomepage6(result.data.homepage6);

                    if(result.data.card_open==0){
                        setCard_open("2");
                    }else{
                        setCard_open(result.data.card_open);
                    }

                    setKeyword(result.data.keyword);
                    setIntroduce(result.data.introduce);

                    setImage(result.data.image);
                    setFile(fileName);
                    setFileFullName(result.data.upfile);
                    setLocation_list(result.data.location_list);
                    setLocation(result.data.location);
                    setPosition_list(result.data.position_list);
                    setPosition_type(result.data.position_type);

                    setC_type_list(result.data.c_type_list);
                    setC_type1(result.data.c_type1);
                    setC_type2(result.data.c_type2);

                    setP_type_list(result.data.p_type_list);
                    setP_type1(result.data.p_type1);
                    setP_type2(result.data.p_type2);

                    if(result.data.c_type1){
                        Axios({
                            url: 'https://sbc.smartbc.kr/sb/getOption.php',
                            method: 'get',
                            params: {
                                table: "company_type_second",
                                mother_type: result.data.c_type1
                            }
                        })
                            .then(function (response) {
                                response.data.map((value) => {
                                    item_c2.push(<option key={value.id} value={value.id}>{value.name}</option>);
                                });
                                setOpList_c2(item_c2);
                                console.log(item_c2);

                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }

                    if(result.data.p_type1){
                        console.log('p_type = ' + result.data.p_type1);
                        Axios({
                            url: 'https://sbc.smartbc.kr/sb/getOption.php',
                            method: 'get',
                            params: {
                                table: "part_type_second",
                                mother_type: result.data.p_type1
                            }
                        })
                            .then(function (response) {
                                response.data.map((value) => {
                                    item_p2.push(<option key={value.id} value={value.id}>{value.name}</option>);
                                });
                                setOpList_p2(item_p2);
                                console.log(item_p2);

                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                    setLoding(false);

                } else {
                    console.log(result);
                    console.log(result.data);
                }
            })
            .catch((err) => console.log(err.message));


    },[]);

    const PopUp = ({file}) => {
        return(
            <>
                <Card className={classes.popUp}>
                    <Typography align='right'>
                        <IconButton>
                            <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                                setModal(false);
                            }}/>
                        </IconButton>
                    </Typography>
                    <CardContent style={{paddingTop: 5,marginBottom:20}}>
                        {/*<Typography>*/}
                        {/*    <img src={require('../resources/card_icon.png')} style={{height: 85}}/>*/}
                        {/*</Typography>*/}
                        <Typography style={{marginTop: 20}}>파일을 다운로드 하시겠습니까?</Typography>
                    </CardContent>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 15}}
                        onClick={() => {
                            console.log('주소',typeof file);
                            window.location.href = 'https://sbc.smartbc.kr/sb/download_file.php?file='+file;
                        }}>
                        다운로드
                    </Button>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, border: '1px solid #303a53', color: '#303a53',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                        onClick={() => {
                            setModal(false);
                        }}>
                        취소
                    </Button>
                </Card>
                <div className={classes.popUpBg} />
            </>
        )
    }


    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center',marginTop:tab ? -80 : 0}}>
            <CssBaseline/>
            {/*header*/}
            {/*share={1} 공유버튼*/}
            { tab ? null : <PcHeader text={'MY 명함 상세보기'} back={1} history={history} match={match} />}
            {/*<PcHeader text={'MY 명함 상세보기'} back={1} history={history} match={match} />*/}

            {/*custom_confirm*/}
            <div>
                <Dialog
                    open={confirmShow}
                    onClose={handleCloseN}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {confirmMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseN} color="primary">
                            {'아니오'}
                        </Button>
                        <Button onClick={handleCloseY} color="primary" autoFocus>
                            {'예'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {/*body*/}
            <div className={classes.paper} style={{margin: '80px auto 0', width: '80%'}}>
                <div>
                    {image?
                        <img src={"https://sbc.smartbc.kr" + image} style={{float: 'center', width: '100%',border: '1px solid grey',borderColor: '#e2e7eb', borderRadius: 5}}/>
                        :
                        <img src={require('resources/mobile_card_sample.png')} style={{float: 'center', width: '90%',}}/>
                    }
                </div>
            </div>
            <Divider style={{boxShadow: 10, marginTop: '2%', marginBottom: '2%',}}/>
            {/*<Box component={'div'}*/}
            {/*     style={{border: '1px solid black', borderColor: '#e2e7eb', borderRadius: 10, minHeight: 60,}}>*/}
                <InputBase item xs={20} style={{ width: 'inherit',border: '1px solid black', borderColor: '#e2e7eb', borderRadius: 10, height: 100,padding:15}}
                           multiline={true}
                           rows={3}
                           placeholder={'50자 이내 소개글을 입력해 주세요 (선택)'}
                           value={introduce}
                           onChange={(event)=>{
                               if(event.target.value.length<50){
                                   setIntroduce(event.target.value);
                               }
                           }}
                />
            {/*</Box>*/}

            {/*이름*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',justifyContent:'center',height:'100%',paddingTop:7}}>이름 *</Grid>
                    <InputBase item xs={20} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'이름'}
                               value={user_name}
                               // type={'text'}
                               onChange={(event)=>{
                                       setUser_name(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>

            {/*휴대전화*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>휴대전화 *</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '58%'}}
                               placeholder={'"-"없이 입력해주세요'}
                               value={phone}
                               // type={"number"} // '-'들어가면 입력값 인식 못함
                               onChange={(event)=>{
                                   if(event.target.value.length<14){
                                       setPhone(event.target.value);
                                   }
                               }}
                        // onChange={setPhoneNumberText}
                    />
                        <img src={require('../resources/call_icon.png')} style={{width: 35,height:35}}
                             onClick={()=>{
                                 if(navigator.platform){
                                     if(!isMobile){
                                         setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                         setConfirmShow(true);
                                         // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                         // if (answer) window.open("https://info.smartbc.kr/b2a");
                                     }else{
                                         customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                     }
                                 }
                             }}/>
                </Grid>
                <Divider/>
            </div>
            {/*이메일*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>이메일 *</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'이메일'}
                               value={email}
                               // type={"email"}
                               onChange={(event)=>{
                                       setEmail(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*유선전화*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>유선전화</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '58%'}}
                               placeholder={'"-"없이 입력해주세요'}
                               value={tel}
                        // type={"number"} // '-'들어가면 입력값 인식 못함
                               onChange={(event)=>{
                                   if(event.target.value.length<15){
                                       setTel(event.target.value);
                                   }
                               }}
                        //        onChange={setTelNumberText}
                    />
                    <img src={require('../resources/call_icon.png')} style={{width: 35,height:35}}
                         onClick={()=>{
                             if(navigator.platform){
                                 if(isMobile){
                                     setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                     setConfirmShow(true);
                                     // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                     // if (answer) window.open("https://info.smartbc.kr/b2a");
                                 }else{
                                     customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                 }
                             }


                         }}/>
                </Grid>
                <Divider/>
            </div>
            {/*팩스번호*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>팩스번호</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'"-"없이 입력해주세요'}
                               value={fax}
                        // type={"number"} // '-'들어가면 입력값 인식 못함
                               onChange={(event)=>{
                                   if(event.target.value.length<15){
                                       setFax(event.target.value);
                                   }
                               }}
                               // onChange={setFaxNumberText}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*소속명*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>소속명 *</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'소속명'}
                               value={company}
                               onChange={(event)=>{
                                   setCompany(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*부서/파트*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>부서/파트 *</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'부서/파트'}
                               value={part}
                               onChange={(event)=>{
                                   setPart(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*직책*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>직책 *</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'직책'}
                               value={position}
                               onChange={(event)=>{
                                   setPosition(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*주소*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>주소</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'주소'}
                               value={address}
                               multiline={true}
                               onChange={(event)=>{
                                   setAddress(event.target.value);
                               }}
                    />
                    {/*<textarea style={{width: '60%', resize: 'none', fontSize: 16, border: 'none', height: '30px', padding: '5px 5px 5px 5%', font: 'inherit',}}>aaaaaa</textarea>*/}
                </Grid>
                <Divider/>
            </div>
            {/*홈페이지*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>홈페이지</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '58%'}}
                               placeholder={'홈페이지'}
                               value={homepage}
                               onChange={(event)=>{
                                       setHomepage(event.target.value);
                               }}
                    />
                    <img src={require('../resources/homepage_icon.png')} style={{width: 35}}
                         onClick={()=>{
                             if(homepage){
                                 if(homepage.indexOf('http://')!=-1 || homepage.indexOf('https://')!=-1){
                                     //문자열 포함
                                     window.open('about:blank').location.href=homepage;//새창 o
                                 }else{
                                     window.open('about:blank').location.href='https://'+homepage;//새창 o
                                 }
                             }

                    }}/>
                </Grid>
                <Divider/>
            </div>
            {/*블로그*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>블로그</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '58%'}}
                               placeholder={'블로그'}
                               value={homepage2}
                               onChange={(event)=>{
                                   setHomepage2(event.target.value);
                               }}
                    />
                    <img src={require('../resources/homepage_icon.png')} style={{width: 35}}
                         onClick={()=>{
                             if(homepage2){
                                 if(homepage2.indexOf('http://')!=-1 || homepage2.indexOf('https://')!=-1){
                                     //문자열 포함
                                     window.open('about:blank').location.href=homepage2;//새창 o
                                 }else{
                                     window.open('about:blank').location.href='https://'+homepage2;//새창 o
                                 }
                             }

                         }}/>
                </Grid>
                <Divider/>
            </div>
            {/*페이스북*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>페이스북</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '58%'}}
                               placeholder={'페이스북'}
                               value={homepage3}
                               onChange={(event)=>{
                                   setHomepage3(event.target.value);
                               }}
                    />
                    <img src={require('../resources/homepage_icon.png')} style={{width: 35}}
                         onClick={()=>{
                             if(homepage3){
                                 if(homepage3.indexOf('http://')!=-1 || homepage3.indexOf('https://')!=-1){
                                     //문자열 포함
                                     window.open('about:blank').location.href=homepage3;//새창 o
                                 }else{
                                     window.open('about:blank').location.href='https://'+homepage3;//새창 o
                                 }
                             }

                         }}/>
                </Grid>
                <Divider/>
            </div>
            {/*트위터 => 유튜브로 변경*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>유튜브</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '58%'}}
                               placeholder={'유튜브'}
                               value={homepage4}
                               onChange={(event)=>{
                                   setHomepage4(event.target.value);
                               }}
                    />
                    <img src={require('../resources/homepage_icon.png')} style={{width: 35}}
                         onClick={()=>{
                             if(homepage4){
                                 if(homepage4.indexOf('http://')!=-1 || homepage4.indexOf('https://')!=-1){
                                     //문자열 포함
                                     window.open('about:blank').location.href=homepage4;//새창 o
                                 }else{
                                     window.open('about:blank').location.href='https://'+homepage4;//새창 o
                                 }
                             }

                         }}/>
                </Grid>
                <Divider/>
            </div>
            {/*인스타그램*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>인스타그램</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '58%'}}
                               placeholder={'인스타그램'}
                               value={homepage5}
                               onChange={(event)=>{
                                   setHomepage5(event.target.value);
                               }}
                    />
                    <img src={require('../resources/homepage_icon.png')} style={{width: 35}}
                         onClick={()=>{
                             if(homepage5){
                                 if(homepage5.indexOf('http://')!=-1 || homepage5.indexOf('https://')!=-1){
                                     //문자열 포함
                                     window.open('about:blank').location.href=homepage5;//새창 o
                                 }else{
                                     window.open('about:blank').location.href='https://'+homepage5;//새창 o
                                 }
                             }

                         }}/>
                </Grid>
                <Divider/>
            </div>
            {/*운영쇼핑몰*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>운영쇼핑몰</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '58%'}}
                               placeholder={'운영쇼핑몰'}
                               value={homepage6}
                               onChange={(event)=>{
                                   setHomepage6(event.target.value);
                               }}
                    />
                    <img src={require('../resources/homepage_icon.png')} style={{width: 35}}
                         onClick={()=>{
                             if(homepage6){
                                 if(homepage6.indexOf('http://')!=-1 || homepage6.indexOf('https://')!=-1){
                                     //문자열 포함
                                     window.open('about:blank').location.href=homepage6;//새창 o
                                 }else{
                                     window.open('about:blank').location.href='https://'+homepage6;//새창 o
                                 }
                             }

                         }}/>
                </Grid>
                <Divider/>
            </div>
            {/*파일첨부*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>파일첨부</Grid>
                    <InputBase style={{textAlign :'left',paddingLeft :'5%', width: '50%'}}
                               placeholder={'파일첨부'}
                               value={file}
                               // disabled={true} //회색으로 보임
                               readOnly={true}
                               onChange={(event)=>{
                                   setFile(event.target.value);
                               }}
                               onClick={()=>{
                                   setModal(true);
                               }}
                    />

                    <Button
                        // variant="contained"
                        component="label" // 이거 써야 <input/> 가능?!
                        type="submit"
                        color="primary"
                        style={{backgroundColor: 'rgb(48,58,83)', color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 25,padding :10, marginLeft: 1}}
                      >
                        <AiOutlinePaperClip size={20}/>
                        {"수정"}
                        {/*파일업로드*/}
                        <input id={"file-input"} style={{ display: 'none' }} type="file" name="file-input"
                               // ref="file"
                               onChange={(event)=>{
                                   //같은파일을 클릭해도 작동되게하지?,,,,

                                   //취소가 아닐 때 (확인 눌렀을 때)
                                   if(event.target.value!=''){
                                       // setModal(true);
                                       // console.log('파일경로',event.target.files[0]);
                                       let fileVal = event.target.files[0];
                                       let fileName = event.target.value.split('\\').pop();


                                       // formData.append("before_upfile",{
                                       //     name: fileVal.name,
                                       //     type: fileVal.type,
                                       //     uri: fileVal.uri
                                       // });
                                       formData.append("before_upfile",fileVal);
                                       console.log('파일ㄹ?',fileVal);
                                       console.log('들어감?',formData);
                                       for (var key of formData.entries()) {
                                           console.log(key[0] + ', ' + key[1]);
                                       }


                                       if(formData) {
                                           Axios({
                                               method: 'POST',
                                               url: 'https://sbc.smartbc.kr/sb/before_upload_file.php',
                                               data: formData,
                                               params: {
                                                   u_id: u_id
                                               },
                                               headers: {
                                                   //'Authorization': "Bearer  "  +  YOUR_BEARER_TOKEN,
                                                   // 'Accept': 'application/json',
                                                   'Content-Type': 'multipart/form-data;'
                                               }
                                           })
                                               .then((result) => {
                                                   console.log('통신함?', result.data);

                                                   if (result.data.status == 27003) {
                                                       setFile(fileName);
                                                       setUpfile(fileVal);
                                                   }
                                                   customAlert(result.data.result_msg);
                                               })
                                               .catch((err) => console.log(err.message));
                                       }else{
                                           customAlert('파일을 다시 선택해 주세요');
                                       }//if end
                                   }


                                   console.log('파일입니다.',event.target.value);
                               }} />

                    </Button>

                </Grid>
                <Divider/>
            </div>

            {/*명함공개 설정 */}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={2.5} style={{textAlign: 'left',paddingTop:7,marginRight:18}}>명함공개 설정 *</Grid>
                    <Grid item xs={7}>
                        <RadioGroup row aria-label="card_open" name="card_open"  defaultValue="2" value={card_open}
                                    style={{marginRight:-25}}
                                    className={'_radioFont'}
                                    onChange={(event)=>{
                                        setCard_open(event.target.value);
                                        console.log('dfsd',event.target.value);

                        }}>
                            <FormControlLabel value="1" control={<Radio color="primary" />} label="전체 공개" style={{fontSize:12}} />
                            <FormControlLabel value="2" control={<Radio color="primary" />} label="모임만 공개" style={{fontSize:12}} />
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Divider/>
            </div>

            {/*회사 업종*/}
            <Grid container style={{padding:15,}}>
                <Grid item xs={3} style={{textAlign :'left',alignSelf:'center',paddingTop:7}}>회사 업종 *</Grid>
                <Grid item xs={9} style={{flexDirection :'row',}}>
                    <FormControl variant="outlined" className={classes.formControl} >
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,width:128,textAlign :'center'}}
                            value={c_type1}
                            onChange={(event) => {
                                console.log('선택',event.target.value);
                                setC_type1(event.target.value);
                                if(event.target.value!=0) {
                                    //2차 옵션 가져오기
                                    Axios({
                                        url: 'https://sbc.smartbc.kr/sb/getOption.php',
                                        method: 'get',
                                        params: {
                                            table: "company_type_second",
                                            mother_type: event.target.value
                                        }
                                    })
                                        .then(function (response) {
                                            response.data.map((value) => {
                                                item_c2.push(<option key={value.id} value={value.id}>{value.name}</option>);
                                            });
                                            setOpList_c2(item_c2);
                                            console.log(item_c2);

                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });
                                }else {
                                    item_c2=[];
                                    setOpList_c2(item_c2);
                                }
                            }}
                        >
                            <option value="0">선택</option>
                            {c_type_list.length>0?(
                                c_type_list.map((value,i)=> {
                                    return (
                                        <option key={value[0].id} value={value[0].id} >{value[0].name}</option>
                                    )
                                })):null}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl} style={{left :5}}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,width:128,textAlign :'center'}}
                            value={c_type2}
                            onChange={(event) => {
                                setC_type2(event.target.value);
                            }}
                        >
                            <option value="0">선택</option>
                            {opList_c2}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider/>
            {/*직업군*/}
            <Grid container style={{padding:15,}}>
                <Grid item xs={3} style={{textAlign :'left',alignSelf:'center',paddingTop:7}}>직업군 *</Grid>
                <Grid item xs={9} style={{flexDirection :'row',}}>
                    <FormControl variant="outlined" className={classes.formControl} >
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,width:128,textAlign :'center'}}
                            value={p_type1}
                            onChange={(event) => {
                                console.log('선택',event.target.value);
                                setP_type1(event.target.value);

                                if(event.target.value!=0) {
                                    //2차 옵션 가져오기
                                    Axios({
                                        url: 'https://sbc.smartbc.kr/sb/getOption.php',
                                        method: 'get',
                                        params: {
                                            table: "part_type_second",
                                            mother_type: event.target.value
                                        }
                                    })
                                        .then(function (response) {
                                            response.data.map((value) => {
                                                item_p2.push(<option key={value.id} value={value.id}>{value.name}</option>);
                                            });
                                            setOpList_p2(item_p2);
                                            console.log(item_p2);

                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });
                                }else {
                                    item_p2=[];
                                    setOpList_p2(item_p2);
                                }
                            }}
                        >
                            <option value="0">선택</option>
                            {p_type_list.length>0?(
                                p_type_list.map((value,i)=> {
                                    return (
                                        <option key={value[0].id} value={value[0].id} >{value[0].name}</option>
                                    )
                                })):null}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl} style={{left :5}}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,width:128,textAlign :'center'}}
                            value={p_type2}
                            onChange={(event) => {
                                setP_type2(event.target.value);
                            }}
                        >
                            <option value="0">선택</option>
                            {opList_p2}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider/>

            {/*직급 유형*/}
            <Grid container style={{padding:15}}>
                <Grid item xs={3} style={{textAlign :'left',alignSelf:'center',paddingTop:7}}>직급 유형 *</Grid>
                <Grid item xs={9}>
                    <FormControl variant="outlined" className={classes.formControl} style={{flexDirection :'row'}}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,textAlign :'center',width :261}}
                            value={position_type}
                            onChange={(event) => {
                                setPosition_type(event.target.value);
                            }}
                        >
                            <option value="0">선택</option>
                            {position_list.length>0?(
                                position_list.map((value,i)=> {
                                    return (
                                        <option key={value.id} value={value.id} >{value.name}</option>
                                    )
                                })):null}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider/>

            {/*지역*/}
            <Grid container style={{padding:15}}>
                <Grid item xs={3} style={{textAlign :'left',alignSelf:'center',paddingTop:7}}>지역 *</Grid>
                <Grid item xs={9}>
                    <FormControl variant="outlined" className={classes.formControl} style={{flexDirection :'row'}}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,textAlign :'center',width :261}}
                            value={location}
                            onChange={(event) => {
                                setLocation(event.target.value);
                            }}
                        >
                            <option value="0">선택</option>
                            {location_list.length>0?(
                                location_list.map((value,i)=> {
                                    return (
                                        <option key={value.id} value={value.id} >{value.name}</option>
                                    )
                                })):null}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider/>

            {/*검색키워드*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>검색키워드</Grid>
                    <InputBase item xs={9} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'검색키워드'}
                               multiline={true}
                               value={keyword}
                               onChange={(event)=>{
                                   setKeyword(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>

            {/*버튼*/}
            <div style={{marginTop :20,marginBottom :20, padding: '0 20px'}}
                 onClick={()=>{
                     if(user_name==''){
                         customAlert("이름을 입력해주세요!");
                         return;
                     }else if(phone==''||phone.length<11){
                         customAlert('휴대폰번호를 입력해주세요.');
                         return;
                     }else if(tel.length>0&&tel.length<7) {
                         customAlert("유선전화를 입력해주세요.");
                         return;
                     }else if(fax.length>0&&fax.length<7) {
                         customAlert("팩스번호를 입력해주세요.");
                         return;
                     }else if(email =='') {
                         customAlert("이메일을 입력해주세요");
                         return;
                     }else if(!isEmail(email)){
                         customAlert('이메일을 맞게 입력했는지 확인해주세요.');
                         return;
                     }else if(company =='') {
                         customAlert("소속명을 입력해주세요");
                         return;
                     }else if(part =='') {
                         customAlert("부서/파트를 입력해주세요");
                         return;
                     }else if(position =='') {
                         customAlert("직책을 입력해주세요");
                         return;
                     }else if(c_type1 ==''||c_type1 =='0') {
                         customAlert("회사업종을 선택해주세요.");
                         return;
                     }else if(c_type2 ==''||c_type2 =='0') {
                         customAlert("회사업종을 선택해주세요.");
                         return;
                     }else if(p_type1 ==''||p_type1 =='0') {
                         customAlert("직업군을 선택해주세요.");
                         return;
                     }else if(p_type2 ==''||p_type2 =='0') {
                         customAlert("직업군을 선택해주세요.");
                         return;
                     }else if(position_type ==''||position_type =='0') {
                         customAlert("직급유형을 선택해주세요.");
                         return;
                     }else if(location ==''||location =='0') {
                         customAlert("지역을 선택해주세요");
                         return;
                     }

                     tmp_data['u_id'] = u_id;
                     tmp_data['name'] = user_name;

                     // '-'(하이픈) 제거
                     // let phone_= phone.replace(/\-/g,'');
                     // tmp_data['phone'] = AddHyphen(phone_);
                     tmp_data['phone'] = AddHyphen(phone);

                     // '-'(하이픈) 제거
                     // let tel_= tel.replace(/\-/g,'');
                     // tmp_data['tel'] = AddHyphen(tel_);
                     tmp_data['tel'] = AddHyphen(tel);

                     // '-'(하이픈) 제거
                     // let fax_= fax.replace(/\-/g,'');
                     // tmp_data['fax'] = AddHyphen(fax_);
                     tmp_data['fax'] = AddHyphen(fax);

                     // let regExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
                     // console.log("result",regExp.test(tel));
                     // return;

                     tmp_data['email'] = email;
                     tmp_data['company'] = company;
                     tmp_data['part'] = part;
                     tmp_data['position'] = position;
                     tmp_data['address'] = address;
                     tmp_data['homepage'] = homepage;
                     tmp_data['homepage2'] = homepage2;
                     tmp_data['homepage3'] = homepage3;
                     tmp_data['homepage4'] = homepage4;
                     tmp_data['homepage5'] = homepage5;
                     tmp_data['homepage6'] = homepage6;
                     tmp_data['card_open'] = card_open;
                     tmp_data['keyword'] = keyword;
                     tmp_data['introduce'] = introduce;
                     tmp_data['c_type1'] = c_type1;
                     tmp_data['p_type1'] = p_type1;
                     tmp_data['c_type2'] = c_type2;
                     tmp_data['p_type2'] = p_type2;
                     tmp_data['location'] = location;
                     tmp_data['position_type'] = position_type;
                     tmp_data['upfile'] = upfile;

                     mycard_data = JSON.stringify(tmp_data);
                     console.log(tmp_data);

                     //파일 이름처리 (나중에)
                     // let file_ext = file.split('.').pop();
                     // console.log('파일이름', file_ext.length);
                     // //서버에서 업로드가능한 확장자 길이는 3~4
                     // if(file_ext.length==3){
                     //     tmp_data['fileName']  = file.slice(0,file.length-4);
                     // }else{
                     //     tmp_data['fileName'] = file.slice(0,file.length-5);
                     // }

                     if(upfile){
                         //파일업로드 하면서 수정할 경우

                         formData.append("upfile", upfile);
                         //콘솔에서 formData 찍으면 이렇게 밖에 {} 안 나옴,,,(RN에서는 안에 내용 보였는데,,,)
                         console.log(formData,'form');

                         Axios({
                             url: 'https://sbc.smartbc.kr/sb/mycard_update.php' ,
                             method: 'POST',
                             data: formData,
                             params:{
                                 mycard_data:mycard_data
                             },
                             headers: {
                                 'Content-Type': 'multipart/form-data;'
                             }})
                             .then((result) => {
                                     console.log(result.data);
                                 customAlert(result.data.result_msg);
                                 // history.replace('/'+match.params.JoinCode+'/MyInfo');
                                 history.goBack();
                             })
                             .catch((err) => console.log(err.message));

                     }else{
                         //파일 없이 수정할 경우
                         Axios({
                             url: 'https://sbc.smartbc.kr/sb/mycard_update.php' ,
                             method: 'GET',
                             params: {
                                 mycard_data:mycard_data
                             }
                         })
                             .then((result) => {
                                 console.log(result.data);
                                 customAlert(result.data.result_msg);
                                 // history.replace('/'+match.params.JoinCode+'/MyInfo');
                                 history.goBack();

                             })
                             .catch((err) => console.log(err.message));
                     }

                 }}>
            <RoundButton text={'MY 명함정보 수정'}/>
            </div>

            {modal?(<PopUp file={fileFullName}/>)
                // <>
                //     <Card className={classes.popUp}>
                //         <Typography align='right'>
                //             <IconButton>
                //                 <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                //                     setModal(false);
                //                 }}/>
                //             </IconButton>
                //         </Typography>
                //         <CardContent style={{paddingTop: 5,marginBottom:20}}>
                //             {/*<Typography>*/}
                //             {/*    <img src={require('../resources/card_icon.png')} style={{height: 85}}/>*/}
                //             {/*</Typography>*/}
                //             <Typography style={{marginTop: 20}}>파일을 다운로드 하시겠습니까?</Typography>
                //         </CardContent>
                //         <Button
                //             color="primary"
                //             className={classes.groupAdd}
                //             style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 15}}
                //             onClick={() => {
                //                     location.href = 'https://sbc.smartbc.kr/sb/download_file.php?file=' + fileFullName;
                //             }}>
                //            다운로드
                //         </Button>
                //         <Button
                //             color="primary"
                //             className={classes.groupAdd}
                //             style={{width: 200, border: '1px solid #303a53', color: '#303a53',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                //             onClick={() => {
                //                 setModal(false);
                //             }}>
                //            취소
                //         </Button>
                //     </Card>
                //     <div className={classes.popUpBg} />
                // </>
                : null}
            {loading?
                <>
                    <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                    <div className={classes.popUpBg} />
                </>
                :null}
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        // display: 'flex', //익스플로러에서 적용하면 이미지 크게 나옴
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    },
    input: {
        display: 'none',
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    '@global': {
        '._radioFont .MuiTypography-body1':{
            fontSize: 13,
        }
    },
}));
export default MyCardDetail;
