import React, {useEffect, useState} from "react";
import {
    Divider,
    FormControl,
    Select,
    Grid,
    Box,
    Container,
    CssBaseline,
    makeStyles,
    TextareaAutosize,
    IconButton,
    Typography,
    Button,
    CardContent,
    FormControlLabel,
    Checkbox,
    InputBase,
    CircularProgress
} from '@material-ui/core';
import PcHeader from "./PcHeader";
import RoundButton from "../component/RoundButton";
import Icon from "@material-ui/icons/ArrowBack";
import Axios from "axios";
import {width} from "@material-ui/system";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import { IoIosCall } from "react-icons/io";
import { RiMessage2Line } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import { ImLocation } from "react-icons/im";
import { SiTwitter } from "react-icons/si";
import { AiOutlineHome } from "react-icons/ai";
import { MdShare } from "react-icons/md";
import Moment from "moment";
import customConfirm from "../component/customConfirm";
import customAlert from "../component/customAlert";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";



function AddHyphen(num) {
    let regexp;
    if(num.length==7){
        regexp = num.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{4})/, '$1-$2');
    }else if(num.length==8){
        regexp = num.replace(/[^0-9]/g, "").replace(/(\d{4})(\d{4})/, '$1-$2');
    } else{
        regexp = num.replace(/[^0-9]/g, "").replace(/(^02|^0505|^0504|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
    }

    return regexp;
}

function CardDetail({history}) {

    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [member, setMember] = React.useState(new Array());
    const[link, setLink] = React.useState(0);
    const[response_type, setResponse_type] = React.useState(null);
    const[card_linked, setCard_linked] = React.useState(0);

    const [modal , setModal] = React.useState(false);

    const [upfile, setUpfile] = React.useState(''); //파일이름포함한 경로
    const [userLevel, setUserLevel] = React.useState(''); //1:운영자, 2:우수회원 4:일반회원
    const [writeList, setWriteList] = React.useState([]);
    const [op2List, setOp2List] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        window.open("https://info.smartbc.kr/b2a");
    };

    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

    //id => 로그인한 유저 아이디 u_id => 상세보기에 나타날 선택된 명함 유저 아이디
    useEffect(() => {
        let jo=[];
        if(history.location.state.join_option){
            jo = history.location.state.join_option.toString();
        }
        Axios({
            url: "https://sbc.smartbc.kr/sb/members_card_detail.php",
            method: 'get',
            params: {
                target_id : history.location.state.sel_u_id + '',
                u_id : u_id,
                join_option:jo,
                b_id : b_id,
            }
        })
            .then(function (response) {
                console.log('정보',response.data);
                setMember(response.data.card_data);
                setLink(response.data.link);
                setResponse_type(response.data.response_type);
                setCard_linked(response.data.card_linked);
                setUpfile(response.data.card_data.upfile);

                if(response.data.op2_list){
                    setOp2List(response.data.op2_list);
                }
                if(response.data.user_level==1){
                    setUserLevel('운영자');
                }else if(response.data.user_level==2){
                    setUserLevel('우수회원');
                }
                if(response.data.write_list) setWriteList(response.data.write_list);

                setLoading(false);
            })
            .catch(function (error) {
                console.log('detail error' + error);
            });
    }, []);


    const InputContainer=({label,text,call,message, email,location, link})=> {
        return (
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={2} style={{textAlign: 'left'}}>{label}</Grid>
                    <Grid item xs={10} style={{textAlign :'left',paddingLeft :'5%', position: 'relative'}}>
                        {text}
                        {/*{profile?*/}
                        {/*    <span style={{display: 'inline-block', position: 'absolute', right: 12, top: '50%', width: 35, height: 35, transform: 'translateY(-50%)', overflow: 'hidden',borderRadius: '50%'}}>*/}
                        {/*        <img src={"https://sbc.smartbc.kr" + profile} style={{width: 40,position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)'}}/>*/}
                        {/*    </span>*/}
                        {/*    :null}*/}
                        {call?
                            <IconButton style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(navigator.platform){
                                                if(isMobile){
                                                    setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                    setConfirmShow(true);
                                                    // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                    // if (answer) window.open("https://info.smartbc.kr/b2a");
                                                }else{
                                                    customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                                }
                                            }
                                        }}>
                                {/*<img src={require('../resources/call_icon.png')} style={{width: 35}}/>*/}
                                <IoIosCall size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {message?
                            <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(navigator.platform){
                                                if(isMobile){
                                                    setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                    setConfirmShow(true);
                                                    // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                    // if (answer) window.open("https://info.smartbc.kr/b2a");
                                                }else{
                                                    customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                                }
                                            }
                                        }}>
                                {/*<img src={require('../resources/call_icon.png')} style={{width: 35}}/>*/}
                                <RiMessage2Line size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {email?
                            <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            window.open("mailto:"+email);
                                        }}>
                                <GoMail size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {location?
                            <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            //window.open('about:blank').location.href='https://'+location;
                                        }}>
                                <ImLocation size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {link?
                            <IconButton style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(link.indexOf('http://')!=-1 ||
                                                link.indexOf('https://')!=-1){
                                                //문자열 포함
                                                window.open('about:blank').location.href=link;//새창 o
                                            }else{
                                                window.open('about:blank').location.href='https://'+link;//새창 o
                                            }
                                        }}>
                                <img src={require('../resources/homepage_icon.png')} style={{width: 35}}/>
                            </IconButton>
                            :null}

                    </Grid>
                </Grid>
                <Divider/>
            </div>
        );
    }

    const Card_image = ({image}) =>{
        return(
            <div className={classes.paper}>
                <div style={{alignItems: 'center', justifyContent: 'center', backgroundColor: '#f9fafc',}}>
                    <img src={"https://sbc.smartbc.kr" + member.image} style={{maxWidth : 400,marginTop : 10}}/>
                </div>
            </div>
        );
    } // end Card_image

    const LinkIcon = ({btn,url})=>{
        let color_ = 'white';
        let icon = null;
        if(btn == 1){
            color_= 'rgb(48 ,58, 83)';
            icon =<AiOutlineHome name={'home'} color={'white'} size={30} style={{height:'100%'}}/>;
        }else if(btn == 2){
            color_= '#2DB400';
            icon=<img src={require('../resources/blog.png')}  style={{height:'70%',width:'70%',marginTop:10}}/>;
        }else if(btn == 3){
            icon =<img src={require('../resources/facebook.jpg')}  style={{height:'100%',width:'100%',borderRadius:50}}/>;
        }else if(btn == 4){
            // color_='rgb(29, 161, 242)';
            // icon=<SiTwitter name={'twitter'} color={'white'} size={30} style={{height:'100%'}}/>;
            icon=<img src={require('../resources/icon_youtube.png')}  style={{height:'100%',width:'100%',borderRadius:50}}/>;

        }else if(btn == 5){
            icon=<img src={require('../resources/instar.png')} style={{height:'100%',width:'100%',borderRadius:50}}/>;

        }else if(btn == 6){
            color_='#003366';
            icon=<MdShare name={'share'} color={'white'} size={30} style={{height:'100%'}}/>;
        }
        return (
            url?
                <>

                    <div style={{cursor: 'pointer',backgroundColor:color_,width:50,height:50,borderRadius:50,marginRight:10,justifyContent:'center', alignItems:'center'}}
                         onClick={() => {
                             if (url.indexOf('http://') != -1 || url.indexOf('https://') != -1) {
                                 //문자열 포함
                                 window.open('about:blank').location.href=url;//새창 o
                             } else {
                                 //문자열 포함 안함
                                 window.open('about:blank').location.href='https://'+url;//새창 o
                             }

                         }}>
                        {icon}
                    </div>

                </>
                :null
        )
    }

    const StateBtn = ({btn}) =>{
        let button = null;
       if(btn=='정보공개 요청'){
            button =
                <Button onClick={() => {setModal(true);}}
                        style={{backgroundColor: '#303a53', borderRadius: 5, color : '#fff',fontSize : 13 , fontWeight :'bold', paddingRight : 15, paddingLeft : 15}}>
                    {btn}</Button>;
        } else if(btn=='주소록 저장'){
            button =
                <Button onClick={()=>{
                    if(navigator.platform){
                        if(isMobile){
                            setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                            setConfirmShow(true);
                            // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                            // if (answer) window.open("https://info.smartbc.kr/b2a");
                        }else{
                            customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                        }
                    }
                }}
                        style={{border: '1px solid rgb(48 ,58, 83)', borderRadius: 5, color : 'rgb(48 ,58, 83)', fontSize : 13 , fontWeight :'bold', paddingRight : 15, paddingLeft : 15}}>
                    {btn}</Button>;
        }
        return(
            button
        );
    } // end

    const FileDownload = ({label, text}) =>{
        let content = text.split('/');
        content = content[content.length-1];
        console.log('네임',content);
        return(
            <div style={{cursor: 'pointer'}}>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={2} style={{textAlign: 'left'}}>{label}</Grid>
                    <Grid item xs={9} style={{textAlign :'left',paddingLeft :'5%', position: 'relative'}}
                          onClick={()=>{
                              window.location.href='https://sbc.smartbc.kr/sb/download_file.php?file='+text
                          }}>
                        {content}

                        <IconButton style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}>
                            <img src={require('../resources/fileimage.png')} style={{width: 15}} onClick={() => {
                                window.location.href='https://sbc.smartbc.kr/sb/download_file.php?file='+text
                            }}/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider/>
            </div>
        );
    }//end FileDownload

    let file = null;
    if(upfile){
        file = <FileDownload label={'첨부파일'} text={member.upfile} />
    }else{
        file = null;
    }

    let state_button = null;
    let phone_open = null;
    let email_open = null;
    let card_image = null;

    if(link ==1){
        state_button = <StateBtn btn={'주소록 저장'}/>;
        phone_open = <InputContainer label={'휴대전화'} text={AddHyphen(member.phone)} call={member.phone} message={member.phone}/> ;
        email_open = <InputContainer label={'이메일'} text={member.email}  email={member.email}/>;
        card_image = <Card_image image={{uri : "https://sbc.smartbc.kr"+member.image}}/>;
    }else{
        if(response_type==0){
            state_button = <StateBtn btn={'연결 중'}/>;
            phone_open = <InputContainer label={'휴대전화'} text={'정보공개 수락 후 공개'}/> ;// request1={'연결 중'}
            email_open = <InputContainer label={'이메일'} text={'정보공개 수락 후 공개'}/>;
            card_image = null;
        }else if(response_type==2){
            state_button = <StateBtn btn={'연결 거부'}/>;
            phone_open = <InputContainer label={'휴대전화'} text={'정보공개 수락 후 공개'} /> ;//request1={'연결 거부'}
            email_open = <InputContainer label={'이메일'} text={'정보공개 수락 후 공개'} />;
            card_image = null;
        }else if(card_linked == 1) {
            state_button = <StateBtn btn={'정보공개 요청'}/>;
            phone_open = <InputContainer label={'휴대전화'} text={'정보공개 수락 후 공개'}/> ; // request1={'연결'}
            email_open = <InputContainer label={'이메일'} text={'정보공개 수락 후 공개'} />; //request1={'연결'}
            card_image = null;
        }else{
            state_button = <StateBtn btn={'연결 요청'}/>;
            phone_open = <InputContainer label={'휴대전화'} text={'정보공개 수락 후 공개'} /> ;//request2={'연결 요청'}
            email_open = <InputContainer label={'이메일'} text={'정보공개 수락 후 공개'} />;
            card_image = null;
        }
    }

    const PopUp = ({targetId}) => {
        const [check,setCheck] = useState(false);
        if(check) document.getElementById('msg').value = '연락처 ,이메일 공개를 요청합니다.';


        return(
            <>
                <Card className={classes.popUp}>
                    <div style={{position: 'relative', padding: '15px 0'}}>
                        <Typography align='center' style={{fontWeight: 'bold'}}>개인정보 공개요청</Typography>
                        <IconButton style={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}} onClick={() => {
                            setModal(!modal);
                        }}>
                            <CloseIcon style={{color: 'black', fontSize: 30}}/>
                        </IconButton>
                    </div>
                    <CardContent style={{paddingTop: 5}}>
                        <Box component={'div'}
                             style={{border: '1px solid #e2e7eb', borderRadius: 10, minHeight: 80, padding: 10,}}>
                            <Box id={'msg'}
                                 component={'textarea'} style={{border: 'none', outline: 'none', width: '100%', height: '100%', minHeight: 80, resize: 'none', lineHeight: 1.3}}
                                 placeholder={'공개요청 사유를 입력해 주세요.'}
                            />
                        </Box>
                        <FormControlLabel
                            onClick={() => {
                                setCheck(!check);
                            }}
                            style={{width: '100%'}}
                            control={
                                <Checkbox
                                    icon={<CheckBoxIcon fontSize="inherit"/>}
                                    checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                />
                            }
                            label={<Typography style={{fontSize: 13}}>연락처, 이메일 공개를 요청합니다.</Typography>}
                            checked={check}
                        />
                    </CardContent>
                    <Button
                        onClick={() => {
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/info_open_request.php',
                                method: 'get',
                                params: {
                                    u_id : u_id,
                                    target_id : targetId,
                                    b_id : b_id,
                                    msg : (check ? '연락처,이메일 공개를 요청합니다.':document.getElementById('msg').value)
                                },
                                // resultType:'text',
                            })
                                .then((result) => {
                                    console.log('info_open_request success');
                                    console.log(result.data);
                                    if(result.data == "30") customAlert("개인정보 공개 요청은 요청 후 30일뒤 \n다시 요청이 가능합니다.");
                                    setModal(false);
                                })
                                .catch((err) => console.log(err.message));
                        }}
                        color="primary"
                        style={{width: '90%', backgroundColor: 'rgb(48,58,83)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                    >
                        공개 요청하기
                    </Button>
                </Card>
                <div className={classes.popUpBg}></div>
            </>
        );
    }

    const WriteList = ({title,date,id,table})=>{
        let tableName='';
        if(table==1){
            tableName='band_board1';
        }else if(table==2){
            tableName='band_board2';
        }else if(table==3){
            tableName='band_board3';
        }

        return(
            <div style={{cursor: 'pointer',alignItems: 'center',justifyContent: 'center',width:'100%',height:40,display:'flex',border: '1px solid rgb(217, 217, 217)',  borderRadius: 5,}}
                 onClick={()=>{
                     history.push({
                         pathname: 'NoticeBoardDetail',
                         state: {board_id: id, table: tableName}
                     });
                 }}>
                <Typography align={'left'}
                            className={classes.textEllipsis}
                            style={{fontSize: 13, letterSpacing: 0.5,color:'black',width:'70%', marginLeft:20,}}>
                    {/*overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal', lineHeight: 2, display: '-webkit-box',lineClamp: 1,boxOrient: 'vertical',*/}
                    {title}
                </Typography>
                <div align={'right'} style={{fontSize: 12, letterSpacing: 0.5,color:'gray',width:'25%',marginRight:20,paddingTop:5 }}>
                    {Moment(date).format('YYYY-MM-DD')}</div>
            </div>
        )
    }

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center', marginTop: 65}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'명함 상세 보기'} back={1} history={history}/>
            {/*body*/}
            {/*명함 이미지*/}
            {/*<div className={classes.paper}>*/}
            {/*    {member.allow_list ?*/}
            {/*        (JSON.parse(member.allow_list).find(element => element == Number(history.location.state.sel_u_id)) ?*/}
            {/*            (member.allow_list_email ?*/}
            {/*                (JSON.parse(member.allow_list_email).find(element => element == Number(history.location.state.sel_u_id)) ?*/}
            {/*                    (<div style={{*/}
            {/*                        alignItems: 'center',*/}
            {/*                        justifyContent: 'center',*/}
            {/*                        backgroundColor: '#f9fafc',*/}
            {/*                    }}>*/}
            {/*                        <img src={"https://sbc.smartbc.kr" + member.image} style={{maxWidth : 400,marginTop : 10}}/>*/}
            {/*                    </div>) : null) : null) : null) : null}*/}

            {/*</div>*/}

            {/*소개글*/}
            {/*{member.introduce ?*/}
            {/*    (*/}
            {/*        <div style={{*/}
            {/*            borderRadius: 10,*/}
            {/*            width: '95%',*/}
            {/*            backgroundColor: 'rgb(249,250,252)',*/}
            {/*            padding: 10,*/}
            {/*            marginLeft: '3%',*/}
            {/*        }}>*/}
            {/*            <InputBase  rowsMin={2} value={member.introduce} readOnly={true} rows={2} multiline={true}*/}
            {/*                        style={{border: 'none', resize: 'none' , width: '100%', backgroundColor: 'transparent',lineHeight: 1.5,fontSize:13}}/>*/}
            {/*        </div>*/}
            {/*    ) : null}*/}
            {/*<Divider style={{boxShadow: 10, marginTop: '2%', marginBottom: '2%',}}/>*/}

            {/*프로필 이미지*/}

            {member.profile ?
                <div align={'center'} style={{width:'100%',height:270,}}>
                    {/* backgroundColor:'#d9d9d9',*/}
                    <img src={"https://sbc.smartbc.kr" + member.profile} style={{maxHeight : 270}}/>
                </div>
                : null}

            {/*custom_confirm*/}
            <div>
                <Dialog
                    open={confirmShow}
                    onClose={handleCloseN}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {confirmMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseN} color="primary">
                            {'아니오'}
                        </Button>
                        <Button onClick={handleCloseY} color="primary" autoFocus>
                            {'예'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


            {/*이름, 버튼 영역*/}
            <div style={{width:'100%',height:100,borderBottomWidth:0.5,flexDirection:'row'}}>
                <Grid container style={{}}>
                    {/*이름,직함*/}
                    <Grid item xs={7} style={{textAlign: 'left',paddingLeft:30,paddingTop:20}}>
                        <Grid container xs={10}>
                            <Grid item xs={5} style={{fontSize:17,fontWeight:'bold',color:'black'}}>{member.name?member.name:'이름'}</Grid>
                            <Grid item xs={5} style={{marginLeft:5,paddingTop:3,fontSize:15,color:'gray'}}>{userLevel? '('+userLevel+')': null}</Grid>
                        </Grid>
                        <Grid item xs={7} style={{fontSize:14,fontWeight:'bold',color:'gray'}}>
                            {member.mc_position?member.mc_position:'직책'} | {member.part?member.part:'부서'}</Grid>
                        <Grid item xs={7} style={{fontSize:14,color:'gray'}}>{member.company?member.company:'소속'}</Grid>
                    </Grid>

                    {/*연결상태 버튼*/}
                    <Grid item xs={4.5} style={{textAlign: 'center',alignItems: 'center', justifyContent: 'center',padding:30}}>
                        {state_button}
                    </Grid>

                </Grid>
            </div>

            {/*<div>*/}
            {/*    <Grid container style={{padding: 15}}>*/}
            {/*        <Grid item xs={2} style={{textAlign: 'left'}}>이름</Grid>*/}
            {/*        <Grid item xs={10} style={{textAlign: 'left', paddingLeft: '15%', position: 'relative'}}>*/}
            {/*            {member.name}*/}
            {/*            <img*/}
            {/*                src={member.profile ? 'https://sbc.smartbc.kr/' + member.profile : require('../resources/profile_icon.png')}*/}
            {/*                style={{*/}
            {/*                    width: 35,*/}
            {/*                    height : 35,*/}
            {/*                    position: 'absolute',*/}
            {/*                    right: 12,*/}
            {/*                    top: '50%',*/}
            {/*                    transform: 'translateY(-50%)',*/}
            {/*                    borderRadius: 50*/}
            {/*                }}/>*/}

            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*    <Divider/>*/}
            {/*</div>*/}
            {phone_open}
            {email_open}
            {member.office_phone?
                <InputContainer label={'유선전화'} text={AddHyphen(member.office_phone)} call={member.office_phone}/>
                :null}
            {member.fax?
                <InputContainer label={'팩스번호'} text={AddHyphen(member.fax)}/>
                :null}
            {member.company?
                <InputContainer label={'소속명'} text={member.company}/>
                :null}
            {member.part?
                <InputContainer label={'부서/파트'} text={member.part}/>
                :null}
            {member.mc_position?
                <InputContainer label={'직책'} text={member.mc_position}/>
                :null}
            {member.address?
                <div>
                    <Grid container style={{padding: 15}}>
                        <Grid item xs={2} style={{textAlign: 'left'}}>{'주소'}</Grid>
                        <Grid item xs={10} style={{textAlign :'left',paddingLeft :'5%', position: 'relative'}}>
                            <Grid item xs={8}>{member.address}</Grid>
                            {/*<Grid item xs={2}>*/}
                            {/*    <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}*/}
                            {/*                onClick={()=>{*/}
                            {/*                }}>*/}
                            {/*        <ImLocation size={20} style={{color:'#303a53'}}/>*/}
                            {/*    </IconButton>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                :null}

            {/*소개글*/}
            {member.introduce ?
                <>
                    {/*<div style={{ borderRadius: 10, backgroundColor: '#f9fafc', width: '90%', padding: 15, alignSelf: 'center', marginTop: 10, justifyContent:'center', alignItems:'center'}}>*/}
                    {/*    <div style={{fontSize: 15, letterSpacing: 0.5}}>{'소개글'}</div>*/}
                    {/*</div>*/}
                    <div  className={classes.introduceContainer}>
                        <Grid align={'left'} style={{fontSize: 13, letterSpacing: 0.5}}>{member.introduce}</Grid>
                    </div>
                    <Divider/>
                </>
                :null}

            {/*사이트 링크*/}
            {member.homepage||member.blog||member.facebook||
            member.twitter||member.instargram||member.shoppingMall?
                <>
                    <div style={{width: '100%', height: 70, display: 'flex',marginTop:20,marginLeft:20}}>
                        {/* justifyContent: 'center', alignItems: 'center'*/}
                        {member.homepage?
                            <LinkIcon btn={1} url={member.homepage}/>
                            :null}
                        {member.blog?
                            <LinkIcon btn={2} url={member.blog}/>
                            :null}
                        {member.facebook?
                            <LinkIcon btn={3} url={member.facebook}/>
                            :null}
                        {member.twitter?
                            <LinkIcon btn={4} url={member.twitter}/>
                            :null}
                        {member.instargram?
                            <LinkIcon btn={5} url={member.instargram}/>
                            :null}
                        {member.shoppingMall?
                            <LinkIcon btn={6} url={member.shoppingMall}/>
                            :null}
                    </div>
                </>
                :null}
            {/*최근 작성글*/}
            {writeList.length>0?
                <>
                    {/*<div style={{ borderRadius: 10, backgroundColor: '#f9fafc', width: '90%', padding: 15, alignSelf: 'center', marginTop: 10, justifyContent:'center', alignItems:'center'}}>*/}
                    {/*    <div style={{fontSize: 15, letterSpacing: 0.5}}>{'최근 작성글'}</div>*/}
                    {/*</div>*/}
                    <div style={{
                        // borderRadius: 10,
                        // backgroundColor: '#f9fafc',
                        width: '90%',
                        // height: 150,
                        alignSelf: 'center',
                        marginTop: 20,
                        // border: '1px solid rgb(217, 217, 217)',
                        marginBottom:40,
                    }}>
                        {writeList.map((value)=>
                            <WriteList key={value.date} title={value.title} date={value.date} id={value.id} table={value.table}/>
                        )}
                    </div>
                </>
                :null}

            <Divider/>
            {file}

            {member.c1_name?
                <InputContainer label={'회사업종'} text={member.c1_name+' > '+member.c2_name}/>
                :null}
            {member.p1_name?
                <InputContainer label={'직업군'} text={member.p1_name+' > '+member.p2_name}/>
                :null}
            {member.pos_name ?
            <InputContainer label={'직급유형'} text={member.pos_name}/>
                :null}
            {member.a1_name ?
            <InputContainer label={'지역'} text={member.a1_name}/>
                :null}
            {op2List.length>0?
                <InputContainer label={'모임옵션'} text={op2List+''}/>
                :null}
            {/*정보공개 요청 팝업*/}
            {modal ? (<PopUp targetId={member.u_id} />) : null}

            {loading?
                <>
                    <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                    <div className={classes.popUpBg} />
                </>
                :null}
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {

        // padding: '0 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.5,
        // height: '40px',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
        // paddingBottom: 5,
    },
    introduceContainer: {
        borderRadius: 10,
        backgroundColor: '#f9fafc',
        width: '90%',
        padding: 15,
        alignSelf: 'center',
        marginTop: 20,
        // borderColor:'gray',
        // borderWidth:0.2,
        justifyContent:'center',
        alignItems:'center',
        marginBottom:20
    },
}));
export default CardDetail;
