import React from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    TextField,

}
from '@material-ui/core';
import PcHeader from "./PcHeader";
import Grid from "@material-ui/core/Grid";

function InviteCode() {
    const classes = useStyles();
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '30px'}}>
                <CssBaseline/>
                <PcHeader text={'스마트 모임 인증'}/>
                <div className={classes.paper}>
                    <Typography
                        style={{fontSize: 15, backgroundColor: 'rgb(232,232,232)', padding: 10, width: '100%', margin: '30px 0', borderRadius: 50}}>
                        초대받은 모임 인증 받기
                    </Typography>
                    <Typography
                        style={{fontSize: 15, marginBottom: 45, color: 'rgb(112,112,112)', width: 300}}>
                        모임에서 초대를 받으신 경우에만 인증 후 가입이
                        가능합니다.(협회 초대 코드 입력)
                    </Typography>

                    <form className={classes.form} noValidate style={{marginBottom: 20}} >
                        <Grid container>
                            <Grid xs={3} style={{alignSelf :'center'}}>초대코드</Grid>
                            <Grid item xs={9}><TextField  style={{width: '90%'}} placeholder={'초대초드를 입력해주세요!'}/></Grid>
                        </Grid>
                    </form>

                        {/* 버튼 */}
                        <Button
                            type="submit"
                            fullWidth
                            color="primary"
                            style={{backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 10}}
                        >
                            초대확인
                        </Button>
                        <Typography className={classes.inconsistency} style={{display: 'none'}}>
                            협회 초대 코드가 맞지 않습니다.<br/>
                            다시한번 확인해주세요!
                        </Typography>
                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            style={{backgroundColor: '#fff', color: '#303a53',fontWeight:'bold',fontSize :15,borderRadius : 25,padding :10, borderColor: '#303A53', marginBottom: 10}}
                        >
                            모임서비스 개설 신청
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            color="primary"
                            style={{backgroundColor: '#FFD400', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 10}}
                            >
                            카카오톡 1 : 1 문의하기
                        </Button>
                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '0 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        fontSize: 14,
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    inconsistency: {
        fontSize: 13,
        color: 'rgb(239,113,113)',
        fontWeight: 600,
        backgroundColor: 'rgb(249,250,252)',
        padding: 10,
        width: '100%',
        borderRadius: 10,
        marginBottom: 15
    }
}));


export default InviteCode;
