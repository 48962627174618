import React from 'react';
import './App.css';
import  'css/MainHome.css';
import Routes from './routes/Routes';

import {Provider} from 'react-redux';
import rootReducer from './reducers';
import {createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';


const store = createStore(rootReducer, composeWithDevTools());

function App() {
    return (
        <Provider store={store}>
            <Routes/>
        </Provider>
    );
}

export default App;
