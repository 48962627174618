import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Axios from "axios";
import Splash from "../screen/Splash";
import PcLogin from "../screen/PcLogin";
import SignUp from "../screen/SignUp";
import SmartGroup from "../screen/SmartGroup";
import InviteList from "../screen/InviteList";
import JoinAssociation from "../screen/JoinAssociation";
import IcConfirm from "../screen/IcConfirm";
import InviteCode from "../screen/InviteCode";
import MainHome from "../screen/MainHome";
import MyInfo from "../screen/MyInfo";
import PointList from "../screen/PointList";
import MyCardRegist from "../screen/MyCardRegist";
import MyCardDetail from "../screen/MyCardDetail";
import Members from "../screen/Members";
import MembersCardDetail from "../screen/MembersCardDetail";
import MembersDetailSearch from "../screen/MembersDetailSearch";
import LinkMember from "../screen/LinkMember";
import CardDetail from "../screen/CardDetail";
import DetailSearch from "../screen/DetailSearch";
import NoticeBoard from "../screen/NoticeBoard";
import Notice from "../screen/Notice";
import Setting from "../screen/Setting";
import Main from "../screen/Main";
import Main1 from "../screen/Main1";
import NoticeDetail_3 from "../screen/NoticeDetail_3";
import Community from "../screen/Community";
import InstallInfo from "../screen/InstallInfo";
import IntroPage from "../screen/IntroPage";

import AdminLogin from "../screen/admin/AdminLogin";
import BandJoin from "../screen/admin/BandJoin";
import BandJoin2 from "../screen/admin/BandJoin2";
import AdminMain from "../screen/admin/AdminMain";
import AdminPointList from "../screen/admin/AdminPointList";
import AdminJoinUser from "../screen/admin/AdminJoinUser";
import AdminJoinUserDetail from "../screen/admin/AdminJoinUserDetail";
import AdminSearchDetail from "../screen/admin/AdminSearchDetail";
import AdminSearchDetail2 from "../screen/admin/AdminSearchDetail2";
import AdminSetting from "../screen/admin/AdminSetting";
import AdminInfoUpdate from "../screen/admin/AdminInfoUpdate";
import AdminSettingInfo from "../screen/admin/AdminSettingInfo";
import AdminUserLevel from "../screen/admin/AdminUserLevel";
import AdminBoardLevel from "../screen/admin/AdminBoardLevel";
import NoticeBoardWrite from "../screen/NoticeBoardWrite";
import NoticeDetail_2 from "../screen/NoticeDetail_2";
import NoticeDetail_1 from "../screen/NoticeDetail_1";
import NoticeDetail_0 from "../screen/NoticeDetail_0";
import NoticeBoardDetail from "../screen/NoticeBoardDetail";
import NoticeBoardDetail2 from "../screen/NoticeBoardDetail2";
import Advertige from "../screen/Advertige";
import ImgDetail from "../screen/ImgDetail";

import PartnerSignUp from "../screen/PartnerSignUp";
import PartnerMyInfo from "../screen/PartnerMyInfo";
import PartnerPointList from "../screen/PartnerPointList";
import PartnerRefJoinList from "../screen/PartnerRefJoinList";
import PartnerNonApprove from "../screen/PartnerNonApprove";

import CommunitySecond from "../screen/CommunitySecond";
import Company_search from "../screen/Company_search";
import CompanyDetail from "../screen/CompanyDetail";
import MyCardDetailTab from "../screen/MyCardDetailTab";
import MembersCardDetailTab from "../screen/MembersCardDetailTab";
import MembersCompany from "../screen/MembersCompany";
import CompanyDetailTab from "../screen/CompanyDetailTab";

function Routes({history,match}) {

    // sessionStorage.setItem('@b_id',1+'');

    /**
     * pc , mobile check
     */
    // 모바일 여부
    let isMobile = false;

    // PC 환경
    const filter = "win16|win32|win64|mac|macintel";
    const varUA = navigator.userAgent.toLowerCase();
    if (navigator.platform) {
        isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
        if(isMobile) {
            console.log('mobile here');
            console.log(filter);
        }else{
            console.log('web here');
            console.log(navigator.platform);
            let param = document.location.href.split('/'); // 그룹 구분자인 관리자 id 값만 가져옴
            // location.href = 'http://groupf.smartbc.kr/'+param[3];
        }
    }

    // function resizeIFrame(iframeId)
    // {
    //
    //     try
    //     {
    //
    //         const innerBody = document.getElementById(iframeId).contentWindow.document.body;
    //         const innerHeight = innerBody.scrollHeight + (innerBody.offsetHeight - innerBody.clientHeight);
    //
    //         if(document.getElementById(iframeId).style.height != innerHeight)
    //         {
    //             document.getElementById(iframeId).style.height = innerHeight;
    //         }
    //         if(document.all)
    //         {
    //             innerBody.attachEvent('onclick',parent.do_resize);
    //             innerBody.attachEvent('onkeyup',parent.do_resize);
    //         }
    //         else
    //         {
    //             innerBody.addEventListener("click", parent.do_resize, false);
    //             innerBody.addEventListener("keyup", parent.do_resize, false);
    //         }
    //     }
    //     catch (e){
    //         //alert("여긴안돼");
    //     }
    // }
    //
    //
    //
    // window.onload = function () {
    //     parent.resizeIFrame("iframeId");
    // }



    // const BandName = ({}) => {
    //     return(
    //         <Route path={} component={}/>
    //     );
    // }

    return(
        <Router>
            <Switch>
                {/*/!*협회 관리자 가입*!/*/}
                {/*<Route exact path={'/BandJoin'} component={BandJoin2}/>*/}

                {/*메인*/}
                <Route exact path={`/:JoinCode`} component={MainHome} />


                {/*<Route exact path={'/'} component={IntroPage}/>*/}

                <Route path={'/:JoinCode/PcLogin'} component={PcLogin}/>
                `
                {/*간편회원가입*/}
                <Route path={'/:JoinCode/signup'} component={SignUp}/>

                <Route path={'/:JoinCode/JoinAssociation'} component={JoinAssociation}/>

                {/*초대코드 인증화면*/}
                <Route path={'/:JoinCode/InviteCode'} component={InviteCode}/>

                {/*그룹옵션 선택화면*/}
                <Route path={'/:JoinCode/IcConfirm'} component={IcConfirm}/>

                {/*내 명함 등록*/}
                <Route path={'/:JoinCode/MyCardRegist'} component={MyCardRegist}/>

                {/*마이페이지*/}
                <Route path={'/:JoinCode/MyInfo'} component={MyInfo}/>

                {/*내포인트내역*/}
                <Route path={'/:JoinCode/PointList'} component={PointList}/>

                {/*내 명함 수정*/}
                <Route path={'/:JoinCode/MyCardDetail'} component={MyCardDetail}/>

                {/*내 명함 수정 tab*/}
                <Route path={'/:JoinCode/MyCardDetailTab'} component={MyCardDetailTab}/>

                {/*전체회원*/}
                <Route path={'/:JoinCode/Members'} component={Members} />

                {/*전체회원 상세검색*/}
                <Route path={'/:JoinCode/MembersDetailSearch'} component={MembersDetailSearch}/>

                {/*전체회원 명함 상세보기*/}
                <Route path={'/:JoinCode/MembersCardDetail'} component={MembersCardDetail}/>

                {/*전체회원 명함 상세보기 tab*/}
                <Route path={'/:JoinCode/MembersCardDetailTab'} component={MembersCardDetailTab}/>

                {/*명함상세 최근 작성글 상세보기*/}
                <Route path={'/:JoinCode/NoticeBoardDetail2'} component={NoticeBoardDetail2}/>

                {/*연결된 회원*/}
                <Route path={'/:JoinCode/LinkMember'} component={LinkMember} />

                {/*회원사 검색*/}
                <Route path={'/:JoinCode/MembersCompany'} component={MembersCompany} />

                {/*회원사 검색 상세 tab*/}
                <Route path={'/:JoinCode/CompanyDetailTab'} component={CompanyDetailTab} />

                {/*명함 상세보기*/}
                <Route path={'/:JoinCode/CardDetail'} component={CardDetail}/>

                {/*상세검색*/}
                <Route path={'/:JoinCode/DetailSearch'} component={DetailSearch}/>

                {/*게시판 3,4,5*/}
                <Route path={'/:JoinCode/NoticeBoard'} component={NoticeBoard}/>

                {/*게시판 3,4,5 상세보기*/}
                <Route path={'/:JoinCode/NoticeBoardDetail'} component={NoticeBoardDetail}/>

                {/*게시판 이미지 상세보기*/}
                <Route path={'/:JoinCode/ImgDetail'} component={ImgDetail}/>

                {/*게시판 3,4,5 글쓰기*/}
                <Route path={'/:JoinCode/NoticeBoardWrite'} component={NoticeBoardWrite} />

                {/*기업검색*/}
                <Route path={'/:JoinCode/Company_search'} component={Company_search} />

                {/*기업상세*/}
                <Route path={'/:JoinCode/CompanyDetail'} component={CompanyDetail} />

                {/*복지,커뮤니티*/}
                <Route path={'/:JoinCode/Community'} component={Community}/>

                {/*부가서비스(복지,커뮤니티) 하위메뉴 */}
                <Route path={'/:JoinCode/CommunitySecond'} component={CommunitySecond}/>

                {/*제휴 & 동영상 광고 문의*/}
                <Route path={'/:JoinCode/Advertige'} component={Advertige}/>

                {/*알림*/}
                <Route path={'/:JoinCode/Notice'} component={Notice}/>

                {/*광고 상세*/}
                <Route path={'/:JoinCode/NoticeDetail_0'} component={NoticeDetail_0}/>
                {/*제휴 상세*/}
                <Route path={'/:JoinCode/NoticeDetail_1'} component={NoticeDetail_1}/>
                {/*연결요청 상세*/}
                <Route path={'/:JoinCode/NoticeDetail_2'} component={NoticeDetail_2}/>
                {/*공개요청 상세*/}
                <Route path={'/:JoinCode/NoticeDetail_3'} component={NoticeDetail_3}/>

                <Route path={'/:JoinCode/Setting'} component={Setting}/>

                {/*파트너 신청 & 로그인*/}
                <Route path={'/:JoinCode/PartnerSignUp'} component={PartnerSignUp}/>

                {/*파트너 미 승인*/}
                <Route path={'/:JoinCode/PartnerNonApprove'} component={PartnerNonApprove}/>

                {/*파트너 메인*/}
                <Route path={'/:JoinCode/PartnerMyInfo'} component={PartnerMyInfo}/>

                {/*파트너 포인트 내역*/}
                <Route path={'/:JoinCode/PartnerPointList'} component={PartnerPointList}/>

                {/*파트너 추천 가입입 내역*/}
               <Route path={'/:JoinCode/PartnerRefJoinList'} component={PartnerRefJoinList}/>

                {/*관리자 로그인화면*/}
                <Route path={'/:JoinCode/AdminLogin'} component={AdminLogin}/>

                {/*협회 관리자 가입*/}
                <Route path={'/:JoinCode/BandJoin'} component={BandJoin}/>

                {/*협회 관리자 가입*/}
                <Route path={'/:JoinCode/BandJoin2'} component={BandJoin2}/>

                {/*관리자 협회 메인*/}
                <Route path={'/:JoinCode/AdminMain'} component={AdminMain}/>

                {/*관리자 가입회원*/}
                <Route path={'/:JoinCode/AdminJoinUser'} component={AdminJoinUser}/>

                {/*관리자 가입회원 상세*/}
                <Route path={'/:JoinCode/AdminJoinUserDetail'} component={AdminJoinUserDetail}/>

                {/*관리자 가입회원 상세검색*/}
                <Route path={'/:JoinCode/AdminSearchDetail'} component={AdminSearchDetail}/>

                {/*관리자 누적포인트내역*/}
                <Route path={'/:JoinCode/AdminPointList'} component={AdminPointList}/>

                {/*관리자 설정*/}
                <Route path={'/:JoinCode/AdminSetting'} component={AdminSetting}/>

                {/*관리자 회원 등급 변경*/}
                <Route path={'/:JoinCode/AdminUserLevel'} component={AdminUserLevel}/>

                {/*관리자 회원 등급 변경 상세검색*/}
                <Route path={'/:JoinCode/AdminSearchDetail2'} component={AdminSearchDetail2}/>

                {/*관리자 게시판 사용 권한 설정*/}
                <Route path={'/:JoinCode/AdminBoardLevel'} component={AdminBoardLevel}/>

                {/*관리자 회원 정보 수정*/}
                <Route path={'/:JoinCode/AdminInfoUpdate'} component={AdminInfoUpdate}/>

                {/*관리자 협회 설정 정보*/}
                <Route path={'/:JoinCode/AdminSettingInfo'} component={AdminSettingInfo}/>


            </Switch>
        </Router>
    );
}

export default Routes;
