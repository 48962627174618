import React, {useCallback, useEffect, useRef} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
    InputBase,
    IconButton,
    Checkbox,
    FormControlLabel,
    FormControl,
    Select,
    MenuItem,
    CircularProgress,
}
    from '@material-ui/core';
import PcHeader from "../PcHeader";
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import Axios from "axios";
import Moment from 'moment';
import customAlert from "../../component/customAlert";

function AdminJoinUser({history,match}) {

    // console.log('holly',history);

    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const [member_list,setMember_list] = React.useState([]);

    // const detail =history.location.state.detail;
    // if(history.location.state.member_list){
    //     const searchMemberList = history.location.state.member_list;
    // }
    // const searchMemberList = history.location.state.member_list;

    // const detail ='';
    // const searchMemberList =[];
    const [loading, setLoading] = React.useState(true);
    const [refresh, setRefresh] = React.useState(false);// 새로고침

    let list = {};
    const [text,setText]=React.useState('');
    const [sub,setSub] = React.useState('');

    let [selectedList,setSelectedList]= React.useState([]);
    // let [selectedList,setSelectedList]= React.useState(new Set());
    // let selectedList = React.useRef([]);
    // let selectedList=[];

    const [preItems,setPreItems]=React.useState(0);
    const [items,setItems]=React.useState([]);

    const _infiniteScroll = React.useCallback(()=>{
        // console.log('무한스크롤높이-------------');
        let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
        let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
        let clientHeight = document.documentElement.clientHeight;
        if(scrollTop+clientHeight >= scrollHeight -100){
            setPreItems(preItems+10);
            setMember_list(JSON.parse(JSON.stringify(member_list)).concat(items.slice(preItems+10,preItems+20)));
        }
    },[preItems,member_list,items]);

    useEffect(() => {
        console.log('무한스크롤----------------');
        window.addEventListener('scroll', _infiniteScroll, true);
        return () => window.removeEventListener('scroll', _infiniteScroll, true);
    }, [_infiniteScroll]);

    useEffect(()=>{
        // console.log('매개변수',history.location.state);
        if(!history.location.state.searchList){
            Axios({
                url: 'https://sbc.smartbc.kr/sb/band_admin/admin_join_user_info.php',
                method: 'get',
                params: {
                    u_id: u_id,
                    b_id: b_id,
                },
            })
                .then((result) => {
                    console.log('통신이',result.data);
                    // setMember_list(result.data.list);

                    setMember_list(result.data.list.slice(0,10));
                    setItems(result.data.list);

                    setLoading(false);

                })
                .catch((err) => console.log(err.message));

        } else {
            // setMember_list(history.location.state.searchList);

            setMember_list(history.location.state.searchList.slice(0,10));
            setItems(history.location.state.searchList);

            setLoading(false);
        }
    },[history.location.state.searchList,refresh]);

    const MemberList = ({index})=> {
        console.log('리스트 다시!!!!')
        const [op2,setOp2] = React.useState();
        // console.log('이거',Object.values(JSON.parse(member_list[index].join_option2_val))); // value 값 추출.
        if(member_list[index].join_option2_val != null) {
            Axios({
                url : 'https://sbc.smartbc.kr/sb/get_option2_name.php',
                method :'get',
                params : {
                    op2_val:Object.values(JSON.parse(member_list[index].join_option2_val))+""
                }
            })
                .then(function (res) {
                    // res.data.map((value) => {
                    //     op2_name.push(value.name);
                    // });
                    // console.log(op2_name[key]);
                    // op2_name = res.data.join();
                    // console.log(op2_name);
                    setOp2(res.data.join());
                    // setOp2(null);
                    //   console.log('여기',res.data.join());
                }).catch(function (e) {
                console.log('fail');
            },[member_list[index].join_option2_val]);
        }

        return (
            <div className={classes.paper}>
                <Grid  container spacing={2}
                       onClick={()=>{
                           let join_option = null;
                           if (member_list[index].join_option2_val) {
                               join_option = Object.values(JSON.parse(member_list[index].join_option2_val));
                           }
                           //회원 상세
                           history.push({
                               pathname :  '/'+match.params.JoinCode+'/AdminJoinUserDetail',
                               state : {
                                   sel_u_id: member_list[index].u_id,
                                   join_option: join_option,
                               }
                           });

                       }}>
                    <Grid container alignItems='center'direction='row' style={{padding: 15, borderRadius: 10, boxShadow: '5px 5px 10px rgba(0,0,0,0.1)', position: 'relative', marginBottom: 35}}>
                        <Grid xs={1} style={{marginRight: 3}} className={'managerCheckbox'}>
                            <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                    <Checkbox
                                        icon={<CheckBoxIcon fontSize="inherit"/>}
                                        checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                        name="checked"
                                        id={member_list[index].u_id}
                                        onChange={(event)=>{
                                            // let tmp = selectedList.slice();
                                            // console.log('체크하기 전 tmp',tmp);
                                            if(event.target.checked){
                                                if(selectedList.indexOf(event.target.id)==-1){
                                                    selectedList.push(event.target.id);
                                                    // selectedList.add(event.target.id); //set
                                                    // setSelectedList(selectedList); //set
                                                    console.log('추가');
                                                    // tmp.push(event.target.id);
                                                    // setSelectedList(tmp);
                                                }
                                            }else{
                                                if(selectedList.indexOf(event.target.id)!=-1){
                                                //     selectedList.delete(event.target.id); //set
                                                //     setSelectedList(selectedList); //set

                                                    let idx =  selectedList.indexOf(event.target.id);
                                                    selectedList.splice(selectedList.indexOf(event.target.id),1);
                                                    console.log('삭제',selectedList.indexOf(event.target.id));


                                                    // selectedList.slice(idx,1);
                                                    // const idx =  tmp.indexOf(event.target.id);
                                                    // tmp.slice(idx,1);

                                                    // setSelectedList(tmp);
                                                }

                                            }
                                            // console.log('임시 배열',tmp);
                                            console.log('본 배열',selectedList);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid xs={2} style={{marginRight: 3}}>
                            <div style={{ borderRadius: '50%', overflow: 'hidden', width: 45, height: 45, position: 'relative'}}>
                            {member_list[index].u_profile?
                                <img alt="사진" src={"https://sbc.smartbc.kr" + member_list[index].u_profile} style={{width: 50, height: 50, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}}/>
                            :
                                <img alt="사진" src={require('../../resources/profile_icon.png')} style={{width: 50, height: 50, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}}/>
                            }
                            </div>
                        </Grid>
                        <Grid xs={6} style={{maringRight: 5}}>
                            <Grid item xs container direction="column">
                                <Grid item>
                                    <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                        {member_list[index].u_name?member_list[index].u_name:'이름'}<span style={{fontSize: 11, color: 'rgb(181,181,181)', marginLeft: 5}}>
                                        {member_list[index].mc_position?member_list[index].mc_position:'직책'} </span>
                                    </Typography>
                                    <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                        {member_list[index].mc_company?member_list[index].mc_company:'소속'}
                                    </Typography>
                                    <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                        {/*rgb(43,66,124) 파란색*/}
                                        <span>{op2?op2:'모임 옵션'}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                {Moment(member_list[index].uba_regist_date).format('YYYY-MM-DD')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

        );
    }



    // const MemberListUM = React.useMemo(()=> member_list.map((value, i) =>
    //     <MemberList key={i} index ={i}/>),[member_list]);

    // const MemberCheckList = React.useMemo(),[]);


    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'가입 회원 ('+items.length+')'} back={1} adminback={1} history={history} match={match}/>
                {/* 상단 고정*/}
                <div style={{padding: '20px 25px 15px 20px', marginTop: 50, boxShadow:  '0 5px 10px rgba(0,0,0,0.15)', position: 'sticky', top: 55, backgroundColor: '#fff', zIndex: 10}}>
                    <Grid container style={{marginBottom: 15}}>
                        <Grid xs={6} style={{borderRadius: '40px'}}>
                            <Button
                                type="submit"
                                color="primary"
                                style={{width: '95%', backgroundColor: 'rgb(43,66,124)', color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 40, padding: 10}}
                                onClick={()=>{
                                    console.log('삭제할 리스트',selectedList);
                                    // return;

                                    if(selectedList.length==0){
                                        customAlert('선택된 회원이 없습니다.');
                                        return;
                                    }
                                    const list = JSON.stringify(selectedList);
                                    Axios({
                                        method: 'get',
                                        url: 'https://sbc.smartbc.kr/sb/band_admin/admin_user_withdraw.php',
                                        params: {
                                            b_id: b_id,
                                            list: list,
                                        }
                                    })
                                        .then((result) => {
                                            console.log(result.data);
                                                setRefresh(true);

                                            customAlert(result.data.result_msg);
                                            // navigation.navigate('AdminJoinUser');

                                        })
                                        .catch((err) => console.log(err.message));
                                }}
                            >
                                선택회원 탈퇴처리
                            </Button>
                        </Grid>
                        <Grid xs={6} style={{borderRadius: '40px'}}>
                            <Button
                                type="submit"
                                color="primary"
                                style={{width: '95%', border: '1px solid rgb(43,66,124)', color: 'rgb(43,66,124)',fontWeight:'bold',fontSize :15,borderRadius : 40, padding: 10}}
                                onClick={()=>{
                                    Axios({
                                        url: 'https://sbc.smartbc.kr/sb/excelexport.php',
                                        method: 'get',
                                        params: {
                                            b_id: b_id
                                        }
                                    }).then(function (response) {
                                        console.log('엑셀파일',response.data);
                                        window.location.href= 'https://sbc.smartbc.kr/sb/download_file.php?file=/sb/resources/excel/'+response.data;

                                    }).catch(function (error) {
                                        console.log('error'+error);
                                    });
                                }}>
                                엑셀 내보내기
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid xs={3} className='serchSelect'>
                            <FormControl variant="outlined"  color='primary' style={{width: '100%', textAlign: 'left', borderRadius: '40px', marginRight: 3}}>
                                <Select
                                    defaultValue=""
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    id="grouped-select"
                                    onChange={(event) => {
                                        setSub(event.target.value);
                                        console.log('분류',event.target.value);
                                    }}
                                >
                                    <MenuItem value="">
                                        전체
                                    </MenuItem>
                                    <MenuItem value={'u.name'}>이름</MenuItem>
                                    <MenuItem value={'mc.company'}>회사명</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={7} style={{textAlign: 'left', borderRadius: '40px', position: 'relative', border: '1px solid #B9BFC3'}}>
                            <IconButton edge="center" className={classes.searchIcon}
                            onClick={()=>{
                                list['sub'] = sub;
                                list['text'] = text;
                                console.log('ddddd',list);
                                let data = JSON.stringify(list);
                                //수정하기
                                Axios({
                                    url: 'https://sbc.smartbc.kr/sb/band_admin/join_user_detail_search.php',
                                    method: 'get',
                                    params: {
                                        data : data,
                                        b_id: b_id
                                    }
                                }).then(function (response) {
                                    console.log(response.data);
                                        history.push({
                                            pathname : '/'+match.params.JoinCode+'/AdminJoinUser',
                                            state : {searchList: response.data.member_list}
                                        });

                                    // customAlert(response.data.result_msg);
                                }).catch(function (error) {
                                    console.log('error'+error);
                                });
                            }}>
                                <SearchIcon style={{fontSize: 30}}/>
                            </IconButton>
                            <InputBase
                                color='primary'
                                placeholder='검색어를 입력하세요.'
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(event)=>{
                                    setText(event.target.value);
                                    console.log('분류',event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid xs={2} style={{borderRadius: '40px'}}>
                            <Button
                                type="submit"
                                color="primary"
                                style={{backgroundColor: 'rgb(43,66,124)', color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 40, padding: '13px 8px'}}
                                onClick={()=>{
                                    history.push( '/'+match.params.JoinCode+'/AdminSearchDetail');
                                }}
                            >
                                상세
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                {/*<div style={{padding: '30px 40px 20px', position: 'sticky', top: 205, backgroundColor: '#fff', zIndex: 9}}>*/}
                {/*    <Typography align='left' style={{backgroundColor: 'rgb(235,235,235)', padding: 10, textIndent: 10, fontSize: 13, borderRadius: 10, fontWeight: 500}}>*/}
                {/*        전체 가입 회원 - <span style={{color: 'rgb(43,66,124)'}}>{member_list.length}</span> 명</Typography>*/}
                {/*</div>*/}

                {/* 회원 리스트 */}
                {member_list.map(((value,i) => <MemberList key={i} index ={i}/>))}
                {/*{MemberListUM}*/}

                {loading?
                    <>
                        <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                        <div className={classes.popUpBg} />
                    </>
                    :null}
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    searchIcon: {
        zIndex: 10,
        position: 'absolute',
        right: -3, top: '50%',
        transform: 'translateY(-50%)',
        color: 'rgb(43,66,124)',
    },
    inputRoot: {
        color: 'inherit',
        width: '85%'
    },
    inputInput: {
        margin: 3,
        padding: '13px 5px 13px 10px'
    },
    '@global':{
        '.serchSelect .MuiInputBase-root':{
            borderRadius: 40,
        },
        '.MuiSelect-select:focus': {
            borderRadius: 40
        }
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },

}));


export default AdminJoinUser;
