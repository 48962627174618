import React, {useEffect, useState, useCallback, useRef} from "react";
import MembersCardDetail from "./MembersCardDetail";
import CompanyDetail from "./CompanyDetail";
import {useDispatch, useSelector} from 'react-redux';
import {setmember_cnt} from '../reducers/session';
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Tab,
    Tabs,
    Divider,
    Grid, Box,
    InputBase, IconButton,
} from '@material-ui/core';
import PropTypes from "prop-types";
import PcHeader from "./PcHeader";
import SwipeableViews from 'react-swipeable-views';




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function MembersCardDetailTab({history,match}) {
    console.log('MembersCardDetailTab',history,match);

    const classes = useStyles();

    const [value, setValue] = React.useState(history.location.state.index ? history.location.state.index : 0);
    // //const searchList = this.props.location.state.searchList
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const tabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <PcHeader text={'명함 상세 보기'} back={1} history={history} match={match}/>
                {/* 상단 고정*/}
                <div style={{
                    padding: '20px 0 10px',
                    marginTop: 40,
                    position: 'sticky',
                    top: 55,
                    backgroundColor: '#fff',
                    zIndex: 10
                }} className={'MembersCardDetailTab'}>
                    <div>
                        <Tabs
                            value={value}
                            onChange={tabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{width: '100%', marginBottom: 0}}
                            classes={{ indicator: classes.indicator }}
                            variant="fullWidth"
                            aria-label="simple tabs example"
                            id={'tab'}
                        >
                            <Tab label="회원 정보" {...a11yProps(0)} />
                            <Tab label="기업 정보" {...a11yProps(1)} />
                        </Tabs>
                    </div>
                    <SwipeableViews enableMouseEvents
                        axis={'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value}  index={0}>
                            <MembersCardDetail history={history} match={match}/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <CompanyDetail history={history} match={match}/>
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </Container>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    indicator:{
        backgroundColor: '#303a53'
    },
    '@global': {
        '.MuiBox-root': {
            padding: 5,
            paddingLeft:0,
            paddingRight:0
        }
    },

}));





