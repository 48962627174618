import React, {useEffect, useState, useCallback, useRef} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Paper,
    FormControl,
    MenuItem,
    Select,
    Avatar,
    Grid, Chip,
    InputBase, TextareaAutosize, IconButton, Box, FormControlLabel, Checkbox, CircularProgress,
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import SearchIcon from '@material-ui/icons/Search';
import Axios from "axios";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import {useDispatch} from "react-redux";
import {setmember_cnt1} from "../reducers/session";
import customAlert from "../component/customAlert";

function Company_search({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    // 간편 검색
    const [address,setAddress]=React.useState('');//주소 검색
    const [name,setName]=React.useState('');//대표자명 검색
    const [text,setText] = React.useState('');
    const [sub,setSub] = React.useState('ec.company_name');
    // const [sort,setSort] = React.useState('');
    // 전체회원 리스트
    const [list,setList] = React.useState(new Array());
    //간편 검색
    const [search, setSearch] = React.useState(false);

    const [refresh, setRefresh] = React.useState(false);

    const [preItems,setPreItems]=React.useState(0);
    const [items,setItems]=React.useState([]);

    const [loading, setLoading] = React.useState(false);


    //검색창
    const Inputtext = () => {
        const [content, setContent] = React.useState('');
        // setText(content);
        return (
            <>
                {/*<IconButton edge="center" className={classes.searchIcon} onClick={() => {*/}
                {/*    setText(content);*/}
                {/*    setSearch(!search);*/}
                {/*}}>*/}
                {/*    <SearchIcon style={{fontSize: 30, color: 'rgb(48,58,83)'}}/>*/}
                {/*</IconButton>*/}
                <InputBase
                    color='primary'
                    placeholder='텍스트 입력'
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    value={content}
                    inputProps={{'aria-label': 'search'}}
                    onChange={(val) => {
                        if(sub==='ec.business_regist_num'||sub==='ec.corporate_regist_num'){
                            const newValue = val.target.value.replace(/[^0-9]/g, '');
                            if(val.target.value != newValue){
                                customAlert('숫자만 입력 가능합니다.');
                            }
                            setContent(newValue);
                            // setText(content);
                        }else{
                            setContent(val.target.value);
                            // setText(content);
                        }
                    }}
                />
            </>
        )
    }

    // const index = useRef(0);
    //useCallback 상태값 변하는 것들 []안에 포함시키기
    const _infiniteScroll = useCallback(()=>{
        // console.log('무한스크롤높이');
        let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
        let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
        let clientHeight = document.documentElement.clientHeight;
        if(scrollTop+clientHeight >= scrollHeight -100){
            // console.log('스크롤높이',scrollHeight);
            // console.log('스크롤탑',scrollTop);
            // console.log('클라이언트높이',clientHeight);

            // console.log('----------높이 같을 때----------');
            // console.log('preItems',preItems);
            // console.log('asdf',items)
            // console.log(JSON.parse(JSON.stringify(list)).concat(items.slice(preItems+10,preItems+20)));
            // console.log('--------------------');
            setPreItems(preItems+10);
            setList(JSON.parse(JSON.stringify(list)).concat(items.slice(preItems+10,preItems+20)));

        }
    },[preItems,list,items]);

    useEffect(() => {
        // console.log('무한스크롤');
        window.addEventListener('scroll', _infiniteScroll, true);
        return () => window.removeEventListener('scroll', _infiniteScroll, true);
    }, [_infiniteScroll]);

    useEffect(() => {

    },[search,refresh]);

    const MemberList = ({val,index})=> {
        // const [userLevel,setUserLevel] = React.useState('일반회원');

        return (
            <div className={classes.userContainer}>
                <div  style={{marginBottom: 5}}>
                    <div  style={{cursor: 'pointer',flexDirection: 'column',padding: '15px 20px', borderRadius: 10, boxShadow: '2px 2px 10px rgba(0,0,0,0.2)', position: 'relative', marginBottom: 0}}
                          onClick={() => {
                              console.log('기업상세보기 : ',val.id);
                              history.push({
                                  pathname: '/' + match.params.JoinCode + '/CompanyDetail',
                                  state: {
                                      ecredible_id: val.id, //ecredible_id
                                  }
                              });

                          }}
                    >
                        <div style={{flexDirection: 'row',marginBottom:8,display:'flex'}} >
                                    { val.company_size ?
                                        <div style={{alignItems: 'center',borderStyle:'solid',borderColor:'black',borderWidth:1, borderRadius: 50,marginRight:5,display:"inline-block"}}>
                                            <Typography style={{padding:2,paddingRight:10,paddingLeft:10,fontSize: 12, color: 'black', fontWeight: 'bold'}}>{val.company_size}</Typography>
                                        </div>
                                        : null
                                    }
                                    { val.company_classification ?
                                        <div style={{alignItems: 'center',borderStyle:'solid',borderColor:'black',borderWidth:1, borderRadius: 50,marginRight:5,display:"inline-block"}}>
                                            <Typography style={{padding:2,paddingRight:10,paddingLeft:10,fontSize: 12, color: 'black', fontWeight: 'bold'}}>{val.company_classification}</Typography>
                                        </div>
                                        : null
                                    }
                                    { val.company_closing =='O' ?
                                        <div style={{alignItems: 'center',backgroundColor:'red',borderStyle:'solid',borderColor:'red',borderWidth:1, borderRadius: 50,display:"inline-block"}}>
                                            <Typography style={{padding:2,paddingRight:10,paddingLeft:10,fontSize: 12, color: 'white', fontWeight: 'bold'}}>{val.AI_ranking}</Typography>
                                        </div>
                                        : null
                                    }

                        </div>
                        <div style={{marginTop:5,display:"block"}}>
                            <div style={{width:'100%'}}>
                                <Typography style={{fontWeight: 'bold', fontSize: 15,padding: 5,paddingLeft:0,textAlign:'initial'}}>{val.company_name}</Typography>
                            </div>
                        </div>
                        <div style={{marginBottom:5,display:"block"}}>
                            <div style={{width:'100%'}}>
                                <Typography style={{color: '#b5b5b5',fontSize: 13,textAlign:'initial'}} >{val.address ? val.address : null}</Typography>
                            </div>
                        </div>
                        <div style={{marginBottom:5,display:"block"}}>
                            <div style={{width:'100%'}}>
                                <Typography style={{fontWeight:'bold',fontSize: 12,textAlign:'initial'}}>{val.representative_name ? val.representative_name +' | ':null}
                                    {val.standard_industry_classification ? val.standard_industry_classification:null}</Typography>
                            </div>
                        </div>
                        <div style={{borderBottomStyle:'solid',borderBottomColor:'#b5b5b5',borderWidth:0.3,marginTop:5,marginBottom:5,display:"block"}}></div>
                        <div style={{display:"block"}}>
                            <div style={{width:'100%'}}>
                                <Typography style={{color: '#b5b5b5',fontSize: 13,textAlign:'initial'}}>업데이트 날짜 : {val.update_dt ? val.update_dt.substr(0,10): val.create_dt.substr(0,10)}</Typography>
                            </div>
                        </div>



                    </div>

                </div>
            </div>

        );
    }

    // const MemberListUM = React.useMemo(()=> list.map((value, i) =>
    //     <MemberList key={i} val ={value} index={i}/>),[list]);

    return (
        <div style={{padding: 0,top:10}}>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px',alignSelf: 'center',}}>
                <CssBaseline/>
                <PcHeader text={'기업 정보 검색'} back={1} history={history} match={match}/>
                {/* 상단 고정*/}
                <div style={{padding: '20px 25px 15px 20px',marginTop: 50, boxShadow:  '0 5px 10px rgba(0,0,0,0.15)', position: 'sticky', top: 55, backgroundColor: '#fff', zIndex: 10}} className={'allUser'}>

                        <FormControl variant="outlined" color='primary' style={{
                            width: '30%',
                            textAlign: 'left',
                            borderRadius: '40px',
                            marginRight: 3,
                            marginLeft: -10
                        }}>
                            <Select
                                defaultValue="ec.company_name"
                                displayEmpty
                                inputProps={{'aria-label': 'Without label'}}
                                id="grouped-select"
                                onChange={(val) => {
                                    setSub(val.target.value);
                                }}
                            >
                                <MenuItem value={'ec.company_name'}>기업명</MenuItem>
                                <MenuItem value={'ec.business_regist_num'}>사업자등록번호</MenuItem>
                                <MenuItem value={'ec.corporate_regist_num'}>법인등록번호</MenuItem>
                                <MenuItem value={'ec.standard_industry_classification'}>표준산업분류명</MenuItem>
                            </Select>
                        </FormControl>
                    <div style={{width: '70%', height: 45, textAlign: 'left', borderRadius: '40px', position: 'relative', border: '1px solid #B9BFC3', display: 'inline-block', marginRight: 0}}>
                        <>
                            <InputBase
                                color='primary'
                                placeholder='텍스트 입력'
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={text}
                                inputProps={{'aria-label': 'search'}}
                                onChange={(val) => {
                                    if(sub==='ec.business_regist_num'||sub==='ec.corporate_regist_num'){
                                        const newValue = val.target.value.replace(/[^0-9]/g, '');
                                        if(val.target.value != newValue){
                                            customAlert('숫자만 입력 가능합니다.');
                                        }
                                        setText(newValue);
                                    }else{
                                        setText(val.target.value);
                                    }
                                    // console.log(text);
                                }}
                            />
                        </>
                    </div>
                    <div style={{width: '100%',marginTop:5}}>
                    <div style={{width: '30%', height: 45, textAlign: 'left', borderRadius: '40px', position: 'relative', border: '1px solid #B9BFC3', display: 'inline-block', marginRight: 3,marginLeft: -10}}>
                        <>
                            <InputBase
                                color='primary'
                                placeholder='대표자명 입력'
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={name}
                                inputProps={{'aria-label': 'search'}}
                                onChange={(val) => {
                                    setName(val.target.value);
                                }}
                            />
                        </>
                    </div>
                    <div style={{width: '70%', height: 45, textAlign: 'left', borderRadius: '40px', position: 'relative', border: '1px solid #B9BFC3', display: 'inline-block', marginRight: 0}}>
                        <>

                            <IconButton className={classes.searchIcon} onClick={() => {
                                setLoading(true);
                                setSearch(true);
                                Axios({
                                    url: "https://sbc.smartbc.kr/sb/company_search.php", //리뉴얼
                                    method: 'get',
                                    params: {
                                        u_id : u_id,
                                        b_id : b_id,
                                        search_txt:text,
                                        search_sub:sub,
                                        search_name:name,
                                        search_address:address,
                                    }
                                })
                                    .then((result) => {
                                        console.log('검색',result.data);
                                        if (result.data.status == 12001) {

                                            setList(result.data.list.slice(0,10));
                                            setItems(result.data.list);
                                            setLoading(false);
                                            return;

                                        } else {
                                            customAlert(result.data.result_msg);
                                        }

                                    })
                                    .catch((err) => console.log(err.message));
                            }}>
                                <SearchIcon style={{fontSize: 30, color: 'rgb(48,58,83)'}}/>
                            </IconButton>
                            <InputBase
                                color='primary'
                                placeholder='시/군/구/동 입력'
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={address}
                                inputProps={{'aria-label': 'search'}}
                                onChange={(val) => {
                                    setAddress(val.target.value);
                                }}
                            />
                        </>
                    </div>
                    </div>
                </div>
                {/*<div style={{padding: '30px 40px 20px',marginTop: 0, position: 'sticky', top: 130, backgroundColor: '#fff', zIndex: 9}}>*/}
                {/*    <Typography align='left' style={{backgroundColor: 'rgb(235,235,235)', padding: 10, textIndent: 10, fontSize: 13, borderRadius: 10, fontWeight: 500}}>*/}
                {/*        현재 회원 - <span style={{color: 'rgb(239,113,113)'}}>{items.length}</span> 명</Typography>*/}
                {/*</div>*/}

                {/* 회원 리스트 */}

                {(!search) ?
                    <div style={{
                        borderColor: '#f8f8f8',
                        borderRadius: 10,
                        alignSelf: 'center',
                        display: 'inline-block',
                        textAlign:'initial',
                        backgroundColor: '#f8f8f8',
                        width: '90%',
                        padding: 20,
                        marginTop:10,
                        }}>
                        <div style={{fontSize: 14, fontWeight: 'bold'}}>기업 신용평가 기업인 ㈜이크레더블로 부터 제공 받은 기업개요, 재무현황, 손익계산서 열람을
                            지원합니다.</div>
                    </div>
                    :
                    list.map(((value,i) => <MemberList key={i} val ={value} index={i}/>))

                }
                {/*{MemberListUM}*/}

                {loading?
                    <>
                        <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                        <div className={classes.popUpBg} />
                    </>
                    :null}

            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 0px',
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    searchIcon: {
        zIndex: 10,
        position: 'absolute',
        right: -3, top: '50%',
        transform: 'translateY(-50%)'
    },
    inputRoot: {
        color: 'inherit',
        width: '75%',
    },
    inputInput: {
        margin: 3,
        padding: '10px 0px 13px 10px'
    },
    '@global':{
        '.text-input .MuiInputBase-input': {
            height: 'auto !important',
            fontSize: 12,
            lineHeight: 1.5
        },
        '.allUser .MuiInputBase-formControl':{
            height: 45
        },
        // '.allUser .makeStyles-inputInput-5':{
        //     margin: 0
        // },
        '.MuiBox-root': {
            padding: 5
        }
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.5,
        height: '40px',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        padding: 5,
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginLeft: 26
    },
    userContainer: {
        borderColor: '#fff',
        borderRadius: 10,
        alignSelf: 'center',
        backgroundColor: '#fff',
        width: '95%',
        padding: 15,
        paddingBottom: 5,
        shadowColor: '#000',
        shadowOffset: {
            width: 1,
            height: 10,
        },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        elevation: 10,
        display: 'inline-block',
    },


}));


export default Company_search;
