import React,{useEffect} from "react";
import {
    Divider,
    Grid,
    Container, CssBaseline, makeStyles,
    Box, TextField, Typography, Button, InputBase
} from '@material-ui/core';
import PcHeader from "../PcHeader";
import Axios from "axios";
import Badge from "@material-ui/core/Badge";
import customAlert from "../../component/customAlert";

function InputContainer({label}) {
    return (
        <div style={{width: '100%',}}>
            <Grid alignItems='center'  container style={{padding: 15}}>
                <Grid item xs={4} style={{textAlign: 'left'}}>{label}</Grid>
                <Grid item xs={8} style={{textAlign :'left'}}>
                    <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}} defaultValue="aaaaaaa"/>
                </Grid>
            </Grid>
            <Divider/>
        </div>
    );
}

function AdminInfoUpdate({history,match}) {

    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const [business_num, setBusiness_num] = React.useState('');
    const [company_name, setCompany_name] = React.useState('');
    const [owner_name, setOwner_name] = React.useState('');

    const [password, setPassword] = React.useState('');
    const [password2, setPassword2] = React.useState('');

    const [incharge, setIncharge] = React.useState('');
    const [incharge_phone, setIncharge_phone] = React.useState('');
    const [incharge_email, setIncharge_email] = React.useState('');

    // const [refer_pt, setRefer_pt] = React.useState('');

    let tmp_data = {};
    let update_data = {};

    // 이메일 체크 정규식
    function isEmail(asValue) {
        var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        // console.log(regExp.test(asValue));
        return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
    }

    useEffect(()=>{

        Axios({
            method: 'get',
            url: 'https://sbc.smartbc.kr/sb/band_admin/admin_user_info.php',
            params: {
                u_id: u_id,
                b_id:b_id,
            },
        })
            .then((result) => {
                console.log(result.data);
                if (result.data.status == 21001) {

                    setBusiness_num(result.data.business_num);
                    setCompany_name(result.data.company_name);
                    setOwner_name(result.data.owner_name);
                    setPassword(result.data.password);
                    setIncharge(result.data.incharge);
                    setIncharge_phone(result.data.incharge_phone);
                    setIncharge_email(result.data.incharge_email);
                    // Toast.show(result.data.result_msg);
                }
            })
            .catch((err) => console.log(err));

    },[]);

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center'}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'회원 정보 수정'} back={1}  history={history} match={match}/>
            {/*body*/}
            <div className={classes.paper}>
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>모임 등록번호</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>
                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                 readOnly={true}
                                 maxLength={10}
                                 value={business_num}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>

                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>회사명</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>
                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                 placeholder={'회사명을 입력해주세요.'}
                                 value={company_name}
                                 onChange={(event)=>{
                                     setCompany_name(event.target.value);
                                 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>대표자명</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>
                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                 placeholder={'대표자명을 입력해주세요.'}
                                 value={owner_name}
                                 onChange={(event)=>{
                                     setOwner_name(event.target.value);
                                 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>아이디</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>
                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                 readOnly={true}
                                 value={u_id}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>비밀번호</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>

                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                 placeholder={'비밀번호를 입력해주세요.'}
                                 value={password}
                                 type="password"
                                 onChange={(event)=>{
                                     setPassword(event.target.value);
                                 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>비밀번호 확인</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>
                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                // defaultValue="aaaaaaa"
                                 placeholder={'비밀번호를 다시 입력해주세요.'}
                                 value={password2}
                                 type="password"
                                 onChange={(event)=>{
                                     setPassword2(event.target.value);
                                 }}
                            />

                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>담당자명</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>
                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                // defaultValue="aaaaaaa"
                                 placeholder={'담당자명을 입력해주세요.'}
                                 value={incharge}
                                 onChange={(event)=>{
                                     setIncharge(event.target.value);
                                 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>담당자 연락처</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>
                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                // defaultValue="aaaaaaa"
                                 placeholder={'담당자 전화번호를 입력해주세요.'}
                                 value={incharge_phone}
                                 maxLength={11}
                                 onChange={(event)=>{
                                     setIncharge_phone(event.target.value);
                                     setIncharge_phone(event.target.value.replace(/[^0-9]/g, ''));
                                 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: 15}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>담당자 이메일</Grid>
                        <Grid item xs={8} style={{textAlign :'left'}}>
                            <Box component={'input'} style={{border: 'none', outline: 'none', width: '100%', padding: 10}}
                                // defaultValue="aaaaaaa"
                                 placeholder={'담당자 이메일 주소를 입력해주세요.'}
                                 value={incharge_email}
                                 onChange={(event)=>{
                                     setIncharge_email(event.target.value);
                                 }}
                            />
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
            </div>
            {/* 버튼 */}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.bottonBtn}
                onClick={()=>{
                    if(company_name==''){
                        customAlert('회사명을 입력해주세요.');
                        return;
                    }else if(owner_name==''){
                        customAlert('대표자명을 입력해주세요.');
                        return;
                    }else if(password==''){
                        customAlert('비밀번호를 입력해주세요.');
                        return;
                    }else if(password2==''){
                        customAlert('비밀번호 확인을 입력해주세요.');
                        return;
                    }else if(password!=password2){
                        customAlert('비밀번호가 일치하지 않습니다. 다시 입력해주세요.');
                        return;
                    }else if(incharge==''){
                        customAlert('담당자명을 입력해주세요.');
                        return;
                    }else if(incharge_phone==''||incharge_phone.length<11){
                        customAlert('담당자 전화번호를 입력해주세요.');
                        return;
                    }else if(incharge_email==''){
                        customAlert('담당자 이메일 주소를 입력해주세요.');
                        return;
                    }else if(!isEmail(incharge_email)){
                        customAlert('이메일 주소가 맞는지 확인해주세요.');
                        return;
                    }
                    // else if(refer_pt==0){
                    //     Toast.show('추천파트너를 선택해주세요.');
                    //     return;
                    // }
                    tmp_data['u_id'] = u_id;
                    tmp_data['b_id'] = b_id;
                    tmp_data['company_name'] = company_name;
                    tmp_data['owner_name'] = owner_name;
                    tmp_data['password'] = password;
                    tmp_data['incharge'] = incharge;
                    tmp_data['incharge_phone'] = incharge_phone;
                    tmp_data['incharge_email'] = incharge_email;
                    // tmp_data['refer_pt'] = refer_pt;

                    update_data = JSON.stringify(tmp_data);
                    console.log('보내기전1',tmp_data);

                    //관리자회원가입
                    Axios({
                        method: 'get',
                        url: 'https://sbc.smartbc.kr/sb/band_admin/admin_user_info_update.php'  ,
                        params: {
                            update_data: update_data,
                        },
                    })
                        .then((result) => {
                            console.log(result.data);
                            customAlert(result.data.result_msg);
                            history.push('/'+match.params.JoinCode+'/AdminSetting');
                        })
                        .catch((err) => console.log(err));
                }}
            >회원정보 수정</Button>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    },
    bottonBtn: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 60,
        background: 'rgb(43,66,124)',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight: 'bold',
        '&:hover': {
            background: 'rgb(43,66,124)',
        },
    }

}));
export default AdminInfoUpdate;
