
import {createAction, handleActions} from 'redux-actions';

const SETU_ID = 'session/SETU_ID';
const SETB_ID = 'session/SETB_ID';
const SETADMIN = 'session/SETADMIN';
const SETTOKEN = 'session/SETTOKEN';
const SETPARTNER_ID = 'session/SETPARTNER_ID';
const SETMEMBER_CNT = 'session/SETMEMBER_CNT';
const SETMEMBER_CNT1 = 'session/SETMEMBER_CNT1';
const SETMEMBER_CNT2 = 'session/SETMEMBER_CNT2';
const SETMEMBER_CNT3 = 'session/SETMEMBER_CNT3';

export const setu_id = createAction(SETU_ID, (input) => {
    return input;
});
export const setb_id = createAction(SETB_ID, (input) => {
    return input;
});
export const setadmin = createAction(SETADMIN, (input) => {
    return input;
});
export const settoken = createAction(SETTOKEN, (input) => {
    return input;
});
export const setpartner_id = createAction(SETPARTNER_ID, (input) => {
    return input;
});
export const setmember_cnt = createAction(SETMEMBER_CNT, (input) => {
    return input;
});
export const setmember_cnt1 = createAction(SETMEMBER_CNT1, (input) => {//등록명함 cnt
    return input;
});
export const setmember_cnt2 = createAction(SETMEMBER_CNT2, (input) => {//미등록명함 cnt
    return input;
});
export const setmember_cnt3 = createAction(SETMEMBER_CNT3, (input) => {//운영자 cnt
    return input;
});

const initialState = {
    u_id: undefined,
    b_id: undefined,
    admin : undefined,
    token: undefined,
    partner_id: undefined,
    member_cnt: undefined,
    member_cnt1: undefined,
    member_cnt2: undefined,
    member_cnt3: undefined,
};

const session = handleActions(
    {
        [SETU_ID]: (state, {payload: input}) => ({
            ...state,
            u_id: input,
        }),
        [SETB_ID]: (state, {payload: input}) => ({
            ...state,
            b_id: input,
        }),
        [SETADMIN]: (state, {payload: input}) => ({
            ...state,
            admin: input,
        }),
        [SETTOKEN]: (state, {payload: input}) => ({
            ...state,
            token: input,
        }),
        [SETPARTNER_ID]: (state, {payload: input}) => ({
            ...state,
            partner_id: input,
        }),
        [SETMEMBER_CNT]: (state, {payload: input}) => ({
            ...state,
            member_cnt: input,
        }),
        [SETMEMBER_CNT1]: (state, {payload: input}) => ({
            ...state,
            member_cnt1: input,
        }),
        [SETMEMBER_CNT2]: (state, {payload: input}) => ({
            ...state,
            member_cnt2: input,
        }),
        [SETMEMBER_CNT3]: (state, {payload: input}) => ({
            ...state,
            member_cnt3: input,
        }),
    },
    initialState,
);

export default session;
