import React, {useEffect} from "react";
import PcHeader from "./PcHeader";
import {
    Container,
    Checkbox,
    FormControlLabel,
    CssBaseline,
    makeStyles,
    Button,
    useScrollTrigger,
} from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckCircleOutline';
import CheckBoxCheckedIcon from '@material-ui/icons/CheckCircle';
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CheckIcon from '@material-ui/icons/Check';
import TermIcon from '@material-ui/icons/ExpandMore';
import TermIcon2 from '@material-ui/icons/ExpandLess';
import TextField from "@material-ui/core/TextField";
import RoundButton from "../component/RoundButton";
import UseWIndowDimensions from "../component/UseWIndowDimensions";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import Timer from "../component/Timer";
import Axios from "axios";
import {setb_id, setu_id} from "../reducers/session";
import {useDispatch, useSelector} from "react-redux";
import Term1 from '../component/Term1';
import Term2 from '../component/Term2';
import customAlert from "../component/customAlert";

function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

function PartnerSignUp({history,match}) {

    const classes = useStyles();
    const {height, width} = UseWIndowDimensions();
    // 약관
    const [term1, setTerm1] = React.useState(false);
    const [term2, setTerm2] = React.useState(false);
    const [termCheck1, setTermCheck1] = React.useState(false);
    const [termCheck2, setTermCheck2] = React.useState(false);
    // 타이머
    const [sent, setSent] = React.useState(false);
    const [verify, setVerify] = React.useState(false);
    const time = new Date();
    // 인증 확인
    const [code, setCode] = React.useState(0);
    const [phone, setPhone] = React.useState(0);
    const [auth, setAuth] = React.useState(false);
    const [userName, setUserName] = React.useState('');
    const [pw,setPw] = React.useState('');

    const varUA = navigator.userAgent.toLowerCase(); //platform

    const b_id = sessionStorage.getItem('@b_id');
    const u_id = sessionStorage.getItem('@u_id');

    const dispatch = useDispatch();//세션 상태
    const stored_uid = useSelector((state) => state.session.u_id);

    console.log('그룹아이디',b_id)

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white',height:height}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'파트너 신청 & 로그인'} back={1} history={history}/>
            {/*body*/}
            <div className={classes.paper} style={{flex: 1}}>
                <FormGroup style={{marginTop: 15,}}>
                    <Grid container style={{alignItems: 'center',  padding: '0 20px'}}>
                        <Grid item xs={7} style={{textAlign: 'left'}}>
                            <FormControlLabel onClick={() => {
                                setTermCheck1(!termCheck1);
                            }}
                                              control={
                                                  <Checkbox
                                                      icon={<CheckBoxIcon fontSize="inherit"/>}
                                                      checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                                      name="checkedI"
                                                      checked={termCheck1}
                                                  />
                                              }
                                              label={<Typography variant="body2">스마트명함 이용 약관</Typography>}
                            />
                        </Grid>
                        <Grid item xs={4}/>
                        <Grid item xs={1}>
                            {term1 ? (<TermIcon2 onClick={() => {
                                setTerm1(!term1);
                            }}/>) : (<TermIcon onClick={() => {
                                setTerm1(!term1);
                            }}/>)}
                        </Grid>
                    </Grid>
                    {
                        term1 ? (<div style={{
                            backgroundColor: 'rgb(226,231,235)', margin: 5, width: '100%', height: 200, wordBreak: 'break',
                            wordWrap: 'break-word', padding: 10, overflow: "auto"
                        }}>
                            {Term1}
                        </div>) : null
                    }
                    <Divider/>
                    <Grid container style={{alignItems: 'center', padding: '0 20px'}}>
                        <Grid item xs={7} style={{textAlign: 'left'}}>

                            <FormControlLabel onClick={() => {
                                setTermCheck2(!termCheck2);
                            }}
                                              control={
                                                  <Checkbox
                                                      icon={<CheckBoxIcon fontSize="inherit"/>}
                                                      checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                                      name="checkedI"
                                                      checked={termCheck2}
                                                  />
                                              }
                                              label={<Typography variant="body2">개인정보 취급방침 동의</Typography>}
                            />
                        </Grid>
                        <Grid item xs={4}/>
                        <Grid item xs={1}>
                            {term2 ? (<TermIcon2 onClick={() => {
                                setTerm2(!term2);
                            }}/>) : (<TermIcon onClick={() => {
                                setTerm2(!term2);
                            }}/>)}
                        </Grid>
                    </Grid>
                    {
                        term2 ? (<div style={{
                            backgroundColor: 'rgb(226,231,235)', margin: 5, width: '100%', height: 200, wordBreak: 'break',
                            wordWrap: 'break-word', padding: 10, overflow: "auto"
                        }}>
                            {Term2}
                        </div>) : null
                    }

                    <Divider/>
                </FormGroup>
            </div>
            {/*<Button*/}
            {/*    type="submit"*/}
            {/*    fullWidth*/}
            {/*    variant="contained"*/}
            {/*    style={{*/}
            {/*        backgroundColor: '#303a53',*/}
            {/*        color: '#fff',*/}
            {/*        fontWeight: 'bold',*/}
            {/*        fontSize: 15,*/}
            {/*        borderRadius: 25,*/}
            {/*        padding: 10,*/}
            {/*    }}*/}
            {/*    startIcon={<CheckIcon/>}*/}
            {/*>*/}
            {/*    모든 약관에 동의합니다.*/}
            {/*</Button>*/}
            <div style={{padding: '0 20px'}} onClick={() => {
                setTermCheck1(true);
                setTermCheck2(true);
            }}>
                <RoundButton text={'모든 약관에 동의합니다.'} startIcon={<CheckIcon/>} styles={termCheck1 == true && termCheck2 == true ? {
                    backgroundColor: '#303a53',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 15,
                    borderRadius: 25,
                    padding: 10, flex: 1, marginTop: 30
                } : {
                    backgroundColor: '#fff',
                    color: '#303a53',
                    fontWeight: 'bold',
                    fontSize: 15,
                    borderRadius: 25,
                    padding: 10, flex: 1, marginTop: 30
                }}/>
            </div>
            <Box component={'div'} style={{color: '#aeb5ba', marginTop: '5%', textAlign: 'center'}}>휴대폰 인증만 하시면 회원 가입 및
                로그인이 <br/>자동
                처리됩니다.</Box>
            <div style={{textAlign: 'left', marginTop: '5%', padding: '0 20px'}}>
                <Grid container>
                    <Grid item xs={3} style={{alignSelf: 'center'}}>이름</Grid>
                    <Grid item xs={9}><TextField placeholder={'이름을 입력하세요.'} onChange={(val) =>{
                        setUserName(val.target.value);
                    }}/></Grid>
                </Grid>
                <Grid container style={{marginTop: 10}}>
                    <Grid item xs={3} style={{alignSelf: 'center'}}>휴대폰 번호</Grid>
                    <Grid item xs={6}>
                        <TextField inputProps={{
                            maxLength: 11,
                        }}
                                   type={'numeric'}
                                   placeholder={'-없이 번호 입력.'} onChange={(val) => {
                            setPhone(val.target.value);
                            setSent(false);
                        }}/></Grid>
                    <Grid item xs={3} style={{textAlign: 'center'}}>
                        <div onClick={() => {
                            if (!userName) {
                                customAlert('이름을 입력해주세요 !');
                                return;
                            } else if (phone == '' || phone.length !== 11) {
                                customAlert('휴대폰 번호를 확인해주세요.');
                            } else {
                                customAlert(
                                    '인증번호가 발송되었습니다.'
                                );
                                Axios({
                                    url: 'https://sbc.smartbc.kr/sb/testsms.php',
                                    method: 'get',
                                    params: {phone: phone}
                                })
                                    .then(function (response) {
                                        setSent(!sent);
                                    })
                                    .catch(function (error) {
                                        console.log('fail****');
                                        console.log(error);
                                    });
                            }
                            setSent(!sent);
                        }}>
                            <RoundButton text={'인증하기'} styles={{
                                backgroundColor: '#303a53',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 13,
                                borderRadius: 25,
                                padding: 5, width: '80%'
                            }}/>
                        </div>
                    </Grid>
                </Grid>
                {sent ? (
                    <Timer
                        style={{textAlign: 'center', marginRight: 20, marginTop: 10}}
                        expiryTimestamp={time.setSeconds(
                            time.getSeconds() + 180,
                        )}
                        status={sent}
                        verify={verify}
                    />
                ) : null}
                <Grid container style={{marginTop: 10}}>
                    <Grid item xs={3} style={{alignSelf: 'center'}}>인증번호</Grid>
                    <Grid item xs={6}>
                        <TextField
                            inputProps={{
                                maxLength: 6
                            }}
                            placeholder={'인증번호를 입력하세요.'}
                            onChange={(val) => {
                                setCode(val.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} style={{textAlign: 'center'}}>
                        <div onClick={() => {
                            if (code == '') {
                                customAlert(
                                    '인증번호를 입력하여주세요.',
                                );
                            }
                                // else if (phone.length !== 11) {
                                //     alert('휴대폰 번호를 확인해주세요');
                            // }
                            else {
                                Axios({
                                    method: 'get',
                                    url: 'https://sbc.smartbc.kr/sb/set_testsms.php',
                                    params: {
                                        certification_number: code,
                                        text: code,
                                    },
                                })
                                    .then((result) => {
                                        setSent(false);
                                        console.log(result.data);
                                        if (
                                            result.data ==
                                            200 || result.data == 'smartImsiSuccess'
                                        ) {
                                            setAuth(!auth);
                                            setVerify(true);
                                            // setData(result.data);
                                            customAlert(
                                                '문자 인증이 성공적으로 완료되었습니다.',
                                            );
                                        } else {
                                            customAlert(
                                                '인증에 실패하였습니다.'
                                            );
                                        }
                                    })
                                    .catch((err) =>
                                        console.log(err),
                                    );
                            }
                        }}>
                            <RoundButton text={'확인'} styles={{
                                backgroundColor: '#303a53',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 13,
                                borderRadius: 25,
                                padding: 5, width: '80%'
                            }}/>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/*다음 버튼*/}
            <Button
                fullWidth
                variant="contained"
                style={{
                    backgroundColor: '#303a53',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: 15,
                    padding: 10,
                    // marginTop: '20%',
                    position:'fixed',
                    left:0,
                    bottom:0,

                }}
                onClick={() => {
                    if (termCheck1 == false || termCheck2 == false) {
                        customAlert('필수 약관에 동의해주세요.');
                    } else if (!userName) {
                        customAlert('이름을 입력해주세요');
                    } else if (!auth) {
                        customAlert('인증번호를 확인해주세요');
                    } else if (phone.length !== 11) {
                        customAlert('휴대폰 번호를 확인해주세요.');
                    }
                    else {
                        // Get the device token
                        // messaging().getToken().then(token => {

                        Axios({
                            url: 'https://sbc.smartbc.kr/sb/partner_login.php' ,
                            method: 'get',
                            params : {
                                u_id: u_id,
                                phone: phone,
                                os_type: varUA.match('android') ? 'android' :  varUA.match('ios') ?'ios':null,
                            }
                        })
                            .then(function (response) {
                                console.log('partner_login log start',response.data);

                                console.log(phone);
                                if (response.data['status'] == 45001) {
                                    let partner_id = response.data['partner_id'];
                                    try {
                                        sessionStorage.setItem('@partner_id',partner_id);
                                        // dispatch(setpartner_id(partner_id));

                                    } catch (e) {
                                        console.log(e);
                                    }
                                    //파트너 마이페이지 (메인)
                                    history.push('/'+match.params.JoinCode+'/PartnerMyInfo');
                                }
                                else if(response.data['status'] == 45002) {
                                    console.log('not confirmed**** =>',response.data['result_msg']);
                                    //미승인
                                    history.push('/'+match.params.JoinCode+'/PartnerNonApprove');
                                }else{
                                    console.log('fail**** =>',response.data['result_msg']);
                                    customAlert('실패하였습니다.');
                                }


                            })
                            .catch(function (error) {
                                console.log('fail****');
                                console.log(error);
                                customAlert('실패하였습니다.');
                            }); //end axios
                        // }); //end getToken()
                    }
                }}
            >다음</Button>

        </Container>
    );
}

const useStyles = makeStyles((theme) => ({

    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    checkButton: {
        backgroundColor: '#303a53',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 13,
        borderRadius: 25,
        padding: 5, width: '80%'
    },
    // button : {
    //     backgroundColor: '#303a53',
    //     color: '#fff',
    //     fontWeight: 'bold',
    //     fontSize: 13,
    //     borderRadius: 25,
    //     padding: 5, width:'80%'
    // }
}));
export default PartnerSignUp;
