import React from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Grid,
    Button, Switch
} from '@material-ui/core';
import PcHeader from "../PcHeader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

function AdminSetting({history,match}) {
    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);

        sessionStorage.removeItem('@u_id_a');
        sessionStorage.removeItem('@u_id_a');
        history.push( '/'+match.params.JoinCode+'/AdminLogin'); //인터넷 창 뒤로가기 방지해야됨,,,(일단 보류)
    };

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '30px'}}>
                <CssBaseline/>
                <PcHeader text={'설정'} back={1} adminback={1} history={history} match={match}/>
                <div className={classes.paper}>
                    {/*custom_confirm*/}
                    <div>
                        <Dialog
                            open={confirmShow}
                            onClose={handleCloseN}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {confirmMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseN} color="primary">
                                    {'아니오'}
                                </Button>
                                <Button onClick={handleCloseY} color="primary" autoFocus>
                                    {'예'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        회원 관련 설정
                    </Typography>
                    <div style={{width: '100%'}}>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}
                        onClick={()=>{
                            setConfirmMessage('로그아웃 하시겠습니까?');
                            setConfirmShow(true);
                            // if( window.confirm('로그아웃 하시겠습니까?')){
                            //     sessionStorage.removeItem('@u_id_a');
                            //     sessionStorage.removeItem('@u_id_a');
                            //     history.push( '/'+match.params.JoinCode+'/AdminLogin'); //인터넷 창 뒤로가기 방지해야됨,,,(일단 보류)
                            // }
                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>로그아웃</Typography>
                            <img src={require('../../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}
                        onClick={()=>{
                            history.push('/'+match.params.JoinCode+'/AdminInfoUpdate');
                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>회원정보 수정</Typography>
                            <img src={require('../../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                    </div>

                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        추가 설정 설정
                    </Typography>
                    <div style={{width: '100%'}}>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}} onClick={()=>{
                            history.push('/'+match.params.JoinCode+'/AdminSettingInfo');
                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>모임 설정 정보 확인</Typography>
                            <img src={require('../../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>

                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}} onClick={()=>{
                            // history.push('/'+match.params.JoinCode+'/AdminUserLevel');
                            history.push({
                                pathname : '/'+match.params.JoinCode+'/AdminUserLevel',
                                state : {searchList : null}
                            });
                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>회원 등급 변경</Typography>
                            <img src={require('../../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>

                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}} onClick={()=>{
                            history.push('/'+match.params.JoinCode+'/AdminBoardLevel');
                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>게시판 사용 권한 설정</Typography>
                            <img src={require('../../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>

                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}
                        onClick={()=>{
                            window.open('https://pf.kakao.com/_HXWYT');//새창 o
                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>카카오톡으로 문의하기</Typography>
                            <img src={require('../../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '0 40px 20px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: 14,
    },
    listBox: {
        display: 'block',
        height: '57px',
        border: '2px solid rgb(189,204,240)',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));


export default AdminSetting;
