// import React from "react";

export function customConfirm(message){
    let result = false;
    const custom_confirm = new Promise(function(resolve, reject) {
        try {
            if (window.self !== window.top) {
                console.log('프레임 있다, customConfirm');
                window.parent.postMessage({
                    action: "systemConfirm", message: message
                }, "*");
                window.addEventListener( 'message', (e)=>{
                    console.log('부모님통신',e.data);
                    result = e.data;
                } );


            } else {
                console.log('프레임 없다, 그냥 Confirm ')
                result = window.confirm(message);

            }

            console.log('resolve', resolve('sucess'));
            console.log('최종', result);

        } catch (err) {
            console.log('err:', err);
            console.log('reject(err):', reject(err));

        }
    });

    custom_confirm.then((successMessage) => {
        window.addEventListener( 'message', (e)=>{
            console.log('부모님통신22222',e.data);
            result = e.data;
        } );
        console.log("Yay! " + successMessage);
    });
    //return promise
    console.log('promise ', custom_confirm);

    console.log('promise.then', result);
    return result;
}

export default customConfirm;

