import React from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
   AppBar, Toolbar
} from '@material-ui/core';
function InstallInfo() {
    const classes = useStyles();
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                {/*main header*/}
                <AppBar style={{background: 'linear-gradient(to left, rgb(48,58,83), rgb(54,64,88), rgb(97,104,122))'}}>
                    <Toolbar style={{flexDirection: 'row'}}>
                        <div style={{margin: '0 auto', width: 430}}>
                            <Typography align='left' style={{fontSize: 20, fontWeight: 'bold', marginLeft: 30}}>
                                모바일 앱 설치안내
                            </Typography>
                        </div>
                    </Toolbar>
                </AppBar>

                <div className={classes.paper}>
                    <Typography style={{fontWeight: 'bold', fontSize: 30, color: 'rgb(107,97,216)', margin: '20px 0'}}>명함앱 + 인맥확장 + CRM</Typography>
                    <div style={{width: '100%', padding: 10, border: '1px solid #e6e6e6', borderRadius: 10, marginBottom: 20}}>
                        <Grid container alignItems='center'direction='row'>
                            <Grid item xs={3} style={{marginRight: 15}}>
                                <img src={require('../resources/install_logo_s.png')} style={{width: 75, height: 75}}/>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 16, color: 'rgb(47,187,240)', fontWeight: 'bold'}}>
                                            스마트 명함(개인용)
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 12, color: 'rgb(92,92,92)', height: 40, lineHeight: 1.5}}>
                                            명함관리, 모바일명함만들기, 인맥확장<br />
                                            모든게 가능한 명함앱!

                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{width: '100%', padding: 10, border: '1px solid #e6e6e6', borderRadius: 10, marginBottom: 20}}>
                        <Grid container alignItems='center'direction='row'>
                            <Grid item xs={3} style={{marginRight: 15}}>
                                <img src={require('../resources/install_logo_b.png')} style={{width: 75, height: 75}}/>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid item xs container direction="column">
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 16, color: 'rgb(107,97,216)', fontWeight: 'bold'}}>
                                            스마트 명함(기업용)
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 12, color: 'rgb(92,92,92)', height: 40, lineHeight: 1.5}}>
                                            고객명함, 메모 공유를 통한<br />
                                            협업 & 명함자산을 체계적으로 관리
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{width: '100%', padding: 10, border: '1px solid #e6e6e6', borderRadius: 10, marginBottom: 20}}>
                        <Grid container alignItems='center'direction='row'>
                            <Grid item xs={3} style={{marginRight: 15}}>
                                <img src={require('../resources/install_logo_g.png')} style={{width: 75, height: 75}}/>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid item xs container direction="column">
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 16, color: 'rgb(48,58,83)', fontWeight: 'bold'}}>
                                            스마트 모임
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 12, color: 'rgb(92,92,92)', height: 40, lineHeight: 1.5}}>
                                            명함기반 회원명부 관리, 회원간 사업 교류,<br />
                                            행사, 협회 소식, 경조사 공지
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                    {/* 버튼 */}
                    <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        style={{backgroundColor: 'rgb(255,212,0)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                    >
                        카카오 1 : 1 문의하기
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        style={{backgroundColor: 'rgb(54,172,55)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                    >
                        앱 활용법 블로그보기
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        style={{backgroundColor: 'rgb(255,0,0)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                    >
                        앱 활용법 유투브보기
                    </Button>

                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));


export default InstallInfo;
