import React,{useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    List,ListItem,ListItemText,
    Grid,Card,
    CardContent,
    IconButton
}from '@material-ui/core';
import PcHeader from "./PcHeader";
import CloseIcon from '@material-ui/icons/Close';
import Axios from "axios";
import Moment from 'moment';

function AddComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}


function PointList({history,match}) {
    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [point_list,setPoint_list] = React.useState([]);
    const [point,setPoint] = React.useState('');

    let listBar = null;

    useEffect(()=>{
        Axios({
            url: 'https://sbc.smartbc.kr/sb/my_point_list.php',
            method: 'get',
            params: {
                u_id: u_id,
                b_id: b_id,
            },
        })
            .then((result) => {
                console.log('dd',result.data);
                if (result.data.status == 15001) {
                    setPoint_list(result.data.point_list);
                    setPoint(result.data.point);
                } else {
                    console.log(result);
                    console.log(result.data);
                    //Toast.show(result.data.result_msg);
                }
            })
            .catch((err) => console.log(err.message));

    },[]);

    const PointList = ({index}) => {

        // if((index%2)==1){
            //연한배경
            //적립금액
            if(point_list[index].point>0){
                listBar =
                <ListItem>
                    <ListItemText className={classes.contentText}>{Moment(point_list[index].create_at).format('YYYY-MM-DD')}</ListItemText>
                    <ListItemText className={classes.contentText}>{AddComma(point_list[index].point)} P</ListItemText>
                    <ListItemText className={classes.contentText}></ListItemText>
                </ListItem>;
            }else{
                //사용금액
                listBar =
                 <ListItem>
                    <ListItemText className={classes.contentText}>{Moment(point_list[index].create_at).format('YYYY-MM-DD')}</ListItemText>
                    <ListItemText className={classes.contentText}></ListItemText>
                    <ListItemText className={classes.contentText}>{AddComma(point_list[index].point)} P</ListItemText>
                </ListItem>;
            }

        // }else{
            //진한배경
            //적립금액
        //     if(point_list[index].point>0){
        //         listBar =
        //             <ListItem>
        //                 <ListItemText className={classes.contentText}>{point_list[index].create_at}</ListItemText>
        //                 <ListItemText className={classes.contentText}>{point_list[index].point} P</ListItemText>
        //                 <ListItemText className={classes.contentText}></ListItemText>
        //             </ListItem>;
        //     }else {
        //         //사용금액
        //         listBar =
        //             <ListItem>
        //                 <ListItemText className={classes.contentText}>{point_list[index].create_at}</ListItemText>
        //                 <ListItemText className={classes.contentText}></ListItemText>
        //                 <ListItemText className={classes.contentText}>{point_list[index].point} P</ListItemText>
        //             </ListItem>;
        //     }
        //
        // }

        return(listBar)
    };

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'누적 포인트'} back={1} history={history} match={match}/>
                {/* 상단 고정*/}
                <div style={{padding: '20px 25px 15px 20px', marginTop: 50, boxShadow:  '0 5px 10px rgba(0,0,0,0.15)', position: 'sticky', top: 55, backgroundColor: '#fff', zIndex: 10}} className={'allUser'}>
                    <div style={{padding: '12px 20px', width: '100%', textAlign: 'center', fontSize: 13, color: 'rgb(126,126,126)', borderRadius: '40px', position: 'relative', backgroundColor: 'rgb(245,249,252)'}}>
                        point 잔액<Typography style={{marginLeft: 13, fontSize: 15, fontWeight: 'bold', color: '#000', display: 'inline-block'}}><span style={{color: 'rgb(239,113,113)',}}>{point?AddComma(point):0}</span> P</Typography>
                        {/*<Button*/}
                        {/*    type="submit"*/}
                        {/*    color="primary"*/}
                        {/*    style={{backgroundColor: 'rgb(43,66,124)', color: '#fff',*/}
                        {/*        fontWeight:'bold',fontSize :15,borderRadius : 40,*/}
                        {/*        padding: '10px 50px', boxShadow: '0 3px 6px rgba(0,0,0,0.16)',*/}
                        {/*        position: 'absolute', top: 0, right: 0*/}
                        {/*    }}*/}
                        {/*>출금 요청*/}
                        {/*</Button>*/}
                    </div>
                </div>
                {/* 리스트 */}
                <div className={classes.paper}>
                    <Grid container style={{color: 'rgb(93,93,93)', fontSize: 13, padding: 10}}>
                        <Grid xs={4}>적립일</Grid>
                        <Grid xs={4}>적립 금액</Grid>
                        <Grid xs={4}>사용 금액</Grid>
                    </Grid>
                    <List className='point_list' style={{width: '100%'}}>
                        {point_list.map(((value,i) => <PointList key={i} index={i} />))}
                    </List>
                </div>

                {/* 팝업 */}
                {/*<Card className={classes.popUp}>*/}
                {/*    <Typography align='right'>*/}
                {/*        <IconButton>*/}
                {/*            <CloseIcon style={{color: 'black', fontSize: 30}}/>*/}
                {/*        </IconButton>*/}
                {/*    </Typography>*/}
                {/*    <CardContent style={{paddingTop: 5}}>*/}
                {/*        <img src={require('../resources/money.png')} style={{height: 85}}/>*/}
                {/*        <Typography style={{marginTop: 10}}>출금 요청 : <span style={{color: 'rgb(239,113,113)', fontWeight: 'bold'}}>456,000</span> P</Typography>*/}
                {/*        <Typography style={{color: 'rgb(93,93,93)', fontSize: 12, padding: '10px 15px'}}>포인트 출금 요청을 하시면 익월(다음달) 20일 정산처리됩니다.</Typography>*/}
                {/*    </CardContent>*/}
                {/*    <Button*/}
                {/*        color="primary"*/}
                {/*        style={{width: 200, backgroundColor: 'rgb(43,66,124)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}*/}
                {/*    >*/}
                {/*        출금 요청하기*/}
                {/*    </Button>*/}
                {/*</Card>*/}
                {/*<div className={classes.popUpBg}></div>*/}
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    contentText: {
        textAlign: 'center',
        fontSize: 13,
        color: 'rgb(93,93,93)',
        width: '33%',
        display: 'inline-block'
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    '@global':{
        '.MuiListItemText-root':{
            flex: '1',
        },
        '.point_list>.MuiListItem-root:nth-child(even)':{
            backgroundColor: '#fff', width: '100%', borderRadius: 20
        },
        '.point_list>.MuiListItem-root:nth-child(odd)':{
            backgroundColor: 'rgba(0,0,0,0.1)', width: '100%', borderRadius: 20
        },
        '.MuiListItemText-primary': {fontSize: 13}
    }

}));


export default PointList;
