import React,{useEffect} from "react";
import {
    Container,
    Checkbox,
    FormControlLabel,
    CssBaseline,
    makeStyles,
    Button,
    Accordion,
    AccordionSummary, AccordionDetails,
    Typography,
    Divider,
    TextField, Grid, FormControl, Select, MenuItem

} from "@material-ui/core";
import PcHeader from "../PcHeader";
import RoundButton from "../../component/RoundButton";
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import Axios from 'axios';
import Term1 from '../../component/Term1';
import Term2 from '../../component/Term2';
import customAlert from "../../component/customAlert";

function BandJoin({history,match}) {
    const classes = useStyles();

    const [agree1, setAgree1] = React.useState(false);
    const [agree2, setAgree2] = React.useState(false);
    // const [checkArr, setCheckArr] =React.useState([]);
    // let checkArr = [];
    const [allCheck, setAllCheck] = React.useState(false);

    const [business_num, setBusiness_num] = React.useState('');
    const [bn_confirm, setBn_confirm] = React.useState(false);

    const [company_name, setCompany_name] = React.useState('');
    const [owner_name, setOwner_name] = React.useState('');

    const [join_code, setJoin_code] = React.useState('');
    const [jc_confirm, setJc_confirm] = React.useState(false);


    const [user_id, setUser_id] = React.useState('');
    const [uid_confirm, setUid_confirm] = React.useState(false);

    const [password, setPassword] = React.useState('');
    const [password2, setPassword2] = React.useState('');

    const [incharge, setIncharge] = React.useState('');
    const [incharge_phone, setIncharge_phone] = React.useState('');
    const [incharge_email, setIncharge_email] = React.useState('');

    const [refer_pt, setRefer_pt] = React.useState('');

    let tmp_data = {};
    let join_data = {};

    const [partner, setPartner] = React.useState('');
    const select = (event) => {
        setPartner(event.target.value);
    };

    //사업자번호 정규식
    function Bn_regular_check(value) {
        var valueMap = value.replace(/-/gi, '').split('').map(function (item) {
            return parseInt(item, 10);
        });
        console.log(valueMap);
        if (valueMap.length === 10) {
            var multiply = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5);
            var checkSum = 0;
            for (var i = 0; i < multiply.length; ++i) {
                checkSum += multiply[i] * valueMap[i];
            }
            checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10);
            // console.log( Math.floor(valueMap[9]) === (10 - (checkSum % 10)));
            return Math.floor(valueMap[9]) === (10 - (checkSum % 10));
        }
        return false;
    }

    // 이메일 체크 정규식
    function isEmail(asValue) {
        var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        // console.log(regExp.test(asValue));
        return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
    }

    // useEffect(()=>{
    //
    // },[agree1,agree2]);

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white'}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'스마트모임 개설 신청'} back={1} history={history} match={match} />
            {/*body*/}
            <div className={classes.paper}>
                <Accordion style={{boxShadow: 'none', margin: 0, padding: '0 20px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <FormControlLabel
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                                <Checkbox
                                    icon={<CheckBoxIcon fontSize="inherit"/>}
                                    checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                    name="checkedOne"
                                    id="checkedOne"
                                    checked={agree1}
                                    onChange={(event)=>{
                                        setAgree1(event.target.checked);
                                        // setAgree1(!agree1);
                                    }}
                                />
                            }
                            label={<Typography variant="body2">스마트모임 이용 약관</Typography>}
                        />
                    </AccordionSummary>
                    <AccordionDetails style={{padding: 0}}>
                        <Typography align='left' color="textSecondary" style={{fontSize: 13, backgroundColor: 'rgb(226,231,235)', width: '100%', color: '#000', maxHeight: 150, overflowY: 'auto', padding: 20, lineHeight: 2}}>
                            {Term1}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Divider/>
                <Accordion style={{boxShadow: 'none', margin: 0, padding: '0 20px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <FormControlLabel
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                                <Checkbox
                                    icon={<CheckBoxIcon fontSize="inherit"/>}
                                    checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                    name="checkedTwo"
                                    id="checkedTwo"
                                    checked={agree2}
                                    onChange={(event)=>{
                                        setAgree2(event.target.checked);
                                        // setAgree2(!agree2);
                                    }}
                                />
                            }
                            label={<Typography variant="body2">개인정보 취급방침 동의</Typography>}
                        />
                    </AccordionSummary>
                    <AccordionDetails style={{padding: 0}}>
                        <Typography align='left' color="textSecondary" style={{fontSize: 13, backgroundColor: 'rgb(226,231,235)', width: '100%', color: '#000', maxHeight: 150, overflowY: 'auto', padding: 20, lineHeight: 2}}>
                            {Term2}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Divider/>

            {/*<RoundButton text={'모든 약관에 동의합니다.'} startIcon={<CheckIcon/>}*/}
            {/*             styles={{backgroundColor: 'rgb(43,66,124)', color: '#fff', fontWeight: 'bold', fontSize: 15, borderRadius: 25, padding: 10,flex:1, margin: '20px 0'}}*/}

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{width: '90%', backgroundColor: 'rgb(43,66,124)', color: '#fff', fontWeight: 'bold', fontSize: 15, borderRadius: 25, padding: 10,flex:1, margin: '20px auto'}}
                    startIcon={<CheckIcon/>}
                    // className={classNames}
                    onClick={()=>{
                        // let name = document.getElementById("checkedOne");
                        if(!agree1||!agree2){
                            setAgree1(true);
                            setAgree2(true);
                        }else{
                            setAgree1(false);
                            setAgree2(false);
                        }
                    }}
                >{'모든 약관에 동의합니다.'}</Button>
            <div style={{padding: '0 40px'}}>
            <Grid container spacing={2} style={{position: 'relative'}}>
                <Grid xs={3} className={classes.inputTilie}>사업자번호</Grid>
                <Grid item xs={9}>
                    <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                        <TextField variant="standard"
                                   placeholder='사업자번호 입력( "-" 없이)'
                                   // type="number"
                                   // maxLength={10}
                                   inputProps={{style: {padding: '15px 5px', width: '70%',fontSize: 14}}}
                                   value={business_num}
                                   onChange={(event)=>{
                                       // console.log('입력',event.target.value);
                                       if(event.target.value.length<11) {
                                           setBusiness_num(event.target.value);
                                           setBusiness_num(event.target.value.replace(/[^0-9]/g, ''));
                                       }
                                   }}
                        />
                    </FormControl>
                </Grid>
                <Button color="primary"  className={classes.listBox}
                        style={{position: 'absolute', backgroundColor: 'rgb(43,66,124)', color: '#fff', borderRadius: 30, right: 10, top: 13,fontSize: 13}}
                        onClick={()=>{
                            if (business_num == '' || business_num.length < 10) {
                                // console.log(business_num.length);
                                customAlert('사업자번호를 입력해주세요.');
                            } else {

                                // console.log(Bn_regular_check(business_num));
                                // if (!Bn_regular_check(business_num)) {
                                //     Toast.show('사업자번호를 맞게 입력했는지 확인해주세요.');
                                // } else {}
                                Axios({
                                    method: 'get',
                                    url: 'https://sbc.smartbc.kr/sb/band_admin/businessNum_confirm.php' ,
                                    params: {
                                        business_num: business_num,
                                    },
                                })
                                    .then((result) => {
                                        console.log(result.data);
                                        if (result.data.status == 2002) {
                                            setBn_confirm(true);
                                        } else {
                                            setBn_confirm(false);
                                        }
                                        customAlert(result.data.result_msg);
                                    })
                                    .catch((err) => console.log(err));
                            }//if end
                        }}
                >가입확인</Button>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={3} className={classes.inputTilie}>협회명</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='협회명을 입력해주세요'
                                       inputProps={{style: {padding: '15px 5px',fontSize: 14}}}
                                       value={company_name}
                                       onChange={(event)=>{
                                            console.log('입력',event.target.value);
                                            setCompany_name(event.target.value);

                                       }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={3} className={classes.inputTilie}>대표자명</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='대표자명을 입력해주세요'
                                       inputProps={{style: {padding: '15px 5px',fontSize: 14}}}
                                       value={owner_name}
                                       onChange={(event)=>{
                                           console.log('입력',event.target.value);
                                           setOwner_name(event.target.value);
                                       }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{position: 'relative'}}>
                    <Grid xs={3} className={classes.inputTilie}>협회 초대코드</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='협회 초대코드 생성'
                                       inputProps={{style: {padding: '15px 5px', width: '70%',fontSize: 14}}}
                                       value={join_code}
                                       onChange={(event)=>{
                                           // console.log('입력',event.target.value);
                                           setJoin_code(event.target.value);
                                       }}
                            />
                        </FormControl>
                    </Grid>
                    <Button color="primary"  className={classes.listBox}
                            style={{position: 'absolute', backgroundColor: 'rgb(43,66,124)', color: '#fff', borderRadius: 30, right: 10, top: 13,fontSize: 13}}
                            onClick={()=>{
                                if (join_code == '') {
                                    // console.log(business_num.length);
                                    customAlert('초대코드를 입력해주세요.');
                                } else {
                                    console.log('joincode', join_code);
                                    Axios({
                                        method: 'get',
                                        url: 'https://sbc.smartbc.kr/sb/band_admin/join_code_confirm.php' ,
                                        params: {
                                            join_code: join_code,
                                        },
                                    })
                                        .then((result) => {
                                            console.log(result.data);
                                            if (result.data.status == 29002) {
                                                setJc_confirm(true);
                                            } else {
                                                setJc_confirm(false);
                                            }
                                            customAlert(result.data.result_msg);
                                        })
                                        .catch((err) => console.log(err));


                                }
                            }}
                    >중복확인</Button>
                </Grid>
                <Grid container spacing={2} style={{position: 'relative'}}>
                    <Grid xs={3} className={classes.inputTilie}>아이디</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='아이디를 입력해주세요'
                                       inputProps={{style: {padding: '15px 5px', width: '70%',fontSize: 14}}}
                                       value={user_id}
                                       onChange={(event)=>{
                                           console.log('입력',event.target.value);
                                           setUser_id(event.target.value);
                                       }}
                            />
                        </FormControl>
                    </Grid>
                    <Button color="primary"  className={classes.listBox}
                            style={{position: 'absolute', backgroundColor: 'rgb(43,66,124)', color: '#fff', borderRadius: 30, right: 10, top: 13,fontSize: 13}}
                            onClick={()=>{
                                if (user_id == '') {
                                    customAlert('아이디를 입력해주세요.');
                                } else {
                                    //관리자아이디 확인
                                    Axios({
                                        method: 'get',
                                        url: 'https://sbc.smartbc.kr/sb/band_admin/band_id_confirm.php',
                                        params: {
                                            user_id: user_id,
                                        },
                                    })
                                        .then((result) => {
                                            console.log(result.data);
                                            if (result.data.status == 3002) {
                                                setUid_confirm(true);
                                            } else {
                                                setUid_confirm(false);
                                            }
                                            customAlert(result.data.result_msg);
                                        })
                                        .catch((err) => console.log(err));
                                }
                            }}
                    >가입확인</Button>
                </Grid>
                <Grid container spacing={2} style={{position: 'relative'}}>
                    <Grid xs={3} className={classes.inputTilie}>비밀번호</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='비밀번호를 입력해주세요'
                                       type='password'
                                       inputProps={{style: {padding: '15px 5px',fontSize: 14}}}
                                       value={password}
                                       onChange={(event)=>{
                                           console.log('입력',event.target.value);
                                           setPassword(event.target.value);
                                       }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{position: 'relative'}}>
                    <Grid xs={3} className={classes.inputTilie}>비밀번호 확인</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='비밀번호를 다시 입력해주세요'
                                       type='password'
                                       inputProps={{style: {padding: '15px 5px',fontSize: 14}}}
                                       value={password2}
                                       onChange={(event)=>{
                                           console.log('입력',event.target.value);
                                           setPassword2(event.target.value);
                                       }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{position: 'relative'}}>
                    <Grid xs={3} className={classes.inputTilie}>담당자명</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='담당자명을 입력해주세요'
                                       inputProps={{style: {padding: '15px 5px',fontSize: 14}}}
                                       value={incharge}
                                       onChange={(event)=>{
                                           console.log('입력',event.target.value);
                                           setIncharge(event.target.value);
                                       }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{position: 'relative'}}>
                    <Grid xs={3} className={classes.inputTilie}>담당자 연락처</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='담당자 연락처를 입력해주세요'
                                       // type='number'
                                       inputProps={{style: {padding: '15px 5px',fontSize: 14}}}
                                       value={incharge_phone}
                                       onChange={(event)=>{
                                           console.log('입력',event.target.value);
                                           if(event.target.value.length<12) {
                                               setIncharge_phone(event.target.value);
                                               setIncharge_phone(event.target.value.replace(/[^0-9]/g, ''));
                                           }
                                       }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{position: 'relative'}}>
                    <Grid xs={3} className={classes.inputTilie}>담당자 이메일</Grid>
                    <Grid item xs={9}>
                        <FormControl className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>
                            <TextField variant="standard"
                                       placeholder='담당자 이메일을 입력해주세요'
                                       inputProps={{style: {padding: '15px 5px',fontSize: 14}}}
                                       value={incharge_email}
                                       onChange={(event)=>{
                                           console.log('입력',event.target.value);
                                           setIncharge_email(event.target.value);
                                       }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
                {/*<Grid container spacing={2} style={{position: 'relative'}}>*/}
                {/*    <Grid xs={3} className={classes.inputTilie}>추천 파트너</Grid>*/}
                {/*    <Grid item xs={9}>*/}
                {/*        <FormControl variant="filled" className={classes.formControl} style={{marginTop: 0, marginBottom: 5}}>*/}
                {/*            <Select*/}
                {/*                value={partner}*/}
                {/*                onChange={select}*/}
                {/*                displayEmpty*/}
                {/*                inputProps={{'aria-label': 'Without label'}}*/}
                {/*                style={{backgroundColor: '#fff'}}*/}
                {/*                classes={{root: classes.selectBox}}*/}
                {/*            >*/}
                {/*                <MenuItem value="" disabled>*/}
                {/*                    추천 파트너 필수선택*/}
                {/*                </MenuItem>*/}
                {/*                <MenuItem value={1} >선택1</MenuItem>*/}
                {/*                <MenuItem value={2}>선택2</MenuItem>*/}
                {/*            </Select>*/}
                {/*        </FormControl>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                {/* 버튼 */}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.bottonBtn}
                    onClick={()=>{
                        //값 잘 들어오고 회원가입 잘 됨.=>약관 동의랑 확인버튼 체크 여부 확인해야됨!!
                        if (agree1 == false) {
                            customAlert('이용 약관에 동의해주세요.');
                            return;
                        } else if (agree2 == false) {
                            customAlert('개인정보 취급방침 동의에 동의해주세요.');
                            return;
                        } else if (business_num == '') {
                            customAlert('사업자번호를 입력해주세요.');
                            return;
                        } else if (bn_confirm == false) {
                            customAlert('사업자번호 가입확인을 해주세요.');
                            return;
                        } else if (join_code == '') {
                            customAlert('협회 초대코드를 입력해주세요.');
                            return;
                        } else if (jc_confirm == false) {
                            customAlert('협회 초대코드 중복확인을 해주세요.');
                            return;
                        } else if (company_name == '') {
                            customAlert('협회명을 입력해주세요.');
                            return;
                        } else if (owner_name == '') {
                            customAlert('대표자명을 입력해주세요.');
                            return;
                        } else if (user_id == '') {
                            customAlert('아이디를 입력해주세요.');
                            return;
                        } else if (uid_confirm == false) {
                            customAlert('아이디 가입확인을 해주세요.');
                            return;
                        } else if (password == '') {
                            customAlert('비밀번호를 입력해주세요.');
                            return;
                        } else if (password2 == '') {
                            customAlert('비밀번호 확인을 입력해주세요.');
                            return;
                        } else if (password != password2) {
                            customAlert('비밀번호가 일치하지 않습니다. 다시 입력해주세요.');
                            return;
                        } else if (incharge == '') {
                            customAlert('담당자명을 입력해주세요.');
                            return;
                        } else if (incharge_phone == '' || incharge_phone.length < 11) {
                            customAlert('담당자 전화번호를 입력해주세요.');
                            return;
                        } else if (incharge_email == '') {
                            customAlert('담당자 이메일 주소를 입력해주세요.');
                            return;
                        } else if (!isEmail(incharge_email)) {
                            customAlert('이메일 주소가 맞는지 확인해주세요.');
                            return;
                        }

                        // else if(refer_pt==0){
                        //     Toast.show('추천파트너를 선택해주세요.');
                        //     return;
                        // }

                        tmp_data['business_num'] = business_num;
                        tmp_data['company_name'] = company_name;
                        tmp_data['owner_name'] = owner_name;
                        tmp_data['join_code'] = join_code;
                        tmp_data['user_id'] = user_id;
                        tmp_data['password'] = password;
                        tmp_data['incharge'] = incharge;
                        tmp_data['incharge_phone'] = incharge_phone;
                        tmp_data['incharge_email'] = incharge_email;
                        // tmp_data['refer_pt'] = refer_pt;

                        join_data = JSON.stringify(tmp_data);
                        console.log('다들어오니',tmp_data);
                        // return;

                        //관리자회원가입
                        Axios({
                            method: 'get',
                            url: 'https://sbc.smartbc.kr/sb/band_admin/band_join.php' ,
                            params: {
                                join_data: join_data,
                            },
                        })
                            .then((result) => {
                                console.log(result.data);//formData =>null
                                if (result.data.status == 2001) {
                                    setBn_confirm(true);
                                    setJc_confirm(true);
                                    // AsyncStorage.removeItem('@u_id_a');
                                    // AsyncStorage.removeItem('@b_id_a');
                                    // navigation.reset({index: 0, routes: [{name: 'AdminLogin',params:{autoLogin:false}}]});
                                    sessionStorage.removeItem('@u_id_a');
                                    sessionStorage.removeItem('@b_id_a');
                                    history.push('/'+match.params.JoinCode+'/AdminLogin');

                                } else if(result.data.status == 2004){
                                    customAlert(result.data.result_msg);
                                }else{
                                    customAlert(result.data.result_msg);
                                    setBn_confirm(false);
                                    setJc_confirm(false);
                                }
                            })
                            .catch((err) => console.log(err));
                    }}
                >다음</Button>
            </div>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({

    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        marginBottom: 80
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    checkButton: {
        backgroundColor: '#303a53',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 13,
        borderRadius: 25,
        padding: 5, width: '80%'
    },
    formControl: {
        width: '100%',
        textAlign: 'left',
        textIndent: 10,
        marginTop: theme.spacing(3),
    },
    inputTilie: {
        fontSize: 13,
        height: 50,
        lineHeight: '50px',
        textAlign: 'left',
        margin: '5px 0'
    },
    selectBox:{
        padding: '15px 5px !important',
        textIndent: 5
    },
    bottonBtn: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 60,
        background: 'rgb(43,66,124)',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight: 'bold',
        '&:hover': {
            background: 'rgb(43,66,124)',
        },
    },


}));
export default BandJoin;
