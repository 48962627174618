import React from "react";
import {
    makeStyles,
    Container,
    Button,
    BottomNavigation,
}from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import navBg from '../resources/main_home_icon/main_bottom_pc.png';
function BottomMenu() {
    const classes = useStyles();
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white'}}>
                {/*하단메뉴*/}
                <div className={classes.bottomNavWrap}>
                    <BottomNavigation
                        iconOnly
                        className={classes.bottomNav}
                    >
                        <Grid container style={{marginTop: 45}}>
                            <Grid item color="primary" style={{width: '20%'}}>
                                <Button style={{borderRadius: '50%'}}>
                                    <img src={require('../resources/main_home_icon/total_member_bottom.png')} style={{height: 45}}/>
                                </Button>
                            </Grid>
                            <Grid item color="primary" style={{ width: '20%'}}>
                                <Button style={{borderRadius: '50%'}}>
                                    <img src={require('../resources/main_home_icon/board.png')} style={{height: 45}}/>
                                </Button>
                            </Grid>
                            <Grid item color="primary"  style={{width: '20%'}}>
                                <div className={classes.bottomNavHome}>
                                    <span className={classes.homeInner}></span>
                                </div>
                                <Button style={{borderRadius: '50%', marginTop: -18}}>
                                    <img src={require('../resources/main_home_icon/home.png')} style={{height: 45}}/>
                                </Button>
                            </Grid>
                            <Grid item color="primary" style={{width: '20%'}}>
                                <Button style={{borderRadius: '50%'}}>
                                    <img src={require('../resources/main_home_icon/welfare_commu_bottom.png')} style={{height: 45}}/>
                                </Button>
                            </Grid>
                            <Grid item color="primary" style={{width: '20%'}}>
                                <Button style={{borderRadius: '50%'}}>
                                    <img src={require('../resources/main_home_icon/share.png')} style={{height: 45}}/>
                                </Button>
                            </Grid>
                        </Grid>

                    </BottomNavigation>
                </div>
            </Container>
        </div>

    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        backgroundColor: 'rgb(249,250,252)',
        padding: '0 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bottomNavWrap: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        backgroundImage:`url(${navBg})`,
        backgroundRepeat : 'repeat-x',backgroundSize: "cover", backgroundPosition: 'top center', width: '100%', height: 130,
    },
    bottomNav:{
        position: 'fixed',
        left: '50%',
        bottom: 0,
        transform: 'translateX(-50%)',
        width: '35%',
        minWidth: '370px',
        maxWidth: 480,
        height: '105px',
        backgroundColor: 'transparent',
    },
    bottomNavHome:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-45%)',
        zIndex: -1,
        width: '60px',
        height: '60px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        boxShadow: '0 0 20px rgba(0,0,0,0.07)'
    },
    homeInner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '45px',
        height: '45px',
        /*backgroundColor: 'rgb(48,58,83)',*/
        backgroundColor: 'rgb(200,208,226)',
        borderRadius: '50%',
    },
}));


export default BottomMenu;