import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    TextField,
    FormControl,
    MenuItem,
    Select,
    Avatar,
    Grid, Chip, IconButton, CircularProgress
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoIosCamera } from "react-icons/io";
import Badge from '@material-ui/core/Badge';
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/icons/KeyboardArrowRight";
import customConfirm from "../component/customConfirm";
import customAlert from "../component/customAlert";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


function AddComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}

function MyInfo({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [name, setName] = React.useState();
    const [phone, setPhone] = React.useState();
    const [position, setPosition] = React.useState();
    const [profile, setProfile] = React.useState();
    const [company, setCompany] = React.useState();
    const [point_total, setPoint_total] = React.useState();
    const [card_regist, setCard_regist] = React.useState();
    const [logo, setLogo] = React.useState();

    const [opList,setOpList] = React.useState([]);
    const  [selectedList, setSelectedList] = React.useState({});//선택한 옵션값

    let tmp_data = {};
    let update_data = {};
    /////////////////////////////////////////
    const [modal, setModal] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);

    const [imageSource,setImageSource] = React.useState(null);
    const [upfile, setUpfile] = React.useState('');
    const formData = new FormData();
    let fileReader = new FileReader();
    const [refresh, setRefresh] = React.useState(false);

    const [loading, setLoading] = React.useState(true);

    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        Axios({
            url : 'https://sbc.smartbc.kr/sb/delete_user.php',
            method : 'get',
            params : {
                u_id : u_id,
                b_id : b_id,
            }
        }).then(function (res) {
            console.log('user delete success');
            console.log(res.data);
            if(res.data == 200){
                try {
                    sessionStorage.removeItem('@u_id');
                } catch(e) {
                    // remove error
                }
                customAlert('회원탈퇴를 완료하였습니다.');
                history.replace('/' + match.params.JoinCode + '/PcLogin');
                // navigation.reset({index: 0, routes: [{name: 'SignUp'}]});
            }else{
                customAlert('회원탈퇴를 실패하였습니다. 관리자에게 문의부탁드립니다.');
            }
        }).catch(function (e) {
            console.log('user delete fail');
        })
    };

    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

    useEffect(() => {
        console.log('모바일?',isMobile);
        Axios({
            url: 'https://sbc.smartbc.kr/sb/myInfo.php',
            method: 'get',
            params: {
                u_id: u_id,
                b_id: b_id,
            },
        })
            .then((result) => {

                    console.log('마이인포',result.data);
                    // console.log('뭐냐아',JSON.parse(result.data.op_val));
                    setOpList(result.data.op1_list);
                    // if(result.data.op1_list.length > 0){
                    //     result.data.op1_list.map((value, i)=> {
                    //         setTmpValue(selectedList[Number(value[0].id)]);
                    //     });
                    // }
                    setPhone( result.data.phone);
                    setName( result.data.name);
                    setPosition( result.data.position);
                    setCompany (result.data.company);
                    setPoint_total (result.data.point_total);
                    setProfile( result.data.profile);
                    setCard_regist( result.data.card_regist);
                    setLogo(result.data.logo);

                    if(result.data.op_val){
                        setSelectedList(JSON.parse(result.data.op_val));
                    }

                setLoading(false);

            })
            .catch((err) => console.log(err.message));

    },[refresh]);

    const OptionList = ({option1,option2,selList ,s})=> {
        const [tmpValue,setTmpValue] = React.useState(selList);
        let items = new Array();
        option2.map((value)=> {
            return (
                items.push( <MenuItem value={value.id} >{value.name}</MenuItem>)
            );
        });
        return(
            <Grid container spacing={2}>
                <Grid item xs={3} className={classes.selectTilie}>{option1.name}</Grid>
                <Grid item xs={9}>
                    <FormControl variant="filled" className={classes.formControl}>
                        <Select
                            // displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{backgroundColor: '#fff'}}
                            // classes={{root: classes.selectInput}}
                            value={tmpValue}
                            onChange={(event) => {
                                console.log('선택',event.target.value);
                                setTmpValue(event.target.value);
                                let tmp = selectedList;
                                tmp[option1.id] = event.target.value;
                                setSelectedList(tmp);
                            }}
                        >
                            <MenuItem value="0">선택</MenuItem>
                            {items}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'MY 정보'} back={1} history={history} match={match}/>
                <div className={classes.paper}>

                    {/*custom_confirm*/}
                    <div>
                        <Dialog
                            open={confirmShow}
                            onClose={handleCloseN}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {confirmMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseN} color="primary">
                                    {'아니오'}
                                </Button>
                                <Button onClick={handleCloseY} color="primary" autoFocus>
                                    {'예'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>


                    {/* 상단 정보 */}
                    <Grid container alignItems='center'direction='row'>
                        <Grid item xs={2} style={{marginRight: 10}}>
                            <Badge
                                className='camera_badge'
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={<IoIosCamera style={{color: 'white',marginLeft:1,fontSize: 20}}  />}
                                component="label" // 이거 써야 <input/> 가능
                                onClick={()=>{
                                    if(navigator.platform){
                                        if(isMobile){
                                            customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다.");//pc
                                        }
                                    }
                                }}
                            >
                                {!isMobile ?
                                    <input id={"file-input"} style={{display: 'none'}} type="file" name="file-input"
                                           accept={"image/gif,image/jpeg,image/png,image/heic,image/HEIC"}
                                           onChange={(event) => {

                                               //취소가 아닐 때 (확인 눌렀을 때)
                                               if (event.target.value != '') {
                                                   // 읽기
                                                   fileReader.readAsDataURL(event.target.files[0]);
                                                   //로드 한 후
                                                   fileReader.onload = function () {
                                                       // console.log('이미지?',fileReader.result);
                                                       //로컬 이미지를 보여주기
                                                       setImageSource(fileReader.result);

                                                       // //썸네일 이미지 생성
                                                       // let tempImage = new Image(); //drawImage 메서드에 넣기 위해 이미지 객체화
                                                       // tempImage.src = fileReader.result; //data-uri를 이미지 객체에 주입
                                                       // tempImage.onload = function() {
                                                       //     //리사이즈를 위해 캔버스 객체 생성
                                                       //     var canvas = document.createElement('canvas');
                                                       //     var canvasContext = canvas.getContext("2d");
                                                       //
                                                       //     //캔버스 크기 설정
                                                       //     canvas.width = 100; //가로 100px
                                                       //     canvas.height = 100; //세로 100px
                                                       //
                                                       //     //이미지를 캔버스에 그리기
                                                       //     canvasContext.drawImage(this, 0, 0, 100, 100);
                                                       //     //캔버스에 그린 이미지를 다시 data-uri 형태로 변환
                                                       //     var dataURI = canvas.toDataURL("image/jpeg");
                                                       //
                                                       //     //썸네일 이미지 보여주기
                                                       //     document.querySelector('#thumbnail').src = dataURI;
                                                       //
                                                       //     //썸네일 이미지를 다운로드할 수 있도록 링크 설정
                                                       //     document.querySelector('#download').href = dataURI;
                                                       // };
                                                   };

                                                   // console.log('파일경로',event.target.files[0]);
                                                   let fileVal = event.target.files[0];
                                                   let fileName = event.target.value.split('\\').pop();
                                                   setModal(true);
                                                   setUpfile(fileVal);

                                                   //파일 확장자 대문자이면 인식 못 함,,,(ex.PNG(x),png(o))

                                               }//if(event.target.value!='')

                                               console.log('파일입니다.', event.target.value);
                                           }}/>
                                           :null}


                            {profile?
                                <Avatar alt="사진" src={"https://sbc.smartbc.kr"+profile} className={classes.large}/>
                                :
                                <Avatar alt="사진" src={require('../resources/profile_icon.png')} className={classes.large}/>
                            }
                            </Badge>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                        {name}<span style={{fontSize: 11, color: 'rgb(181,181,181)', marginLeft: 5}}>{position}</span>
                                    </Typography>
                                    <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                        {company}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} >
                            {card_regist == 'N' ?
                                <AiFillPlusCircle size={45} style={{marginTop:10}} onClick={() => {
                                    history.push('/'+match.params.JoinCode+'/MyCardRegist');
                                    // history.push('/MyCardRegist');
                                }} />
                                :
                                <Button
                                    type="submit"
                                    color="primary"
                                    style={{
                                        backgroundColor: 'rgb(48,58,83)',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        borderRadius: 25,
                                        padding: 10,
                                        marginLeft: 10
                                    }}
                                    onClick={() => {
                                        history.push('/'+match.params.JoinCode+'/MyCardDetail');
                                    }}>
                                    수정
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    <Button color="primary"  className={classes.listBox}
                            style={{position: 'relative', cursor: 'pointer', width: '100%', color: 'rgb(51,51,51)', border: '1px solid rgb(157,165,185)', padding: '15px 20px', margin: '20px 0', borderRadius: 10}}
                            onClick={()=>{
                                history.push('/'+match.params.JoinCode+'/PointList');
                                // history.push('/PointList');
                            }}>
                        <Grid align='left' item xs={5} style={{fontSize: 15}}>포인트잔액</Grid>
                        <Grid item xs container direction="row" spacing={0}>
                            <Grid item xs={9}><Typography align='right' style={{fontWeight: 'bold'}}><span style={{color: 'rgb(239,113,113)'}}>{point_total?AddComma(point_total):0}</span> P</Typography></Grid>
                            <Grid item xs={3}><img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', marginTop: 5}}/></Grid>
                        </Grid>
                        {/*<Grid item xs={4}  direction='column' spacing={2}>
                            <Typography align='left'><span style={{color: 'rgb(#239,113,113)', display: 'inline-block'}}>1,000</span>원</Typography>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px'}}/>
                        </Grid>*/}
                    </Button>

                    <Typography align= 'center'
                        style={{fontSize: '13px', backgroundColor: 'rgb(239,242,246)', padding: 10, width: '100%', borderRadius: 10}}>
                        모임 부가 정보 수정하기
                    </Typography>
                    <form className={classes.form} noValidate style={{marginBottom: 20}}>
                        {opList.length>0?
                            opList.map((value)=> <OptionList key={value[0].id} option1={value[0]} option2={value[1]} selList={selectedList[value[0].id]} s={value[0].id}/>)
                        :
                            <Typography align= 'center'
                                        style={{fontSize: '13px', backgroundColor: 'rgb(239,242,246)', padding: 25, width: '100%', borderRadius: 10,marginTop:20}}>
                                    {'모임에서 설정한 옵션이 없습니다.'}
                            </Typography>
                        }
                    </form>
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                        style={{color: 'white',backgroundColor:'#303a53',fontWeight:'bold',fontSize :15,borderRadius : 10,padding :10, margin: '20px 0'}}
                        onClick={()=>{
                            let status = true;
                            if(opList.length ==  Object.keys(selectedList).length){
                                for (const [key, value] of Object.entries(selectedList)) {
                                    if(value==0){
                                        status = false;
                                    }
                                }
                            } else {
                                status = false;
                            }

                            if(status) {
                                tmp_data['u_id'] = u_id;
                                tmp_data['b_id'] = b_id;
                                tmp_data['selectedList'] = selectedList;

                                update_data = JSON.stringify(tmp_data);

                                Axios({
                                    url: 'https://sbc.smartbc.kr/sb/req_info_update.php'  ,
                                    method: 'get',
                                    params: {
                                        update_data:update_data
                                    },
                                    // resultType:'text',
                                })
                                    .then((result) => {
                                        if (result.data.status == 11001) {
                                            console.log(result.data);
                                            customAlert(result.data.result_msg);
                                        } else {
                                            console.log(result.data);
                                        }
                                    })
                                    .catch((err) => console.log(err.message));
                            }else{
                                customAlert('값들을 모두 선택해 주세요');
                            }
                        }}
                    >
                        부가정보 수정
                    </Button>

                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                        style={{color: '#303a53',fontWeight:'bold',fontSize :15,borderRadius : 10,padding :7, border: '3px solid #303a53', margin: '5px 0'}}
                        onClick={()=>{
                            setModal2(true);

                        }}
                    >
                       모임 탈퇴하기
                    </Button>

                    {/* 버튼 */}
                    {/*<Button*/}
                    {/*    type="submit"*/}
                    {/*    fullWidth*/}
                    {/*    variant="contained"*/}
                    {/*    color="primary"*/}
                    {/*    className={classes.bottonBtn}*/}
                    {/*    onClick={()=>{*/}
                    {/*        let status = true;*/}
                    {/*        if(opList.length ==  Object.keys(selectedList).length){*/}
                    {/*            for (const [key, value] of Object.entries(selectedList)) {*/}
                    {/*                if(value==0){*/}
                    {/*                    status = false;*/}
                    {/*                }*/}
                    {/*            }*/}
                    {/*        } else {*/}
                    {/*            status = false;*/}
                    {/*        }*/}

                    {/*        if(status) {*/}
                    {/*            tmp_data['u_id'] = u_id;*/}
                    {/*            tmp_data['b_id'] = b_id;*/}
                    {/*            tmp_data['selectedList'] = selectedList;*/}

                    {/*            update_data = JSON.stringify(tmp_data);*/}

                    {/*            Axios({*/}
                    {/*                url: 'https://sbc.smartbc.kr/sb/req_info_update.php'  ,*/}
                    {/*                method: 'get',*/}
                    {/*                params: {*/}
                    {/*                    update_data:update_data*/}
                    {/*                },*/}
                    {/*                // resultType:'text',*/}
                    {/*            })*/}
                    {/*                .then((result) => {*/}
                    {/*                    if (result.data.status == 11001) {*/}
                    {/*                        console.log(result.data);*/}
                    {/*                        alert(result.data.result_msg);*/}
                    {/*                    } else {*/}
                    {/*                        console.log(result.data);*/}
                    {/*                    }*/}
                    {/*                })*/}
                    {/*                .catch((err) => console.log(err.message));*/}
                    {/*        }else{*/}
                    {/*            alert('값들을 모두 선택해 주세요');*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    수정하기*/}
                    {/*</Button>*/}
                </div>

                {modal?
                    <>
                        <Card className={classes.popUp}>
                            <Typography align='right'>
                                <IconButton>
                                    <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                                        setModal(false);
                                    }}/>
                                </IconButton>
                            </Typography>
                            <CardContent style={{paddingTop: 5}}>
                                <Typography>
                                    <span style={{display: 'block',transform: 'translateX(-50%)',
                                        left: '50%',
                                        position: 'relative',
                                        width: '70px',
                                        height: '70px',
                                        borderRadius: '50%',
                                        overflow: 'hidden'}}>
                                        <img src={imageSource} style={{maxWidth: '100px',height: 'auto',
                                            position: 'absolute',
                                            top: '50%',
                                            width: '90px',
                                            left: '50%',
                                            transform:'translate(-50%, -50%)'}}/>
                                    </span>
                                </Typography>
                                <Typography style={{marginTop: 20}}>프로필을 등록하시겠습니까?</Typography>
                            </CardContent>
                            <Button
                                color="primary"
                                className={classes.groupAdd}
                                style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :8, marginBottom: 15}}
                                onClick={() => {
                                    formData.append("profile", upfile);
                                    Axios({
                                        method: 'POST',
                                        url: 'https://sbc.smartbc.kr/sb/upload_image_file.php',
                                        data: formData,
                                        params: {
                                            u_id:u_id,
                                        },
                                        headers: {
                                            'Content-Type': 'multipart/form-data;'
                                        }})
                                        .then((result) => {
                                            customAlert(result.data);
                                        })
                                        .catch((err) => console.log(err.message));

                                    setModal(false);
                                    setRefresh(!refresh);
                                    history.go(0);
                                }}>
                               등록하기
                            </Button>
                            <Button
                                color="primary"
                                className={classes.groupAdd}
                                style={{width: 200, backgroundColor: 'white', color: '#303a53',fontWeight:'bold',fontSize : 15,borderRadius : 25,  border: '2px solid #303a53', marginBottom: 20}}
                                onClick={() => {
                                    setModal(false);
                                }}>
                               취소
                            </Button>
                        </Card>
                        <div className={classes.popUpBg} />
                    </>
                    : null}
                {modal2?
                    <>
                        <Card className={classes.popUp}>
                            <Grid container alignItems='center' direction='row' style={{width: '100%',height:25,marginTop:15,marginLeft:50}}>
                                <Grid item xs={8} style={{textAlign :'center',fontWeight:'bold'}}>
                                    {'가입 모임 탈퇴하기'}
                                </Grid>
                                <Grid item xs={2}>
                                    <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{setModal2(false);}}/>
                                </Grid>

                            </Grid>
                            <CardContent style={{}}>
                                <div style={{
                                    border: '2px solid #9da5b9',
                                    display: 'flex',
                                    padding: 5,
                                    alignItems: 'center',
                                    borderRadius: 10
                                }}>
                                    {/*협회 목록 리스트*/}
                                    <Grid container style={{alignItems :'center'}}>
                                        <Grid item xs={11} style={{textAlign :'left'}}>
                                            {logo?
                                                <img src={'https://sbc.smartbc.kr'+logo} style={{width:200,height: 40}}/>
                                                :
                                                <img src={require('../resources/logo_icon_update/basic_logo.png')} style={{width:200,height: 40}}/>
                                            }
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Icon style={{fontSize :30}}/>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Typography style={{width: 270, backgroundColor: 'rgb(239,242,246)', color: 'black',fontWeight:'bold',fontSize : 13,borderRadius : 10,padding :8,marginTop:15, marginBottom: 5}}>
                                    해당 모임에서 탈퇴를 하시겠습니까?</Typography>
                            </CardContent>
                            <Button
                                color="primary"
                                className={classes.groupAdd}
                                style={{width: 270, backgroundColor: 'white', color: '#303a53',fontWeight:'bold',fontSize : 13,borderRadius : 10,  border: '3px solid #303a53', marginBottom: 20}}
                                onClick={() => {
                                    setConfirmMessage('정말 탈퇴 하시겠습니까?');
                                    setConfirmShow(true);

                                    // let aa = customConfirm('정말 탈퇴 하시겠습니까?');
                                    // if(aa) {
                                    //     Axios({
                                    //         url : 'https://sbc.smartbc.kr/sb/delete_user.php',
                                    //         method : 'get',
                                    //         params : {
                                    //             u_id : u_id,
                                    //             b_id : b_id,
                                    //         }
                                    //     }).then(function (res) {
                                    //         console.log('user delete success');
                                    //         console.log(res.data);
                                    //         if(res.data == 200){
                                    //             try {
                                    //                 sessionStorage.removeItem('@u_id');
                                    //             } catch(e) {
                                    //                 // remove error
                                    //             }
                                    //             customAlert('회원탈퇴를 완료하였습니다.');
                                    //             history.replace('/' + match.params.JoinCode + '/PcLogin');
                                    //             // navigation.reset({index: 0, routes: [{name: 'SignUp'}]});
                                    //         }else{
                                    //             customAlert('회원탈퇴를 실패하였습니다. 관리자에게 문의부탁드립니다.');
                                    //         }
                                    //     }).catch(function (e) {
                                    //         console.log('user delete fail');
                                    //     })
                                    // }
                                }}>
                                모임 탈퇴하기
                            </Button>
                        </Card>
                        <div className={classes.popUpBg} />
                    </>
                    : null}
                {loading?
                    <>
                        <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                        <div className={classes.popUpBg} />
                    </>
                    :null}
            </Container>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '30px 40px 60px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    title: {
        fontSize: 14,
    },
    formControl: {
        width: '100%',
        textAlign: 'left',
        textIndent: 10,
        marginTop: theme.spacing(3),
    },
    selectTilie: {
        fontSize: 15,
        textAlign: 'center',
        marginTop: theme.spacing(4),
        backgroundColor: 'rgb(249,250,252)',
        width: 105,
        height: 60,
        lineHeight: '60px',
        borderRadius: 10,
        marginRgiht: 10
    },
    selectInput:{
        padding: '23px 12px 20px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    bottonBtn: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 60,
        background: '#303a53',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight:'bold',
        '&:hover': {
            background: '#303a53',
        },

    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    "@global":{
        ".camera_badge .MuiBadge-badge":{
            padding: 3,
            borderRadius: '50%',
            backgroundColor: 'rgb(48,58,83)',
            width: 27,
            height: 27
        }
    }


}));


export default MyInfo;
