
import {createAction, handleActions} from 'redux-actions';

const TOGGLE = 'hamburger/TOGGLE';

export const toggle = createAction(TOGGLE);

const initialState = {
    status: false,
};

const hamburger = handleActions(
    {
        [TOGGLE]: (state, action) => ({...state, status: !state.status}),
    },
    initialState,
);

export default hamburger;
