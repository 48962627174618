import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Grid,
} from '@material-ui/core';
import PcHeader from "../PcHeader";
import Axios from "axios";

function AddComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}

function AdminMain({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');
    //그룹 관리자 여부
    useEffect(() => {
        sessionStorage.setItem('@admin','1');
    })

    //그룹 관리자 여부
    const admin = sessionStorage.getItem('@admin');

    // console.log('-------------');
    // console.log('session value');
    // console.log('admin = '+ admin);
    // console.log('-------------');
    const [band_name,setBand_name] = React.useState('');
    const [join_code,setJoin_code] = React.useState('');
    const [li,setLi] = React.useState('');
    const [band_user_count,setBand_user_count] = React.useState('');
    const [board_count,setBoard_count] = React.useState('');
    const [point,setPoint] = React.useState('');
    // const [spinner, setSpinner] = React.useState(true);

    const[menuName3,setMenuName3] = React.useState('NEWS');
    const[menuName4,setMenuName4] = React.useState('협력해요!');
    const[menuName5,setMenuName5] = React.useState('자유게시판');

    const [news_cnt, setNews_cnt] = React.useState(0);
    const [cooperation_cnt, setCooperation_cnt] = React.useState(0);
    const [free_board_cnt, setFree_board_cnt] = React.useState(0);


    // function updateData(){
    //     Axios({
    //         url: 'https://sbc.smartbc.kr/sb/band_admin/admin_main_info.php',
    //         method: 'get',
    //         params: {
    //             u_id: u_id,
    //             b_id: b_id,
    //         },
    //         // resultType:'text',
    //     })
    //         .then((result) => {
    //             if (result.data.status == 16001) {
    //                 console.log('메인 다시');
    //                 // console.log(result.data);
    //                 setBand_name(result.data.band_name);
    //                 setJoin_code(result.data.join_code);
    //                 setLi(result.data.li);
    //                 setBand_user_count(result.data.band_user_count);
    //                 setBoard_count(result.data.board_count);
    //                 setPoint(result.data.point);
    //                 // setSpinner(false);
    //
    //             } else {
    //                 // console.log(result);
    //                 // console.log(result.data);
    //
    //             }
    //         })
    //         .catch((err) => console.log(err.message));
    // }

    useEffect(()=>{
        Axios({
            url: 'https://sbc.smartbc.kr/sb/band_admin/admin_main_info.php' ,
            method: 'get',
            params: {
                u_id:u_id,
                b_id:b_id,
            },
        })
            .then((result) => {
                console.log(result.data);
                if (result.data.status == 16001) {
                    if(result.data.menu_name.length>0){
                        setMenuName3(JSON.parse(result.data.menu_name)[2]);
                        setMenuName4(JSON.parse(result.data.menu_name)[3]);
                        setMenuName5(JSON.parse(result.data.menu_name)[4]);
                    }

                    setBand_name(result.data.band_name);
                    setJoin_code(result.data.join_code);
                    setLi(result.data.li);
                    setBand_user_count(result.data.band_user_count);
                    setBoard_count(result.data.board_count);
                    setNews_cnt(result.data.news_cnt);
                    setCooperation_cnt(result.data.cooperation_cnt);
                    setFree_board_cnt(result.data.free_board_cnt);
                    setPoint(result.data.point);
                    // setSpinner(false);

                } else {

                }
            })
            .catch((err) => console.log(err.message));
    },[]);

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '30px'}}>
                <CssBaseline/>
                <PcHeader text={'스마트모임 관리자'} adminsetting={1} history={history} match={match} />
                <div className={classes.paper}>
                    {li?
                        <img src={"https://sbc.smartbc.kr" + li} style={{width: 250, margin: '80px 0 50px'}}/>
                    :
                        <img src={require('../../resources/logo_icon_update/스마트그룹_기본_BI.png')} style={{width: 250, margin: '80px 0 50px'}}/>
                    }
                    <Typography style={{fontSize: 18}}>{band_name}</Typography>
                        <Typography style={{fontSize: 18, color: 'rgb(112,112,112)'}}>(초대코드 : {join_code})</Typography>
                    <div style={{width: '100%', marginTop: 54}}>
                        <Grid container alignItems='center' color="primary" className={classes.listBox}   onClick={()=>{
                            history.push({
                                pathname : '/'+match.params.JoinCode+'/AdminJoinUser',
                                state : {searchList : null}
                            });
                            // navigation.navigate('AdminJoinUser',{updateData:updateData}); // or push
                        }}>
                            <Grid xs={6}><Typography align='left'>가입회원</Typography></Grid>
                            <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{band_user_count?AddComma(band_user_count):0}</span>명</Typography></Grid>
                        </Grid>
                        <Grid container alignItems='center' color="primary" className={classes.listBox} onClick={() => {
                            history.push({
                                pathname :  '/'+match.params.JoinCode+'/NoticeBoard',
                                state : {
                                    table : 'band_board1',
                                }
                            });
                        }}>
                            <Grid xs={6}><Typography align='left'>{menuName3} 관리</Typography></Grid>
                            <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{board_count?AddComma(news_cnt):0}</span>건</Typography></Grid>
                        </Grid>
                        <Grid container alignItems='center' color="primary" className={classes.listBox} onClick={() => {
                            history.push({
                                pathname :  '/'+match.params.JoinCode+'/NoticeBoard',
                                state : {
                                    table : 'band_board2',
                                }
                            });
                        }}>
                            <Grid xs={6}><Typography align='left'>{menuName4} 관리</Typography></Grid>
                            <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{board_count?AddComma(cooperation_cnt):0}</span>건</Typography></Grid>
                        </Grid>
                        <Grid container alignItems='center' color="primary" className={classes.listBox} onClick={() => {
                            history.push({
                                pathname :  '/'+match.params.JoinCode+'/NoticeBoard',
                                state : {
                                    table : 'band_board3',
                                }
                            });
                        }}>
                            <Grid xs={6}><Typography align='left'>{menuName5} 관리</Typography></Grid>
                            <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{board_count?AddComma(free_board_cnt):0}</span>건</Typography></Grid>
                        </Grid>
                        <Grid container alignItems='center' color="primary" className={classes.listBox}
                        onClick={()=>{
                            history.push( '/'+match.params.JoinCode+'/AdminPointList');
                        }}>
                            <Grid xs={6}><Typography align='left'>모임 누적 포인트</Typography></Grid>
                            <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{point?AddComma(point):0}</span>P</Typography></Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '0 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    listBox: {
        height: '57px',
        border: '2px solid rgb(189,204,240)',
        borderRadius: '10px',
        marginBottom: '10px',
        padding: '0 20px'
    },
}));


export default AdminMain;
