import React, {useEffect, useState} from "react";
import {
    Divider,
    FormControl,
    Select,
    Grid, Box,
    Container, CssBaseline, makeStyles,
    TextareaAutosize, IconButton, Typography, Button,
    InputBase, FormControlLabel, Checkbox, CircularProgress
} from '@material-ui/core';
import PcHeader from "./PcHeader";
import RoundButton from "../component/RoundButton";
import Icon from "@material-ui/icons/ArrowBack";
import Axios from "axios";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import { IoIosCall } from "react-icons/io";
import { RiMessage2Line } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import { ImLocation } from "react-icons/im";
import { SiTwitter } from "react-icons/si";
import { AiOutlineHome } from "react-icons/ai";
import { MdShare } from "react-icons/md";
import Moment from 'moment';
import customAlert from "../component/customAlert";
import customConfirm from "../component/customConfirm";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


function AddHyphen(num) {
    let regexp;
    if(num){
        if(num.length==8){//설립일
            regexp = num.replace(/[^0-9]/g, "").replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        }else if(num.length==10){//사업자등록번호
            regexp = num.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
        }else if(num.length==13){//법인등록번호
            regexp = num.replace(/[^0-9]/g, "").replace(/(\d{6})(\d{7})/, '$1-$2');
        } else{
            regexp = num
        }
    }
    return regexp;
}

//리뉴얼
function CompanyDetail({history,match}) {

    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [tab, setTab] = React.useState(false);

    const [company,setCompany] = useState(new Array());
    const [update_dt,setUpdate_dt] = useState('');

    const [refresh, setRefresh] = React.useState(false);

    const [loading, setLoading] = React.useState(true);

    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        window.open("https://info.smartbc.kr/b2a");

    };

    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

    useEffect(() => {

        console.log('타켓아이디',history.location.state.ecredible_id);
        if(history.location.state.tab){
            console.log('tab : ',history.location.state.tab);
            setTab(true);
        }
        Axios({
            url: "https://sbc.smartbc.kr/sb/company_detail.php", //리뉴얼
            method: 'get',
            params: {
                target_id: Number(history.location.state.ecredible_id), //ecredible_id
            }
        })
            .then(function (response) {
                if (response.data.status == 12001) {
                    console.log('기업 정보',response.data.company_data);
                    setCompany(response.data.company_data);
                    setLoading(false);

                    if(response.data.company_data.update_dt != null){
                        setUpdate_dt(response.data.company_data.update_dt.substr(0,10));
                    }else{
                        setUpdate_dt(response.data.company_data.create_dt.substr(0,10));
                    }
                    // console.log(update_dt);
                }else{
                    setLoading(false);
                    customAlert(response.data.result_msg);
                }


            })
            .catch(function (error) {
                console.log('detail error' + error);
            });
    }, [refresh]);

    const InputContainer=({label,text,call,message, email,location, link})=> {
        return (
            <div>
                <Grid container style={{padding: 15,paddingLeft:30,paddingRight:5}}>
                    <Grid item xs={4} style={{textAlign: 'left'}}>{label}</Grid>
                    <Grid item xs={8} style={{textAlign :'left', position: 'relative',paddingRight:30}}>
                        {text}
                        {/*{profile?*/}
                        {/*    <span style={{display: 'inline-block', position: 'absolute', right: 12, top: '50%', width: 35, height: 35, transform: 'translateY(-50%)', overflow: 'hidden',borderRadius: '50%'}}>*/}
                        {/*        <img src={"https://sbc.smartbc.kr" + profile} style={{width: 40,position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)'}}/>*/}
                        {/*    </span>*/}
                        {/*    :null}*/}
                        {call?
                            <IconButton style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(navigator.platform){
                                                if(isMobile){
                                                    setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                    setConfirmShow(true);
                                                    // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                    // if (answer) window.open("https://info.smartbc.kr/b2a");
                                                }else{
                                                    customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                                }
                                            }
                                        }}>
                                {/*<img src={require('../resources/call_icon.png')} style={{width: 35}}/>*/}
                                <IoIosCall size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {message?
                            <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(navigator.platform){
                                                if(isMobile){
                                                    setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                    setConfirmShow(true);
                                                    // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                    // if (answer) window.open("https://info.smartbc.kr/b2a");
                                                }else{
                                                    customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                                }
                                            }
                                        }}>
                                {/*<img src={require('../resources/call_icon.png')} style={{width: 35}}/>*/}
                                <RiMessage2Line size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {email?
                            <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}
                            onClick={()=>{
                                window.open("mailto:"+email);
                            }}>
                                <GoMail size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {location?
                            <IconButton style={{position: 'absolute',right:0, padding:15, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            window.open('about:blank').location.href='https://maps.apple.com/maps?address='+location;
                                        }}>
                                <ImLocation size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {link?
                            <IconButton style={{position: 'absolute',right:0, padding:15, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(link.indexOf('http://')!=-1 ||
                                                link.indexOf('https://')!=-1){
                                                //문자열 포함
                                                window.open('about:blank').location.href=link;//새창 o
                                            }else{
                                                window.open('about:blank').location.href='https://'+link;//새창 o
                                            }
                                        }}>
                                <img src={require('../resources/homepage_icon.png')} style={{width: 20}}/>
                            </IconButton>
                            :null}

                    </Grid>
                </Grid>
                <Divider/>
            </div>
        );
    }


    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center', marginTop: tab ? -10 : 65}}>
            <CssBaseline/>
            {/*header*/}
            { tab ?  null : <PcHeader text={'기업 상세'} back={1} history={history} match={match}/> }
            {/*<PcHeader text={'기업 상세'} back={1} history={history} match={match}/>*/}
            {/*body*/}

            {/*상단*/}
            <div style={{width:'100%',borderBottomWidth:0.5,flexDirection:'row',backgroundColor: '#353245',marginTop:-10}}>
                <div style={{width:'100%',height:'100%',paddingLeft:30,paddingTop:20,paddingRight:30}}>
                    <div style={{flexDirection:'column'}}>
                        <div style={{flexDirection: 'row',marginBottom:10,display:'flex'}}>
                            { company.company_size ?
                                <div style={{alignItems: 'center',borderStyle:'solid',borderColor:'white',backgroundColor:'white',borderWidth:1, borderRadius: 50,marginRight:5}}>
                                    <Typography style={{padding:2,paddingRight:10,paddingLeft:10,fontSize: 12, color: 'black', fontWeight: 'bold'}}>{company.company_size}</Typography>
                                </div>
                                : null
                            }
                            { company.company_classification ?
                                <div style={{alignItems: 'center',borderStyle:'solid',borderColor:'white',backgroundColor:'white',borderWidth:1, borderRadius: 50,marginRight:5}}>
                                    <Typography style={{padding:2,paddingRight:10,paddingLeft:10,fontSize: 12, color: 'black', fontWeight: 'bold'}}>{company.company_classification}</Typography>
                                </div>
                                : null
                            }
                            { company.company_closing =='O' ?
                                <div style={{alignItems: 'center',backgroundColor:'red',borderStyle:'solid',borderColor:'red',borderWidth:1, borderRadius: 50}}>
                                    <Typography style={{padding:2,paddingRight:10,paddingLeft:10,fontSize: 12, color: 'white', fontWeight: 'bold'}}>{company.AI_ranking}</Typography>
                                </div>
                                : null
                            }
                        </div>
                        <div>
                            <Typography style={{fontSize:20,fontWeight:'bold',color:'white',paddingBottom: 25,textAlign:'initial'}}>{company.company_name}</Typography>
                        </div>
                        <div style={{borderBottomStyle: 'solid',borderBottomWidth: 0.5, borderBottomColor: 'black'}}></div>
                        <Typography style={{fontSize:13,color:'white',paddingBottom:10,paddingTop:10,textAlign:'initial'}}>업데이트 일자 : {update_dt}</Typography>
                    </div>
                </div>

            </div>


            {/*하단*/}
            {company.business_regist_num?
                <>
                    <InputContainer label={'사업자번호'} text={AddHyphen(company.business_regist_num)} />
                    <Divider/>
                </>
                :null}
            {company.corporate_regist_num?
                <>
                    <InputContainer label={'법인번호'} text={AddHyphen(company.corporate_regist_num)} />
                    <Divider/>
                </>
                :null}
            {company.representative_name?
                <>
                    <InputContainer label={'대표자명'} text={company.representative_name} />
                    <Divider/>
                </>
                :null}
            {company.standard_industry_classification?
                <>
                    <InputContainer label={'표준산업분류명'} text={company.standard_industry_classification} />
                    <Divider/>
                </>
                :null}
            {company.company_anniversary?
                <>
                    <InputContainer label={'설립일'} text={AddHyphen(company.company_anniversary)} />
                    <Divider/>
                </>
                :null}
            {company.employees_num?
                <>
                    <InputContainer label={'종업원수'} text={(company.average_salary) ?company.employees_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'명 | '+ company.average_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '만원' : company.employees_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+'명'} />
                    <Divider/>
                </>
                :null}
            {company.address?
                <>
                    <InputContainer label={'주소'} text={company.address} location={company.address}/>
                    <Divider/>
                </>
                :null}
            {company.homepage?
                <>
                    <InputContainer label={'홈페이지'} text={company.homepage} link={company.homepage}/>
                    <Divider/>
                </>
                :null}
            {company.tel?
                <>
                    <InputContainer label={'대표번호'} text={company.tel} />
                    <Divider/>
                </>
                :null}
            {company.fax?
                <>
                    <InputContainer label={'팩스번호'} text={company.fax} />
                    <Divider/>
                </>
                :null}

            <>
                <div>
                    <Grid container style={{padding: 15,paddingLeft:30,paddingRight:5}}>
                        <Grid item xs={4} style={{textAlign: 'left'}}>{'DATA 제공'}</Grid>
                        <Grid item xs={8} style={{textAlign :'left', position: 'relative'}}>
                                <img style={{ width: 80}} src={require('../resources/ecredible_logo.png')}/>
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
            </>

            <div onClick={() => {
                customAlert("준비중입니다.");
                return;
            }}>
                <div style={{
                    backgroundColor:'#353245',
                    height:40,
                    marginBottom:20,
                    marginTop:20,
                    marginLeft:'5%',
                    marginRight:'5%',
                    borderRadius:25,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Typography style={{fontSize:16,color:'white',fontWeight: 'bold',lineHeight:2.5}}>재무 정보 열람</Typography>
                </div>
            </div>


            {/*custom_confirm*/}
            <div>
                <Dialog
                    open={confirmShow}
                    onClose={handleCloseN}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {confirmMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseN} color="primary">
                            {'아니오'}
                        </Button>
                        <Button onClick={handleCloseY} color="primary" autoFocus>
                            {'예'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {loading?
                <>
                    <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                    <div className={classes.popUpBg} />
                </>
                :null}

        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '10px 40px',
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.5,
        // height: '40px',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
        // paddingBottom: 5,
    },
    introduceContainer: {
        borderRadius: 10,
        backgroundColor: '#f9fafc',
        width: '90%',
        padding: 15,
        alignSelf: 'center',
        marginTop: 20,
        // borderColor:'gray',
        // borderWidth:0.2,
        justifyContent:'center',
        alignItems:'center',
        marginBottom:20
    },

}));
export default CompanyDetail;
