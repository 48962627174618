import React from "react";
import {
    makeStyles,
    Container,
    Typography,
    Button,
    Grid

}from '@material-ui/core';
/*import IframeResizer from 'iframe-resizer-react';*/

function IntroPage() {
    const classes = useStyles();
    // const iframeRef = React.useRef(null)
    // const [messageData, setMessageData] = React.useState()

    // const onResized = data => setMessageData(data)

/*    const onMessage = data => {
        setMessageData(data)
        iframeRef.current.sendMessage('Hello back from the parent page')
    }*/

    return (
        <div>
            <Container style={{width: '100%' , padding: 10}}>
                {/* main content */}
                <Grid container style={{paddingTop: 30, maxWidth: 1200, margin: '0 auto'}}>
                    {/* 핸드펀 */}
                    <Grid xs={12} sm={5} className='contentArea' alignItems='center' style={{padding: 10, maxWidth: 400, margin: '0 auto', marginBottom: 50}}>
                        <div style={{position: 'relative', top: 50}}>
                            <div style={{width: 370, height: 780, margin: '0 auto', boxSizing: 'border-box',  borderRadius: 10,top : -20,}}>
                                <div className='mainContent' style={{width: 363, height: 762, borderRadius: 10, overflow: 'hidden', paddingRight: 10,}}>
                                    {/*내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어*/}
                                    {/*갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                    {/*용이들어갑니다내용이들어갑니다내용이들어갑니다내용이*/}
                                   <iframe src={'http://smartbc.kr:8080/'} rameborder="0" width="387px" height='782px' marginwidth="0" marginheight="0" scrolling="auto" frameBorder="0"
                                            style={{backgroundColor : 'transparent'}} id={'iframe'}/>
                                    {/*<IframeResizer*/}
                                    {/*    forwardRef={iframeRef}*/}
                                    {/*    heightCalculationMethod="lowestElement"*/}
                                    {/*    inPageLinks*/}
                                    {/*    log*/}
                                    {/*    onMessage={onMessage}*/}
                                    {/*    onResized={onResized}*/}
                                    {/*    src="http://localhost:3001/"*/}
                                    {/*    style={{ width: '1px', minWidth: '100%'}}*/}
                                    {/*/>*/}
                                    {/*<p style={{backgroundColor: 'red', height: 2,}}></p>*/}
                                </div>
                            </div>
                            <img src={require('../resources/galaxy_mockup.png')} style={{width: 400, height: 'auto', position: 'absolute', top: -42, left: 1, zIndex: -1}}/>
                        </div>
                    </Grid>

                    {/*설치정보*/}
                    <Grid xs={12} sm={7} alignItems='center' style={{padding: 10, maxWidth: 630, margin: '0 auto', marginTop: 50}}>
                        <Grid item style={{width: '80%', margin: '0 auto',}}>
                           <img src={require('../resources/logo_1.png')} style={{height: 50}}/>
                            <Typography style={{color: '#303A53', marginTop: 15, fontSize: 20}}>
                                협회, 단체를 위한 명함 기반 회원명부 관리서비스!<br/>
                                가급적 모바일앱으로 이용해 주세요!
                            </Typography>
                            <Grid container alignItems='center' style={{ marginTop: 10}}>
                                <Grid xs={6} style={{width: '100%', height: 190, lineHeight: '190px',}}><div style={{backgroundColor: '#999', width: '80%',  maxWidth: 190, height: 'auto', margin: '0 auto'}}>QR코드</div></Grid>
                                <Grid xs={6}>
                                    <Button style={{width: '80%', minWidth: 180, height: '33%'}}><img src={require('../resources/store_apple.png')} style={{width: '100%', height: '100%'}}/></Button>
                                    <Button style={{width: '80%', minWidth: 180, height: '33%'}}><img src={require('../resources/store_google.png')} style={{width: '100%', height: '100%'}}/></Button>
                                    <Button style={{width: '80%', minWidth: 180, height: '33%'}}><img src={require('../resources/store_one.png')} style={{width: '100%', height: '100%'}}/></Button>
                                </Grid>
                            </Grid>
                            <Typography style={{color: '#303A53', padding: 20, fontSize: 18}}>구글 PLAY, IOS, ONE스토어에서<br/>>스마트 모임 검색 설치</Typography>
                            {/* 버튼 */}
                            <Button
                                type="submit"
                                fullWidth
                                color="primary"
                                style={{width: '90%', backgroundColor: 'rgb(255,212,0)', borderRadius : 25,padding :10, marginTop: 10}}
                            >
                                <a href={'https://www.naver.com'} style={{textDecoration: 'none',color: 'white',fontWeight:'bold',fontSize : 15,}}>
                                    카카오 1 : 1 문의하기</a>
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                color="primary"
                                style={{width: '90%', backgroundColor: 'rgb(54,172,55)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                            >
                                앱 활용법 블로그보기
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                color="primary"
                                style={{width: '90%', backgroundColor: 'rgb(255,0,0)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                            >
                                앱 활용법 유투브보기
                            </Button>
                        </Grid>
                        <Grid container alignItems='center' style={{width: '100%', minHeight: 100, position: 'relative', bottom: 0, paddingTop: 20, borderTop: '1px solid #555', marginTop: 80}}>
                            <div style={{width: '100%'}}>
                      {/*          <div className='footerImg' className={classes.footerImg}>
                                    <img src={require('../resources/footer_logo.png')} style={{width: '80%',}}/>
                                </div>*/}
                                <div  className='footerText' className={classes.footerText}>
                                    <Typography align='left'>서울시 서초구 반포대로 89 선흥빌딩 3층   |   법인명 : 밸류마크컨설팅   |   대표이사 : 최병욱</Typography>
                                    <Typography align='left'>대표전화 : 1666-4214   |   팩스 : 02-525-0037   |   사업자등록번호 : 114-87-06056</Typography>
                                    <Typography align='left'>Copyright ⓒ <a href="#" style={{textDecoration: 'none' , color: '#555', fontWeight: 'bold'}}>스마트명함</a> All right reserved.</Typography>
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>

        </div>

    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        backgroundColor: 'rgb(249,250,252)',
        padding: '0 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    downInfo: {
        backgroundColor: '#fff',
        padding: '50px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 50,
    },
    // footerImg: {
    //     width: '20%',
    //     display: 'inline-block'
    // },
    footerText:{
        width: '100%',
        display: 'inline-block',
        color: '#C9C9C9',
        // borderLeft: '1px solid #555',
        // paddingLeft: 10
    },






}));

export default IntroPage;
