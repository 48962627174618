import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
    InputBase, IconButton, Checkbox, FormControlLabel, FormControl, Select, MenuItem, CircularProgress,
}
    from '@material-ui/core';
import PcHeader from "../PcHeader";
import Axios from "axios";
import Moment from 'moment';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import customAlert from "../../component/customAlert";

export default function AdminBoardLevel({history,match}) {

    // console.log('holly',history);

    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const [loading, setLoading] = React.useState(true);

    const[menuName3,setMenuName3] = React.useState('NEWS');
    const[menuName4,setMenuName4] = React.useState('협력해요!');
    const[menuName5,setMenuName5] = React.useState('자유게시판');

    const [menuLevel3,setMenuLevel3] = React.useState(0);
    const [menuLevel4,setMenuLevel4] = React.useState(0);
    const [menuLevel5,setMenuLevel5] = React.useState(0);

    useEffect(()=>{

        Axios({
            url: 'https://sbc.smartbc.kr/sb/band_admin/admin_board_level.php',
            method: 'get',
            params: {
                u_id: u_id,
                b_id: b_id,
            },
        })
            .then((result) => {

                console.log('??', JSON.parse(result.data.write_level));
                if(result.data.write_level.length>0){
                        setMenuLevel3(JSON.parse(result.data.write_level)[0]);
                        setMenuLevel4(JSON.parse(result.data.write_level)[1]);
                        setMenuLevel5(JSON.parse(result.data.write_level)[2]);
                }

                if(result.data.menu_name.length>0){
                    setMenuName3(JSON.parse(result.data.menu_name)[2]);
                    setMenuName4(JSON.parse(result.data.menu_name)[3]);
                    setMenuName5(JSON.parse(result.data.menu_name)[4]);
                }

                setLoading(false)

            })
            .catch((err) => console.log(err.message));

    },[]);



    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'게시판 설정'} back={1} history={history} match={match}/>

                {/*게시핀1*/}
                <div style={{width: '100%', alignSelf: 'center', marginTop: 80,}}>
                    <div style={{display: 'flex', width: '100%'}}>
                        <InputBase
                            className={classes.inputText}
                            placeholder={'NEWS'}
                            placeholderTextColor={'#a6a6a6'}
                            value={menuName3}
                            onChange={(event) => {
                                setMenuName3(event.target.value);
                            }}
                        />

                            <div style={{
                                width: '20%',
                                height: 40,
                                backgroundColor: '#303a53',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display:'flex',
                                borderRadius: 5,
                            }}
                                 onClick={()=>{
                                     Axios({
                                         url: 'https://sbc.smartbc.kr/sb/band_admin/admin_board_level_update.php',
                                         method: 'get',
                                         params: {
                                             u_id: u_id,
                                             b_id: b_id,
                                             number: 2,
                                             menu_name: menuName3,
                                             menu_level: menuLevel3,
                                         },
                                     })
                                         .then((result) => {
                                             console.log('바뀜?', result.data);
                                             customAlert('수정되었습니다.');

                                         })
                                         .catch((err) => console.log(err.message));
                                 }}>
                                <Typography style={{color: 'white', fontWeight: 'bold', fontSize: 14}}>적용</Typography>
                            </div>
                    </div>
                    <div style={{width: '100%', height: 30, display: 'flex', marginTop: 10}}>
                        <Typography style={{fontSize: 12,width:'20%'}}>글쓰기 권한 : </Typography>
                        <RadioGroup row aria-label="menuLevel3"
                                    name="menuLevel3"
                                    // defaultValue={4}
                                    value={menuLevel3}
                                    style={{width:'80%',marginTop: -10}}
                                    className={'_radioFont'}
                                    onChange={(event)=>{
                                        setMenuLevel3(Number(event.target.value));
                                        console.log('라디오',typeof event.target.value);
                                        console.log('라디오',menuLevel3);

                                    }}>
                            <FormControlLabel value={4} control={<Radio color="primary" />} label="전체" style={{fontSize:5}}/>
                            <FormControlLabel value={1} control={<Radio color="primary" />} label="운영자" />
                            <FormControlLabel value={2} control={<Radio color="primary" />} label="우수회원" />
                            <FormControlLabel value={3} control={<Radio color="primary" />} label="운영자+우수회원" />
                        </RadioGroup>
                    </div>

                </div>
                {/*구분선*/}
                <div style={{width: '100%', justifyContent: 'center', alignItems: 'center',}}>
                    <div style={{width: '100%', borderBottom: '1px solid black', }}/>
                </div>


                {/*게시핀2*/}
                <div style={{width: '100%', alignSelf: 'center', marginTop: 20,}}>
                    <div style={{display: 'flex', width: '100%'}}>
                        <InputBase
                            className={classes.inputText}
                            placeholder={'협력해요'}
                            placeholderTextColor={'#a6a6a6'}
                            value={menuName4}
                            onChange={(event) => {
                                setMenuName4(event.target.value);
                            }}
                        />

                        <div style={{
                            width: '20%',
                            height: 40,
                            backgroundColor: '#303a53',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display:'flex',
                            borderRadius: 5,
                        }}
                        onClick={()=>{
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/band_admin/admin_board_level_update.php',
                                method: 'get',
                                params: {
                                    u_id: u_id,
                                    b_id: b_id,
                                    number: 3,
                                    menu_name: menuName4,
                                    menu_level: menuLevel4,
                                },
                            })
                                .then((result) => {
                                    console.log('바뀜?', result.data);
                                    customAlert('수정되었습니다.');
                                })
                                .catch((err) => console.log(err.message));
                        }}>
                            <Typography style={{color: 'white', fontWeight: 'bold', fontSize: 14}}>적용</Typography>
                        </div>
                    </div>
                    <div style={{width: '100%', height: 30, display: 'flex', marginTop: 10}}>
                        <Typography style={{fontSize: 12,width:'20%'}}>글쓰기 권한 : </Typography>
                        <RadioGroup row aria-label="card_open" name="card_open"
                                    value={menuLevel4}
                                    style={{width:'80%',marginTop: -10}}
                                    className={'_radioFont'}
                                    onChange={(event)=>{
                                        setMenuLevel4(Number(event.target.value));
                                        console.log('라디오',event.target.value);

                                    }}>
                            <FormControlLabel value={4} control={<Radio color="primary" />} label="전체" style={{fontSize:5}}/>
                            <FormControlLabel value={1} control={<Radio color="primary" />} label="운영자" />
                            <FormControlLabel value={2} control={<Radio color="primary" />} label="우수회원" />
                            <FormControlLabel value={3} control={<Radio color="primary" />} label="운영자+우수회원" />
                        </RadioGroup>
                    </div>

                </div>
                {/*구분선*/}
                <div style={{width: '100%', justifyContent: 'center', alignItems: 'center',}}>
                    <div style={{width: '100%', borderBottom: '1px solid black', }}/>
                </div>

                {/*게시핀3*/}
                <div style={{width: '100%', alignSelf: 'center', marginTop: 20,}}>
                    <div style={{display: 'flex', width: '100%'}}>
                        <InputBase
                            className={classes.inputText}
                            placeholder={'자유게시판'}
                            placeholderTextColor={'#a6a6a6'}
                            value={menuName5}
                            onChange={(event) => {
                                setMenuName5(event.target.value);
                                console.log('자유게시판',event.target.value );
                            }}
                        />

                        <div style={{
                            width: '20%',
                            height: 40,
                            backgroundColor: '#303a53',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display:'flex',
                            borderRadius: 5,
                        }}
                             onClick={()=>{
                                 Axios({
                                     url: 'https://sbc.smartbc.kr/sb/band_admin/admin_board_level_update.php',
                                     method: 'get',
                                     params: {
                                         u_id: u_id,
                                         b_id: b_id,
                                         number: 4,
                                         menu_name: menuName5,
                                         menu_level: menuLevel5,
                                     },
                                 })
                                     .then((result) => {
                                         console.log('바뀜?', result.data);
                                         customAlert('수정되었습니다.');
                                     })
                                     .catch((err) => console.log(err.message));
                             }}>
                            <Typography style={{color: 'white', fontWeight: 'bold', fontSize: 14}}>적용</Typography>
                        </div>
                    </div>
                    <div style={{width: '100%', height: 30, display: 'flex', marginTop: 10}}>
                        <Typography style={{fontSize: 12,width:'20%'}}>글쓰기 권한 : </Typography>
                        <RadioGroup row aria-label="card_open" name="card_open"
                                    value={menuLevel5}
                                    style={{width:'80%',marginTop: -10}}
                                    className={'_radioFont'}
                                    onChange={(event)=>{
                                        setMenuLevel5(Number(event.target.value));
                                        console.log('라디오',event.target.value);

                                    }}>
                            <FormControlLabel value={4} control={<Radio color="primary" />} label="전체" style={{fontSize:5}}/>
                            <FormControlLabel value={1} control={<Radio color="primary" />} label="운영자" />
                            <FormControlLabel value={2} control={<Radio color="primary" />} label="우수회원" />
                            <FormControlLabel value={3} control={<Radio color="primary" />} label="운영자+우수회원" />
                        </RadioGroup>
                    </div>

                </div>
                {/*구분선*/}
                <div style={{width: '100%', justifyContent: 'center', alignItems: 'center',}}>
                    <div style={{width: '100%', borderBottom: '1px solid black', }}/>
                </div>

                {loading?
                    <>
                        <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                        <div className={classes.popUpBg} />
                    </>
                    :null}

            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    inputText : {
        // backgroundColor: 'red',
        width:'80%',
        height:40,
        fontSize: 15,
        color:'black',
        fontWeight : 'bold',
        marginRight:'1%',
        border:'1px solid black',
        borderRadius: 5,
        padding:'0 10px',
    },

    '@global': {
        '._radioFont .MuiTypography-body1':{
            fontSize: 5,
        },
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },

}));

