import React, {useEffect} from "react";
import {useTimer} from 'react-timer-hook';

function Timer({style, expiryTimestamp, status, verify}) {

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

    useEffect(() => {
        if (verify) {
            pause();
        }
        if(status) {
            resume();
        }
    }, [verify]);


    return(
        <div>
            <div style={{...style}}>
                {minutes} : {seconds.toString().length == 1 ? '0' +seconds : seconds}
            </div>
        </div>
    );
}

export default Timer;
