import React, {useEffect, useRef, useState} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Paper,
    FormControl,
    MenuItem,
    Select,
    Grid, Chip,
    InputBase, TextareaAutosize, IconButton, CardContent, Box, FormControlLabel, Checkbox
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import SearchIcon from '@material-ui/icons/Search';
import Axios from "axios";
import {Search} from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import CloseIcon from '@material-ui/icons/Close';
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import customAlert from "../component/customAlert";

function LinkMember({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    // 간편 검색
    const [text, setText] = React.useState('');
    const [sub, setSub] = React.useState('');
    const [sort, setSort] = React.useState('');
    // 연결 된 회원 리스트
    const [list, setList] = React.useState(new Array());
    // (로그인 한)자신의 연결 회원 중 폰 ,이메일 공개를 수락한 리스트
    const [u_allow_list, setU_allow_list] = React.useState([]);
    const [u_allow_list_email, setU_allow_list_email] = React.useState([]);
    //간편 검색
    const [search, setSearch] = React.useState(false);
    //모달 (정보공개 요청 시)
    const [modal , setModal] = React.useState(false);
    const [targetId,setTargetId] = React.useState('');//정보공개 요청을 보낼 타겟 아이디


    function CardDetail(uid,join_option) {
            history.push({
                pathname: 'CardDetail',
                state: {
                    sel_u_id: uid,
                    join_option:join_option,
                }
            });
    }

    const PopUp = ({}) => {
        const [check,setCheck] = useState(false);
        if(check) document.getElementById('msg').value = '연락처 ,이메일 공개를 요청합니다.';

        return(
            <>
                <Card className={classes.popUp}>
                    <div style={{position: 'relative', padding: '15px 0'}}>
                        <Typography align='center' style={{fontWeight: 'bold'}}>개인정보 공개요청</Typography>
                        <IconButton style={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}} onClick={() => {
                            setModal(!modal);
                        }}>
                            <CloseIcon style={{color: 'black', fontSize: 30}}/>
                        </IconButton>
                    </div>
                    <CardContent style={{paddingTop: 5}}>
                        <Box component={'div'}
                             style={{border: '1px solid #e2e7eb', borderRadius: 10, minHeight: 80, padding: 10,}}>
                            <Box component={'textarea'} style={{border: 'none', outline: 'none', width: '100%', height: '100%', minHeight: 80, resize: 'none', lineHeight: 1.3}}
                                 id={'msg'}
                                 placeholder={'공개요청 사유를 입력해 주세요.'}
                            />
                        </Box>
                        <FormControlLabel
                            onClick={() => {
                                setCheck(!check);
                            }}
                            style={{width: '100%'}}
                            control={
                                <Checkbox
                                    icon={<CheckBoxIcon fontSize="inherit"/>}
                                    checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                />
                            }
                            label={<Typography style={{fontSize: 13}}>연락처, 이메일 공개를 요청합니다.</Typography>}
                            checked={check}
                        />
                    </CardContent>
                    <Button
                        onClick={() => {
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/info_open_request.php',
                                method: 'get',
                                params: {
                                    u_id : u_id,
                                    target_id : targetId,
                                    b_id : b_id,
                                    msg : (check ? '연락처,이메일 공개를 요청합니다.':document.getElementById('msg').value)
                                },
                                // resultType:'text',
                            })
                                .then((result) => {
                                    console.log('info_open_request success');
                                    console.log(result.data);
                                    if(result.data == "30") customAlert("개인정보 공개 요청은 요청 후 30일뒤 \n다시 요청이 가능합니다.");
                                    setModal(false);
                                })
                                .catch((err) => console.log(err.message));
                        }}
                        color="primary"
                        style={{width: '90%', backgroundColor: 'rgb(48,58,83)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                    >
                        공개 요청하기
                    </Button>
                </Card>
                <div className={classes.popUpBg}></div>
            </>
        );
    }

    const Inputtext = () => {
        const [content, setContent] = React.useState('');

        return (
            <>
                <IconButton edge="center" className={classes.searchIcon} onClick={() => {
                    setText(content);
                    setSearch(!search);
                }}>
                    <SearchIcon style={{fontSize: 30, color: 'rgb(48,58,83)'}}/>
                </IconButton>
                <InputBase
                    color='primary'
                    placeholder='2글자이상'
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{'aria-label': 'search'}}
                    onChange={(val) => {
                        setContent(val.target.value);
                    }}
                />
            </>
        )
    }
    useEffect(() => {
        if(!history.location.state.searchList) {
            Axios({
                url: 'https://sbc.smartbc.kr/sb/link_members_list.php',
                method: 'get',
                params: {
                    id: u_id,
                    b_id: b_id,
                    search_txt: text,
                    search_sub: sub,
                    sort_list: sort
                }
            })
                .then(function (response) {
                    setU_allow_list(JSON.parse(response.data['allow_list']));
                    setU_allow_list_email(JSON.parse(response.data['allow_list_email']));
                    sessionStorage.setItem('allow_list',JSON.parse(response.data['allow_list']));
                    sessionStorage.setItem('allow_list_email',JSON.parse(response.data['allow_list_email']));
                    console.log(JSON.parse(response.data['allow_list']));
                    console.log(u_allow_list_email);
                    console.log(typeof (u_allow_list));
                    console.log(typeof (response.data['allow_list']));
                    setList(response.data['list']);
                })
                .catch(function (error) {
                    console.log('!!!!!!!!!!!!!!!!!!!' + error);
                })
        }
        else{
            console.log('searchlist');
            // console.log(history.state.searchList);
            // let allow_list = []
            // setU_allow_list(JSON.parse(sessionStorage.getItem('allow_list')));
            // setU_allow_list_email(JSON.parse(sessionStorage.getItem('allow_list_email')));
            setList(history.location.state.searchList);
        }
    }, [search]);


    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'연결 된 회원'} back={1} history={history} match={match} cnt={list.length}/>
                {/* 상단 고정*/}
                <div style={{
                    padding: '20px 25px 15px 20px',
                    marginTop: 50,
                    boxShadow: '0 5px 10px rgba(0,0,0,0.15)',
                    position: 'sticky',
                    top: 55,
                    backgroundColor: '#fff',
                    zIndex: 10
                }} className={'allUser'}>
                    <FormControl variant="outlined" color='primary' style={{
                        width: '25%',
                        textAlign: 'left',
                        borderRadius: '40px',
                        marginRight: 3,
                        marginLeft: -10
                    }}>
                        <Select
                            defaultValue=""
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            id="grouped-select"
                            onChange={(val) => {
                                setSort(val.target.value);
                                setSearch(!search);
                            }}
                        >
                            <MenuItem value="">
                                전체
                            </MenuItem>
                            <MenuItem value={'info_open'}>정보공개</MenuItem>
                            <MenuItem value={'info_off'}>정보 비공개</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" color='primary'
                                 style={{width: '25%', textAlign: 'left', borderRadius: '40px', marginRight: 3}}>
                        <Select
                            defaultValue=""
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            id="grouped-select"
                            onChange={(val) => {
                                setSub(val.target.value);
                            }}
                        >
                            <MenuItem value="">
                                전체
                            </MenuItem>
                            <MenuItem value={'mc.name'}>이름</MenuItem>
                            <MenuItem value={'mc.company'}>회사명</MenuItem>
                            <MenuItem value={'a1.name'}>주소</MenuItem>
                        </Select>
                    </FormControl>
                    <div style={{
                        width: '35%',
                        height: 45,
                        textAlign: 'left',
                        borderRadius: '40px',
                        position: 'relative',
                        border: '1px solid #B9BFC3',
                        display: 'inline-block',
                        marginRight: 3
                    }}>
                        <Inputtext/>
                    </div>
                    <div style={{
                        width: '12%',
                        textAlign: 'left',
                        borderRadius: '40px',
                        position: 'relative',
                        display: 'inline-block',
                    }} onClick={() => {
                        history.push('DetailSearch');
                    }}>
                        <Button
                            type="submit"
                            color="primary"
                            style={{
                                backgroundColor: 'rgb(48,58,83)',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 15,
                                borderRadius: 40,
                                padding: '10px 8px', height: 43, marginTop: -5}}

                        >
                            상세
                        </Button>
                    </div>
                </div>
                {/*<div style={{*/}
                {/*    padding: '30px 40px 20px',*/}
                {/*    marginTop: 0,*/}
                {/*    position: 'sticky',*/}
                {/*    top: 130,*/}
                {/*    backgroundColor: '#fff',*/}
                {/*    zIndex: 9*/}
                {/*}}>*/}
                {/*    <Typography align='left' style={{*/}
                {/*        backgroundColor: 'rgb(235,235,235)',*/}
                {/*        padding: 10,*/}
                {/*        textIndent: 10,*/}
                {/*        fontSize: 13,*/}
                {/*        borderRadius: 10,*/}
                {/*        fontWeight: 500*/}
                {/*    }}>현재 회원 - <span style={{color: 'rgb(239,113,113)'}}>{list.length}</span> 명</Typography>*/}
                {/*</div>*/}

                {/* 회원 리스트 */}
                {list.length > 0 ? (
                    list.map((val, index) => {

                        // const [op2,setOp2] = React.useState();
                        // const op2 = React.useRef(null);

                        let userLevel='';
                        if(val.user_level==1){
                            userLevel='운영자';
                        }else if(val.user_level==2){
                            userLevel='우수회원';
                        }

                        if ((val.join_option2_val != null)) {
                            const string1 = Object.values(JSON.parse(val.join_option2_val)) + "";
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/get_option2_name.php',
                                method: 'get',
                                params: {
                                    op2_val: string1,
                                },
                            })
                                .then(function (res) {
                                    // res.data.map((value) => {
                                    //     op2_name.push(value.name);
                                    // });
                                    /////
                                    // console.log(op2_name[key]);
                                    // op2_name = res.data.join();
                                    // console.log('--------------------------here');
                                    // console.log(res.data);
                                    // console.log(Object.values(JSON.parse(join_option)));
                                    // console.log(res.data.join());
                                    if(res.data.length>0){
                                        document.getElementById('groupOption'+index).value = '모임 옵션 :' + res.data.join(', ') + '\n' + val.u_company2 + ',' + val.u_part2 + ',' + val.u_area;
                                    }else{
                                        document.getElementById('groupOption'+index).value = '모임 옵션 : 미설정 '   + '\n' + val.u_company2 + ',' + val.u_part2 + ',' + val.u_area;
                                    }

                                    // op2.current = res.data.join(', ');
                                    // return res.data.join(', ');
                                    // '그룹 옵션 :' + res.data.join(', ') + '\n' + val.u_company2 + ',' + val.u_part2 + ',' + val.u_area
                                })
                                .catch(function (e) {
                                    console.log('fail' + e);
                                });
                        }
                        // const intro2 = '그룹 옵션 :' + op2 + '\n' + val.u_company2 + ',' + val.u_part2 + ',' + val.u_area;

                        return (
                            <div className={classes.paper}>
                                <Grid container spacing={3} style={{marginBottom: 5}}>
                                    <Grid container spacing={0} style={{
                                        padding: '15px 20px',
                                        borderRadius: 10,
                                        boxShadow: '2px 2px 10px rgba(0,0,0,0.2)',
                                        position: 'relative',
                                        marginBottom: 0
                                    }}>
                                        <Grid container direction='row' onClick={() => {
                                            let join_option = null;
                                            if( val.join_option2_val){
                                                join_option =  Object.values(JSON.parse(val.join_option2_val));
                                            }
                                            CardDetail(val.u_id, join_option);
                                        }}>
                                            <Grid item xs={8} container direction="column">
                                                <Typography align='left' style={{
                                                    fontSize: 13,
                                                    color: 'rgb(51,51,51)',
                                                    fontWeight: 'bold'
                                                }}>
                                                    {val.name} {userLevel? '('+userLevel+')': null}
                                                </Typography>
                                                <Typography align='left'
                                                            style={{fontSize: 13, color: 'rgb(181,181,181)'}}>
                                                    <span>{val.position}</span> | <span>{val.part}</span>
                                                </Typography>
                                                <Typography align='left'
                                                            style={{fontSize: 13, color: 'rgb(181,181,181)'}}>
                                                    {val.company}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}
                                                  onContextMenu={
                                                      (e) => {
                                                          //우클릭 방지
                                                          e.preventDefault();
                                                          console.log('우클!');
                                                      }}
                                                  onDragStart={
                                                      (e) => {
                                                          //드래그 방지
                                                          e.preventDefault();
                                                          console.log('드래그방지!');
                                                      }}
                                                  style={{
                                                borderRadius: 5,
                                                overflow: 'hidden',
                                                width: '100%',
                                                height: 54
                                            }}>
                                                <img alt="사진" src={'https://sbc.smartbc.kr/' + val.image}
                                                     style={{width: '90%', height: '100%'}}/>
                                            </Grid>
                                        </Grid>
                                        <Grid container direction='row' style={{marginTop: 3}}>
                                            <Grid item xs={2} style={{textAlign: 'left'}}>
                                                <img src={require('resources/eye_icon.png')}
                                                     style={{
                                                         height: 18,
                                                         width: 'auto',
                                                         verticalAlign: 'middle'
                                                     }}/>
                                                <Typography style={{
                                                    fontSize: 11,
                                                    display: 'inline-block',
                                                    height: 18,
                                                    lineHeight: '18px',
                                                    marginLeft: 5
                                                }}>{val.view_count ? val.view_count : 0}</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: 'left'}}>
                                                <img src={require('resources/link.png')}
                                                     style={{
                                                         height: 18,
                                                         width: 'auto',
                                                         verticalAlign: 'middle'
                                                     }}/>
                                                <Typography style={{
                                                    fontSize: 11,
                                                    display: 'inline-block',
                                                    height: 18,
                                                    lineHeight: '18px',
                                                    marginLeft: 5
                                                }}>{val.link_count ? val.link_count : 0}</Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: 'left'}}>
                                                <img src={require('resources/write_icon.png')}
                                                     style={{
                                                         height: 18,
                                                         width: 'auto',
                                                         verticalAlign: 'middle'
                                                     }}/>
                                                <Typography style={{
                                                    fontSize: 11,
                                                    display: 'inline-block',
                                                    height: 18,
                                                    lineHeight: '18px',
                                                    marginLeft: 5
                                                }}>{val.board_count ? val.board_count : 0}</Typography>
                                            </Grid>
                                            <Grid item xs={2}/>
                                            <Grid item xs={4}
                                                  style={{borderRadius: 5, overflow: 'hidden', width: '100%'}}>
                                                {(u_allow_list.find(element => element == Number(val.u_id)) &&
                                                u_allow_list_email.find(element => element == Number(val.u_id))) || (val.allow_list1 == 1 && val.allow_list_email1 ==1)
                                                    ? (
                                                        <Button
                                                            color="primary"
                                                            style={{
                                                                color:'#303a53',
                                                                fontWeight :'bold',
                                                                width: '95%',
                                                                border : '1px solid',
                                                                borderRadius: 25,
                                                                fontSize :11
                                                            }}
                                                        >
                                                            정보 공개
                                                        </Button>
                                                    )
                                                    :
                                                    (
                                                        <Button
                                                            color="primary"
                                                            style={{
                                                                backgroundColor: 'rgb(48,58,83)',
                                                                color: '#fff',
                                                                fontWeight: 'bold',
                                                                borderRadius: 25,
                                                                padding: 6,
                                                                width: '95%',
                                                                fontSize :11
                                                            }}
                                                            onClick={() => {
                                                                setModal(!modal);
                                                                setTargetId(val.u_id);
                                                            }}
                                                        >
                                                            정보공개 요청
                                                        </Button>
                                                    )}
                                            </Grid>
                                        </Grid>
                                        {val.introduce ?
                                            (
                                                <div direction='row' style={{
                                                    marginTop: 25,
                                                    backgroundColor: 'rgb(249,250,252)',
                                                    borderRadius: 10,
                                                    padding: 5,
                                                    width: '100%'
                                                }}>
                                                    <InputBase
                                                        // aria-label="minimum height"
                                                        rowsMin={1} rowsMax={2}
                                                        value={val.introduce}
                                                        readOnly={true}
                                                        multiline={true}
                                                        rows={2}
                                                        style={{width: '100%', border: 0, backgroundColor: 'transparent', resize: 'none', fontSize: 13}}/>
                                                </div>
                                            ) : null}
                                        <div direction='row' style={{
                                            marginTop: 25,
                                            backgroundColor: 'rgb(249,250,252)',
                                            borderRadius: 10,
                                            padding: 5,
                                            width: '100%'
                                        }}>
                                            {(val.u_company2 && val.u_part2 && val.u_area) ?
                                                (
                                                    <InputBase
                                                        id={'groupOption'+index}
                                                        defaultValue={'모임 옵션 : 미설정 \n'+val.u_company2+','+val.u_part2+','+val.u_area}
                                                        rowsMin={2} rowsMax={3}
                                                        readOnly={true}
                                                        multiline={true}
                                                        rows={2}
                                                        style={{width: '100%',height:'100%', border: 0, backgroundColor: 'transparent', resize: 'none', fontSize: 13,}}/>
                                                ) : null}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    })
                ) : null}

                {/*정보공개 요청 팝업*/}
                {modal ?
                    (
                        <PopUp/>
                    ) : null}


                {/*회원 리스트 end*/}
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 20px',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    allUser: {},
    searchIcon: {
        zIndex: 10,
        position: 'absolute',
        right: -3, top: '50%',
        transform: 'translateY(-50%)'
    },
    inputRoot: {
        color: 'inherit',
        width: '75%',
    },
    inputInput: {
        margin: 3,
        padding: '10px 5px 13px 10px'
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    '@global':{
        '.text-input .MuiInputBase-input': {
            height: 'auto !important',
            fontSize: 13,
            lineHeight: 1.5
        },
        '.allUser .MuiInputBase-formControl':{
            height: 45
        },
        '.allUser .makeStyles-inputInput-5':{
            margin: 0
        }
    },

}));


export default LinkMember;
