import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
    ButtonGroup, Avatar,
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import BottomMenu from "./BottomMenu";
import Axios from "axios";

function Community({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    const stored_partner_id = sessionStorage.getItem('@partner_id');
    const [list,setList] = React.useState([]);

    useEffect(()=>{
        Axios({
            url: 'https://sbc.smartbc.kr/sb/community_list.php',
            method: 'get',
            params: {
                u_id: u_id,
                b_id: b_id,
            },
        })
            .then((result) => {
                if (result.data.status == 13001) {
                    console.log(result);
                    console.log(result.data);
                    setList(result.data.community_list);
                } else {
                    console.log(result.data);
                    console.log(result.data.result_msg);
                    // Toast.show(result.data.result_msg);
                }
            })
            .catch((err) => console.log(err.message));
    },[])
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'복지 서비스&커뮤니티'} back={1} history={history} match={match}/>

                <div className={classes.paper}>

                    {list.length>0?(list.map((value,i)=>{
                        let linkURL = list[i].cm_link;
                        return (
                            <div style={{width: '100%', padding: 10, border: '1px solid rgb(174,181,186)', borderRadius: 10, marginBottom: 20}}
                                 onClick={()=>{
                                     if(list[i].cm_link) {
                                         if(list[i].cm_link.indexOf('info.smartbc.kr/partner') != -1){
                                             if(stored_partner_id){
                                                 history.push( '/'+match.params.JoinCode+'/PartnerMyInfo');
                                                 return;
                                             }else{
                                                 history.push( '/'+match.params.JoinCode+'/PartnerSignUp');
                                                 return;
                                             }

                                         }

                                         if (list[i].cm_link.indexOf('http://') != -1 ||
                                             list[i].cm_link.indexOf('https://') != -1) {
                                             //문자열 포함
                                             window.open('about:blank').location.href = list[i].cm_link;
                                         } else {
                                             window.open('about:blank').location.href = 'https://' + list[i].cm_link;
                                         }
                                     }
                                 }}>
                                <Grid container alignItems='center'direction='row'>
                                    <Grid item xs={3} style={{marginRight: 15}}>
                                        <img src={"https://sbc.smartbc.kr" + list[i].cm_image} style={{width: 75, height: 75, borderRadius:20}}/>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid item xs container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography align='left' style={{fontSize: 14, color: 'rgb(48,58,83)', fontWeight: 'bold'}}>{list[i].cm_name}</Typography>
                                                <Typography align='left' style={{fontSize: 12, color: 'rgb(92,92,92)', height: 40, lineHeight: 1.5}} className={classes.textEllipsis}>{list[i].cm_intro}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>)
                    })):null}

                </div>

                {/*<BottomMenu/>*/}
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 60px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.2,
        height: '40px',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        padding: 2.5,
    }

}));


export default Community;
