import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
    Avatar,
}from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";
import customConfirm from "../component/customConfirm";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

function NoticeDetail_2({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [notice,setNotice] = React.useState(new Array());

    const [confirmID , setConfirmID ] = React.useState(0);
    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        if(confirmID == 1){
            Axios({
                url : 'https://sbc.smartbc.kr/sb/accept_link.php',
                method :'get',
                params :{
                    n_id : history.location.state.n_id,
                    u_id : u_id,
                    send_id : notice.u_id,
                }
            }).then(function (res) {
                console.log('accept success');
                // navigation.navigate('Notification',{render:false,u_id:stored_uid});
                history.push('Notice');
            }).catch(function (e) {
                console.log('accept fail');
            })
        }else if(confirmID == 2) {
            Axios({
                url : 'https://sbc.smartbc.kr/sb/reject_link.php',
                method :'get',
                params :{
                    n_id : history.location.state.n_id,
                    u_id : u_id,
                    send_id : notice.u_id,
                }
            }).then(function (res) {
                console.log('reject success');
                // navigation.navigate('Notification',{render:false,u_id:stored_uid});
                history.push('Notice');
            }).catch(function (e) {
                console.log('reject fail');
            })
        }
    };

    useEffect(() => {
        Axios({
            url:"https://sbc.smartbc.kr/sb/notification_detail.php",
            method :'get',
            params : {
                type : 2,
                n_id : history.location.state.n_id,
            }
        })
            .then(function (res) {
                // if(res.data['profile']) setProfile({uri : "https://sbc.smartbc.kr" + res.data['profile']});
                // else setProfile(require('../resources/profile_exampleImg.png'));
                // setName(res.data['name'])  ;
                // setPosition(res.data['position']);
                // setCompany(res.data['company']);
                // setDate(res.data['create_at']);
                // setMsg(res.data['msg']);
                // setU_id(res.data['u_id']); //연결 요청을 보낸 user id
                setNotice(res.data);
                console.log('n_detail list up success');
            })
            .catch(function (e) {
                console.log('n_detail list up fail');
            })
    },[]);

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'알림 상세'} back={1}  history={history}/>

                <div className={classes.paper}>
                    {/*custom_confirm*/}
                    <div>
                        <Dialog
                            open={confirmShow}
                            onClose={handleCloseN}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {confirmMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseN} color="primary">
                                    {'아니오'}
                                </Button>
                                <Button onClick={handleCloseY} color="primary" autoFocus>
                                    {'예'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>



                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        [연결요청]<br/>{notice.name}님이 명함교환 요청을 보내셨습니다.
                    </Typography>
                    {/* 상단 정보 */}
                    <div style={{width: '100%', padding: '20px 0', borderBottom: '1px solid rgb(226,231,235)', borderTop: '1px solid rgb(226,231,235)'}}>
                        <Grid container alignItems='center'direction='row' >
                            <Grid item xs={2} style={{marginRight: 10}}>
                                <Avatar alt="사진" src={notice.profile ? 'https://sbc.smartbc.kr'+notice.profile : require('../resources/profile_exampleImg.png')} className={classes.profileImgSize}/>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                            {notice.name}<span style={{fontSize: 11, color: 'rgb(181,181,181)', marginLeft: 5}}>{notice.position}</span>
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                            {notice.company}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography align='right' style={{width: '100%', fontSize: 10, color: 'rgb(181,181,181)'}}>{notice.create_at}</Typography>
                    </div>
                    <div align='left' style={{width: '100%', marginTop: 20}}>
                        <Button style={{width: '30%', padding: 10, border: '1px solid rgb(48,58,83)', borderRadius: 40, fontWeight: 'bold', marginRight: '5%'}}
                        onClick={() => {
                            history.push({
                                pathname : 'CardDetail',
                                state : {sel_u_id : notice.u_id}
                            });
                        }}>명함 보기</Button>
                        <Button style={{width: '30%', padding: 10, backgroundColor: 'rgb(48,58,83)', borderRadius: 40, fontWeight: 'bold', color: '#fff', marginRight: '5%'}}
                        onClick={() => {

                            setConfirmID(1);
                            setConfirmMessage('명함교환 요청을 수락하시겠습니까?');
                            setConfirmShow(true);

                            // let aa = customConfirm('명함교환 요청을 수락하시겠습니까?');
                            // if(aa) {
                            //     Axios({
                            //         url : 'https://sbc.smartbc.kr/sb/accept_link.php',
                            //         method :'get',
                            //         params :{
                            //             n_id : history.location.state.n_id,
                            //             u_id : u_id,
                            //             send_id : notice.u_id,
                            //         }
                            //     }).then(function (res) {
                            //         console.log('accept success');
                            //         // navigation.navigate('Notification',{render:false,u_id:stored_uid});
                            //         history.push('Notice');
                            //     }).catch(function (e) {
                            //         console.log('accept fail');
                            //     })
                            // }
                        }}>연결 수락</Button>
                        <Button style={{width: '30%', padding: 10, backgroundColor: 'rgb(239,113,113)', borderRadius: 40, fontWeight: 'bold', color: '#fff'}}
                                onClick={() => {
                                    setConfirmID(2);
                                    setConfirmMessage('명함교환 요청을 거절하시겠습니까?');
                                    setConfirmShow(true);

                                    // let aa = customConfirm('명함교환 요청을 거절하시겠습니까?');
                                    // if(aa) {
                                    //     Axios({
                                    //         url : 'https://sbc.smartbc.kr/sb/reject_link.php',
                                    //         method :'get',
                                    //         params :{
                                    //             n_id : history.location.state.n_id,
                                    //             u_id : u_id,
                                    //             send_id : notice.u_id,
                                    //         }
                                    //     }).then(function (res) {
                                    //         console.log('reject success');
                                    //         // navigation.navigate('Notification',{render:false,u_id:stored_uid});
                                    //         history.push('Notice');
                                    //     }).catch(function (e) {
                                    //         console.log('reject fail');
                                    //     })
                                    // }
                                }}
                        >연결 거절</Button>
                    </div>
                    {/*<Typography*/}
                    {/*    style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>*/}
                    {/*    MY명함 공개 전체로 설정되어 스마트명함에서 요청되었습니다. 앱을 설치하시면 연결 인맥을 확인 하실 수 있습니다.*/}
                    {/*</Typography>*/}

                    {/*<div style={{width: '100%', padding: 10, border: '2px solid #E6E6E6', borderRadius: 10}}>*/}
                    {/*    <Grid container alignItems='center'direction='row'>*/}
                    {/*        <Grid item xs={3} style={{marginRight: 15}}>*/}
                    {/*            <img src={require('../resources/logo_sb.png')} style={{width: 75, height: 75}}/>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item xs={8}>*/}
                    {/*            <Grid item xs container direction="column" spacing={2}>*/}
                    {/*                <Grid item>*/}
                    {/*                    <Typography align='left' style={{fontSize: 16, color: 'rgb(48,58,83)', fontWeight: 'bold'}}>*/}
                    {/*                        스마트 명함*/}
                    {/*                    </Typography>*/}
                    {/*                    <Typography align='left' style={{fontSize: 12, color: 'rgb(92,92,92)',}}>*/}
                    {/*                        개인을 위한 명함 관리 앱!<br/>*/}
                    {/*                        회원가입 없이 이용 가능*/}
                    {/*                    </Typography>*/}
                    {/*                </Grid>*/}
                    {/*            </Grid>*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*</div>*/}

                </div>
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 60px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    profileImgSize: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },

}));


export default NoticeDetail_2;
