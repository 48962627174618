import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    FormControl,
    MenuItem,
    Select,
    Grid, Chip,
    Switch, Avatar
}
from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";

function Notice({history,match}) {
    const classes = useStyles();


    /**
     *
     */
    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [list_type, setList_type] = React.useState('');
    const [list, setList] = React.useState(new Array());
    const [auto_change, setAuto_change] = React.useState(false);
    // const [render, setRender] = React.useState(history.location.state.render);

    useEffect(() => {
        Axios({
            url: "https://sbc.smartbc.kr/sb/notification.php",
            method: "get",
            params: {
                u_id : u_id,
                type : list_type,
            }
        })
            .then(function (res) {
                setList(res.data['notification']);
                if (res.data['get_setting']['auto_change'] == 'Y') setAuto_change(true);
                console.log('notification list up success');
                console.log(res.data['get_setting']['auto_change']);
                list.map((val, index) => {
                    console.log(val);
                })
            })
            .catch(function (e) {
                console.log('notification list up error');
            })
    }, [list_type]);

    const NotiList = ({id, type, profile, content, date,}) => {

        let text; //type에 따른 공지사항,연결요청 등.. 표시
        if (!profile) {
            if(type == 0){
                profile = require('../resources/logo_sb.png')
            }else{
                profile = require('../resources/profile_exampleImg.png');
            }
        } // 이미지 없을 시 기본 이미지
        else profile = 'https://sbc.smartbc.kr/' + profile;
        if (type == 0) {
            text = "공지사항";
        }
        if (type == 1) {
            text = "제휴 요청";
            content = content + "님이 명함 교환 요청과 소개서를 보냈습니다.";
        }
        if (type == 2) {
            text = "연결 요청";
            content = content + "님이 명함 교환 요청을 하셨습니다.";
        }
        if (type == 3) {
            text = "연락처 요청";
            content = content + "님이 개인정보 공개 요청을 하셨습니다.";
        }
        if (type == 4) {
            text = "광고 시청";
            content = "기업명이 당신과 연결을 원하고 있습니다.";
        }

        return(

                <Grid container spacing={3} style={{marginTop : 20}} onClick={() => {
                    if(type == 0) history.push({pathname : 'NoticeDetail_0',state : {n_id : id}}); //광고
                    else if(type == 1) history.push({pathname : 'NoticeDetail_1',state : {n_id : id}}); //제휴
                    else if(type == 2) history.push({pathname : 'NoticeDetail_2',state : {n_id : id}}); //연결요청
                    else if(type == 3) history.push({pathname : 'NoticeDetail_3',state : {n_id : id}}); //공개요청


                    // if(type == 0) navigation.navigate('Notification_detail_0',{n_id : id});
                    // if(type == 0) history.push({
                    //     pathname : '/Notice'
                    // });
                    // else if(type ==1) navigation.navigate('Notification_detail_1',{n_id : id});
                    // else if(type == 2) navigation.navigate('Notification_detail_2',{n_id : id});
                    // else if(type == 3) navigation.navigate('Notification_detail_3',{n_id : id});
                    // else if(type == 4) navigation.navigate('Notification_detail_4',{n_id : id});
                }}>
                    <Grid container spacing={0} direction='row' style={{padding: '15px 20px', borderRadius: 10, boxShadow: '5px 5px 10px rgba(0,0,0,0.1)', position: 'relative', marginBottom: 35}}>
                        <Chip label={text} style={{position: 'absolute', top: -10, left: '50%', transform: 'translateX(-50%)', color: '#fff', backgroundColor: 'rgb(48,58,83)'}}/>
                        <Grid item xs={3} >
                            <Avatar alt="사진" src={profile} className={classes.large}/>
                        </Grid>
                        <Grid item xs={9} container direction="column">
                            <Grid item style={{marginTop: 15}}>
                                <Typography align='left' style={{fontSize: 13, color: 'rgb(51,51,51)', fontWeight: 'bold', lineHeight: 2}}>
                                    {content}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography align='right' style={{fontSize: 10, color: 'rgb(181,181,181)',width: '100%'}}>
                            {date}
                        </Typography>
                    </Grid>
                </Grid>
        )
    }

    /**
     * material ui function start
     */
    const [fillter, setFillter] = React.useState('');
    const fillterSelect = (event) => {
        setFillter(event.target.value);
        setList_type(event.target.value);
    };
    /*스위치*/
    const [state, setSwitch] = React.useState({
        checked: false,
    });
    const switchHandle = (event) => {
        setSwitch({ ...state, [event.target.name]: event.target.checked});
        setAuto_change(!auto_change);
        let col_val;//auto_change (setting 명함 자동교환 값 Y or N)
        if (!auto_change) col_val = 'Y';
        else col_val = 'N';

        Axios({
            url: 'https://sbc.smartbc.kr/sb/set_setting.php',
            method: 'get',
            params: {
                u_id : u_id,
                col : "auto_change",
                col_val : col_val
            },
        })
            .then(function (res) {
                console.log(res.data);
            })
            .catch(function (e) {
                console.log(e);
            })
    };
    /**
     * material ui function end
     */
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'알림'} back={1} history={history} match={match}/>
                {/* 상단 고정*/}
                <div style={{padding: '30px 40px 10px',marginTop: 50, boxShadow:  '0 5px 10px rgba(0,0,0,0.15)', position: 'sticky', top: 55, backgroundColor: '#fff', zIndex: 10}}>
                    <FormControl variant="outlined"  color='primary' style={{width: '100%', textAlign: 'left'}}>
                            <Select
                                aline= 'left'
                                value={fillter}
                                onChange={fillterSelect}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value="">전체</MenuItem>
                                <MenuItem value={'0'}>공지</MenuItem>
                                <MenuItem value={'1'}>제휴</MenuItem>
                                <MenuItem value={'2'}>인맥연결</MenuItem>
                                <MenuItem value={'3'}>공개요청</MenuItem>
                                <MenuItem value={'4'}>광고</MenuItem>

                            </Select>
                        </FormControl>
                    <Grid container style={{backgroundColor: 'rgb(239,242,246)', padding: 10, width: '100%', height: 40, borderRadius: 30, margin: '16px 0'}}>
                        <Grid xs={10}>
                            <Typography align= 'left'
                                        style={{fontSize: '12px', textIndent: 10}}>
                                개인정보 비공개 명함 자동교환
                            </Typography>
                        </Grid>
                        <Grid xs={2}>
                            <div style={{marginTop: -8}}>
                                <Switch
                                    checked={auto_change}
                                    onChange={switchHandle}
                                    color="primary"
                                    name="checked"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* 알림 리스트 */}
                <div className={classes.paper}>
                {list.map((val,index) => <NotiList key={index} id={val.id} type={val.type} date={val.create_at}
                                                   content={val.type == 0 ? val.title : val.name}
                                                   profile={val.profile}/>)}
                </div>
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 60px',
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },

}));


export default Notice;
