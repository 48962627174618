import React from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Chip,
    ListItem, Button, IconButton,

} from '@material-ui/core';
import PcHeader from "./PcHeader";

function InviteList() {
    const classes = useStyles();
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '30px'}}>
                <CssBaseline/>
                <PcHeader text={'초대 받은 모임 목록'}/>
                <div className={classes.paper}>
                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        초대 받은 모임이 복수로있습니다.<br/>
                        모임을 클릭하고 개별 가입해주세요!
                    </Typography>
                    <div style={{width: '100%'}}>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', cursor: 'pointer', width: '100%'}}>
                            <img src={require('../resources/association_example.png')} style={{height: 100, left: 20}} className={classes.yCenter}/>
                            <Chip label="미가입" className={classes.yCenter} style={{right: 28, height: 25, backgroundColor: 'rgb(239,113,113)', color: '#fff', fontSize: 12}}/>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', cursor: 'pointer', width: '100%'}}>
                            <img src={require('../resources/association_example.png')} style={{height: 100, left: 20}} className={classes.yCenter}/>
                            <Chip label="미가입" className={classes.yCenter} style={{right: 28, height: 25, backgroundColor: 'rgb(239,113,113)', color: '#fff', fontSize: 12}}/>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '0 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: 14,
    },
    listBox: {
        display: 'block',
        height: '57px',
        border: '2px solid rgb(157,165,185)',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));


export default InviteList;
