import React,{useEffect} from "react";
import {
    CircularProgress,
    Container,
    CssBaseline, IconButton,
    makeStyles,
    Typography,Button
} from "@material-ui/core";
import PcHeader from "../PcHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Axios from "axios";
import { IoIosCall } from "react-icons/io";
import { RiMessage2Line } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import { ImLocation } from "react-icons/im";
import { SiTwitter } from "react-icons/si";
import { AiOutlineHome } from "react-icons/ai";
import { MdShare } from "react-icons/md";
import Moment from 'moment';
import customConfirm from "../../component/customConfirm";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import customAlert from "../../component/customAlert";

function AddHyphen(num) {
    let regexp;
    if(num.length==7){
        regexp = num.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{4})/, '$1-$2');
    }else if(num.length==8){
        regexp = num.replace(/[^0-9]/g, "").replace(/(\d{4})(\d{4})/, '$1-$2');
    } else{
        regexp = num.replace(/[^0-9]/g, "").replace(/(^02|^0505|^0504|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
    }

    return regexp;
}

export default function AdminJoinUserDetail({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const[member,setMember] = React.useState(new Array());

    const [upfile, setUpfile] = React.useState(''); //파일이름포함한 경로
    const [loading, setLoading] = React.useState(true);

    const [userLevel, setUserLevel] = React.useState(''); //1:운영자, 2:우수회원 4:일반회원
    const [writeList, setWriteList] = React.useState([]);
    const [op2List, setOp2List] = React.useState([]);

    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        window.open("https://info.smartbc.kr/b2a");
    };

    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

    useEffect(() => {
        console.log('상세',history.location.state)
        let jo=[];
        if(history.location.state.join_option){
            jo = history.location.state.join_option.toString();
        }

        Axios({
            url: "https://sbc.smartbc.kr/sb/band_admin/admin_join_user_detail2.php", //리뉴얼
            method: 'get',
            params: {
                target_id : history.location.state.sel_u_id,
                join_option:jo,
                b_id : b_id,
            }
        })
            .then(function (response) {
                console.log('정보',response.data);

                setMember(response.data.card_data);
                setUpfile(response.data.card_data.upfile);

                if(response.data.op2_list){
                    setOp2List(response.data.op2_list);
                }
                if(response.data.user_level==1){
                    setUserLevel('운영자');
                }else if(response.data.user_level==2){
                    setUserLevel('우수회원');
                }
                if(response.data.write_list) setWriteList(response.data.write_list);


                setLoading(false);
                // console.log('반응타입',response.data.response_type);
                // console.log('카드연결',response.data.card_linked);

            })
            .catch(function (error) {
                console.log('detail error' + error);
            });
    }, []);

    const InputContainer=({label,text,call,message, email,location, link})=> {
        return (
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={2} style={{textAlign: 'left'}}>{label}</Grid>
                    <Grid item xs={10} style={{textAlign :'left',paddingLeft :'5%', position: 'relative'}}>
                        {text}
                        {/*{profile?*/}
                        {/*    <span style={{display: 'inline-block', position: 'absolute', right: 12, top: '50%', width: 35, height: 35, transform: 'translateY(-50%)', overflow: 'hidden',borderRadius: '50%'}}>*/}
                        {/*        <img src={"https://sbc.smartbc.kr" + profile} style={{width: 40,position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)'}}/>*/}
                        {/*    </span>*/}
                        {/*    :null}*/}
                        {call?
                            <IconButton style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(navigator.platform){
                                                if(isMobile){
                                                    setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                    setConfirmShow(true);

                                                    // let answer1 = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                    // if (answer1) window.open("https://info.smartbc.kr/b2a");
                                                }else{
                                                    customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                                }
                                            }
                                        }}>
                                {/*<img src={require('../resources/call_icon.png')} style={{width: 35}}/>*/}
                                <IoIosCall size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {message?
                            <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(navigator.platform){
                                                if(isMobile){
                                                    setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                    setConfirmShow(true);

                                                    // let answer2 = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                    // if (answer2) window.open("https://info.smartbc.kr/b2a");
                                                }else{
                                                    customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                                }
                                            }
                                        }}>
                                {/*<img src={require('../resources/call_icon.png')} style={{width: 35}}/>*/}
                                <RiMessage2Line size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {email?
                            <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            window.open("mailto:"+email);
                                        }}>
                                <GoMail size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {location?
                            <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            //window.open('about:blank').location.href='https://'+location;
                                        }}>
                                <ImLocation size={20} style={{color:'#303a53'}}/>
                            </IconButton>
                            :null}
                        {link?
                            <IconButton style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}
                                        onClick={()=>{
                                            if(link.indexOf('http://')!=-1 ||
                                                link.indexOf('https://')!=-1){
                                                //문자열 포함
                                                window.open('about:blank').location.href=link;//새창 o
                                            }else{
                                                window.open('about:blank').location.href='https://'+link;//새창 o
                                            }
                                        }}>
                                <img src={require('../../resources/homepage_icon.png')} style={{width: 35}}/>
                            </IconButton>
                            :null}

                    </Grid>
                </Grid>
                <Divider/>
            </div>
        );
    }

    const Card_image = ({image}) =>{
        return(
            <div className={classes.paper}>
                <div style={{alignItems: 'center', justifyContent: 'center', backgroundColor: '#f9fafc',}}>
                    <img src={"https://sbc.smartbc.kr" + member.image} style={{maxWidth : 400,marginTop : 10}}/>
                </div>
            </div>
        );
    } // end Card_image

    const LinkIcon = ({btn,url})=>{
        let color_ = 'white';
        let icon = null;
        if(btn == 1){
            color_= 'rgb(48 ,58, 83)';
            icon =<AiOutlineHome name={'home'} color={'white'} size={30} style={{height:'100%'}}/>;
        }else if(btn == 2){
            color_= '#2DB400';
            icon=<img src={require('../../resources/blog.png')}  style={{height:'70%',width:'70%',marginTop:10}}/>;
        }else if(btn == 3){
            icon =<img src={require('../../resources/facebook.jpg')}  style={{height:'100%',width:'100%',borderRadius:50}}/>;
        }else if(btn == 4){
            // color_='rgb(29, 161, 242)';
            // icon=<SiTwitter name={'twitter'} color={'white'} size={30} style={{height:'100%'}}/>;
            icon =<img src={require('../../resources/icon_youtube.png')}  style={{height:'100%',width:'100%',borderRadius:50}}/>;

        }else if(btn == 5){
            icon=<img src={require('../../resources/instar.png')} style={{height:'100%',width:'100%',borderRadius:50}}/>;

        }else if(btn == 6){
            color_='#003366';
            icon=<MdShare name={'share'} color={'white'} size={30} style={{height:'100%'}}/>;
        }
        return (
            url?
                <>

                    <div style={{cursor: 'pointer',backgroundColor:color_,width:50,height:50,borderRadius:50,marginRight:10,justifyContent:'center', alignItems:'center'}}
                         onClick={() => {
                             if (url.indexOf('http://') != -1 || url.indexOf('https://') != -1) {
                                 //문자열 포함
                                 window.open('about:blank').location.href=url;//새창 o
                             } else {
                                 //문자열 포함 안함
                                 window.open('about:blank').location.href='https://'+url;//새창 o
                             }

                         }}>
                        {icon}
                    </div>

                </>
                :null
        )
    }


    const FileDownload = ({label, text}) =>{
        let text2 = text+'';
        let content = text2.split('/'); //split은 string일 때 가능
        content = content[content.length-1];
        return(
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={2} style={{textAlign: 'left'}}>{label}</Grid>
                    <Grid item xs={10} style={{textAlign :'left',paddingLeft :'15%', position: 'relative'}}
                          onClick={()=>{
                              window.location.href='https://sbc.smartbc.kr/sb/download_file.php?file='+text
                          }}>
                        {content}
                        <IconButton style={{position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)'}}>
                            <img src={require('../../resources/fileimage.png')} style={{width: 25}} onClick={() => {
                                window.location.href='https://sbc.smartbc.kr/sb/download_file.php?file='+text
                            }}/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider/>
            </div>
        );
    }//end FileDownload


    const WriteList = ({title,date,id,table})=>{
        let tableName='';
        if(table==1){
            tableName='band_board1';
        }else if(table==2){
            tableName='band_board2';
        }else if(table==3){
            tableName='band_board3';
        }

        return(
            <div style={{alignItems: 'center',justifyContent: 'center',width:'100%',height:40,display:'flex',border: '1px solid rgb(217, 217, 217)',  borderRadius: 5,}}>
                 {/*cursor: 'pointer',*/}
                 {/*onClick={()=>{*/}
                 {/*    history.push({*/}
                 {/*        pathname: 'NoticeBoardDetail',*/}
                 {/*        state: {board_id: id, table: tableName}*/}
                 {/*    });*/}
                 {/*}}*/}
                <Typography align={'left'}
                            className={classes.textEllipsis}
                            style={{fontSize: 13, letterSpacing: 0.5,color:'black',width:'70%', marginLeft:20,}}>
                    {/*overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal', lineHeight: 2, display: '-webkit-box',lineClamp: 1,boxOrient: 'vertical',*/}
                    {title}
                </Typography>
                <div align={'right'} style={{fontSize: 12, letterSpacing: 0.5,color:'gray',width:'25%',marginRight:20,paddingTop:5 }}>
                    {Moment(date).format('YYYY-MM-DD')}</div>
            </div>
        )
    }

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center',marginTop: 65}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'가입 회원 상세'} back={1} history={history} match={match}/>
            {/*body*/}
            {/*프로필 이미지*/}

            {/*verticalAlign:'middle'*/}
            {member.profile ?
                <div align={'center'} style={{width:'100%',height:270,}}>
                    {/* backgroundColor:'#d9d9d9',*/}
                    <img src={"https://sbc.smartbc.kr" + member.profile} style={{maxHeight : 270}}/>
                </div>
                :null}

            {/*custom_confirm*/}
            <div>
                <Dialog
                    open={confirmShow}
                    onClose={handleCloseN}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {confirmMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseN} color="primary">
                            {'아니오'}
                        </Button>
                        <Button onClick={handleCloseY} color="primary" autoFocus>
                            {'예'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {/*이름, 버튼 영역*/}
            <div style={{width:'100%',height:100,borderBottomWidth:0.5,flexDirection:'row'}}>
                <Grid container style={{}}>
                    {/*이름,직함*/}
                    <Grid item xs={7} style={{textAlign: 'left',paddingLeft:30,paddingTop:20}}>
                        <Grid container xs={10}>
                            <Grid item xs={5} style={{fontSize:17,fontWeight:'bold',color:'black'}}>{member.name?member.name:'이름'}</Grid>
                            <Grid item xs={5} style={{marginLeft:5,paddingTop:3,fontSize:15,color:'gray'}}>{userLevel? '('+userLevel+')': null}</Grid>
                        </Grid>
                        <Grid item xs={7} style={{fontSize:14,fontWeight:'bold',color:'gray'}}>
                            {member.mc_position?member.mc_position+' | ':null}  {member.part?member.part:null}</Grid>
                        <Grid item xs={7} style={{fontSize:14,color:'gray'}}>{member.company?member.company:null}</Grid>
                    </Grid>

                    {/*연결상태 버튼*/}
                    <Grid item xs={4.5} style={{textAlign: 'center',alignItems: 'center', justifyContent: 'center',padding:30}}>
                       {null}
                    </Grid>

                </Grid>
            </div>

            <Divider/>


            <InputContainer label={'휴대전화'} text={member.phone} call={member.phone} message={member.phone}/>
            {member.email?
                <InputContainer label={'이메일'} text={member.email}  email={member.email}/>
            :null}
            {member.office_phone?
                <InputContainer label={'유선전화'} text={member.office_phone} call={member.office_phone}/>
                :null}
            {member.fax?
                <InputContainer label={'팩스번호'} text={member.fax}/>
                :null}
            {member.company?
                <InputContainer label={'소속명'} text={member.company}/>
                :null}
            {member.part?
                <InputContainer label={'부서/파트'} text={member.part}/>
                :null}
            {member.mc_position?
                <InputContainer label={'직책'} text={member.mc_position}/>
                :null}
            {member.address?
                <div>
                    <Grid container style={{padding: 15}}>
                        <Grid item xs={2} style={{textAlign: 'left'}}>{'주소'}</Grid>
                        <Grid item xs={10} style={{textAlign :'left',paddingLeft :'5%', position: 'relative'}}>
                            <Grid item xs={8}>{member.address}</Grid>
                            {/*<Grid item xs={2}>*/}
                            {/*    <IconButton style={{position: 'absolute', right: 40, top: '50%', transform: 'translateY(-50%)'}}*/}
                            {/*                onClick={()=>{*/}
                            {/*                }}>*/}
                            {/*        <ImLocation size={20} style={{color:'#303a53'}}/>*/}
                            {/*    </IconButton>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                :null}

            {/*소개글*/}
            {member.introduce ?
                <>
                    {/*<div style={{ borderRadius: 10, backgroundColor: '#f9fafc', width: '90%', padding: 15, alignSelf: 'center', marginTop: 10, justifyContent:'center', alignItems:'center'}}>*/}
                    {/*    <div style={{fontSize: 15, letterSpacing: 0.5}}>{'소개글'}</div>*/}
                    {/*</div>*/}
                    <div  className={classes.introduceContainer}>
                        <Grid align={'left'} style={{fontSize: 13, letterSpacing: 0.5}}>{member.introduce}</Grid>
                    </div>
                    <Divider/>
                </>
                :null}

            {/*사이트 링크*/}
            {member.homepage||member.blog||member.facebook||
            member.twitter||member.instargram||member.shoppingMall?
                <>
                    <div style={{width: '100%', height: 70, display: 'flex',marginTop:20,marginLeft:20}}>
                        {/* justifyContent: 'center', alignItems: 'center'*/}
                        {member.homepage?
                            <LinkIcon btn={1} url={member.homepage}/>
                            :null}
                        {member.blog?
                            <LinkIcon btn={2} url={member.blog}/>
                            :null}
                        {member.facebook?
                            <LinkIcon btn={3} url={member.facebook}/>
                            :null}
                        {member.twitter?
                            <LinkIcon btn={4} url={member.twitter}/>
                            :null}
                        {member.instargram?
                            <LinkIcon btn={5} url={member.instargram}/>
                            :null}
                        {member.shoppingMall?
                            <LinkIcon btn={6} url={member.shoppingMall}/>
                            :null}
                    </div>
                </>
                :null}

            {/*최근 작성글*/}
            {writeList.length>0?
                <>
                    {/*<div style={{ borderRadius: 10, backgroundColor: '#f9fafc', width: '90%', padding: 15, alignSelf: 'center', marginTop: 10, justifyContent:'center', alignItems:'center'}}>*/}
                    {/*    <div style={{fontSize: 15, letterSpacing: 0.5}}>{'최근 작성글'}</div>*/}
                    {/*</div>*/}
                    <div style={{
                        // borderRadius: 10,
                        // backgroundColor: '#f9fafc',
                        width: '90%',
                        // height: 150,
                        alignSelf: 'center',
                        marginTop: 20,
                        // border: '1px solid rgb(217, 217, 217)',
                        marginBottom:40,
                    }}>
                        {writeList.map((value)=>
                            <WriteList key={value.date} title={value.title} date={value.date} id={value.id} table={value.table}/>
                        )}
                    </div>
                </>
                :null}
            <Divider/>

            {member.upfile?
                <FileDownload label={'첨부파일'} text={member.upfile} />
                :null}
            {member.c1_name?
                <InputContainer label={'회사업종'} text={member.c1_name+' > '+member.c2_name}/>
                :null}
            {member.p1_name?
                <InputContainer label={'직업군'} text={member.p1_name+' > '+member.p2_name}/>
                :null}
            {member.pos_name?
                <InputContainer label={'직급유형'} text={member.pos_name}/>
                :null}

            {member.a1_name?
                <InputContainer label={'지역'} text={member.a1_name}/>
                :null}
            {op2List.length>0?
                <InputContainer label={'모임옵션'} text={op2List+''}/>
                :null}
            {loading?
                <>
                    <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                    <div className={classes.popUpBg} />
                </>
                :null}
        </Container>
    );
}
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },

    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.5,
        // height: '50px',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
        // padding: 10,

    },
    introduceContainer: {
        borderRadius: 10,
        backgroundColor: '#f9fafc',
        width: '90%',
        padding: 15,
        alignSelf: 'center',
        marginTop: 20,
        // borderColor:'gray',
        // borderWidth:0.2,
        justifyContent:'center',
        alignItems:'center',
        marginBottom:20
    },

}));

