import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    TextField,
    FormControl,
    MenuItem,
    Select,
    FormHelperText
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import Grid from "@material-ui/core/Grid";
import UseWIndowDimensions from "../component/UseWIndowDimensions";
import Axios from "axios";
import customAlert from "../component/customAlert";


function IcConfirm({history,match}) {

    const classes = useStyles();
    /* selectbox */
    const [cardinalNumber, setCardinalNumber] = React.useState('');
    const [bloodType, setBloodType] = React.useState('');
    const [university, setUniversity] = React.useState('');
    const { height, width } = UseWIndowDimensions();

    const select1 = (event) => {
        setCardinalNumber(event.target.value);
    };
    const select2 = (event) => {
        setUniversity(event.target.value);
    };

    const select3= (event) => {
        setBloodType(event.target.value);
    };


    const join_status = history.location.state.data;
    // const userName = route.params.userName;
    const phone = history.location.state.phone;

    const [u_id,setU_id]=React.useState('');
    const [b_id,setB_id]=React.useState('');


    const [opList,setOpList] = React.useState([]);
    const  [selectedList, setSelectedList] = React.useState({});//선택한 옵션값

    let tmp_data = {};
    let join_data = {};

    useEffect(()=>{
        console.log('history',history);//state: {data: 114, phone: "01012341212"}
        console.log('match',match); // match.params.JoinCode
        Axios({
            url: 'https://sbc.smartbc.kr/sb/icConfirm_PC.php',
            method: 'get',
            params: {
                phone: history.location.state.phone,
                admin_id: match.params.JoinCode,
                join_status: history.location.state.data,
                // fcm_token:token,
            },
        })
            .then((result) => {
                console.log(result.data);
                setOpList(result.data.op1_list);
                setU_id(result.data.u_id);
                setB_id(result.data.b_id);


            })
            .catch((err) => console.log(err.message));
    },[]);

    const OptionList = ({option1,option2,selList ,s})=> {
        // const [tmpValue,setTmpValue] = React.useState(selList);
        let items = new Array();
        option2.map((value)=> {
            return (
                items.push( <MenuItem value={value.id} >{value.name}</MenuItem>)
            );
        });
        return(
            <Grid container>
                <Grid item xs={3} className={classes.selectTilie}>{option1.name}</Grid>
                <Grid item xs={9}>
                    <FormControl className={classes.formControl}>
                        <Select
                            displayEmpty
                            className={classes.selectEmpty}
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{backgroundColor: '#fff'}}
                            // classes={{root: classes.selectInput}}
                            // value={tmpValue}
                            onChange={(event) => {
                                console.log('선택',event.target.value);
                                // setTmpValue(event.target.value);
                                let tmp = selectedList;
                                tmp[option1.id] = event.target.value;
                                setSelectedList(tmp);
                            }}
                        >
                            <MenuItem value="" disabled>선택</MenuItem>
                            {items}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '30px'}}>
            <CssBaseline/>
            <PcHeader text={'스마트 모임'} history={history} match={match}/>
            <div className={classes.paper}>
                <Typography
                    style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                    {/*협회 초대코드가 확인되었습니다.<br/>*/}
                    모임에서 요청하는 정보를 설정해 주세요.
                </Typography>
                <form className={classes.form} noValidate style={{marginBottom: 20}} >
                    {opList.map((value)=> <OptionList key={value[0].id} option1={value[0]} option2={value[1]} selList={selectedList[value[0].id]} s={value[0].id}/>)}
                </form>

                {/* 버튼 */}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.bottomBtn}
                    onClick={()=>{
                        let status = true;
                        console.log('asdf');
                        console.log(opList.length,'option length');
                        console.log( Object.keys(selectedList).length,'selsct length');


                        if(opList.length ==  Object.keys(selectedList).length){
                            for (const [key, value] of Object.entries(selectedList)) {
                                if(value==0){
                                    status = false;
                                }
                            }
                        } else {
                            status = false;
                        }


                        if(status){
                            tmp_data['join_status'] = join_status;
                            tmp_data['phone'] = phone;
                            tmp_data['b_id'] = b_id;
                            tmp_data['selectList'] = selectedList;

                            join_data = JSON.stringify(tmp_data);
                            console.log('tmp_data',tmp_data);
                            console.log('status',status);
                            // return;
                            // messaging().getToken().then(token => {
                            //     console.log('token',token);
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/band_req_info_regist.php' ,
                                method: 'get',
                                params: {
                                    join_data: join_data,
                                    // fcm_token:token,
                                },
                                // resultType:'text',
                            })
                                .then((result) => {
                                    console.log(result.data);
                                    customAlert(result.data.result_msg);
                                    if (result.data.status == 5001||result.data.status == 5002 ) {
                                        //로그인 정보 저장
                                        try {
                                            sessionStorage.setItem('@u_id', result.data.u_id);
                                            sessionStorage.setItem('@b_id', result.data.b_id);
                                            history.replace('/' + match.params.JoinCode);

                                        } catch (e) {
                                            console.log(e);
                                        }
                                    }else{
                                        history.replace('/'+match.params.JoinCode+'/SignUp');
                                    }

                                })
                                .catch((err) => console.log(err.message));
                            // });

                        } else {
                            customAlert('값들을 모두 선택해 주세요');
                        }
                    }}
                >
                    모임 요청 정보 등록
                </Button>
            </div>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '0 30px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    title: {
        fontSize: 14,
    },
    formControl: {
        width: '100%',
        textAlign: 'left',
        textIndent: 10,
        marginTop: theme.spacing(3),
    },
    selectTilie: {
        textAlign: 'left',
        marginTop: theme.spacing(4),
    },
    bottomBtn: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 60,
        background: '#303a53',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight:'bold',
        '&:hover': {
            background: '#303a53',
        },

    },

}));


export default IcConfirm;
