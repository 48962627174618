import React, {useEffect} from "react";
import {
    Divider,
    Grid,
    Container, CssBaseline, makeStyles,
    Box, TextField, Typography, Button, FormControlLabel, CircularProgress, InputBase
} from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Datetime from 'react-datetime';
import DateTimePicker from 'react-datetime-picker' ;
import Moment from 'moment';
import { AiOutlinePaperClip } from "react-icons/ai";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import customAlert from "../component/customAlert";

//리뉴얼
export default function NoticeBoardWrite({history,match}) {
    const classes = useStyles();

    const admin = sessionStorage.getItem('@admin');
    const u_id = sessionStorage.getItem('@u_id');
    const b_id = admin == 1 ? sessionStorage.getItem('@b_id_a') : sessionStorage.getItem('@b_id');

    const table = history.location.state.table; //게시글 작성 테이블 / 소식,협력,자유
    const type = history.location.state.type;  // type => 작성 (write),수정(update)
    const formData = new FormData();
    let postData = {};

    const [title, setTitle] = React.useState('');
    const [content, setContent] = React.useState('');

    const [notiType3,setNotiType3] = React.useState(0); //보드3 공지유형 = 0:일반공지  1:행사공지
    const [eventDate,setEventDate] = React.useState(); //행사공지 행사날짜
    const [eventPlace,setEventPlace] = React.useState(''); //행사공지 행사장소

    const [loading , setLoading ] = React.useState(true);

    const [file1, setFile1] = React.useState('');
    const [file2, setFile2] = React.useState('');
    const [file3, setFile3] = React.useState('');
    const [file4, setFile4] = React.useState('');

    const [upfiles, setUpfiles] = React.useState([]);
    const [upfiles2, setUpfiles2] = React.useState([]); // 수정시 파일첨부

    //수정 시 기존 업로드된 파일명 보여주기
    const SetFilePath = (i,fileName) =>{
        console.log('set파일',i, fileName);
        if(i == 0){
            setFile1(fileName);
        }else if(i==1){
            setFile2(fileName);
        }else if(i==2){
            setFile3(fileName);
        }else if(i==3){
            setFile4(fileName);
        }
    }

    const BeforeUpload = (formData,fileVal,fileName,i) =>{
        console.log('before',formData)
        Axios({
            method: 'POST',
            url: 'https://sbc.smartbc.kr/sb/before_upload_file.php',
            data: formData,//formData
            params: {
                u_id: u_id
            },
            headers: {
                'Content-Type': 'multipart/form-data;'
            }
        })
            .then((result) => {
                console.log('통신함?', result.data);

                if (result.data.status == 27003) {

                    if(upfiles.length>0){
                        // for(let i=0; i<upfiles.length; i++){
                        let tmp = upfiles;
                        let idx = -1;
                        console.log('임시파일',upfiles);
                        if(i==0){
                           idx = tmp.indexOf(tmp.find(element=> element.name==file1))
                        }else if(i==1){
                            idx = tmp.indexOf(tmp.find(element=> element.name==file2))
                        }else if(i==2){
                            idx = tmp.indexOf(tmp.find(element=> element.name==file3))
                        }else if(i==3){
                            idx = tmp.indexOf(tmp.find(element=> element.name==file4))
                        }
                        console.log('뺄 인덱스',idx)
                        if(idx != -1){
                            upfiles.splice(idx,1);
                            console.log('겹치는 값 뺀 임시 체크리스트',tmp);
                            tmp.push(fileVal);
                            setUpfiles(tmp);
                            console.log('리스트 담음1',tmp)
                        }else{
                            tmp.push(fileVal);
                            setUpfiles(tmp);
                            console.log('리스트 담음2',tmp)
                        }
                        // }
                    }else{
                        let tmp = upfiles;
                        tmp.push(fileVal);
                        setUpfiles(tmp);
                        console.log('리스트 담음3',tmp)
                    }
                    SetFilePath(i,fileName);
                    // if(i==1){
                    //     setFile1(fileName);
                    //     // setUpfile(fileVal);
                    // }else if(i==2){
                    //     setFile2(fileName);
                    // }else if(i==3){
                    //     setFile3(fileName);
                    // }else if(i==4){
                    //     setFile4(fileName);
                    // }

                }
                customAlert(result.data.result_msg);
            })
            .catch((err) => console.log(err.message));
    }



    // /**
    //  * pc , mobile check
    //  */
    //     // 모바일 여부
    // let isMobile = false;
    //
    // // PC 환경
    // const filter = "win16|win32|win64|mac|macintel";
    // const varUA = navigator.userAgent.toLowerCase();
    // if (navigator.platform) {
    //     isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
    //     if(isMobile) {
    //         console.log('mobile here');
    //         console.log(filter);
    //     }else{
    //         console.log('web here');
    //         console.log(navigator.platform);
    //         let param = document.location.href.split('/'); // 그룹 구분자인 관리자 id 값만 가져옴
    //         // location.href = 'http://groupf.smartbc.kr/'+param[3];
    //     }
    // }

    useEffect(() => {
        if(history.location.state.board_id) {
            Axios({
                url: 'https://sbc.smartbc.kr/sb/board_detail.php',
                method: 'get',
                params: {
                    board_id: history.location.state.board_id,
                    table: table
                }
            })
                .then(function (res) {
                    console.log("write success");
                    console.log(res.data);
                    if (res.data['upfile2']) {
                        //다중 파일
                        let arr = JSON.parse(res.data['upfile2']);
                        let tmp = upfiles2;
                        let imgExtArr = ['jpg', 'jpeg', 'gif', 'png','JPG', 'JPEG', 'GIF', 'PNG'];
                        for (let i = 0; i < arr.length; i++) {
                            let filename = arr[i].split("/").pop();
                            let ext = filename.split(".").pop();
                            if (imgExtArr.includes(ext)) {
                                SetFilePath(i, filename);
                                tmp.push(arr[i]);
                                console.log('이미지첨부파일')
                            } else {
                                setFile4(filename);
                                tmp.push(arr[i]);

                            }
                        } // for end
                        setUpfiles2(tmp);
                        console.log('서버저장파일', tmp)
                    } //다중 파일 끝

                    setNotiType3(Number(res.data['type']));

                    //string to date
                    let event_date = res.data['event_date'];
                    if (event_date) {
                        let y = event_date.substr(0, 4);
                        let m = event_date.substr(6, 2);
                        let d = event_date.substr(10, 2);
                        let h = event_date.substr(14, 2);
                        let mm = event_date.substr(17, 2);
                        console.log('라와',y, m, d ,h ,mm)
                        event_date = new Date(y,m-1,d, h, mm);
                        console.log('시간!!1',event_date)
                        setEventDate(event_date);
                    }

                    setEventPlace(res.data['event_place']);
                    setTitle(res.data['title']);
                    setContent(res.data['content']);

                    setLoading(false);
                })
                .catch(function (e) {
                    console.log(e + "write error");
                })
        }
    }, [history.location.state.board_id]);


if(history.location.state.board_id&&loading){
    return (
        <>
            <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
            <div className={classes.popUpBg} />
        </>
    )
}else {


    return (
        <>
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center'}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'게시글 작성'} back={1} history={history}/>
            {/*body*/}
            <div className={classes.paper}>

                {/*공지 타입*/}
                {table == 'band_board1' ?
                    <>
                        <div style={{width: '100%', marginTop: 15,}}>
                            <RadioGroup row aria-label="notiType3"
                                        name="notiType3"
                                        // defaultValue={0}
                                        value={notiType3}
                                        style={{padding: 10,}}
                                        className={'_radioFont'}
                                        onChange={(event) => {
                                            setNotiType3(Number(event.target.value));
                                            console.log('라디오', event.target.value);
                                            console.log('라디오', notiType3);

                                        }}>
                                <FormControlLabel value={0} control={<Radio color="primary"/>} label="일반공지" style={{}}/>
                                <FormControlLabel value={1} control={<Radio color="primary"/>} label="행사공지" style={{}}/>
                            </RadioGroup>
                            <Divider/>
                        </div>
                        {notiType3==1?
                            <>
                        <div style={{
                            width: '100%',
                            padding: '15px 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}>
                            <div style={{width: '20%',}}>
                                <Typography style={{fontSize: 14}}>{'일자 / 시간'}</Typography>
                            </div>
                            <div style={{width: '80%', textAlign: 'left', paddingLeft: 20}}>
                                {/*<InputBase style={{textAlign :'left'}}*/}
                                {/*           placeholder={'시간을 입력해주세요'}*/}
                                {/*           disabled={true}*/}
                                {/*           value={eventDate}*/}
                                {/*           onChange={(event)=>{*/}
                                {/*               setEventDate(event.target.value);*/}
                                {/*           }}*/}
                                {/*/>*/}
                                {/*<Datetime />*/}

                                <DateTimePicker
                                    calendarIcon={null}
                                    disableClock={true}
                                    onChange={(value) => {
                                        console.log('타입', typeof value) //obj
                                        console.log('원래표기', value) //obj
                                        // console.log('바뀐표기',value.toLocaleString("ko-KR", {timeZone: "Asia/Seoul"}));
                                        // console.log('포맷', Moment(value).format("YYYY년 MM월 DD일 HH:mm"))
                                        setEventDate(value);

                                    }}
                                    value={eventDate}
                                />
                            </div>
                        </div>

                        <div style={{
                            width: '100%',
                            padding: '15px 15px 15px 0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                        }}>
                            <div style={{width: '20%',}}>
                                <Typography style={{fontSize: 14}}>{'장소 / 주소'}</Typography>
                            </div>
                            <div style={{width: '80%', paddingLeft: 20}}>
                                <InputBase style={{textAlign: 'left', width: '100%'}}
                                           multiline={true}
                                           placeholder={'장소를 입력해주세요'}
                                           value={eventPlace}
                                           onChange={(event) => {
                                               console.log('장소', event.target.value)
                                               setEventPlace(event.target.value);
                                           }}
                                />
                            </div>
                        </div>
                            </>:null}
                    </>
                    : null}
                {/*제목*/}
                <div style={{width: '100%',}}>
                    <Grid alignItems='center' container style={{padding: 15}}>
                        {/*<Box id={'title'} component={'input'} style={{fontSize:13,border: 'none', outline: 'none', width: '100%', padding: 10}} placeholder='제목을 입력해 주세요.'/>*/}
                        <InputBase style={{textAlign: 'left', width: '100%'}}
                                   multiline={true}
                                   placeholder={'제목을 입력해 주세요.'}
                                   value={title}
                                   onChange={(event) => {
                                       console.log('제목', event.target.value)
                                       setTitle(event.target.value);
                                   }}
                        />
                    </Grid>
                    <Divider/>
                </div>
                {/*내용*/}
                <div style={{width: '100%',}}>
                    <Grid alignItems='center' container style={{padding: 15}}>
                        {/*<Box id={'content'} component={'textarea'}  style={{fontSize:13,border: 'none', outline: 'none', width: '100%', padding: 10, resize: 'none', minHeight: 430, lineHeight: 1.5}} placeholder={'내용을 입력해 주세요.'}/>*/}
                        <InputBase style={{textAlign: 'left', height: 300, width: '100%'}}
                                   multiline={true}
                                   rows={15}
                                   placeholder={'내용을 입력해 주세요.'}
                                   value={content}
                                   onChange={(event) => {
                                       console.log('내용', event.target.value)
                                       setContent(event.target.value);
                                   }}
                        />
                    </Grid>
                    <Divider/>
                </div>
                {/*이미지1*/}
                <div style={{width: '100%'}}>
                    <Grid alignItems='center' container style={{padding: 15}}>
                        <div style={{width: '20%', textAlign: 'left', fontSize: 14}}>사진첨부1</div>
                        <InputBase style={{textAlign: 'left', width: '50%',fontSize: 14,}}
                                   placeholder={'사진/이미지 등록'}
                                   value={file1}
                            // disabled={true} //회색으로 보임
                                   readOnly={true}
                                   onChange={(event) => {
                                       setFile1(event.target.value);
                                   }}
                            // onClick={()=>{
                            //     setModal(true);
                            // }}
                        />
                        {file1?
                            <CancelPresentationIcon size={20} style={{margin:'0 10px'}} onClick={()=>{
                                if(upfiles.length>0) {
                                    let tmp = upfiles;
                                    const idx = tmp.indexOf(tmp.find(element => element.name == file1))
                                    console.log('임시파일',upfiles);
                                    console.log('뺄 인덱스', idx)
                                    if (idx != -1) {
                                        upfiles.splice(idx, 1);
                                        console.log('겹치는 값 뺀 임시 체크리스트', tmp);
                                        setUpfiles(tmp);
                                        setFile1('');
                                    }
                                }

                                if(history.location.state.board_id){
                                    if(upfiles2.length>0) {
                                        let tmp = upfiles2;
                                        const idx = tmp.indexOf(tmp.find(element => element.split("/").pop() == file1))
                                        console.log('뺄 인덱스', idx)
                                        if (idx != -1) {
                                            upfiles2.splice(idx, 1);
                                            console.log('겹치는 값 뺀 임시 체크리스트', tmp);
                                            setUpfiles2(tmp);
                                            setFile1('');
                                        }
                                    }
                                }
                            }}/>
                            : <div style={{margin:'0 20px'}}/>}

                        <Button component="label" // 이거 써야 <input/> 가능?!
                                type="submit"
                                color="primary"
                                style={{backgroundColor: 'rgb(43,66,124)', color: '#fff', fontWeight: 'bold', fontSize: 15, borderRadius: 25, padding: 10,}}>
                            <AiOutlinePaperClip size={20}/>
                            {"첨부"}
                            {/*파일업로드*/}
                            <input id={"file-input"} style={{display: 'none'}} type="file" name="file-input"
                                   accept={'.gif, .jpg, .jpeg, .png'}
                                // ref="file"
                                   onChange={(event) => {
                                       //취소가 아닐 때 (확인 눌렀을 때)
                                       if (event.target.value != '') {
                                           // setModal(true);
                                           // console.log('파일경로',event.target.files[0]);
                                           // console.log('??',event.target.files[0].name)
                                           let fileVal = event.target.files[0];
                                           let fileName = event.target.value.split('\\').pop();

                                           if(file2==fileName||file3==fileName){
                                               customAlert('이미 선택한 이미지입니다.');
                                               return;
                                           }

                                           formData.append("before_upfile", fileVal);
                                           console.log('파일ㄹ?', fileVal);
                                           console.log('들어감?', formData);

                                           let i = 0;
                                           if (formData) {
                                               BeforeUpload(formData,fileVal,fileName,i);
                                           } else {
                                               customAlert('파일을 다시 선택해 주세요');
                                           }

                                       }


                                       console.log('파일입니다.', event.target.value);
                                   }}/>

                        </Button>

                    </Grid>
                    <Divider/>
                </div>

                {/*이미지2*/}
                <div style={{width: '100%'}}>
                    {/*,justifyContent:'center',alignItems:'center',*/}
                    <Grid alignItems='center' container style={{padding: 15}}>
                        {/*<div style={{width:'20%',textAlign: 'left',fontSize:14}}>파일첨부</div>*/}
                        <div style={{width: '20%', textAlign: 'left', fontSize: 14}}>사진첨부2</div>
                        <InputBase style={{textAlign: 'left', width: '50%',fontSize: 14,}}
                                   placeholder={'사진/이미지 등록'}
                                   value={file2}
                            // disabled={true} //회색으로 보임
                                   readOnly={true}
                                   onChange={(event) => {
                                       setFile2(event.target.value);
                                   }}
                            // onClick={()=>{
                            //     setModal(true);
                            // }}
                        />
                        {file2?
                            <CancelPresentationIcon size={20} style={{margin:'0 10px'}} onClick={()=>{
                                if(upfiles.length>0) {
                                    let tmp = upfiles;
                                    const idx = tmp.indexOf(tmp.find(element => element.name == file2))
                                    console.log('뺄 인덱스', idx)
                                    if (idx != -1) {
                                        upfiles.splice(idx, 1);
                                        console.log('겹치는 값 뺀 임시 체크리스트', tmp);
                                        setUpfiles(tmp);
                                        setFile2('');
                                    }
                                }

                                if(history.location.state.board_id){
                                    if(upfiles2.length>0) {
                                        let tmp = upfiles2;
                                        const idx = tmp.indexOf(tmp.find(element => element.split("/").pop() == file2))
                                        console.log('뺄 인덱스', idx)
                                        if (idx != -1) {
                                            upfiles2.splice(idx, 1);
                                            console.log('겹치는 값 뺀 임시 체크리스트', tmp);
                                            setUpfiles2(tmp);
                                            setFile2('');
                                        }
                                    }
                                }
                            }}/>
                            : <div style={{margin:'0 20px'}}/>}
                        <Button
                            // variant="contained"
                            component="label" // 이거 써야 <input/> 가능?!
                            type="submit"
                            color="primary"
                            style={{
                                backgroundColor: 'rgb(43,66,124)',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 15,
                                borderRadius: 25,
                                padding: 10,
                            }}
                        >
                            <AiOutlinePaperClip size={20}/>
                            {"첨부"}
                            {/*파일업로드*/}
                            <input id={"file-input"} style={{display: 'none'}} type="file" name="file-input"
                                   accept={'.gif, .jpg, .jpeg, .png'}
                                // ref="file"
                                   onChange={(event) => {
                                       //취소가 아닐 때 (확인 눌렀을 때)
                                       if (event.target.value != '') {

                                           let fileVal = event.target.files[0];
                                           let fileName = event.target.value.split('\\').pop();

                                           if(file1==fileName||file3==fileName){
                                               customAlert('이미 선택한 이미지입니다.');
                                               return;
                                           }

                                           formData.append("before_upfile", fileVal);
                                           console.log('파일ㄹ?', fileVal);
                                           console.log('들어감?', formData);

                                           let i = 1;
                                           if (formData) {
                                               BeforeUpload(formData,fileVal,fileName,i);
                                           } else {
                                               customAlert('파일을 다시 선택해 주세요');
                                           }

                                       }

                                       console.log('파일입니다.', event.target.value);
                                   }}/>

                        </Button>

                    </Grid>
                    <Divider/>
                </div>

                {/*이미지3*/}
                <div style={{width: '100%'}}>
                    {/*,justifyContent:'center',alignItems:'center',*/}
                    <Grid alignItems='center' container style={{padding: 15}}>
                        {/*<div style={{width:'20%',textAlign: 'left',fontSize:14}}>파일첨부</div>*/}
                        <div style={{width: '20%', textAlign: 'left', fontSize: 14}}>사진첨부3</div>
                        <InputBase style={{textAlign: 'left', width: '50%', }}
                                   placeholder={'사진/이미지 등록'}
                                   value={file3}
                            // disabled={true} //회색으로 보임
                                   readOnly={true}
                                   onChange={(event) => {
                                       setFile3(event.target.value);
                                   }}
                            // onClick={()=>{
                            //     setModal(true);
                            // }}
                        />
                        {file3?
                            <CancelPresentationIcon size={20} style={{margin:'0 10px'}} onClick={()=>{
                                if(upfiles.length>0) {
                                    let tmp = upfiles;
                                    const idx = tmp.indexOf(tmp.find(element => element.name == file3))
                                    console.log('뺄 인덱스', idx)
                                    if (idx != -1) {
                                        upfiles.splice(idx, 1);
                                        console.log('겹치는 값 뺀 임시 체크리스트', tmp);
                                        setUpfiles(tmp);
                                        setFile3('');
                                    }
                                }

                                if(history.location.state.board_id){
                                    if(upfiles2.length>0) {
                                        let tmp = upfiles2;
                                        const idx = tmp.indexOf(tmp.find(element => element.split("/").pop() == file3))
                                        console.log('뺄 인덱스', idx)
                                        if (idx != -1) {
                                            upfiles2.splice(idx, 1);
                                            console.log('겹치는 값 뺀 임시 체크리스트', tmp);
                                            setUpfiles2(tmp);
                                            setFile3('');
                                        }
                                    }
                                }
                            }}/>
                            : <div style={{margin:'0 20px'}}/>}

                        <Button
                            // variant="contained"
                            component="label" // 이거 써야 <input/> 가능?!
                            type="submit"
                            color="primary"
                            style={{
                                backgroundColor: 'rgb(43,66,124)',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 15,
                                borderRadius: 25,
                                padding: 10,
                            }}
                        >
                            <AiOutlinePaperClip size={20}/>
                            {"첨부"}
                            {/*파일업로드*/}
                            <input id={"file-input"} style={{display: 'none'}} type="file" name="file-input"
                                   accept={'.gif, .jpg, .jpeg, .png'}
                                // ref="file"
                                   onChange={(event) => {
                                       //취소가 아닐 때 (확인 눌렀을 때)
                                       if (event.target.value != '') {

                                           let fileVal = event.target.files[0];
                                           let fileName = event.target.value.split('\\').pop();

                                           if(file1==fileName||file2==fileName){
                                               customAlert('이미 선택한 이미지입니다.');
                                               return;
                                           }

                                           formData.append("before_upfile", fileVal);
                                           console.log('파일ㄹ?', fileVal);
                                           console.log('들어감?', formData);

                                           let i = 2;
                                           if (formData) {
                                               BeforeUpload(formData,fileVal,fileName,i);
                                           } else {
                                               customAlert('파일을 다시 선택해 주세요');
                                           }

                                       }
                                       console.log('파일입니다.', event.target.value);
                                   }}/>

                        </Button>

                    </Grid>
                    <Divider/>
                </div>

                {/*문서*/}
                <div style={{width: '100%'}}>
                    {/*,justifyContent:'center',alignItems:'center',*/}
                    <Grid alignItems='center' container style={{padding: 15}}>
                        {/*<div style={{width:'20%',textAlign: 'left',fontSize:14}}>파일첨부</div>*/}
                        <div style={{width: '20%', textAlign: 'left', fontSize: 14}}>문서첨부1</div>
                        <InputBase style={{textAlign: 'left', width: '50%',}}
                                   placeholder={'첨부 문서만 등록'}
                                   value={file4}
                            // disabled={true} //회색으로 보임
                                   readOnly={true}
                                   onChange={(event) => {
                                       setFile4(event.target.value);
                                   }}
                            // onClick={()=>{
                            //     setModal(true);
                            // }}
                        />
                        {file4?
                            <CancelPresentationIcon size={20} style={{margin:'0 10px'}} onClick={()=>{
                                if(upfiles.length>0) {
                                    let tmp = upfiles;
                                    const idx = tmp.indexOf(tmp.find(element => element.name == file4))
                                    console.log('뺄 인덱스', idx)
                                    if (idx != -1) {
                                        upfiles.splice(idx, 1);
                                        console.log('겹치는 값 뺀 임시 체크리스트', tmp);
                                        setUpfiles(tmp);
                                        setFile4('');
                                    }
                                }

                                if(history.location.state.board_id){
                                    if(upfiles2.length>0) {
                                        let tmp = upfiles2;
                                        const idx = tmp.indexOf(tmp.find(element => element.split("/").pop() == file4))
                                        console.log('뺄 인덱스', idx)
                                        if (idx != -1) {
                                            upfiles2.splice(idx, 1);
                                            console.log('겹치는 값 뺀 임시 체크리스트', tmp);
                                            setUpfiles2(tmp);
                                            setFile4('');
                                        }
                                    }
                                }
                            }}/>
                            : <div style={{margin:'0 20px'}}/>}
                        <Button
                            // variant="contained"
                            component="label" // 이거 써야 <input/> 가능?!
                            type="submit"
                            color="primary"
                            style={{
                                backgroundColor: 'rgb(43,66,124)',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: 15,
                                borderRadius: 25,
                                padding: 10,
                            }}
                        >
                            <AiOutlinePaperClip size={20}/>
                            {"첨부"}
                            {/*파일업로드*/}
                            <input id={"file-input"} style={{display: 'none'}} type="file" name="file-input"
                                   accept={'.doc, .xls, .xlsx, .ppt, .pptx, .pdf, .PDF, .hwp, .zip'}
                                // ref="file"
                                   onChange={(event) => {
                                       //취소가 아닐 때 (확인 눌렀을 때)
                                       if (event.target.value != '') {

                                           let fileVal = event.target.files[0];
                                           let fileName = event.target.value.split('\\').pop();

                                           formData.append("before_upfile", fileVal);
                                           console.log('파일ㄹ?', fileVal);
                                           console.log('들어감?', formData);

                                           let i = 3;
                                           if (formData) {
                                               BeforeUpload(formData,fileVal,fileName,i);
                                           } else {
                                               customAlert('파일을 다시 선택해 주세요');
                                           }

                                       }
                                       console.log('파일입니다.', event.target.value);
                                   }}/>

                        </Button>

                    </Grid>
                    <Divider/>
                </div>

            </div>
            {/* 버튼 */}
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.bottonBtn}
                onClick={() => {
                    console.log('타입',notiType3)
                    console.log('날짜',eventDate)
                    //공백 확인
                    if (notiType3==1&&eventDate==null){
                        customAlert('날짜를 정해주세요');
                        return;
                    }else if (notiType3==1&&eventPlace==''&&eventPlace.replace(/^\s+|\s+$/g, "") ==''){
                        customAlert('장소를 작성해주세요!');
                        return;
                    } else if(title==''||title.replace(/^\s+|\s+$/g, "") ==''){
                        customAlert('제목을 작성해주세요!');
                        return;
                    }else if (content==''||content.replace(/^\s+|\s+$/g, "") =='') {
                        customAlert('내용을 작성해주세요!');
                        return;
                    }

                    let f_title = title.replace(/'/g, "\\'");
                    postData['title'] = f_title;

                    let f_content = content.replace(/'/g, "\\'");
                    postData['content'] = f_content;

                    postData['table'] = table;
                    if (admin == '1') postData['admin'] = 1;
                    else postData['admin'] = 0;

                    postData['type'] = notiType3; //공지유형 (board3만 해당)
                    postData['event_date'] = Moment(eventDate).format("YYYY년 MM월 DD일 HH:mm"); //행사날짜 (board3만 해당)
                    postData['event_place'] = eventPlace; //행사주소 (board3만 해당)
                    postData['upfiles2'] = upfiles2; //기존에 저장된 첨부파일 목록

                    console.log('파일업로드?',upfiles);
                    console.log('기존파일',upfiles2);
                    // return;

                    postData = JSON.stringify(postData);


                    // formData.append("upfile",fileVal);
                    for (let i = 0; i < upfiles.length; i++) {
                        // formData.delete("upfile");
                        formData.append("upfile" + [i], upfiles[i]);
                    }

                    console.log(formData, 'form');

                    if (type == 'write') {//게시글 작성일때
                        console.log('새글')

                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/board_write2.php',
                                method: 'POST',
                                data: formData,
                                params: {
                                    u_id: u_id,
                                    b_id: b_id,
                                    postData: postData
                                },
                                headers: {
                                    'Content-Type': 'multipart/form-data;'
                                }
                            })
                                .then((result) => {
                                    console.log(result.data);
                                    if (result.data == 200) {
                                        customAlert('작성완료');
                                        history.push({
                                            pathname: '/' + match.params.JoinCode + '/NoticeBoard',
                                            state: {table: table}
                                        });
                                    } else {
                                        customAlert('작성실패');
                                        alert(result.data);
                                        // navigation.navigate('Splash');
                                    }

                                })
                                .catch((err) => console.log(err.message));


                    } else { // 게시글 수정일때
                        console.log('수정')
                        Axios({
                            url: 'https://sbc.smartbc.kr/sb/board_update2.php',
                            method: 'POST',
                            data: formData,
                            params: {
                                board_id: history.location.state.board_id,
                                postData: postData,
                            },
                            headers: {
                                //'Authorization': "Bearer  "  +  YOUR_BEARER_TOKEN,
                                // 'Accept': 'application/json',
                                'Content-Type': 'multipart/form-data;'
                            }
                        })
                            .then(function (res) {
                                console.log(res.data);
                                if (res.data == 200) {
                                    customAlert('수정완료');
                                    history.push({
                                        pathname: '/' + match.params.JoinCode + '/NoticeBoard',
                                        state: {table: table}
                                    });
                                } else {
                                    customAlert('수정실패');
                                    // navigation.navigate('Splash');
                                }
                            })
                            .catch(function (e) {
                                console.log(e + 'write update error');
                            })
                    }
                }}
            >
                작성 완료
            </Button>

            {/*{loading?*/}
            {/*    <>*/}
            {/*        <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>*/}
            {/*        <div className={classes.popUpBg} />*/}
            {/*    </>*/}
            {/*    :null}*/}
        </Container>
            </>
    );
}
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 60,
        width: '100%',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    uploadName: {
        border: 0,
        width: '100%',
        height: '100%',
        background: '#fff'
    },
    fileSelectBtn: {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: 'rgb(43,66,124)',
        display: 'inline-block',
        width : '100%',
        padding: 12,
        borderRadius: 30,
        verticalAign: 'middle',
        color: '#fff',
        fontWeight: 'bold'
    },
    fileInput: {
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        border: 0,
        opacity: 0
    },
    bottonBtn: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 60,
        background: 'rgb(43,66,124)',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight: 'bold',
        '&:hover': {
            background: 'rgb(43,66,124)',
        },
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    '@global': {
        '._radioFont .MuiTypography-body1':{
            fontSize: 14,
        },
    },

}));
// export default NoticeBoardWrite;
