import React, {useEffect, useState} from 'react';

const Term2 = '개인정보처리방침\n\n' +
    ' 주식회사 밸류마크컨설팅(이하 \'회사\'라 함)는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법 등 관련 법령에 따라 정보통신서비스제공자가 준수하여야 할 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.\n' +
    '본 개인정보처리방침은 회사가 운영하는 스마트명함, 스마트기업명함 서비스(이하 \'스마트명함\'라 합니다) 등 관련 제반서비스에 적용되며 다음과 같은 내용을 담고 있습니다.\n' +
    '이 개인정보처리방침에서 사용하는 용어의 의미는 관련 법령 및 회사의 이용약관에서 정한 바에 따르며, 그 밖의 사항은 일반적인 상관례에 따릅니다.\n' +
    '1. 수집하는 개인정보의 항목 및 수집방법\n' +
    '2. 개인정보의 수집 및 이용 목적\n' +
    '3. 개인정보의 제3자 제공 및 처리위탁\n' +
    '4. 개인정보 보유 및 이용기간\n' +
    '5. 개인정보의 열람, 수정, 삭제 및 개인정보의 도용\n' +
    '6. 개인정보의 파기\n' +
    '7. 쿠키의 운영\n' +
    '8. 회원의 권리와 의무\n' +
    '9. 개인정보의 기술적/관리적 보호대책\n' +
    '10. 개인정보보호책임자 및 담당자의 연락처\n' +
    '11. 기타\n' +
    '12. 고지의 의무\n' +
    '\n' +
    '1. 수집하는 개인정보의 항목 및 수집방법\n' +
    '가. 수집하는 개인정보의 항목\n' +
    '회사는 회원이 입력한 정보를 기반으로 회원가입, 스마트명함 서비스의 제공, 원활한 고객상담 등을 위하여 다음과 같은 정보를 수집합니다.\n' +
    '1) 필수 항목\n' +
    '• 회원의 이름, 휴대폰번호\n' +
    '• 기업서비스 이용시 기업을 식별하기 위한 기업 이메일 정보 및 인증 코드 정보\n' +
    '• 회원의 명함 이미지, 회사, 부서, 직책, 주소 등 직장 정보, 이름, 휴대폰번호, 이메일 등 연락처등의 정보\n' +
    '• OS 버전, 단말기 정보 등 운영체제 및 하드웨어 환경정보\n' +
    '2) 선택 항목\n' +
    '• 프로필 사진, 이력 정보(과거 회사명), 학교 정보(출신 학교명), 홈페이지 및 블로그 주소 및 회원이 입력한 본인과 기업 의 기타 정보\n' +
    '• 외부 서비스 계정과 연동할 경우, 외부 서비스에서 제공받는 계정 정보, 서비스 이용 정보 및 기타 회원이 제공에 동의한 개인 정보\n' +
    '• 이벤트 진행에 따른 경품 지급 시, 이름, 휴대폰번호, 주소등\n' +
    '3) 서비스 이용시 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.\n' +
    '• IP Address, 쿠키, 기기 정보, 방문 일시, 서비스 이용 기록, 불량 이용 기록 등\n' +
    '4) 유료 서비스 이용 과정에서 결제 등을 위하여 불가피하게 필요한 때에는 다음의 정보가 수집될 수 있습니다.\n' +
    '• 신용카드 정보, 휴대폰번호, 통신사 정보 등과 같이 결제에 필요한 정보\n' +
    '나. 개인정보의 수집방법\n' +
    '회사는 다음과 같은 방법으로 개인정보를 수집합니다.\n' +
    '1) 서비스 가입이나 사용 중 이용자의 자발적 제공을 통한 수집\n' +
    '2) 회원이 획득 또는 보유한 타인의 명함 정보를 스마트명함에 등록함으로써 위탁하는 경우 그에 따라 수집 (기업용 서비스에 한함)\n' +
    '4) 그 외 제휴된 외부 기업이나 단체로부터 제공\n' +
    '5) 기기 정보, 서비스 이용 기록 등 생성 정보는 서비스 이용과정에서 자동으로 수집\n' +
    '\n' +
    '2. 개인정보의 수집 및 이용 목적\n' +
    '회사는 다음과 같은 목적으로 이용자 등의 개인정보를 수집합니다.\n' +
    '가. 회원관리\n' +
    '• 회원제 서비스 이용에 따른 본인확인, 회원 식별을 위한 목적, 가입의사 확인, 가입 및 가입횟수 제한, 원활한 의사소통 경로의 확보, 고객 문의에 대한 응답, 새로운 정보의 소개 및 고지사항 전달, 불량회원의 부정이용방지와 비인가 사용방지, 본인확인 및 분쟁 조정을 위한 기록보존, 불만 등 민원처리 등\n' +
    '나. 스마트명함 서비스 기능의 제공\n' +
    '• 회원간 공개된 명함 검색 및 명함 교환 요청, 수락등의 관계로 맺어진 회원들 간에 명함 정보를 포함한 프로필 정보의 자동 업데이트, 인맥 간 소통 및 교류를 위한 메시지의 송ᆞ수신 또는 게시물의 작성ᆞ게재ᆞ조회 등 서비스 내에서 회원의 계정을 통하여 이용할 수 있는 서비스 등을 제공\n' +
    '다. 서비스 제공에 관한 계약 이행 및 필요 시 유료 서비스 제공에 따른 요금 정산\n' +
    '• 약관 개정 등 고지사항 전달, 유료 서비스 제공에 구매 및 요금 결제, 상품 및 서비스 배송 등\n' +
    '라. 서비스 개선 및 신규 서비스 개발\n' +
    '• 인구 통계학적 분석, 서비스 방문 및 이용 기록의 분석, 인맥 및 관심사 등에 기반한 맞춤형 서비스 제공, 개인정보 및 관심에 기반한 회원간 관계의 형성 등 신규 서비스 요소를 발굴하고 기존 서비스의 개선에 이용\n' +
    '마. 마케팅 및 광고성 정보의 제공\n' +
    '• 새로운 기능 출시 안내, 이용 방법 및 이용 혜택 안내, 각종 이벤트 참여 기회 및 광고성 정보 게재ᆞ제공, 광고 반응 내역에 대한 통계 자료의 외부 제공 등 법령 등의 이행 및 준수 법령 등에 규정된 의무의 이행, 법령이나 이용약관에 반하여 이용자에게 피해를 줄 수 있는 잘못된 이용 행위의 방지 등\n' +
    '바. 이벤트 경품 지급 및 제세공과금 신고\n' +
    '• 이벤트 참여 의사 확인, 이벤트 참여자에 대한 경품 배송, 제세공과금 처리 등\n' +
    '\n' +
    '3. 개인정보의 제3자 제공 및 처리 위탁\n' +
    '회사는 회원의 개인정보를 \'개인정보의 수집 및 이용 목적\'에서 명시한 범위에서 사용하며, 이에 따라 서비스 이용과정에서 다음의 정보가 공개될 수 있습니다. 또한, 서비스의 목적상 필수적으로 제공되어야 하거나 회원의 사전 동의가 있는 경우 또는 아래 예외적인 경우를 제외하고는, 그 범위를 초과하여 이용하거나 제3자에 제공하지 않습니다.\n' +
    '가. 개인정보의 공개\n' +
    '• 스마트명함 서비스 이용 도중 회원의 명함 정보 및 회원이 선택적으로 입력한 기타 정보가 연결된 회원 간에 공개될 수 있습니다. 또한 회원이 상대방을 초대할 경우, 초대자 식별을 위해 이름, 프로필 사진, 일부 명함 정보가 피초대자에게 공개될 수 있습니다.\n' +
    '나. 개인정보의 제3자 제공\n' +
    '1) 회원이 사전에 동의하여 제공한 경우\n' +
    '• 외부 제휴된 기업 또는 단체의 서비스를 이용하기 위하여 개인정보 제공에 동의한 경우\n' +
    '• 포인트 사용을 위한 포인트몰 연동을 위한 정보 제공\n' +
    '제공 받는자 : ㈜스마트웰\n' +
    '제공하는 항목 : 회원의 이름, 휴대폰 번호\n' +
    '이용 목적 : 포인트몰 회원 가입시 회원 식별 및 포인트 조회 및 차감 목적\n' +
    '보유 기간 : 포인트몰, 스마트명함 회원 탈퇴시 자동 삭제\n' +
    '2) 법령에 특별한 규정이 있는 경우\n' +
    '3) 수사기관이 수사 목적으로 정해진 절차와 방법에 따라 요구한 경우\n' +
    '다. 개인정보처리의 위탁\n' +
    '회사는 서비스의 향상을 위하여 아래와 같이 개인정보를 위탁하고 있으며, 관련 법령에 따라 위탁계약시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 회사는 수탁자가 변경되는 경우 지체없이 본 개인정보 처리방침을 통하여 공개합니다.\n' +
    '1) 국외법인에 대한 처리위탁 - 없음\n' +
    '2) 기타 외부 수탁업체 – 없음\n' +
    '\n' +
    '4. 개인정보 보유 및 이용기간\n' +
    '회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용 목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보관합니다. \n' +
    '가. 회사 내부 방침에 의한 정보보유\n' +
    '• 부정이용기록 보유 : 1년 (부정 이용 방지)\n' +
    '나. 관련 법령에 의한 정보 보유\n' +
    '• 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련법령의 규정에 의하여 일정기간 정보를 보유하여야 할 필요가 있을 경우 회사는 보관하는 정보를 일정기간 보유하며, 보유기간은 아래와 같습니다. \n' +
    '• 계약 또는 청약철회 등에 관한 기록 보유 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)\n' +
    '• 대금결제 및 재화 등의 공급에 관한 기록 보유 : 5년 (전자상거래 등에서의 소비자보호에 관한 법률)\n' +
    '• 소비자 불만 또는 분쟁처리에 관한 기록 보유 : 3년 (전자상거래 등에서의 소비자보호에 관한 법률)\n' +
    '• 본인확인에 관한 기록 보유 : 6개월 (정보통신망 이용촉진 및 정보보호 등에 관한 법률)\n' +
    '• 방문에 관한 기록 보유 : 3개월 (통신비밀보호법)\n' +
    '\n' +
    '5. 개인정보의 열람, 수정, 삭제 및 개인정보의 도용\n' +
    '회원은 언제든지 자신의 개인정보를 열람, 공개 및 비공개 처리하거나 수정할 수 있으며, 삭제 및 파기를 요청할 수 있습니다.\n' +
    '가. 개인정보의 열람 및 수정 요청\n' +
    '• 회원 및 법정대리인은 언제든지 등록되어 있는 본인 또는 만 14세미만 아동의 개인정보를 조회, 수정할 수 있습니다. 회원 또는 법정대리인은 당해 개인정보의 조회, 수정을 위하여 스마트명함 서비스에 등록된 명함 정보를 클릭하거나, 설정 메뉴를 클릭하여 계정 등 등록된 개인정보를 직접 열람하거나 수정할 수 있습니다.\n' +
    '나. 개인정보의 삭제(회원탈퇴)\n' +
    '• 회원 및 만 14세 미만 아동의 법정대리인이 회원탈퇴를 원하는 경우, 모바일 애플리케이션 또는 웹사이트로 로그인한 후 \'설정-스마트명함 탈퇴하기\'를 통해 직접 탈퇴할 수 있습니다. 만일, 직접 탈퇴가 어려운 경우 개인정보관리담당자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치를 취하겠습니다.\n' +
    '다. 개인정보의 도용\n' +
    '• 회사는 타인의 휴대폰 번호, 이메일 주소, 페이스북 등 제휴업체 아이디 또는 기타 개인정보를 도용하여 회원 가입한 자 또는 스마트명함을 이용한 자를 발견한 경우 지체 없이 해당 아이디에 대한 서비스 이용 정지 또는 회원 탈퇴 등 필요한 조치를 취합니다.\n' +
    '\n' +
    '6. 개인정보의 파기\n' +
    '회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용 목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.\n' +
    '가. 파기절차\n' +
    '• 회원이 서비스 가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB 또는 보관 장소로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(개인정보 보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다. 위 별도 보관된 개인정보는 정해진 목적 이외의 다른 목적으로 이용되지 않습니다.\n' +
    '다. 파기방법\n' +
    '• 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.\n' +
    '\n' +
    '7. 쿠키의 운영\n' +
    '쿠키란 스마트명함 사이트 접속시 이용자의 저장장치에 전송하는 특별한 텍스트 파일(text file)을 말합니다. 쿠키는 웹사이트의 서버(server)에서만 읽어 들일 수 있는 형태로 전송되며 개인이 사용하는 브라우저(browser)의 디렉터리(directory) 하위에 저장됩니다. 모바일 애플리케이션과 같이 쿠키 기술을 사용할 수 없는 경우에는 쿠키와 유사한 기능을 수행하는 기술(광고식별자 등)을 사용할 수도 있습니다.\n' +
    '가. 쿠키의 사용 목적\n' +
    '• 스마트명함이 쿠키를 통해 수집하는 정보는 \'수집하는 개인정보 항목\'과 같으며 \'개인정보의 수집 및 이용목적\' 외의 용도로는 이용되지 않습니다.\n' +
    '나. 쿠키 설정 거부\n' +
    '• 이용자는 쿠키에 대한 선택권을 가지고 있습니다. 웹 브라우저 옵션(option)을 선택함으로써 모든 쿠키의 허용, 동의를 통한 쿠키의 허용, 모든 쿠키의 차단을 스스로 결정할 수 있습니다. 단, 쿠키 저장을 거부할 경우에는 로그인이 필요한 일부 서비스를 이용하지 못할 수도 있습니다.\n' +
    '\n' +
    '8. 회원의 권리와 의무\n' +
    '회원은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 회원의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오.\n' +
    '회원이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있습니다. 회원은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방하여야 할 의무가 있습니다.\n' +
    '회원이 위 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등 관련 법률에 의해 처벌받을 수 있습니다. \n' +
    '\n' +
    '9. 개인정보의 기술적/관리적 보호대책 \n' +
    '회사는 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.\n' +
    '가. 해킹 등에 대비한 대책\n' +
    '• 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 회원의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.\n' +
    '나. 처리 직원의 최소화 및 교육\n' +
    '• 회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사의 개인정보처리방침의 준수를 항상 강조하고 있습니다.\n' +
    '다. 개인정보보호전담기구의 운영\n' +
    '• 회사는 사내 개인정보보호전담기구 등을 통하여 회사의 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의 부주의나 회사의 고의 또는 중대한 과실이 아닌 사유로 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.\n' +
    '\n' +
    '10. 개인정보보호책임자 및 담당자의 연락처\n' +
    '귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 회원의 신고사항에 대하여 신속하게 답변을 드릴 것입니다.\n' +
    '개인정보보호책임자\n' +
    '• 이 름 : 이기재\n' +
    '• 소 속 : (주)밸류마크컨설팅\n' +
    '• 직 위 : 부대표\n' +
    '• 전 화 : 1666-4214\n' +
    '• 이메일 : coo@smartbc.kr\n' +
    '기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.\n' +
    '• 개인정보침해신고센터 (www.118.or.kr / 118)\n' +
    '• 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)\n' +
    '• 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000) \n' +
    '• 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330) \n' +
    '\n' +
    '11. 기타\n' +
    '스마트명함 서비스 내에 링크되어 있는 웹사이트 등 타 서비스들이 개인정보를 수집하는 행위에 대해서는 본 스마트명함 개인정보처리방침이 적용되지 않음을 알려드립니다.\n' +
    '\n' +
    '12. 고지의 의무\n' +
    '현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 시행일 최소 7일전부터 스마트명함 웹사이트(http://www.smartbc.kr) 또는 서비스 내 \'공지사항\'을 통해 공지할 것입니다. 다만, 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하도록 하며 필요 시 이용자의 동의를 다시 받을 수도 있습니다.\n' +
    '공고일자 : 2019. 12. 01.\n' +
    '시행일자 : 2019. 12. 01.\n';

export default Term2;
