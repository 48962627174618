import React from "react";

function customAlert(message) {
    //window.location !== window.parent.location
    if (window.self !== window.top) {
        console.log('프레임 있다, customAlert');
        window.parent.postMessage({
            action: "systemAlert", message: message
        }, "*");
    } else {
        console.log('프레임 없다, 그냥 alert ')
        alert(message);
    }
}

export default customAlert;