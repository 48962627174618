import {combineReducers} from 'redux';
import hamburger from './hamburger';
import location from './location';
import checkList from './checkList';
import session from './session';
import adminSession from './adminSession';

const rootReducer = combineReducers({
    hamburger,//지울예정
    location,//지울예정
    checkList,
    session,
    adminSession,
});

export default rootReducer;
