import React,{useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    List,ListItem,ListItemText,
    Grid,Card,
    CardContent,
    IconButton
}from '@material-ui/core';
import PcHeader from "./PcHeader";
import CloseIcon from '@material-ui/icons/Close';
import Axios from "axios";
import Moment from 'moment';

function AddComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}


export default function PartnerRefJoinList({history,match}) {
    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id');

    const [list,setList] = React.useState([]);
    const [point,setPoint] = React.useState('');
    let listBar = null;

    const [modal, setModal] = React.useState(false);

    useEffect(()=>{
        Axios({
            url: 'https://sbc.smartbc.kr/sb/partner_ref_join_list.php',
            method: 'get',
            params: {
                u_id: u_id,
            },
        })
            .then((result) => {
                console.log('추천가입리스트',result.data);

                setList(result.data.list2);

            })
            .catch((err) => console.log(err.message));

    },[]);

    const PointList = ({index}) => {

            listBar =
                <ListItem>
                    <ListItemText className={classes.contentText1}>{Moment(list[index].create_at).format('YYYY-MM-DD')}</ListItemText>
                    <ListItemText className={classes.contentText2}>{list[index].type=='s'?'[개인앱]':list[index].type=='sc'?'[기업앱]':'[단체앱]'}</ListItemText>
                    <ListItemText className={classes.contentText3}>{list[index].name}</ListItemText>
                </ListItem>;

        return(listBar)
    };

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}} className='point'>
                <CssBaseline/>
                <PcHeader text={'추천 가입 ( '+list.length+' )'} back={1} history={history} match={match}/>

                {/* 리스트 */}
                <div className={classes.paper}>
                    <Grid container style={{color: 'black', fontWeight:'bold',fontSize: 13, padding: 10,borderBottom:'2px solid black'}}>
                        <Grid xs={4}>일자</Grid>
                        <Grid xs={3}>앱종류</Grid>
                        <Grid xs={5}>가입자명</Grid>
                    </Grid>
                    <List style={{width: '100%'}}>
                        {list.map(((value,i) => <PointList key={i} index={i} />))}

                    </List>
                </div>



            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px',
        // marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 50,
    },
    contentText1: {
        textAlign: 'left',
        fontSize: 13,
        color: 'rgb(93,93,93)',
        width: '28%',
        display: 'inline-block'
    },
    contentText2: {
        textAlign: 'left',
        fontSize: 13,
        color: 'rgb(93,93,93)',
        width: '22%',
        display: 'inline-block'
    },
    contentText3: {
        textAlign: 'left',
        fontSize: 13,
        color: 'rgb(93,93,93)',
        width: '50%',
        display: 'inline-block'
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    '@global':{
        '.point .MuiListItemText-root':{
            flex: '1',
        },
        '.point .MuiListItem-root:nth-child(even)':{
            backgroundColor: 'rgba(0,0,0,0.1)', width: '100%', borderRadius: 10
        },
        '.point .MuiListItem-root:nth-child(odd)':{
            backgroundColor: '#fff', width: '100%', borderRadius: 10
        },
        '.point .MuiListItemText-primary': {fontSize: 13}
    }

}));


