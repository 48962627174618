import React, {useEffect} from "react";
import {
    Divider,
    Grid,
    Container, CssBaseline, makeStyles,
    Box, TextField, Typography, Button, IconButton, Avatar, InputBase, FormControlLabel, CircularProgress
} from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";
import {Link} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import DateTimePicker from "react-datetime-picker";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import customAlert from "../component/customAlert";
import customConfirm from "../component/customConfirm";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


function NoticeBoardDetail({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    const admin = sessionStorage.getItem('@admin');

    const [boardDetail,setBoardDetail] = React.useState(new Array()); //게시글 상세
    const [r_content,setR_content] = React.useState('');// 게시글 중 url이 있을 시 자동링크된 게시글.
    const [replyList,setReplyList] = React.useState(new Array()); //댓글 리스트
    const [refresh , setRefresh] = React.useState(false);
    const [WriteReply , setWriteReply] = React.useState(false);

    const table = history.location.state.table; //소식 , 협력 , 자유게시판에 따른 게시글 테이블
    const board_id = history.location.state.board_id // 게시글 아이디

    const [title, setTitle] = React.useState();
    const [content, setContent] = React.useState();
    const [profile, setProfile] = React.useState();
    const [name, setName] = React.useState();
    const [company, setCompany] = React.useState();
    const [date, setDate] = React.useState();
    const [writer, setWriter] = React.useState();
    const [like_num, setLike_num] = React.useState();
    const [like_search, setLike_search] = React.useState();
    const [writeAdmin, setWriteAdmin] = React.useState();
    const [upfile, setUpfile] = React.useState();
    const [joinOpVal, setJoinOpVal] = React.useState();
    const [selectID, setSelectID] = React.useState();
    const [withDraw, setWithDraw] = React.useState(0); //탈퇴여부    0:회원   1:탈퇴

    const [images,setImages] = React.useState(new Array());
    const [doc,setDoc] = React.useState();

    const [notiType3,setNotiType3] = React.useState(0); //보드3 공지유형 = 0:일반공지  1:행사공지
    const [eventDate,setEventDate] = React.useState(); //행사공지 행사날짜
    const [eventPlace,setEventPlace] = React.useState(''); //행사공지 행사장소

    const [eventInList, setEventInList] = React.useState([]);
    const [eventOutList, setEventOutList] = React.useState([]);
    const [cardRegist, setCardRegist] = React.useState('N');
    const [modal, setModal] = React.useState(false); //파일 다운로드
    const [modal2,setModal2] = React.useState(false); //명함 없을 시 등록 팝업


    const [loading , setLoading ] = React.useState(true);

    function aa() {
        return {__html : r_content};
    }

    const ReplyList = ({id,name , company_name,profile,uid,content,regist_date}) => {
        const [UpdateReply , setUpdateReply] = React.useState(false);

        let isMobile = false;
        const filter = "win16|win32|win64|mac|macintel";
        isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

        return(
            <>
                {/*글쓴이*/}
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: '10px 30px', position: 'relative'}}>
                        <Grid container alignItems='center'direction='row'>
                            <Grid item xs={2} style={{marginRight: 10}} onClick={() => {
                                // history.push('/' );
                            }}>
                                <img alt="사진" src={profile ? 'https://sbc.smartbc.kr/'+profile : require('../resources/profile_exampleImg.png')} style={{width: 50, height: 50,borderRadius : 50}}/>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                            {name ? name : '모임관리자'}
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                            {company_name ? company_name : '스마트 모임' }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*댓글 3점버튼*/}
                        {(uid == u_id || admin == '1') ?
                            (
                                <ThreeDotsButton type={'reply'} update={setUpdateReply} reply_id={id}/>
                            ) : null}
                    </Grid>
                </div>
                {/*내용*/}
                {UpdateReply ?
                    (
                        <div style={{width: '100%',}}>
                            <div alignItems='center'  container style={{padding: '10px 30px'}}>
                                <Box component={'textarea'}
                                     id={'UpdateReply'}
                                     style={{border: 'none', outline: 'none', width: '100%', padding: 10, resize: 'none', lineHeight: 1.5, minHeight: 60}} placeholder='명예훼손, 욕성 등의 악플은 경고없이 삭제됩니다.'/>
                            </div>
                            <div style={{padding: '0 30px'}}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    style={{backgroundColor: 'rgb(48,58,83)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                                    onClick={() => {
                                        Axios({
                                            url: "https://sbc.smartbc.kr/sb/reply_update.php",
                                            method: 'get',
                                            params: {
                                                reply_id : id,
                                                content : document.getElementById('UpdateReply').value,
                                            }
                                        })
                                            .then(function (res) {
                                                console.log('reply write success');
                                                console.log(res.data);
                                                if (res.data == 200) {
                                                    setRefresh(!refresh);
                                                } else {
                                                    console.log('reply error');
                                                }
                                            })
                                            .catch(function (e) {
                                                console.log('reply write fail');
                                                console.log(e);
                                            })
                                    }}
                                >
                                    수정하기
                                </Button>
                            </div>
                        </div>
                    ) :
                    (
                        <>
                            <div style={{width: '100%', padding: '10px 30px'}}>
                                <Typography align='right' style={{border: 'none', outline: 'none', width: '100%', padding: 10,marginTop:-20, fontSize: 11, color: 'rgb(181,181,181)', lineHeight: 1.6,}}>
                                    {regist_date}
                                </Typography>
                                <Typography align='left' style={{border: '0.5px solid rgba(0, 0, 0, 0.12)',borderRadius:10, outline: 'none', width: '100%', padding: 10, fontSize: 13, lineHeight: 1.6,marginBottom : 20,}}>
                                    {content}
                                </Typography>
                            </div>
                            <Divider/>
                        </>
                    )}

            </>
        );
    }

    const ThreeDotsButton = ({type,update,reply_id}) => {

        /**
         * 3점버튼 클릭 시 이벤트
         * type = 'board' , 'reply' 게시물 상세 ,댓글
         */

        const [open,setOpen] = React.useState(false);
        const [select,setSelect] = React.useState('');
        const anchorRef = React.useRef(null);

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }
            if(type == 'board'){
                //게시물 상세 수정
                if(event.target.value == 1){
                    history.push({
                        pathname : 'NoticeBoardWrite',
                        state : {
                            table : table,
                            type : 'update',
                            board_id : board_id
                        }
                    })
                }else if(event.target.value == 2) {
                    setConfirmID(1);
                    setConfirmMessage('정말 게시물을 삭제하시겠습니까?');
                    setConfirmShow(true);
                }
            }else if(type == 'reply'){
                //댓글 수정
                if(event.target.value == 1){
                    update(true);
                    console.log('update = ' + update );
                }else if(event.target.value == 2){
                    setReply_id(reply_id);
                    setConfirmID(2);
                    setConfirmMessage('정말 댓글을 삭제하시겠습니까?');
                    setConfirmShow(true);
                    // let aa = customConfirm('정말 댓글을 삭제하시겠습니까?');
                }
            }

                setOpen(false);
            }

        function handleListKeyDown(event) {
            if (event.key === 'Tab') {
                event.preventDefault();
                setOpen(false);
            }
        }

        return (
            <>
            <IconButton style={{position: 'absolute', right: 10, padding: '23px 8px', transform: 'rotate(90deg)'}} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                <span style={{width: 6, height: 6, borderRadius: 10, backgroundColor: '#888', margin: 2}}></span>
                <span style={{width: 6, height: 6, borderRadius: 10, backgroundColor: '#888', margin: 2}}></span>
                <span style={{width: 6, height: 6, borderRadius: 10, backgroundColor: '#888', margin: 2}}></span>
            </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleClose} value={'1'}>수정하기</MenuItem>
                                        <MenuItem onClick={handleClose} value={'2'}>삭제하기</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                </>
        );
    }



    // function down(url) {
    //     const link = document.createElement('a');
    //     link.href = 'https://sbc.smartbc.kr/'+url;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     return;
    // }

    useEffect(() => {
        Axios({
            url: "https://sbc.smartbc.kr/sb/board_detail2.php",
            method: 'get',
            params: {
                table : table,
                board_id : board_id,
                u_id : u_id,
                b_id: b_id,
            }
        })
            .then(function (res) {
                console.log('뭐냐',table , board_id ,u_id ,b_id )
                console.log("board_detail success");
                console.log(res.data);
                setCardRegist(res.data.card_regist);

                if(res.data.list['upfile2']){
                    //다중 파일
                    let arr = JSON.parse(res.data.list['upfile2']);
                    let tmp = [];
                    let imgExtArr = ['jpg', 'jpeg', 'gif', 'png','JPG', 'JPEG', 'GIF', 'PNG'];
                    for(let i=0; i<arr.length; i++){
                        let filename=  arr[i].split("/").pop();
                        let ext = filename.split(".").pop();
                        if(imgExtArr.includes(ext)){
                            tmp.push(arr[i]);
                            console.log('이미지배열',tmp)
                        }else{
                            setDoc(arr[i]);
                        }
                    }
                    setImages(tmp);
                    //다중 파일 끝
                }


                if (res.data.list['profile']) setProfile(res.data.list['profile']);
                // else setProfile(require('../resources/profile_exampleImg.png'));
                setWithDraw(res.data.with_draw);
                setJoinOpVal(res.data.list['join_option2_val']);
                setSelectID(res.data.list['writer']);
                setTitle(res.data.list['title']);
                // setContent(res.data.list['content']);
                setR_content(res.data.r_content);
                setName(res.data.list['u_name']);
                setCompany(res.data.list['company']);
                setDate(res.data.list['date']);
                setWriter(res.data.list['writer']);
                setLike_num(res.data.list['like_num'] ? res.data.list['like_num'] : 0);
                setLike_search(res.data.list['like_search']);
                setWriteAdmin(res.data.list['admin']);
                setUpfile(res.data.list['upfile']);
                setNotiType3(Number(res.data.list['type']));
                setEventDate(res.data.list['event_date']);
                setEventPlace(res.data.list['event_place']);

            })
            .catch(function (e) {
                console.log("board_detail fail",e);
            })

        let board_type = "";
        if (table == 'band_board1') board_type = 1;
        else if (table == 'band_board2') board_type = 2;
        else if (table == 'band_board3') board_type = 3;

        console.log('boardid',board_type ,board_id);
        Axios({
            url: "https://sbc.smartbc.kr/sb/band_reply_list.php",
            method: 'get',
            params: {
                board_type : board_type,
                board_id : board_id,
            }
        })
            .then(function (res) {
                console.log("reply list success");
                console.log(res.data);
                setReplyList(res.data);
            })
            .catch(function (e) {
                console.log("reply list fail");
            })

        Axios({
            url: "https://sbc.smartbc.kr/sb/event_in_out_list.php",
            method: 'get',
            params: {
                board_id : board_id,
            }
        })
            .then(function (result) {
                console.log("inout list success");
                console.log(result.data);
                setEventInList(result.data.in_list);
                setEventOutList(result.data.out_list);
            })
            .catch(function (e) {
                console.log("inout list fail",e);
            })

        setLoading(false);

    }, [refresh]);

    const InList = ({name,profile,company})=>{
        console.log('참석 프로필',profile)
        return(
            <Grid container alignItems='center'direction='row' style={{ marginTop:20, padding:'2% 5%', borderBottom:'1px solid #aeb5ba', }}>
                <Grid item xs={2} style={{marginRight: 10}} onClick={() => {
                    //프로필 상세보기
                    // if(writeAdmin != 1){
                    //     let join_option = null;
                    //     if(boardDetail.join_option2_val){
                    //         join_option =  Object.values(JSON.parse(boardDetail.join_option2_val));
                    //     }
                    //     history.push({
                    //         pathname : '/'+match.params.JoinCode+'/MembersCardDetail',
                    //         state : {
                    //             sel_u_id : boardDetail.writer, //targetId
                    //             join_option:join_option ,
                    //         }
                    //     });
                    // }
                }}>
                    <img src={profile? 'https://sbc.smartbc.kr/'+profile : require('../resources/profile_exampleImg.png')}
                         style={{width: 50, height: 50,borderRadius : 50}}/>
                </Grid>
                <Grid item xs={7}>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item>
                            <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                {name}
                            </Typography>
                            <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                {company}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} style={{height:30,backgroundColor:'#ff0000',alignItems:'center',justifyContent:'center',borderRadius:10,marginRight:'5%',padding:'5px 0'}}>
                    <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'white'}}>{'참석 '}</Typography>
                </Grid>
            </Grid>

            // <div style={{
            //     width:'100%',
            //     marginTop:20,
            //     display: 'flex',
            //     alignItems: 'center',
            //     alignSelf: 'center',
            //     paddingHorizontal:'5%',
            //     borderBottomWidth:0.5,
            //     borderColor:'#aeb5ba',
            // }}>
            //     <img src={profile? 'https://sbc.smartbc.kr/'+profile : require('../resources/profile_exampleImg.png')}
            //            style={{width: 50, height: 50, borderRadius: 100, marginBottom: 15}}/>
            //     <Typography style={{marginLeft: 10, letterSpacing: 0.5,width:'60%'}}>
            //         <Typography style={{fontSize: 15, fontWeight: 'bold'}}>{name}&nbsp;</Typography>{'\n'}
            //         {/*<Text style={{fontSize: 11, color: '#b5b5b5'}}>{'사원'}&nbsp;</Text>{'\n'}*/}
            //         <Typography style={{fontSize: 11, color: '#b5b5b5'}}>{company}</Typography>{'\n'}
            //     </Typography>
            //     <div style={{width:'20%',height:30,backgroundColor:'#ff0000',alignItems:'center',justifyContent:'center',borderRadius:10,marginRight:'5%'}}>
            //         <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'white'}}>{'참석 '}</Typography>
            //     </div>
            // </div>
        )
    }

    const OutList = ({name,profile,company})=>{
        return(
            <Grid container alignItems='center'direction='row' style={{ marginTop:20, padding:'2% 5%', borderBottom:'1px solid #aeb5ba', }}>
                <Grid item xs={2} style={{marginRight: 10}} onClick={() => {
                    //프로필 상세보기
                    // if(writeAdmin != 1){
                    //     let join_option = null;
                    //     if(boardDetail.join_option2_val){
                    //         join_option =  Object.values(JSON.parse(boardDetail.join_option2_val));
                    //     }
                    //     history.push({
                    //         pathname : '/'+match.params.JoinCode+'/MembersCardDetail',
                    //         state : {
                    //             sel_u_id : boardDetail.writer, //targetId
                    //             join_option:join_option ,
                    //         }
                    //     });
                    // }
                }}>
                    <img src={profile? 'https://sbc.smartbc.kr/'+profile : require('../resources/profile_exampleImg.png')}
                         style={{width: 50, height: 50,borderRadius : 50}}/>
                </Grid>
                <Grid item xs={7}>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item>
                            <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                {name}
                            </Typography>
                            <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                {company}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} style={{height:30,backgroundColor:'#ffcc00',alignItems:'center',justifyContent:'center',borderRadius:10,marginRight:'5%',padding:'5px 0'}}>
                    <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'white'}}>{'불참 '}</Typography>
                </Grid>
            </Grid>
        )
    }

    const ImgExample = ({uri})=>{
        // console.log('왜안보여',uri)

        return(
                <div style={{width:'32%',height:100,marginRight:10,     border: '1px solid #aeb5ba', borderRadius: 10, padding:'1%'}}
                onClick={()=>{
                    // setModal(true);
                    // PopUp(uri);

                    // window.location.href = 'https://sbc.smartbc.kr/sb/download_file.php?file='+uri;
                    // history.push('/'+match.params.JoinCode+'/ImgDetail');
                    history.push({
                        pathname: '/' + match.params.JoinCode + '/ImgDetail',
                        state: {
                            uri: uri,
                        }
                    });
                }}>
                    <img style={{width: '100%', height: '100%',}}
                         src={'https://sbc.smartbc.kr'+uri} />
                </div>

        )
    }
    const PopUp = ({file}) => {
        // console.log('다운file명',file);
        return(
            <>
                <Card className={classes.popUp}>
                    <Typography align='right'>
                        <IconButton>
                            <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                                setModal(false);
                            }}/>
                        </IconButton>
                    </Typography>
                    <CardContent style={{paddingTop: 5,marginBottom:20}}>
                        <Typography style={{marginTop: 20}}>파일을 다운로드 하시겠습니까?</Typography>
                    </CardContent>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 15}}
                        onClick={() => {
                            console.log('주소',typeof file);
                            window.location.href = 'https://sbc.smartbc.kr/sb/download_file.php?file='+file;
                        }}>
                        다운로드
                    </Button>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, border: '1px solid #303a53', color: '#303a53',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                        onClick={() => {
                            setModal(false);
                        }}>
                        취소
                    </Button>
                </Card>
                <div className={classes.popUpBg} />
            </>
        )
    }

    const PopUp2 = ()=>{
        return(
            <>
                <Card className={classes.popUp}>
                    <Typography align='right'>
                        <IconButton>
                            <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                                setModal2(false);
                            }}/>
                        </IconButton>
                    </Typography>
                    <CardContent style={{paddingTop: 5}}>
                        <Typography>
                            <img src={require('../resources/card_icon.png')} style={{height: 85}}/>
                        </Typography>
                        <Typography style={{marginTop: 20}}>MY 모바일 명함 등록을 먼저 해주세요!</Typography>
                    </CardContent>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                        onClick={() => {
                            history.push('/'+match.params.JoinCode+'/MyCardRegist');
                        }}>
                        MY명함 등록하기
                    </Button>
                </Card>
                <div className={classes.popUpBg} />
            </>

        )
    }

    const [reply_id , setReply_id ] = React.useState(0);
    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmID , setConfirmID ] = React.useState(0);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        if(confirmID === 1) {
            Axios({
                url: "https://sbc.smartbc.kr/sb/delete_board.php",
                method: 'get',
                params: {
                    board_id: board_id,
                    table: table
                }
            })
                .then(function (res) {
                    console.log('delete board success');
                    if (res.data == 200) {
                        customAlert('삭제 되었습니다.');
                        if (table == 'band_board1') {
                            history.push({
                                pathname: 'NoticeBoard',
                                state: {table: table}
                            });
                        } else if (table == 'band_board2') {
                            history.push({
                                pathname: 'NoticeBoard',
                                state: {table: table}
                            });
                        } else if (table == 'band_board3') {
                            history.push({
                                pathname: 'NoticeBoard',
                                state: {table: table}
                            });
                        }
                    } else {
                        customAlert('삭제 실패');
                    }
                })
                .catch(function (e) {
                    console.log('delete board error');
                    console.log(e);
                })
        }else if(confirmID == 2){
            Axios({
                url: "https://sbc.smartbc.kr/sb/delete_reply.php",
                method: 'get',
                params: {
                    reply_id : reply_id
                }
            })
                .then(function (res) {
                    console.log('delete reply success');
                    if (res.data == 200) {
                        customAlert('삭제 되었습니다.');
                        setRefresh(!refresh);
                    } else {
                        customAlert('삭제 실패');
                    }
                })
                .catch(function (e) {
                    console.log('delete board error');
                    console.log(e);
                })
        } else if(confirmID == 3){
            window.open("https://info.smartbc.kr/b2a");
        }

    };



    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center'}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'게시글 상세'} back={1} history={history}/>
            {/*body*/}
            <div className={classes.paper}>
                    {/*custom_confirm*/}
                    <div>
                        <Dialog
                            open={confirmShow}
                            onClose={handleCloseN}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {confirmMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseN} color="primary">
                                    {'아니오'}
                                </Button>
                                <Button onClick={handleCloseY} color="primary" autoFocus>
                                    {'예'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                {/*제목*/}
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: '15px 30px'}}>
                        <Grid item xs={10}>
                            <Typography align='left' style={{border: 'none', outline: 'none', width: '100%', padding: 10}}>{title}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{textAlign :'right' ,}}>
                            {upfile ?
                                (
                                    <IconButton>
                                    <img src={require('resources/fileimage.png')}
                                         style={{
                                             height: 25,
                                             width: 'auto',
                                             verticalAlign: 'middle'
                                         }}
                                    onClick={() => {
                                        window.location.href = 'https://sbc.smartbc.kr/sb/download_file.php?file='+upfile;
                                    }}/>
                                    </IconButton>
                                ) : null}
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                {/*글쓴이*/}
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: '10px 30px', position: 'relative'}}>
                        <Grid container alignItems='center'direction='row'>
                            <Grid item xs={2} style={{marginRight: 10}} onClick={() => {
                                console.log('id', selectID) //u_id,setSelectID => string
                                console.log('with_draw', withDraw);

                                if(withDraw == 0) {
                                    let join_option = null;
                                    if (writeAdmin != 1) {
                                        if (Number(selectID) !== Number(u_id)) {
                                            if (joinOpVal) {
                                                join_option = Object.values(JSON.parse(joinOpVal));
                                            }

                                            history.push({
                                                pathname: '/' + match.params.JoinCode + '/MembersCardDetail',
                                                state: {
                                                    sel_u_id: selectID, //targetId
                                                    join_option: join_option,
                                                }
                                            });
                                        }
                                    }
                                }else {
                                    customAlert('탈퇴 회원입니다.');
                                    return;
                                }


                            }}>
                                <img alt="사진" src={writeAdmin == 1 ? (require('../resources/profile_exampleImg.png')) : (profile ? 'https://sbc.smartbc.kr/'+profile : require('../resources/profile_exampleImg.png'))} style={{width: 50, height: 50,borderRadius : 50}}/>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                            {writeAdmin == 1 ? '모임관리자' : name}
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                            {writeAdmin == 1 ? '스마트 모임' : company?company:'스마트 모임'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*게시글 3점버튼*/}
                        {(writer == u_id || admin == '1')?
                            (
                                <ThreeDotsButton type={'board'}/>
                            ) : null}
                    </Grid>
                </div>
                {/*공지 타입*/}
                {table == 'band_board1' ?
                    <>
                        {notiType3==1?
                            <>
                                <div style={{
                                    width: '100%',
                                    padding: '15px 0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                }}>
                                    <div style={{width: '20%',}}>
                                        <Typography style={{fontSize: 14}}>{'일자 / 시간'}</Typography>
                                    </div>
                                    <div style={{width: '80%', textAlign: 'left', paddingLeft: 20}}>
                                        <InputBase style={{textAlign :'left',color:'black'}}
                                                   placeholder={'시간'}
                                                   disabled={true}
                                                   value={eventDate}
                                        />
                                    </div>
                                </div>

                                <div style={{
                                    width: '100%',
                                    padding: '15px 15px 15px 0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
                                }}>
                                    <div style={{width: '20%',}}>
                                        <Typography style={{fontSize: 14}}>{'장소 / 주소'}</Typography>
                                    </div>
                                    <div style={{width: '80%', paddingLeft: 20}}>
                                        <InputBase style={{textAlign: 'left', width: '100%'}}
                                                   multiline={true}
                                                   placeholder={'장소를 입력해주세요'}
                                                   value={eventPlace}
                                                   onChange={(event) => {
                                                       console.log('장소', event.target.value)
                                                       setEventPlace(event.target.value);
                                                   }}
                                        />
                                    </div>
                                </div>
                            </>:null}
                    </>
                    : null}
                {/*내용*/}
                <div style={{width: '100%', padding: '10px 30px'}}>
                        <Typography align='left' style={{border: 'none', outline: 'none', width: '100%', padding: 10, fontSize: 13, lineHeight: 1.6,wordBreak:'break-all'}} id={'boardDetailContent'}>
                            <pre style={{whiteSpace : 'pre-wrap'}}><div dangerouslySetInnerHTML={aa()}></div></pre>
                        </Typography>
                    {images.length > 0 ?
                        <>
                            {/*대표이미지*/}
                    {/*<div style={{height: 280, margin: 15, border: '1px solid #aeb5ba', borderRadius: 10, padding:'1%'}}>*/}
                    {/*    <img style={{width: '100%', height: '100%',}} src={'https://sbc.smartbc.kr'+images[0]} />*/}
                    {/*</div>*/}
                            {/*이미지 첨부파일 미리보기*/}
                            <div style={{display: 'flex', height: 100, margin: 15}}>
                                {/*{ImgListUM}*/}
                                {images.map((value, i) =>
                                    <ImgExample key={i} uri={value}/>)}
                            </div>
                    </>
                        :null}
                    <div style={{display: 'flex',margin:15, height:30,}}>
                        {doc ?
                                <div style={{width: '65%', display: 'flex', marginRight: '5%',}}>
                                    {/*<Image source={require('../resources/fileimage.png')} resizeMode={'contain'}*/}
                                    {/*       style={{width: 20, height: 20, marginRight: 5}}/>*/}
                                    <IconButton>
                                        <img src={require('resources/fileimage.png')}
                                             style={{
                                                 height: 25,
                                                 width: 'auto',
                                                 verticalAlign: 'middle'
                                             }}
                                             onClick={() => {
                                                 // setModal(true);
                                                 window.location.href = 'https://sbc.smartbc.kr/sb/download_file.php?file='+doc;
                                             }}
                                        />
                                    </IconButton>
                                    <Typography className={classes.textEllipsis} style={{
                                        height:20,
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                        color: '#b5b5b5',
                                        paddingRight: 5,
                                        marginTop:10
                                    }}>
                                        {doc.split('/').pop()}
                                    </Typography>
                                </div>
                            : <div style={{width: '65%', display: 'flex', marginRight: '5%'}}/>}

                        <div style={{width:'30%',marginTop:15}}>
                            <Typography style={{fontSize: 10, color: '#b5b5b5',}}>
                                {date}
                            </Typography>
                        </div>
                    {/*      <Typography align='right' style={{fontSize: 13, color: '#888', marginTop: 10}}>{date}</Typography>*/}
                    </div>

                </div>



                {table == 'band_board1'&&notiType3==1?
                            <>
                                <div style={{width:'100%'}}>
                                    {/*참서여부 버튼영역*/}
                                    <div style={{height:80,display:'flex',alignItems:'center',justifyContent:'center',}}>
                                        {/*참석*/}
                                            <div style={{width:'45%',height:50,backgroundColor:'#ff0000',alignItems:'center',justifyContent:'center',borderRadius:10,marginRight:'5%',padding:'13px 0'}}
                                            onClick={()=>{
                                                if(admin==1) {
                                                    customAlert('관리자는 참석여부를 선택할 수 없습니다.');
                                                    return;
                                                }
                                                console.log('게시글아이디',board_id)
                                                Axios({
                                                    url: 'https://sbc.smartbc.kr/sb/event_in_out_update.php',
                                                    method: 'get',
                                                    params: {
                                                        u_id: u_id,
                                                        board_id : board_id,
                                                        check: 1, //참석
                                                        // b_id: stored_bid,
                                                    },
                                                })
                                                    .then((result) => {
                                                        console.log('결과',result.data);
                                                        setEventInList(result.data.in_list);
                                                        setEventOutList(result.data.out_list);

                                                    })
                                                    .catch((err) => console.log(err.message));
                                            }}>
                                                <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'white'}}>{'참석 합니다.'}</Typography>
                                            </div>
                                        {/*불참*/}
                                            <div style={{width:'45%',height:50,backgroundColor:'#ffcc00',alignItems:'center',justifyContent:'center',borderRadius:10,padding:'13px 0'}}
                                            onClick={()=>{
                                                if(admin==1) {
                                                    customAlert('관리자는 참석여부를 선택할 수 없습니다.');
                                                    return;
                                                }
                                                Axios({
                                                    url: 'https://sbc.smartbc.kr/sb/event_in_out_update.php',
                                                    method: 'get',
                                                    params: {
                                                        u_id: u_id,
                                                        board_id : board_id,
                                                        check: 0, //불참
                                                        // b_id: stored_bid,
                                                    },
                                                })
                                                    .then((result) => {
                                                        console.log('결과',result.data)
                                                        setEventInList(result.data.in_list);
                                                        setEventOutList(result.data.out_list);
                                                    })
                                                    .catch((err) => console.log(err.message));
                                            }}>
                                                <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'white'}}>{'불참 합니다.'}</Typography>
                                            </div>
                                    </div>
                                    {/*버튼 영역 끝*/}

                                    {/*참석인원 현황*/}
                                    <div style={{display:'flex',borderRadius: 10, backgroundColor: '#f2f2f2', width: '90%',margin:'0 20px', padding: 15, alignSelf: 'center', marginTop: 10, justifyContent:'center', alignItems:'center'}}>
                                        <div style={{display:'flex',}}>
                                            <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'black',marginRight:5}}>{'참석'}</Typography>
                                            <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'#ff0000',marginRight:5}}>{eventInList.length>0?eventInList.length:0}</Typography>
                                            <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'black',marginRight:5}}>{' 명  /  '}</Typography>
                                        </div>
                                        <div style={{display:'flex',}}>
                                            <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'black',marginRight:5}}>{'불참 '}</Typography>
                                            <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'#ffcc00',marginRight:5}}>{eventOutList.length>0?eventOutList.length:0}</Typography>
                                            <Typography style={{fontSize:16,fontWeight:'bold',textAlign: 'center',color:'black',marginRight:5}}>{' 명'}</Typography>
                                        </div>
                                    </div>

                                    {/*참석 회원 리스트*/}
                                    {eventInList.map(((value,i)=><InList key={i} name={value.name} profile={value.profile} company={value.company_name}/>))}

                                    {/*불참 회원 리스트*/}
                                    {eventOutList.map(((value,i)=><OutList key={i} name={value.name} profile={value.profile} company={value.company_name} />))}

                                </div>
                            </>
                            :
                        <>
                            <div style={{width: '100%'}}>
                                <div align='left' style={{width: '100%', margin: '20px 0', padding: '0 30px'}}>
                                    <Button onClick={() => {
                                        Axios({
                                            url: 'https://sbc.smartbc.kr/sb/like_update.php',
                                            method: 'get',
                                            params: {
                                                u_id : u_id,
                                                table : table,
                                                board_id : board_id
                                            }
                                        }).then(function (res) {
                                            console.log('like update success');
                                            setRefresh(!refresh);
                                            console.log(res.data);
                                        })
                                            .catch(function (e) {
                                                console.log('like update fail');
                                                console.log(e);
                                            })
                                    }}
                                            style={like_search ? {width: '30%', padding: 10, borderRadius: 40, backgroundColor: '#c1dff5', fontWeight: 'bold', marginRight: '5%',fontSize : 12} : {width: '30%', padding: 10, borderRadius: 40, backgroundColor: 'rgb(249,250,252)', fontWeight: 'bold', marginRight: '5%',fontSize : 12}}>
                                        <img src={require('../resources/like_icon.png')} style={{width: 15, marginRight: 10}}/><span style={{fontSize: 12}}>{like_num ? like_num : 0}</span>
                                    </Button>
                                    <Button onClick={() => {
                                        if(cardRegist=='Y') {
                                            setWriteReply(!WriteReply);
                                        }else{
                                            setModal2(true);
                                        }
                                    }}
                                            style={{width: '30%', padding: 10, backgroundColor: 'rgb(249,250,252)', borderRadius: 40, fontWeight: 'bold', marginRight: '5%',fontSize : 11}}>
                                        <img src={require('../resources/review_icon.png')} style={{width: 15, marginRight: 10}}/>댓글쓰기
                                    </Button>
                                    <Button onClick={() => {
                                        let isMobile = false;

                                        // PC 환경
                                        const filter = "win16|win32|win64|mac|macintel";
                                        const varUA = navigator.userAgent.toLowerCase();

                                        if(navigator.platform){
                                            isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
                                            if(isMobile){
                                                // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                // if (answer) window.open("https://info.smartbc.kr/b2a");
                                                setConfirmID(3);
                                                setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                setConfirmShow(true);
                                            }else{
                                                customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                            }
                                        }
                                    }}
                                            style={{width: '30%', padding: 10, backgroundColor: 'rgb(249,250,252)', borderRadius: 40, fontWeight: 'bold',fontSize : 11}}>
                                        <img src={require('../resources/share.png')} style={{width: 15, marginRight: 10}}/>공유하기
                                    </Button>
                                </div>
                                <Divider/>
                            </div>

                        </>}




                {/* 댓글쓰기 */}
                {WriteReply ?
                    (
                        <div style={{width: '100%',}}>
                            <div alignItems='center'  container style={{padding: '10px 30px'}}>
                                <Box component={'textarea'}
                                     id={'replyContents'}
                                     style={{border: 'none', outline: 'none', width: '100%', padding: 10, resize: 'none', lineHeight: 1.5, minHeight: 60}} placeholder='명예훼손, 욕성 등의 악플은 경고없이 삭제됩니다.'/>
                            </div>
                            <div style={{padding: '0 30px'}}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    style={{backgroundColor: 'rgb(48,58,83)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                                    onClick={() => {
                                        if (document.getElementById('replyContents').value==''&&document.getElementById('replyContents').value.replace(/^\s+|\s+$/g, "") ==''){
                                            customAlert('내용을 작성해주세요');
                                            return;
                                        }

                                        Axios({
                                            url: "https://sbc.smartbc.kr/sb/reply_write.php",
                                            method: 'get',
                                            params: {
                                                u_id : (admin == '0' ? u_id : 0),
                                                board_id : board_id,
                                                board_type : table,
                                                content : document.getElementById('replyContents').value,
                                            }
                                        })
                                            .then(function (res) {
                                                console.log('reply write success');
                                                console.log(res.data);
                                                if (res.data == 200) {
                                                    setWriteReply(!WriteReply);
                                                    setRefresh(!refresh);
                                                } else {
                                                    console.log('reply error');
                                                }
                                            })
                                            .catch(function (e) {
                                                console.log('reply write fail');
                                                console.log(e);
                                            })
                                    }}
                                >
                                    댓글쓰기
                                </Button>
                            </div>
                        </div>
                    ) : null}
                {/*댓글*/}
                <div style={{width: '100%',}}>
                    {replyList ?
                        (replyList.map((val,index) =>
                                <ReplyList key={index} id={val.id} name={val.name} company_name={val.company_name} uid={val.u_id} content={val.content}
                                           profile={val.profile} regist_date={val.regist_date} />
                        )

                    ) : null}

                </div>
            </div>
            {modal?<PopUp/>:null}
            {modal2?<PopUp2/>:null}
            {loading?
                <>
                    <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                    <div className={classes.popUpBg} />
                </>
                :null}
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 60,
        width: '100%',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    uploadName: {
        border: 0,
        width: '100%',
        height: '100%',
        background: '#fff'
    },
    fileSelectBtn: {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: 'rgb(43,66,124)',
        display: 'inline-block',
        width : '100%',
        padding: 12,
        borderRadius: 30,
        verticalAign: 'middle',
        color: '#fff',
        fontWeight: 'bold'
    },
    fileInput: {
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        border: 0,
        opacity: 0
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        display: 'hidden',
        margin: theme.spacing(1),
        minWidth: 120,
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.2,
        height: '40px',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
        padding: 2.5,
    }
}));
export default NoticeBoardDetail;
