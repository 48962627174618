import React,{useEffect} from "react";
import {
    makeStyles,
    Container,
    Button, TextField,

} from '@material-ui/core';
import line_bg from '../../resources/splash_line.png';
import Axios from "axios";
import Badge from "@material-ui/core/Badge";
import customAlert from "../../component/customAlert";


function AdminLogin({history,match}) {
    const classes = useStyles();

    const [u_id,setU_id] = React.useState('');
    const [pw,setPw] = React.useState('');

    const AdminLogin = (e)=>{
        // if (e.key === 'Enter') {
            console.log('do validate');
        // }
        if (u_id == '') {
            customAlert('아이디를 입력해주세요!');
            return;
        } else if (pw == '') {
            customAlert('비밀번호를 입력해주세요!');
            return;
        }
        console.log('로그인!');
        Axios({
            method: 'get',
            url: 'https://sbc.smartbc.kr/sb/band_admin/admin_login.php',
            params: {
                u_id: u_id,
                pw: pw,
            },
        })
            .then((result) => {
                console.log(result.data);
                if (result.data.status == 18001) {

                    sessionStorage.setItem('@u_id_a', result.data.u_id);//관리자아이디
                    sessionStorage.setItem('@b_id_a', result.data.b_id);//그룹번호
                    history.replace('/' + match.params.JoinCode + '/AdminMain');
                    // history.push('/AdminMain');
                    // history.push({
                    //     pathname :'/AdminMain',
                    //     state : {},
                    // });
                } else {
                    customAlert(result.data.result_msg);
                }
            }).catch((err) => console.log(err));
    };

    if(history.location.search){
        setTimeout(function () {
            Axios({
                method: 'get',
                url: 'https://sbc.smartbc.kr/sb/band_admin/admin_login.php',
                params: {
                    u_id: history.location.search.split("=")[1].split("&")[0],
                    pw: history.location.search.split("=")[2],
                },
            })
                .then((result) => {
                    console.log(result.data);
                    if (result.data.status == 18001) {

                        sessionStorage.setItem('@u_id_a', result.data.u_id);//관리자아이디
                        sessionStorage.setItem('@b_id_a', result.data.b_id);//그룹번호
                        history.replace('/' + match.params.JoinCode + '/AdminMain');
                        // history.push('/AdminMain');
                        // history.push({
                        //     pathname :'/AdminMain',
                        //     state : {},
                        // });
                    } else {
                        customAlert(result.data.result_msg);
                    }
                }).catch((err) => console.log(err));
        },500);

        // window.location.reload();
        // document.getElementById('id').value = param_id;
        // document.getElementById('password').value = param_pw;
    }

    const getData = async () => {
        try {

            const u_id = sessionStorage.getItem('@u_id_a');
            const b_id = sessionStorage.getItem('@b_id_a');
            if (u_id !== null && b_id!== null ) {
                console.log(u_id);
                console.log(b_id);
                history.push('/'+match.params.JoinCode+'/AdminMain');
                // history.push('/AdminMain');

            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(()=>{
        //자동로그인
        // history.location.state.autoLogin&&getData();

    },[]);

    return (
        <div style={{width: '100%', height: '100%'}}>
            <Container component="main" maxWidth="xs" style={{paddingBottom: '30px', height: '750px',}}>
                <div className={classes.paper}>
                    <img src={require('../../resources/logo_icon_update/admin_login_icon.png')} style={{height: 100, marginTop: 185}}/>
                    <div style={{width: '100%', marginTop: 20}} className='login_input'>
                            <TextField
                                id={'id'}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                placeholder='아이디를 입력해주세요.'
                                autoFocus
                                onChange={(event) => {
                                    console.log('아이디',event.target.value);
                                    setU_id(event.target.value);
                                }}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                        console.log('lololol');
                                        AdminLogin();
                                    }
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                placeholder='비밀번호를 입력해주세요.'
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                onChange={(event) => {
                                    console.log('패스워드',event.target.value);
                                    setPw(event.target.value);
                                }}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                       console.log('lololol');
                                       AdminLogin();
                                    }
                                }}
                            />
                            <Button
                                // component="label" // 이거 써야 <input/> 가능
                                fullWidth
                                color="primary"
                                className={classes.submit}
                                style={{backgroundColor: '#fff', fontWeight:'bold',fontSize : 15,borderRadius : 25,padding : 10, color: 'rgb(43,66,124)', marginTop: 37}}
                                onClick={AdminLogin}
                            >
                                로그인
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                style={{backgroundColor: 'transparent', color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 25,padding :10, border: '2px solid #fff', marginTop: 15}}
                                onClick={()=>{
                                    history.push('/'+match.params.JoinCode+'/BandJoin');
                                    // history.push('/BandJoin');
                                }}
                            >
                                모임 관리자 가입
                            </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        height: '100%',
        padding: '0 40px 20px',
        display: 'fixed',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgb(43,66,124)',
        backgroundImage:`url(${line_bg})`,
        backgroundRepeat : 'no-repeat', backgroundPosition: 'center center',
        backgroundSize: 730

    },
    title: {
        fontSize: 14,
    },
    '@global': {
        /*'#root' :{
            height: '100%', width: '100%',
            position: 'fixed', left: '50%', top: 0,
            transform: 'translateX(-50%)',

        },*/
       '.login_input .MuiInputBase-input' : {
           border: '2px solid rgb(219,213,255)',
           borderRadius: 10,
           backgroundColor: '#fff'
       }

        },
}));


export default AdminLogin;
