import React from "react";
import {AppBar, Button, IconButton, Toolbar, Typography} from "@material-ui/core";
import Icon from "@material-ui/icons/ArrowBack";
import customConfirm from "../component/customConfirm";
import customAlert from "../component/customAlert";

function PcHeader({history, text, back, main, share, adminsetting,match,cnt,adminback}) {
    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
    return (
        <div style={{flex: 1, backgroundColor: 'white'}}>
            <AppBar>
                <Toolbar style={{flexDirection: 'row', backgroundColor: 'white',alignItems:'center'}}>
                    {back == 1 ? (
                        <Typography variant="h6" align='left' onClick={()=>{
                            if(text == '연결 된 회원' || text == '회원 명부' || text == '회원 게시판 관리' || text =='알림'||text=='파트너'||text=='자문단 & 파트너 미 승인') {
                                if(text == '회원 게시판 관리' && sessionStorage.getItem('@admin') == '1'){
                                    history.push('/'+match.params.JoinCode+'/AdminMain');
                                    return;
                                }
                                history.push('/'+match.params.JoinCode);
                                return;
                            }
                            if(text=='전체 회원 상세검색'){
                                history.push({
                                    pathname :  '/'+match.params.JoinCode+'/Members',
                                    state : {searchList : null}
                                });
                                return;
                            }
                            if(text=='가입 회원 상세 검색'){
                                history.push({
                                    pathname : '/'+match.params.JoinCode+'/AdminJoinUser',
                                    state : {searchList : null}
                                });
                                return;
                            }
                            //게시판 뒤로가기
                            if(main==1){
                                sessionStorage.removeItem('@scrollPos');
                                console.log('스크롤값지워짐?',sessionStorage.getItem('@scrollPos'))
                                history.push('/'+match.params.JoinCode);
                                return;
                            }else if(main==2){
                                sessionStorage.removeItem('@scrollPos');
                                console.log('스크롤값지워짐?',sessionStorage.getItem('@scrollPos'))
                                history.push('/'+match.params.JoinCode+'/AdminMain');
                                return;
                            }
                            //어드민  뒤로가기
                            if(adminback== 1){
                                history.push(  '/'+match.params.JoinCode+'/AdminMain');
                                return;
                            }
                            if(adminback== 2){
                                history.push(  '/'+match.params.JoinCode+'/AdminSetting');
                                return;
                            }
                            // if(text=='스마트그룹 관리자'){
                            //     history.push({
                            //         pathname : '/'+match.params.JoinCode+'/AdminLogin',
                            //         state : {autoLogin:false}
                            //     });
                            //     return;
                            // }
                            history.goBack();
                        }}>
                            <IconButton edge="start" aria-label="menu">
                                <Icon style={{color: 'black',position: 'absolute',left:15}}/>
                            </IconButton>
                        </Typography>
                    ) : null
                    }
                    {text=='회원 명부'||text=='연결 된 회원'?
                        <Typography variant="h6" align="center" style={{flex: 1, color: 'black', fontWeight: 'bold',}}>
                            {text}({cnt?cnt:0})
                        </Typography>
                    :
                        <Typography variant="h6" align="center" style={{flex: 1, color: 'black', fontWeight: 'bold',}}>
                            {text}
                        </Typography>
                    }
                    {share?
                        <Button
                            // type="submit"
                            color="primary"
                            style={{backgroundColor: 'rgb(48,58,83)', color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 25,padding :10, marginLeft: 10}}
                            onClick={()=>{
                                if(navigator.platform){
                                    if(isMobile){
                                        let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                        if (answer) window.open("https://info.smartbc.kr/b2a");
                                    }else{
                                        customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                    }
                                }

                            }}>
                            공 유
                        </Button>
                        :null
                    }
                    {adminsetting?
                        <IconButton color="primary" style={{cursor: 'pointer', marginLeft: -15}}
                        onClick={()=>{
                            history.push('/'+match.params.JoinCode+'/AdminSetting');
                        }}>
                            <img src={require('../resources/main_home_icon/setting.png')} style={{height: 32}}/>
                        </IconButton>
                        :
                        null
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default PcHeader;
