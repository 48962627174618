import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    TextField,
    FormControl,
    MenuItem,
    Select,
    Avatar,
    Grid, Chip
}
    from '@material-ui/core';
import PcHeader from "../PcHeader";
import Axios from "axios";

function AdminSearchDetail({history,match}) {
    const classes = useStyles();
    /* selectbox */
    const [infoOpen, setInfoOpen] = React.useState('');
    const [companyIndustry, setcompanyIndustry] = React.useState('');
    const [occupation, setoccupation] = React.useState('');
    const [area, setarea] = React.useState('');
    const [position, setposition] = React.useState('');
    const [year, setyear] = React.useState('');
    const [university, setuniversity] = React.useState('');
    const select = (event) => {
        setInfoOpen(event.target.value);
    };
    const select1 = (event) => {
         setcompanyIndustry(event.target.value);
    };
    const select2 = (event) => {
        setoccupation(event.target.value);
    };
    const select3 = (event) => {
        setarea(event.target.value);
    };
    const select4 = (event) => {
        setposition(event.target.value);
    };
    const select5 = (event) => {
        setyear(event.target.value);
    };
    const select6 = (event) => {
            setuniversity(event.target.value);
    };

    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const [infoType,setInfoType]=React.useState('');
    const [op_list, setOp_list] = React.useState(new Array());
    const [cardInfo, setCardInfo] = React.useState({});//선택한 명함 정보 선택 값 저장
    const [selectList, setSelectList] = React.useState({});// 선택한 협회 가입 정보 값 저장
    let list = {};//검색하기 버튼 클릭 시 데이터 전송 값 저장
    let table ;

    const Search1 = ({title, table}) => {
        const [select,setSelect] = React.useState('');
        const [opList, setOpList] = React.useState(new Array());
        let items = new Array();

        useEffect(() => {
            //명함 정보 셀렉트 박스 값 가져오기
            Axios({
                url: 'https://sbc.smartbc.kr/sb/getOption.php',
                method: 'get',
                params: {
                    table: table,
                }
            })
                .then(function (response) {
                    // response.data.map((value) => {
                    //     items.push({label: value.name, value: value.id})
                    // });
                    setOpList(response.data);
                    // opList.push({label:response.data.name,value:response.data.id});

                })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        return (
            <Grid container spacing={2}>
                <Grid item xs={3} className={classes.selectTilie}>{title}</Grid>
                <Grid item xs={9}>
                    <FormControl variant="filled" className={classes.formControl}>
                        <Select
                            value={select}
                            onChange={(val) => {
                                let tmpList = cardInfo;
                                tmpList[table] = val.target.value;
                                console.log(tmpList);
                                setCardInfo(tmpList);
                                console.log(cardInfo);
                                setSelect(val.target.value);
                            }}
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            style={{backgroundColor: '#fff'}}
                        >
                            <MenuItem value="" disabled>
                                선택
                            </MenuItem>
                            {opList.map((val, index) => <MenuItem key={index} value={val.id}>{val.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    const Search2 = ({title,table,table2}) => {

        const [opList1, setOpList1] = React.useState(new Array());
        const [opList2, setOpList2] = React.useState(new Array());
        const [select1 , setSelect1] = React.useState('');
        const [select2 , setSelect2] = React.useState('');

        useEffect(() => {
            //명함 정보 셀렉트 박스 값 가져오기
            Axios({
                url: 'https://sbc.smartbc.kr/sb/getOption.php',
                method: 'get',
                params: {
                    table : table
                }
            })
                .then(function (response) {
                    setOpList1(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }, []);

        return(
            <Grid container spacing={2}>
                <Grid item xs={3} className={classes.selectTilie}>{title}</Grid>
                <Grid item xs={4}>
                    <FormControl variant="filled" className={classes.formControl}>
                        <Select
                            value={select1}
                            onChange={(val) => {
                                //2차 옵션 가져오기
                                Axios({
                                    url: 'https://sbc.smartbc.kr/sb/getOption.php',
                                    method: 'get',
                                    params: {
                                        table : table2,
                                        mother_type : val.target.value
                                    }
                                })
                                    .then(function (response) {
                                        setOpList2(response.data);
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                                let tmpList = cardInfo;
                                tmpList[table] = val.target.value;
                                setCardInfo(tmpList);
                                setSelect1(val.target.value);
                            }}
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            style={{backgroundColor: '#fff'}}
                        >
                            <MenuItem value="" disabled>
                                선택
                            </MenuItem>
                            {opList1.map((val,index) => <MenuItem value={val.id}>{val.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl variant="filled" className={classes.formControl}>
                        <Select
                            value={select2}
                            onChange={(val) => {
                                let tmpList = cardInfo;
                                tmpList[table2] = val.target.value;
                                setCardInfo(tmpList);
                                setSelect2(val.target.value);
                            }}
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            style={{backgroundColor: '#fff'}}
                        >
                            <MenuItem value="" disabled>
                                선택
                            </MenuItem>
                            {opList2.map((val,index) => <MenuItem value={val.id}>{val.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }


    /**
     *협회 가입 정보 셀렉트 박스 View 그리기 위한 data 가져오기
     * */
    useEffect(() => {
        Axios({
            url: 'https://sbc.smartbc.kr/sb/getJoinInfo.php',
            method: 'get',
            params: {
                b_id: b_id,
            }
        })
            .then(function (response) {
                setOp_list(response.data.op_list);
                console.log(response.data.op_list);
                op_list.map(value => console.log(value));
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const JoinInfo = ({option1, option2}) => {

        const [select, setSelect] = React.useState('');

        return (
            <Grid container spacing={2}>
                <Grid item xs={3} className={classes.selectTilie}>{option1.name}</Grid>
                <Grid item xs={9}>
                    <FormControl variant="filled" className={classes.formControl}>
                        <Select
                            value={select}
                            onChange={(val) => {
                                let tmpList = selectList;
                                tmpList[option1.id] = val.target.value;
                                setSelectList(tmpList);
                                setSelect(val.target.value);
                            }}
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            style={{backgroundColor: '#fff'}}
                        >
                            <MenuItem value="" disabled>
                                선택
                            </MenuItem>
                            {option2.map((val, index) => {
                                return (
                                    <MenuItem key={index} value={val.id}>{val.name}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'가입 회원 상세 검색'} back={1} history={history} match={match}/>
                <div className={classes.paper}>
                    <Typography align='center'
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', borderRadius: 10}}>
                        명함 정보
                    </Typography>
                    <form className={classes.form} noValidate>
                        {/* 정보공개 */}
                        <Grid container spacing={2}>
                            <Grid item xs={3} className={classes.selectTilie}>정보공개</Grid>
                            <Grid item xs={9}>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <Select
                                        value={infoType}
                                        onChange={(val) => {
                                            // let tmpList = cardInfo;
                                            // tmpList[table] = val.target.value;
                                            // setCardInfo(tmpList);
                                            setInfoType(val.target.value);
                                        }}
                                        displayEmpty
                                        inputProps={{'aria-label': 'Without label'}}
                                        style={{backgroundColor: '#fff'}}
                                    >
                                        <MenuItem value="" disabled>
                                            선택
                                        </MenuItem>
                                        <MenuItem value={1}>전체</MenuItem>
                                        <MenuItem value={2}>모임만</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Search2 title={'회사 업종'} table={'company_type_first'} table2={'company_type_second'}/>
                        <Search2 title={'직업군'} table={'part_type_first'} table2={'part_type_second'}/>
                        <Search1 title={'지역'} table={'area_type_first'}/>
                        <Search1 title={'직급'} table={'position_category'}/>
                    </form>
                    <Typography align='center'
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', marginTop: 30, borderRadius: 10}}>
                        모임 가입 정보
                    </Typography>
                    <form className={classes.form} noValidate>
                        {/* 그룹 가입 정보 (그룹 옵션) */}
                        {op_list.map((val, index) => <JoinInfo key={index} option1={val[0]} option2={val[1]}/>)}

                    </form>

                    {/* 버튼 */}
                    <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        style={{backgroundColor: 'rgb(48,58,83)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 30}}
                        onClick={() => {
                            list['selectList'] = selectList;
                            list['cardInfo'] = cardInfo;
                            list['infoType'] = infoType;


                            console.log("selectlist start ");
                            console.log( selectList);
                            console.log('cardinfo start ');
                            console.log( cardInfo);
                            console.log('ddddd',list);

                            let data = JSON.stringify(list);

                            console.log('ddddd',data);
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/band_admin/join_user_detail_search.php',
                                method: 'get',
                                params: {
                                    data : data,
                                    b_id : b_id
                                }
                            })
                                .then(function (response) {
                                    console.log(response.data);
                                    history.replace({
                                        pathname : '/'+match.params.JoinCode+'/AdminJoinUser',
                                        state : {searchList : response.data.member_list}
                                    });

                                }).catch(function (error) {
                                console.log('error'+error);
                            });
                        }}
                    >
                        검색하기
                    </Button>
                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '30px 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    formControl: {
        width: '100%',
        textAlign: 'left',
        textIndent: 10,
        marginTop: theme.spacing(3),
    },
    selectTilie: {
        fontSize: 15,
        textAlign: 'center',
        marginTop: theme.spacing(4),
        backgroundColor: 'rgb(249,250,252)',
        width: 105,
        height: 60,
        lineHeight: '60px',
        borderRadius: 10,
        marginRgiht: 10
    },

}));


export default AdminSearchDetail;
