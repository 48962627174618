import React, {useCallback, useEffect,useMemo} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
    InputBase, IconButton, Checkbox, FormControlLabel, FormControl, Select, MenuItem, CircularProgress,
}
    from '@material-ui/core';
import PcHeader from "../PcHeader";
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import Axios from "axios";
import Moment from 'moment';
import customAlert from "../../component/customAlert";

export default function AdminUserLevel({history,match}) {

    // console.log('holly',history);

    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const [member_list,setMember_list] = React.useState([]);

    // const detail =history.location.state.detail;
    // if(history.location.state.member_list){
    //     const searchMemberList = history.location.state.member_list;
    // }
    // const searchMemberList = history.location.state.member_list;

    // const detail ='';
    // const searchMemberList =[];
    const [loading, setLoading] = React.useState(true);
    const [refresh, setRefresh] = React.useState(false);// 새로고침

    let list = {};
    const [text,setText]=React.useState('');
    const [sort,setSort] = React.useState(0);

    // let [selectedList,setSelectedList]= React.useState([]);
    let selectedList=[];


    // const [preItems,setPreItems]=React.useState(0);
    // const [items,setItems]=React.useState([]);

    // const _infiniteScroll = useCallback(()=>{
    //     // console.log('무한스크롤높이');
    //     let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    //     let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    //     let clientHeight = document.documentElement.clientHeight;
    //     if(scrollTop+clientHeight >= scrollHeight -100){
    //         setPreItems(preItems+10);
    //         setMember_list(JSON.parse(JSON.stringify(member_list)).concat(items.slice(preItems+10,preItems+20)));
    //
    //     }
    // },[preItems,member_list,items]);
    //
    // useEffect(() => {
    //     // console.log('무한스크롤');
    //     window.addEventListener('scroll', _infiniteScroll, true);
    //     return () => window.removeEventListener('scroll', _infiniteScroll, true);
    // }, [_infiniteScroll]);

    useEffect(()=>{
        console.log('1111111111111111');
        if(history.location.state.searchList) {
            console.log('2222222222');
            console.log(history.location.state.searchList);
            setMember_list(history.location.state.searchList);
            setLoading(false);
            // setMember_list(history.location.state.searchList.slice(0,10));
            // setItems(history.location.state.searchList);

        }
        else{
            console.log('회원등급 기본');
            Axios({
                url: 'https://sbc.smartbc.kr/sb/band_admin/admin_join_user_info.php',
                method: 'get',
                params: {
                    u_id: u_id,
                    b_id: b_id,
                },
            })
                .then((result) => {
                    console.log('3333333333333');
                    console.log('통신이',result.data);
                    setMember_list(result.data.list);
                    setLoading(false);
                    // setMember_list(result.data.list.slice(0,10));
                    // setItems(result.data.list);

                })
                .catch((err) => console.log(err.message));

        }

    },[history.location.state.searchList,refresh]);

    const MemberList = ({index})=> {

        let userLevel='일반회원';
        if(member_list[index].user_level==1){
            // setUserLevel('운영자');
            userLevel='운영자';
        }else if(member_list[index].user_level==2){
            // setUserLevel('우수회원');
            userLevel='우수회원';
        }

        const [op2,setOp2] = React.useState();
        // console.log('이거',Object.values(JSON.parse(member_list[index].join_option2_val))); // value 값 추출.
        if(member_list[index].join_option2_val != null) {
            Axios({
                url : 'https://sbc.smartbc.kr/sb/get_option2_name.php',
                method :'get',
                params : {
                    op2_val:Object.values(JSON.parse(member_list[index].join_option2_val))+""
                }
            })
                .then(function (res) {
                    // res.data.map((value) => {
                    //     op2_name.push(value.name);
                    // });
                    // console.log(op2_name[key]);
                    // op2_name = res.data.join();
                    // console.log(op2_name);
                    setOp2(res.data.join());
                    // setOp2(null);
                    //   console.log('여기',res.data.join());
                }).catch(function (e) {
                console.log('fail');
            },[member_list[index].join_option2_val]);
        }

        return (
            <div className={classes.paper}>
                <Grid  container spacing={2}>
                    <Grid container alignItems='center'direction='row' style={{padding: 15, borderRadius: 10, boxShadow: '5px 5px 10px rgba(0,0,0,0.1)', position: 'relative', marginBottom: 35}}>
                        <Grid xs={1} style={{marginRight: 3}} className={'managerCheckbox'}>
                            <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                    <Checkbox
                                        icon={<CheckBoxIcon fontSize="inherit"/>}
                                        checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                        name="checked"
                                        id={member_list[index].u_id}
                                        onChange={(event)=>{
                                            // let tmp = selectedList.slice();
                                            // console.log('tmp',tmp);
                                            if(event.target.checked){
                                                if(selectedList.indexOf(event.target.id)==-1){
                                                    selectedList.push(event.target.id);
                                                    console.log('추가');
                                                    // tmp.push(event.target.id);
                                                    // setSelectedList(tmp);
                                                }
                                            }else{
                                                if(selectedList.indexOf(event.target.id)!=-1){
                                                    let idx =  selectedList.indexOf(event.target.id);
                                                    selectedList.splice(selectedList.indexOf(event.target.id),1);
                                                    console.log('삭제',selectedList.indexOf(event.target.id));
                                                    // selectedList.slice(idx,1);
                                                    // const idx =  tmp.indexOf(event.target.id);
                                                    // tmp.slice(idx,1);
                                                    // setSelectedList(tmp);
                                                }
                                            }
                                            console.log('배열',selectedList);
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid xs={2} style={{marginRight: 3}}>
                            <div style={{ borderRadius: '50%', overflow: 'hidden', width: 45, height: 45, position: 'relative'}}>
                            {member_list[index].u_profile?
                                <img alt="사진" src={"https://sbc.smartbc.kr" + member_list[index].u_profile} style={{width: 50, height: 50, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}}/>
                            :
                                <img alt="사진" src={require('../../resources/profile_icon.png')} style={{width: 50, height: 50, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)'}}/>
                            }
                            </div>
                        </Grid>
                        <Grid xs={6} style={{maringRight: 5}}>
                            <Grid item xs container direction="column">
                                <Grid item>
                                    <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                        {member_list[index].u_name?member_list[index].u_name:'이름'}<span style={{fontSize: 11, color: 'rgb(181,181,181)', marginLeft: 5}}>
                                        {member_list[index].mc_position?member_list[index].mc_position:'직책'} </span>
                                    </Typography>
                                    <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                        {member_list[index].mc_company?member_list[index].mc_company:'소속'}
                                    </Typography>
                                    <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                        {/*rgb(43,66,124) 파란색*/}
                                        <span>{op2?op2:'모임 옵션'}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Typography align='center' style={{fontSize: 14, color: 'red',fontWeight:'bold'}}>
                                {userLevel? userLevel: null}
                            </Typography>
                            <Typography align='center' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                {Moment(member_list[index].uba_regist_date).format('YYYY-MM-DD')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

        );
    }

    const MemberListUM = useMemo(()=> member_list.map((value, i) =>
        <MemberList key={i} index ={i}/>),[member_list]);

    return (
        <div style={{width: 444, margin: '0 auto'}}>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', marginBottom: '70px', width: 444}}>
                <CssBaseline/>
                <PcHeader text={'회원 등급 변경'} back={1} adminback={2} history={history} match={match}/>
                {/* 상단 고정*/}
                <div style={{padding: '20px 25px 15px 20px', marginTop: 50, boxShadow:  '0 5px 10px rgba(0,0,0,0.15)', position: 'sticky', top: 55, backgroundColor: '#fff', zIndex: 10}}>
                    <Grid container>
                        <Grid xs={3} className='serchSelect'>
                            <FormControl variant="outlined"  color='primary' style={{width: '100%', textAlign: 'left', borderRadius: '40px', marginRight: 3}}>
                                <Select
                                    defaultValue=""
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    id="grouped-select"
                                    onChange={(event) => {

                                        console.log('분류',event.target.value);
                                        setSort(event.target.value);
                                        Axios({
                                            url: "https://sbc.smartbc.kr/sb/band_admin/admin_user_level_search.php",
                                            method: 'get',
                                            params: {
                                                u_id: u_id,
                                                b_id: b_id,
                                                search_text: text,
                                                sort_type: event.target.value,
                                            },
                                        })
                                            .then((result) => {
                                                console.log('정렬',result.data)
                                                // setRefresh(!refresh);
                                                setMember_list(result.data.list);
                                                setLoading(false);
                                                // setMember_list(result.data.list.slice(0,10));
                                                // setItems(result.data.list);


                                            })
                                            .catch((err) => console.log(err.message));
                                    }}
                                >
                                    <MenuItem value="0">
                                        등급
                                    </MenuItem>
                                    <MenuItem value={''}>전체</MenuItem>
                                    <MenuItem value={'1'}>운영자</MenuItem>
                                    <MenuItem value={'2'}>우수회원</MenuItem>
                                    <MenuItem value={'4'}>일반회원</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={7} style={{textAlign: 'left', borderRadius: '40px', position: 'relative', border: '1px solid #B9BFC3'}}>
                            <IconButton edge="center" className={classes.searchIcon}
                            onClick={()=>{
                                //이름 검색
                                Axios({
                                    url: 'https://sbc.smartbc.kr/sb/band_admin/admin_user_level_search.php',
                                    method: 'get',
                                    params: {
                                        u_id: u_id,
                                        b_id: b_id,
                                        search_text:text,
                                        sort_type:sort,
                                    }
                                }).then(function (response) {
                                    // console.log('검색~',response.data);
                                    //     setRefresh(!refresh);
                                    setMember_list(response.data.list);
                                    setLoading(false);
                                    // setMember_list(response.data.list.slice(0,10));
                                    // setItems(response.data.list);


                                }).catch(function (error) {
                                    console.log('error'+error);
                                });
                            }}>
                                <SearchIcon style={{fontSize: 30}}/>
                            </IconButton>
                            <InputBase
                                color='primary'
                                placeholder='검색어를 입력하세요.'
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(event)=>{
                                    setText(event.target.value);
                                    console.log('분류',event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid xs={2} style={{borderRadius: '40px'}}>
                            <Button
                                type="submit"
                                color="primary"
                                style={{backgroundColor: 'rgb(43,66,124)', color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 40, padding: '13px 8px'}}
                                onClick={()=>{
                                    setSort(0);
                                    setText('');
                                    history.push( '/'+match.params.JoinCode+'/AdminSearchDetail2');
                                }}
                            >
                                상세
                            </Button>
                        </Grid>
                    </Grid>
                </div>

                {/* 회원 리스트 */}
                {member_list.map(((value,i) => <MemberList key={i} index ={i}/>))}
                {/*{MemberListUM}*/}

                <div style={{display:'flex',  marginTop:50, position: 'fixed', bottom: 0, left: '50%', transform: 'translateX(-50%)' , width: 444}}>
                {/* 버튼 */}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.bottonBtn}
                    style={{borderRight: '1px solid #fff'}}
                    onClick={()=>{
                        console.log('삭제할 리스트',selectedList);
                        if(selectedList.length==0){
                            customAlert('선택된 회원이 없습니다.');
                            return;
                        }
                        const list = JSON.stringify(selectedList);

                        Axios({
                            method: 'get',
                            url: 'https://sbc.smartbc.kr/sb/band_admin/admin_user_level_update.php',
                            params: {
                                b_id: b_id,
                                list: list,
                                level: 1, //운영자 등급
                            }
                        })
                            .then((result) => {
                                console.log('등급변경',result.data);
                                if(result.data.status==38001){
                                    setRefresh(!refresh);
                                }

                            })
                            .catch((err) => console.log(err.message));
                    }}
                >
                   운영자
                </Button>
                    {/* 버튼 */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.bottonBtn}
                        style={{borderRight: '1px solid #fff'}}
                        onClick={()=>{
                            console.log('삭제할 리스트',selectedList);
                            if(selectedList.length==0){
                                customAlert('선택된 회원이 없습니다.');
                                return;
                            }
                            const list = JSON.stringify(selectedList);

                            Axios({
                                method: 'get',
                                url: 'https://sbc.smartbc.kr/sb/band_admin/admin_user_level_update.php',
                                params: {
                                    b_id: b_id,
                                    list: list,
                                    level: 2, //우수회원 등급
                                }
                            })
                                .then((result) => {
                                    console.log('등급변경',result.data);
                                    if(result.data.status==38001){
                                        setRefresh(!refresh);
                                    }

                                })
                                .catch((err) => console.log(err.message));
                        }}
                    >
                      우수회원
                    </Button>
                    {/* 버튼 */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.bottonBtn}
                        style={{borderRight: '1px solid #fff'}}
                        onClick={()=>{
                            console.log('삭제할 리스트',selectedList);
                            if(selectedList.length==0){
                                customAlert('선택된 회원이 없습니다.');
                                return;
                            }
                            const list = JSON.stringify(selectedList);

                            Axios({
                                method: 'get',
                                url: 'https://sbc.smartbc.kr/sb/band_admin/admin_user_level_update.php',
                                params: {
                                    b_id: b_id,
                                    list: list,
                                    level: 4, //일반회원
                                }
                            })
                                .then((result) => {
                                    console.log('등급변경',result.data);
                                    if(result.data.status==38001){
                                        setRefresh(!refresh);
                                    }

                                })
                                .catch((err) => console.log(err.message));
                        }}>
                        일반회원
                    </Button>
                </div>
                {loading?
                    <>
                        <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                        <div className={classes.popUpBg} />
                    </>
                    :null}
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    searchIcon: {
        zIndex: 10,
        position: 'absolute',
        right: -3, top: '50%',
        transform: 'translateY(-50%)',
        color: 'rgb(43,66,124)',
    },
    inputRoot: {
        color: 'inherit',
        width: '85%'
    },
    inputInput: {
        margin: 3,
        padding: '13px 5px 13px 10px'
    },
    '@global':{
        '.serchSelect .MuiInputBase-root':{
            borderRadius: 40,
        },
        '.MuiSelect-select:focus': {
            borderRadius: 40
        }
    },
    bottonBtn: {
        flex: 1,
        height: 60,
        background: 'rgb(43,66,124)',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight: 'bold',
        '&:hover': {
            background: 'rgb(43,66,124)',
        },
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },

}));

