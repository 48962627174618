
import {createAction, handleActions} from 'redux-actions';

const SETCHECKLIST = 'checkList/SETLIST';


export const setCheckList = createAction(SETCHECKLIST, (input) => {
    return input;
});

const initialState = {
    check_list: [],
};

const checkList = handleActions(
    {
        [SETCHECKLIST]: (state, {payload: input}) => ({
            ...state,
            check_list: input,
        }),
    },
    initialState,
);

export default checkList;
