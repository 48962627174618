import React from "react";
import {Container, CssBaseline, makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import PcHeader from "./PcHeader";
import Icon from '@material-ui/icons/KeyboardArrowRight';
import Grid from "@material-ui/core/Grid";
import RoundButton from "../component/RoundButton";

function JoinAssociation_0() {

    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white',}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'가입 모임 목록'}/>
            {/*body*/}
            <div className={classes.paper} style={{flex: 1,marginLeft:25,marginRight:25}}>
                <div style={{backgroundColor: '#f9fafc', padding: 20, borderRadius: 10, marginTop: 10}}>
                    <div>
                        가입된 모임 목록을 클릭하시면 해당 모임
                    </div>
                    <div>
                        메인 페이지로 이동합니다.
                    </div>
                </div>
                <p/>
                <div style={{
                    border: '2px solid #9da5b9',
                    display: 'flex',
                    padding: 15,
                    alignItems: 'center',
                    borderRadius: 15
                }}>
                    {/*협회 목록 리스트*/}
                    <Grid container style={{alignItems :'center'}}>
                        <Grid item xs={11} style={{textAlign :'left'}}>
                            <img src={require('../resources/logo_icon_update/basic_logo.png')} style={{height: 30}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <Icon style={{fontSize :30}}/>
                        </Grid>
                    </Grid>
                </div>
                    {/*협회 추가 하기 + 버튼*/}
                    <RoundButton styles={{
                        backgroundColor: '#303a53',
                        color: '#fff',
                        fontSize: 15,
                        borderRadius: 25,
                        padding: 10,
                        marginTop : 30
                    }} text={'모임 추가 하기 +'}/>
            </div>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default JoinAssociation_0;
