import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
    ButtonGroup, Avatar,
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import BottomMenu from "./BottomMenu";
import Axios from "axios";

function CommunitySecond({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    const stored_partner_id = sessionStorage.getItem('@partner_id');
    const [community_list,setCommunity_list] = React.useState([]);


    const mother_type = history.location.state.mother_type;
    const [mother_name,setMother_name] = React.useState('부가서비스');

    useEffect(()=>{
        Axios({
            url: 'https://sbc.smartbc.kr/sb/community_list_second.php',
            method: 'get',
            params: {
                mother_type: mother_type
            },
        })
            .then((result) => {
                console.log('두번째 부가',result.data);
                setMother_name(result.data.mother_name);
                setCommunity_list(result.data.second_list);
                return;
            })
            .catch((err) => console.log(err.message));
    },[]);

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={mother_name} back={1} history={history} match={match}/>

                <Grid container direction='row' style={{ marginTop: 70,width:'100%', paddingHorizontal:'5%', flexWrap : 'wrap',flexDirection:'row'}}>


                    {community_list.length > 0 ? (
                        community_list.map((value,i)=>{
                            return(

                                <Grid style={{margin:5,width:'30%',height:100}}
                                    // style={{width: '100%', height: 100, padding: 10, border: '1px solid rgb(174,181,186)', borderRadius: 10, marginBottom: 20}}
                                      onClick={()=>{
                                              if (community_list[i].cm_link) {
                                                  if (community_list[i].cm_link.indexOf('info.smartbc.kr/partner') != -1) {
                                                      if (stored_partner_id) {
                                                          history.push('PartnerMyInfo');
                                                          return;
                                                      } else {
                                                          history.push('PartnerSignUp');
                                                          return;
                                                      }

                                                  }
                                                  if (community_list[i].cm_link.indexOf('http://') != -1 ||
                                                      community_list[i].cm_link.indexOf('https://') != -1) {
                                                      //문자열 포함
                                                      window.open('about:blank').location.href = community_list[i].cm_link;
                                                  } else {
                                                      window.open('about:blank').location.href = 'https://' + community_list[i].cm_link;
                                                  }
                                              }//주소값 여부


                                      }}>
                                    <div container alignItems='center' direction='row'  style={{width:'100%', height:'100%',border:'1px solid #aeb5ba', borderRadius:10, flexWrap : 'wrap',padding: 3, alignItems:'center'}}>
                                        <img src={"https://sbc.smartbc.kr" + community_list[i].cm_image}
                                             style={{width: '100%', height: '100%', borderRadius:10}} />
                                    </div>
                                </Grid>) })
                    ) : null}

                </Grid>

                {/*<BottomMenu/>*/}
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 60px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.2,
        height: '40px',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        padding: 2.5,
    }

}));


export default CommunitySecond;
