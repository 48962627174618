import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Grid,
    ListItem, Button, Switch, IconButton

} from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";
import {func} from "prop-types";
function Setting({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    const partner_id = sessionStorage.getItem('@partner_id');

    const [autologin, setAutologin] = React.useState(false);
    const [push, setPush] = React.useState(false);
    const [autoAccept, setAutoAccept] = React.useState(false);
    const [phoneOpen, setPhoneOpen] = React.useState(false);
    const [emailOpen, setEmailOpen] = React.useState(false);

    /*스위치*/
    const [state, setSwitch] = React.useState({
        login: true,
        push: true,
        autoSubmit: true,
        cellphoneOpen: true,
        emailOpen: true,
    });

    const [userLevel, setUserLevel] = React.useState(4);
    const [admin_id, setAdmin_id] = React.useState(0);

    function aa() {
        history.pushState(null, document.title, window.location.href);  // push
        window.addEventListener('popstate', function(event) {    //  뒤로가기 이벤트 등록
            // 특정 페이지로 가고싶다면 location.href = '';
            history.push('/PcLogin');
            // history.pushState(null, document.title, location.href);  // 다시 push함으로 뒤로가기 Block
            return;
        });
    }

    useEffect(() => {

        Axios({
            url: 'https://sbc.smartbc.kr/sb/get_setting.php',
            method : 'get',
            params : {
                u_id : u_id,
                b_id : b_id
            }
        })
            .then(function (res) {
                console.log(res.data);
                console.log('data[allim]' + res.data['allim']);
                setUserLevel(res.data['user_level']);
                setAdmin_id(res.data['admin_id']);

                (res.data['auto_login'] == 'Y' ? setAutologin(true) : setAutologin(false) );
                (res.data['allim'] == 'Y' ? setPush(true) : setPush(false) );
                (res.data['auto_change'] == 'Y' ? setAutoAccept(true) : setAutoAccept(false) );
                (res.data['open_phone'] == 'Y' ? setPhoneOpen(true) : setPhoneOpen(false) );
                (res.data['open_email'] == 'Y' ? setEmailOpen(true) : setEmailOpen(false) );

                console.log("res.data['auto_login']=" + autologin);
                console.log("res.data['allim']=" + push);
                console.log("res.data['auto_change']" + autoAccept);
                console.log("res.data['open_phone']" + phoneOpen);
                console.log("res.data['open_email']" + emailOpen);
            })
            .catch(function (e) {
                console.log(e);
            })
    },[]);

    const switchHandle = (event) => {
        setSwitch({ ...state, [event.target.name]: event.target.checked});
        console.log('target name');
        console.log(event.target.name);
        let col;
        let col_val;
        switch (event.target.name) {
            case 'push' :
                col = 'allim';
                col_val = !push ? 'Y' : 'N';
                setPush(!push);
                break;
            case 'autoSubmit' :
                col = 'auto_change';
                col_val = !autoAccept ? 'Y' : 'N';
                setAutoAccept(!autoAccept);
                break;
            case 'cellphoneOpen' :
                col = 'open_phone';
                col_val = !phoneOpen ? 'Y' : 'N';
                setPhoneOpen(!phoneOpen);
                break;
            case 'emailOpen' :
                col = 'open_email';
                col_val = !emailOpen ? 'Y' : 'N';
                setEmailOpen(!emailOpen);
                break;
        }
        Axios({
            url : 'https://sbc.smartbc.kr/sb/set_setting.php' ,
            method : 'get',
            params : {
                u_id : u_id,
                col : col,
                col_val : col_val
            },
        })
            .then(function (res) {
                console.log(res.data);
            })
            .catch(function (e) {
                console.log(e);
            })
    };
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '30px'}}>
                <CssBaseline/>
                <PcHeader text={'설정'} back={1} history={history}/>
                <div className={classes.paper}>
                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        회원 관련 설정
                    </Typography>
                    <div style={{width: '100%'}}>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}} onClick={() => {
                            sessionStorage.removeItem('@partner_id');
                            history.replace('/'+ match.params.JoinCode +'/PcLogin');
                            // aa();
                            // history.pushState(null, document.title, location.href);  // push
                            // window.addEventListener('popstate', function(event) {    //  뒤로가기 이벤트 등록
                            //     // 특정 페이지로 가고싶다면 location.href = '';
                            //     history.push('/PcLogin');
                            //     // history.pushState(null, document.title, location.href);  // 다시 push함으로 뒤로가기 Block
                            //
                            // });
                            sessionStorage.removeItem('@u_id');
                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>로그아웃</Typography>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>

                        {/*<Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}} onClick={() => {*/}
                        {/*    let aa = confirm('정말 탈퇴 하시겠습니까?');*/}
                        {/*    if(aa) {*/}
                        {/*        Axios({*/}
                        {/*            url : 'https://sbc.smartbc.kr/sb/delete_user.php',*/}
                        {/*            method : 'get',*/}
                        {/*            params : {*/}
                        {/*                u_id : u_id,*/}
                        {/*                b_id : b_id,*/}
                        {/*            }*/}
                        {/*        }).then(function (res) {*/}
                        {/*            console.log('user delete success');*/}
                        {/*            console.log(res.data);*/}
                        {/*            if(res.data == 200){*/}
                        {/*                try {*/}
                        {/*                    sessionStorage.removeItem('@u_id');*/}
                        {/*                } catch(e) {*/}
                        {/*                    // remove error*/}
                        {/*                }*/}
                        {/*                alert('회원탈퇴를 완료하였습니다.');*/}
                        {/*                history.replace('/' + match.params.JoinCode + '/PcLogin');*/}
                        {/*                // navigation.reset({index: 0, routes: [{name: 'SignUp'}]});*/}
                        {/*            }else{*/}
                        {/*                alert('회원탈퇴를 실패하였습니다. 관리자에게 문의부탁드립니다.');*/}
                        {/*            }*/}
                        {/*        }).catch(function (e) {*/}
                        {/*            console.log('user delete fail');*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*}}>*/}
                        {/*    <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>회원탈퇴</Typography>*/}
                        {/*    <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>*/}
                        {/*</Button>*/}

                        {/*<Grid color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}>*/}
                        {/*    <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)', lineHeight: '57px'}}>자동로그인</Typography>*/}
                        {/*    <div  style={{right: 0}} className={classes.yCenter}>*/}
                        {/*        <Switch*/}
                        {/*        checked={state.login}*/}
                        {/*        onChange={switchHandle}*/}
                        {/*        color="primary"*/}
                        {/*        name="login"*/}
                        {/*        inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                        {/*    />*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                    </div>

                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        회원 관련 설정
                    </Typography>
                    <div style={{width: '100%'}}>
                        <div style={{width: '100%'}}>
                            <Grid color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}>
                                <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)', lineHeight: '57px'}}>푸쉬알림</Typography>
                                <div  style={{right: 0}} className={classes.yCenter}>
                                    <Switch
                                        checked={push}
                                        onChange={switchHandle}
                                        color="primary"
                                        name="push"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </div>
                            </Grid>
                        </div>
                        <div style={{width: '100%'}}>
                            <Grid color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}>
                                <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)', lineHeight: '57px'}}>명함 교환 자동 수락</Typography>
                                <div  style={{right: 0}} className={classes.yCenter}>
                                    <Switch
                                        checked={autoAccept}
                                        onChange={switchHandle}
                                        color="primary"
                                        name="autoSubmit"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </div>
                            </Grid>
                        </div>
                        <div style={{width: '100%'}}>
                            <Grid color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}>
                                <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)', lineHeight: '57px'}}>명함 교환시 휴대폰번호 공개</Typography>
                                <div  style={{right: 0}} className={classes.yCenter}>
                                    <Switch
                                        checked={phoneOpen}
                                        onChange={switchHandle}
                                        color="primary"
                                        name="cellphoneOpen"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </div>
                            </Grid>
                        </div>
                        <div style={{width: '100%'}}>
                            <Grid color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}>
                                <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)', lineHeight: '57px'}}>명함 교환시 이메일 공개</Typography>
                                <div  style={{right: 0}} className={classes.yCenter}>
                                    <Switch
                                        checked={emailOpen}
                                        onChange={switchHandle}
                                        color="primary"
                                        name="emailOpen"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </div>
                            </Grid>
                        </div>


                    </div>

                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        고객 지원 센터
                    </Typography>
                    <div style={{width: '100%'}}>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}} onClick={() => {
                            // history.push('https://pf.kakao.com/_HXWYT');
                            // history.push('/kakao');
                            window.open('https://pf.kakao.com/_HXWYT','_blank');
                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>1:1문의(카카오톡)</Typography>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                        <Button onClick={() => {
                            history.push('/'+match.params.JoinCode+'/Advertige');
                        }}
                            color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>제휴 & 동영상 광고 문의</Typography>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}} onClick={()=>{

                            if(userLevel == 1){
                                sessionStorage.setItem('@u_id_a', admin_id);//관리자아이디
                                sessionStorage.setItem('@b_id_a', b_id);//그룹번호
                                history.replace('/' + match.params.JoinCode + '/AdminMain');
                            }else{
                                history.push({
                                    pathname : '/'+match.params.JoinCode+'/AdminLogin',
                                    state : {autoLogin:true}
                                });
                            }


                        }}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)',}}>모임 관리자 로그인</Typography>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}} onClick={()=>{
                            if(partner_id){
                                history.push('/'+match.params.JoinCode+'/PartnerMyInfo');
                            }else{
                                history.push('/'+match.params.JoinCode+'/PartnerSignUp');
                            }

                        }}>
                            <Typography align='left' style={{textIndent: 20, color: '#303a53',}}>자문단 & 파트너 로그인</Typography>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '0 40px 20px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: 14,
    },
    listBox: {
        display: 'block',
        height: '57px',
        border: '2px solid rgb(157,165,185)',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));


export default Setting;
