import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    TextField,
    FormControl,
    MenuItem,
    Select,
    Avatar,
    Grid, Chip, IconButton, CircularProgress, Switch, InputBase
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";
import { AiFillPlusCircle } from "react-icons/ai";
import { IoIosCamera } from "react-icons/io";
import Badge from '@material-ui/core/Badge';
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from '@material-ui/icons/Remove';
import customAlert from "../component/customAlert";
import customConfirm from "../component/customConfirm";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


function AddComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}

export default function PartnerMyInfo({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    const partner_id = sessionStorage.getItem('@partner_id');

    const [name, setName] = React.useState();
    const [phone, setPhone] = React.useState();
    const [position, setPosition] = React.useState();
    const [profile, setProfile] = React.useState();
    const [company, setCompany] = React.useState();
    const [point_total, setPoint_total] = React.useState();
    const [card_regist, setCard_regist] = React.useState();
    const [logo, setLogo] = React.useState();

    const [recommendationCnt, setRecommendationCnt] = React.useState();
    let item_consulting = [];
    const [opList_consulting , setOpList_consulting] = React.useState([]);
    const [consultingType , setConsultingType] = React.useState('');
    const [consultingIntro , setConsultingIntro] = React.useState();
    const [consultingConfirm , setConsultingConfirm] = React.useState();
    const [referrerCode , setReferrerCode ] = React.useState();
    const [partnerLink , setPartnerLink] = React.useState();
    let postData = {};

    let tmp_data = {};
    let update_data = {};
    /////////////////////////////////////////
    const [modal, setModal] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);

    const [imageSource,setImageSource] = React.useState(null);
    const [upfile, setUpfile] = React.useState('');
    const formData = new FormData();
    let fileReader = new FileReader();
    const [refresh, setRefresh] = React.useState(false);

    const [loading, setLoading] = React.useState(true);

    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        window.open("https://info.smartbc.kr/b2a");
    };

    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

    useEffect(() => {
        console.log('모바일?',isMobile);
        window.scrollTo(0,0);
        Axios({
            url: 'https://sbc.smartbc.kr/sb/myInfo.php',
            method: 'get',
            params: {
                u_id: u_id,
                b_id: b_id,
                partner_id:partner_id,
            },
        })
            .then((result) => {

                    console.log('마이인포', result.data.consulting_list);
                setOpList_consulting(result.data.consulting_list);
                setConsultingType(result.data.consulting_type);
                setRecommendationCnt(result.data.recommendation_row);
                setConsultingIntro(result.data.consulting_intro);
                setConsultingConfirm(Number(result.data.consulting_confirm));
                setReferrerCode(result.data.referrer_code);
                setPartnerLink(result.data.partner_link);


                    setPhone( result.data.phone);
                    setName( result.data.name);
                    setPosition( result.data.position);
                    setCompany (result.data.company);
                    setPoint_total (result.data.point_total);
                    setProfile( result.data.profile);
                    setCard_regist( result.data.card_regist);
                    setLogo(result.data.logo);


                setLoading(false);
                console.log('어ㅣㅇ나ㅓㅇㄹ니ㅏ',consultingType)

            })
            .catch((err) => console.log(err.message));

    },[refresh]);


    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                {loading?
                    <>
                        <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                        <div className={classes.popUpBg} />
                    </>
                    :null}
                <CssBaseline/>
                <PcHeader text={'파트너'} back={1} history={history} match={match}/>
                <div className={classes.paper} style={{paddingTop:5,paddingBottom:60}}>

                    {/*custom_confirm*/}
                    <div>
                        <Dialog
                            open={confirmShow}
                            onClose={handleCloseN}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {confirmMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseN} color="primary">
                                    {'아니오'}
                                </Button>
                                <Button onClick={handleCloseY} color="primary" autoFocus>
                                    {'예'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    {/* 상단 정보 */}
                    <Grid container alignItems='center'direction='row' style={{ padding: '20px 40px',boxShadow:  '0px 5px 10px 0px rgba(0,0,0,0.15)',}}>
                        <Grid item xs={2} style={{marginRight: 10}}>
                            <Badge
                                className='camera_badge'
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={<IoIosCamera style={{color: 'white',marginLeft:1,fontSize: 20}}  />}
                                component="label" // 이거 써야 <input/> 가능
                                onClick={()=>{
                                    if(navigator.platform){
                                        if(isMobile){
                                            customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다.");//pc
                                        }
                                    }
                                }}
                            >
                                {!isMobile ?
                                    <input id={"file-input"} style={{display: 'none'}} type="file" name="file-input"
                                           accept={"image/gif,image/jpeg,image/png,image/heic,image/HEIC"}
                                           onChange={(event) => {

                                               //취소가 아닐 때 (확인 눌렀을 때)
                                               if (event.target.value != '') {
                                                   // 읽기
                                                   fileReader.readAsDataURL(event.target.files[0]);
                                                   //로드 한 후
                                                   fileReader.onload = function () {
                                                       // console.log('이미지?',fileReader.result);
                                                       //로컬 이미지를 보여주기
                                                       setImageSource(fileReader.result);

                                                   };

                                                   // console.log('파일경로',event.target.files[0]);
                                                   let fileVal = event.target.files[0];
                                                   let fileName = event.target.value.split('\\').pop();
                                                   setModal(true);
                                                   setUpfile(fileVal);

                                                   //파일 확장자 대문자이면 인식 못 함,,,(ex.PNG(x),png(o))

                                               }//if(event.target.value!='')

                                               console.log('파일입니다.', event.target.value);
                                           }}/>
                                           :null}


                            {profile?
                                <Avatar alt="사진" src={"https://sbc.smartbc.kr"+profile} className={classes.large}/>
                                :
                                <Avatar alt="사진" src={require('../resources/profile_icon.png')} className={classes.large}/>
                            }
                            </Badge>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                        {name}<span style={{fontSize: 11, color: 'rgb(181,181,181)', marginLeft: 5}}>{position}</span>
                                    </Typography>
                                    <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                        {company}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} >
                            {card_regist == 'N' ?
                                <AiFillPlusCircle size={45} style={{marginTop:10}} onClick={() => {
                                    history.push('/'+match.params.JoinCode+'/MyCardRegist');
                                    // history.push('/MyCardRegist');
                                }} />
                                :
                                <Button
                                    type="submit"
                                    color="primary"
                                    style={{
                                        backgroundColor: 'rgb(48,58,83)',
                                        color: '#fff',
                                        fontWeight: 'bold',
                                        fontSize: 15,
                                        borderRadius: 25,
                                        padding: 10,
                                        marginLeft: 10
                                    }}
                                    onClick={() => {
                                        history.push('/'+match.params.JoinCode+'/MyCardDetail');
                                    }}>
                                    수정
                                </Button>
                            }
                        </Grid>
                    </Grid>

                    <div style={{width: '100%', padding: '0 40px',}}>
                        <Typography
                            style={{fontSize: '13px', backgroundColor: '#f2f2f2', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                            스마트APP 추천 현황
                        </Typography>
                        <div style={{width: '100%'}}>
                            <Grid container alignItems='center' color="primary" className={classes.listBox}   onClick={()=>{
                                history.push( '/'+match.params.JoinCode+'/PartnerPointList');
                            }}>
                                <Grid xs={6}><Typography align='left'>포인트 잔액</Typography></Grid>
                                <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{point_total ? AddComma(point_total) : '00'}</span>P</Typography></Grid>
                            </Grid>

                            <Grid container alignItems='center' color="primary" className={classes.listBox}   onClick={()=>{
                                history.push( '/'+match.params.JoinCode+'/PartnerRefJoinList');
                            }}>
                                <Grid xs={6}><Typography align='left'>스마트APP 추천 가입</Typography></Grid>
                                <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{recommendationCnt ? AddComma(recommendationCnt) : '00'}</span>명</Typography></Grid>
                            </Grid>

                            <Grid container alignItems='center' color="primary" className={classes.listBox}   onClick={()=>{
                                // history.push( '/'+match.params.JoinCode+'/PartnerNonApprove');
                                if(navigator.platform){
                                    if(isMobile){
                                        setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                        setConfirmShow(true);
                                        // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                        // if (answer) window.open("https://info.smartbc.kr/b2a");
                                    }else{
                                        customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                    }
                                }
                            }}>
                                <Grid xs={6}><Typography align='left'>스마트 APP 추천하기</Typography></Grid>
                                <Grid xs={6}>
                                    <Typography align='right'>
                                    <img src={require('../resources/more_arrow.png')} style={{color: 'rgb(239,113,113)', height: 15}} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>

                        <Typography style={{fontSize: '13px', backgroundColor: '#f2f2f2', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                            자문단 분야
                        </Typography>
                        <div style={{width: '100%'}}>
                            <Grid container alignItems='center' color="primary" className={classes.listBox} >
                                    <Grid xs={4}><Typography align='left'>신청분야</Typography></Grid>
                                    <Grid xs={2}><Typography align='left'><RemoveIcon style={{color: 'black', fontSize: 15}} /></Typography></Grid>
                                    <Grid xs={6} style={{height:57,}}>
                                        <FormControl variant="filled"  style={{
                                            width: '100%',
                                            textAlign: 'left',
                                            textIndent: 10,
                                        }} className="formControl">
                                        <Select
                                            className="_input"
                                            // displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            style={{backgroundColor: '#fff',}}
                                            // classes={{root: classes.selectInput}}
                                            value={consultingType}
                                            onChange={(event) => {
                                                setConsultingType(event.target.value);
                                                console.log('선택', event.target.value);
                                                // setTmpValue(event.target.value);
                                                // let tmp = selectedList;
                                                // tmp[option1.id] = event.target.value;
                                                // setSelectedList(tmp);
                                            }}
                                        >
                                            <option value="0">선택</option>
                                            {opList_consulting.length>0?(
                                                opList_consulting.map((value,i)=> {
                                                    return (
                                                        <option key={value.id} value={value.id} >{value.name}</option>
                                                    )
                                                })):null}
                                        </Select>
                                    </FormControl>
                                    </Grid>


                            </Grid>

                            <InputBase item xs={20} style={{ width: 'inherit',border: '2px solid #303a53', borderRadius: 10, height: 100,padding:15}}
                                       multiline={true}
                                       rows={3}
                                       placeholder={'모임에 노출한 소개 글을 입력해 주세요!!!'}
                                       value={consultingIntro}
                                       onChange={(event)=>{
                                           if(event.target.value.length<50){
                                               setConsultingIntro(event.target.value);
                                           }
                                       }}
                            />

                            <Typography
                                style={{fontSize: '13px', backgroundColor: '#303a53', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10,color:'white'}}
                                onClick={()=>{
                                    if (consultingType == '' || consultingType == null) {
                                        customAlert('신청분야를 선택해주세요!');
                                        return;
                                    } else if (consultingIntro == '' || consultingIntro == null) {
                                        customAlert('소개글을 작성해주세요!');
                                        return;
                                    }
                                    postData['partner_id'] = partner_id;
                                    postData['consulting_confirm'] = consultingConfirm;
                                    postData['consulting_intro'] = consultingIntro;
                                    postData['consulting_type'] = consultingType;
                                    console.log('partner_postData : ', postData);

                                    Axios({
                                        url: 'https://sbc.smartbc.kr/sb/consulting_update.php',
                                        method: 'get',
                                        params: {
                                            postData: postData,
                                        },
                                    })
                                        .then((result) => {
                                            customAlert(result.data.result_msg);
                                            // setRefresh(!refresh);
                                            console.log('자문단', result.data);

                                        })
                                        .catch((err) => console.log(err.message));
                            }}>
                                {consultingConfirm ? '자문단 소개글 수정하기' : '자문단 신청 & 수정'}
                            </Typography>
                        </div>

                        <Typography
                            style={{fontSize: '13px', backgroundColor: '#f2f2f2', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                           자문단 활동 현황
                        </Typography>
                        <div style={{width: '100%'}}>
                            <Grid container alignItems='center' color="primary" className={classes.listBox}   onClick={()=>{
                                // history.push( '/'+match.params.JoinCode+'/AdminPointList');
                                customAlert('준비중 입니다.');
                            }}>
                                <Grid xs={6}><Typography align='left'>MY 분야 질문 게시판</Typography></Grid>
                                <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{'00'}</span>건</Typography></Grid>
                            </Grid>

                            <Grid container alignItems='center' color="primary" className={classes.listBox}   onClick={()=>{
                                // history.push( '/'+match.params.JoinCode+'/AdminPointList');
                                customAlert('준비중 입니다.');
                            }}>
                                <Grid xs={6}><Typography align='left'>MY 명함 열람 회원</Typography></Grid>
                                <Grid xs={6}><Typography align='right'><span style={{color: 'rgb(239,113,113)', marginRight: 5}}>{'00'}</span>명</Typography></Grid>
                            </Grid>
                        </div>

                    </div>

                </div>
                {modal?
                    <>
                        <Card className={classes.popUp}>
                            <Typography align='right'>
                                <IconButton>
                                    <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                                        setModal(false);
                                    }}/>
                                </IconButton>
                            </Typography>
                            <CardContent style={{paddingTop: 5}}>
                                <Typography>
                                    <span style={{display: 'block',transform: 'translateX(-50%)',
                                        left: '50%',
                                        position: 'relative',
                                        width: '70px',
                                        height: '70px',
                                        borderRadius: '50%',
                                        overflow: 'hidden'}}>
                                        <img src={imageSource} style={{maxWidth: '100px',height: 'auto',
                                            position: 'absolute',
                                            top: '50%',
                                            width: '90px',
                                            left: '50%',
                                            transform:'translate(-50%, -50%)'}}/>
                                    </span>
                                </Typography>
                                <Typography style={{marginTop: 20}}>프로필을 등록하시겠습니까?</Typography>
                            </CardContent>
                            <Button
                                color="primary"
                                className={classes.groupAdd}
                                style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :8, marginBottom: 15}}
                                onClick={() => {
                                    formData.append("profile", upfile);
                                    Axios({
                                        method: 'POST',
                                        url: 'https://sbc.smartbc.kr/sb/upload_image_file.php',
                                        data: formData,
                                        params: {
                                            u_id:u_id,
                                        },
                                        headers: {
                                            'Content-Type': 'multipart/form-data;'
                                        }})
                                        .then((result) => {
                                            customAlert(result.data);
                                        })
                                        .catch((err) => console.log(err.message));

                                    setModal(false);
                                    setRefresh(!refresh);
                                    history.go(0);
                                }}>
                                등록하기
                            </Button>
                            <Button
                                color="primary"
                                className={classes.groupAdd}
                                style={{width: 200, backgroundColor: 'white', color: '#303a53',fontWeight:'bold',fontSize : 15,borderRadius : 25,  border: '2px solid #303a53', marginBottom: 20}}
                                onClick={() => {
                                    setModal(false);
                                }}>
                                취소
                            </Button>
                        </Card>
                        <div className={classes.popUpBg} />
                    </>
                    : null}

            </Container>
        </div>

    )
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        // padding: '30px 40px 60px',
        // padding: '0 40px 20px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    title: {
        fontSize: 14,
    },
    selectTilie: {
        fontSize: 15,
        textAlign: 'center',
        marginTop: theme.spacing(4),
        backgroundColor: 'rgb(249,250,252)',
        width: 105,
        height: 60,
        lineHeight: '60px',
        borderRadius: 10,
        marginRgiht: 10
    },
    selectInput:{
        padding: '23px 12px 20px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    bottonBtn: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 60,
        background: '#303a53',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight:'bold',
        '&:hover': {
            background: '#303a53',
        },

    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    "@global":{
        ".camera_badge .MuiBadge-badge":{
            padding: 3,
            borderRadius: '50%',
            backgroundColor: 'rgb(48,58,83)',
            width: 27,
            height: 27
        },
        "._input .MuiFilledInput-input":{
            padding:17
        },
        ".formControl .MuiFilledInput-underline:before":{
            borderBottom:0
        }

    },
    // listBox: {
    //     display: 'block',
    //     height: '57px',
    //     border: '2px solid rgb(157,165,185)',
    //     borderRadius: '10px',
    //     marginBottom: '10px',
    // },
    listBox: {
        height: '57px',
        border: '2px solid #303a53',
        borderRadius: '10px',
        marginBottom: '10px',
        padding: '0 20px',
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },


}));



