import React, {useEffect} from "react";
import {
    CssBaseline, CircularProgress,
    makeStyles,
    Container,
    Typography,
    Button,
    Badge,
    Toolbar, IconButton, AppBar,
    BottomNavigation,
    List, ListItem, ListItemIcon, Divider, InputBase
}
    from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import navBg from '../resources/main_home_icon/main_bottom_pc.png';
import {useDispatch, useSelector} from "react-redux";
import Axios from "axios";
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import {Helmet} from "react-helmet";
import GetAppIcon from '@material-ui/icons/GetApp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MetaTags from 'react-meta-tags';
import customAlert from "../component/customAlert";
import customConfirm from "../component/customConfirm";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


function AddComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}

function getCookie(name) {
    // customAlert('쿠키!',document.cookie);
    let cookie = document.cookie;
    if (document.cookie != "") {

        let cookie_array  = cookie.split("; ");
        for ( let index in cookie_array) {
            let cookie_name = cookie_array[index].split("=");
            if (cookie_name[0] == "popupYN") {
                return cookie_name[1];
            }
        }
        // let x, y;
        // let val  = cookie.split("; ");
        // for (let i = 0; i < val.length; i++) {
        //     x = val[i].substr(0, val[i].indexOf('='));
        //     y = val[i].substr(val[i].indexOf('=') + 1);
        //     x = x.replace(/^\s+|\s+$/g, ''); // 앞과 뒤의 공백 제거하기
        //     if (x == name) {
        //         return unescape(y); // unescape로 디코딩 후 값 리턴
        //     }
        // }
    }
    return ;
}




function MainHome({history,match}) {

    const dispatch = useDispatch();
    // sessionStorage.setItem('@b_id',1+'');
    // sessionStorage.setItem('@u_id',1+'');
    const stored_uid = sessionStorage.getItem('@u_id');
    const stored_bid = sessionStorage.getItem('@b_id');
    const stored_partner_id = sessionStorage.getItem('@partner_id');

    useEffect(() => {
        sessionStorage.setItem('@admin','0');
    })

    //그룹 관리자 여부
    const admin = sessionStorage.getItem('@admin');

    // console.log('-------------');
    // console.log('session value');
    // console.log('admin = '+ admin);
    // console.log('-------------');

    const classes = useStyles();
    const newsTitle = `제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목`;
    const writeDay = `2020.00.00 12:00:00`;

    const varUA = navigator.userAgent.toLowerCase();
    const [modal, setModal] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);
    const [card_regist, setCard_regist] = React.useState('N');
    const [total_member_cnt, setTotal_member_cnt] = React.useState(0);
    const [company_cnt, setCompany_cnt] = React.useState(0);
    const [connected_member_cnt, setConnected_member_cnt] = React.useState(0);
    const [news_cnt, setNews_cnt] = React.useState(0);
    const [cooperation_cnt, setCooperation_cnt] = React.useState(0);
    const [free_board_cnt, setFree_board_cnt] = React.useState(0);
    const [community_cnt, setCommunity_cnt] = React.useState(0);
    const [logo, setLogo] = React.useState('');
    const [banner, setBanner] = React.useState('');
    const [b_link, setB_link] = React.useState('');
    const [news_list, setNews_list] = React.useState([]);
    const [community_list, setCommunity_list] = React.useState([]);
    const [user_name, setUser_name] = React.useState('');
    const [band_name, setBand_name] = React.useState('');
    const [join_code, setJoin_code] = React.useState('');
    const [url, setUrl] = React.useState('');
    const [b_link_allow,setB_link_allow] = React.useState(0);
    const [aCount,setAcount] = React.useState(0);
    const [JoinStatus,setJoinStatus] = React.useState(false);
    const linkUrl = 'http://group.smartbc.kr/'+match.params.JoinCode;
    const [group_intro, setGroup_intro] = React.useState();

    let background = <div className={classes.popUpBg} />;

    const[menuName1,setMenuName1]=React.useState('회원 명부');
    const[menuName2,setMenuName2]=React.useState('회원 기업');
    const[menuName3,setMenuName3]=React.useState('공지사항');
    const[menuName4,setMenuName4]=React.useState('게시판1');
    const[menuName5,setMenuName5]=React.useState('게시판2');
    const[menuName6,setMenuName6]=React.useState('1 : 1 메시지');

    const[menuImage1,setMenuImage1]=React.useState('');
    const[menuImage2,setMenuImage2]=React.useState('');
    const[menuImage3,setMenuImage3]=React.useState('');
    const[menuImage4,setMenuImage4]=React.useState('');
    const[menuImage5,setMenuImage5]=React.useState('');
    const[menuImage6,setMenuImage6]=React.useState('');

    const [phone,setPhone] = React.useState('');
    const [notiCnt,setNotiCnt] = React.useState(0);

    const[userProfile,setUserProfile] = React.useState('');


    const [loading, setLoading] = React.useState(true);

    console.log('main group id');
    console.log(match.params.JoinCode);

    const [groupInfo,setGroupInfo] = React.useState(new Array());

    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        window.open("https://info.smartbc.kr/b2a");
    };

    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
    console.log('모바일?',isMobile);

    useEffect(() => {

        function firstAxios() {
            return Axios({
                url : 'https://sbc.smartbc.kr/sb/band_user_list.php',
                method : 'get',
                params : {
                    u_id : match.params.JoinCode // 초대코드가 아닌 관리자 아이디 기준으로 그룹별 url 생성
                },
            }).then(function (res) {
                console.log('route success');
                console.log(res.data);
                sessionStorage.setItem('@b_id',res.data.id);
                return res.data;
            }).catch(function (e) {
                console.log('route fail');
            })

        }
        async function startAxios(){
            try {
                const groupInfo = await firstAxios();
                Axios({
                    url: stored_uid ? 'https://sbc.smartbc.kr/sb/mainInfo.php' : 'https://sbc.smartbc.kr/sb/mainInfo2.php',
                    method: 'get',
                    params: {
                        // u_id: stored_uid ? stored_uid : 185,
                        u_id : stored_uid ? stored_uid : 'a',
                        // b_id: stored_bid ? stored_bid : 1,
                        b_id : groupInfo.id
                    },
                    // resultType:'text',
                })
                    .then((result) => {

                        console.log('storr', stored_uid);
                        if (result.data.status == 6001) {
                            console.log('result', JSON.parse(result.data.menu_name).length);
                            console.log('메인',result.data);
                            setCard_regist(result.data.card_regist);
                            setTotal_member_cnt(result.data.total_member_cnt);
                            setCompany_cnt(result.data.company_cnt)
                            setConnected_member_cnt(result.data.connected_member_cnt);
                            setNews_cnt(result.data.news_cnt);
                            setCooperation_cnt(result.data.cooperation_cnt);
                            setFree_board_cnt(result.data.free_board_cnt);
                            setLogo(result.data.logo);
                            setBanner(result.data.banner);
                            setB_link(result.data.b_link);
                            setNews_list(result.data.news_list);
                            setCommunity_list(result.data.community_list);
                            setUser_name(result.data.user_name);
                            setBand_name(result.data.band_name);
                            setJoin_code(result.data.join_code);
                            setB_link_allow(result.data.b_link_allow);
                            setCommunity_cnt(result.data.community_list.length);
                            setGroup_intro(result.data.group_intro);

                            setUserProfile(result.data.user_profile);
                            setPhone(result.data.phone);
                            // setNotiCnt(result.data.notification.length);

                            if(JSON.parse(result.data.menu_name).length>0){
                                setMenuName1(JSON.parse(result.data.menu_name)[0]);
                                setMenuName2(JSON.parse(result.data.menu_name)[1]);
                                setMenuName3(JSON.parse(result.data.menu_name)[2]);
                                setMenuName4(JSON.parse(result.data.menu_name)[3]);
                                setMenuName5(JSON.parse(result.data.menu_name)[4]);
                                setMenuName6(JSON.parse(result.data.menu_name)[5]);
                                // setMenuName1(JSON.parse(result.data.menu_name)[0].replace(/(.{10})/g,"$1\n") );
                                // setMenuName2(JSON.parse(result.data.menu_name)[1].replace(/(.{10})/g,"$1\n") );
                                // setMenuName3(JSON.parse(result.data.menu_name)[2].replace(/(.{10})/g,"$1\n") );
                                // setMenuName4(JSON.parse(result.data.menu_name)[3].replace(/(.{10})/g,"$1\n") );
                                // setMenuName5(JSON.parse(result.data.menu_name)[4]).replace(/(.{10})/g,"$1\n") ;
                                // setMenuName6(JSON.parse(result.data.menu_name)[5].replace(/(.{10})/g,"$1\n") );
                            }
                            if(JSON.parse(result.data.menu_images).length>0){
                                setMenuImage1(JSON.parse(result.data.menu_images)[0]);
                                setMenuImage2(JSON.parse(result.data.menu_images)[1]);
                                setMenuImage3(JSON.parse(result.data.menu_images)[2]);
                                setMenuImage4(JSON.parse(result.data.menu_images)[3]);
                                setMenuImage5(JSON.parse(result.data.menu_images)[4]);
                                setMenuImage6(JSON.parse(result.data.menu_images)[5]);
                            }

                            if(result.data.JoinStatus) setJoinStatus(true);

                        } else if(result.data.status == 6003) {
                            console.log('메인',result.data);
                            setTotal_member_cnt(result.data.total_member_cnt);
                            setNews_cnt(result.data.news_cnt);
                            setCooperation_cnt(result.data.cooperation_cnt);
                            setFree_board_cnt(result.data.free_board_cnt);
                            setLogo(result.data.logo);
                            setBanner(result.data.banner);
                            setB_link(result.data.b_link);
                            setNews_list(result.data.news_list);
                            setCommunity_list(result.data.community_list);
                            setBand_name(result.data.band_name);
                            setJoin_code(result.data.join_code);
                            setCommunity_cnt(result.data.community_list.length);
                            setGroup_intro(result.data.group_intro);

                            if(JSON.parse(result.data.menu_name).length>0){
                                setMenuName1(JSON.parse(result.data.menu_name)[0]);
                                setMenuName2(JSON.parse(result.data.menu_name)[1]);
                                setMenuName3(JSON.parse(result.data.menu_name)[2]);
                                setMenuName4(JSON.parse(result.data.menu_name)[3]);
                                setMenuName5(JSON.parse(result.data.menu_name)[4]);
                                setMenuName6(JSON.parse(result.data.menu_name)[5]);
                            }
                            if(JSON.parse(result.data.menu_images).length>0){
                                setMenuImage1(JSON.parse(result.data.menu_images)[0]);
                                setMenuImage2(JSON.parse(result.data.menu_images)[1]);
                                setMenuImage3(JSON.parse(result.data.menu_images)[2]);
                                setMenuImage4(JSON.parse(result.data.menu_images)[3]);
                                setMenuImage5(JSON.parse(result.data.menu_images)[4]);
                                setMenuImage6(JSON.parse(result.data.menu_images)[5]);
                            }
                        } else customAlert(result.data.result_msg);

                        setLoading(false);

                        if (isMobile && stored_uid) {
                            let cookieCheck = getCookie("popupYN");
                            if (cookieCheck != "N") setModal2(true);
                        }



                    })
                    .catch((err) => console.log(err.message));
            }catch (e) {
                console.log(e);
            }

        }

        startAxios();

        //setRefresh(route.params.refresh);


        Axios({
            url: "https://sbc.smartbc.kr/sb/notification.php",
            method: "get",
            params: {
                u_id : stored_uid,
                type : '',
            }
        })
            .then(function (res) {
                setAcount(res.data['notification'].length);
            })
            .catch(function (e) {
                console.log('notification list up error');
            })

            //ios는 https 넣으면 문자 보낼때 주소 안 보임
           //초대링크
            setUrl('info.smartbc.kr/b2a');

    }, []);

    return (
        <div onClick={()=>{
            if(!stored_uid || JoinStatus == false){
                if(!stored_uid) history.push({
                    pathname : '/'+match.params.JoinCode+'/PcLogin',
                });
                else if(JoinStatus == false) {
                    customAlert('가입되지 않은 모임입니다. 가입 후 이용해주세요.');
                    history.push('/'+match.params.JoinCode+'/PcLogin');
                }
            }
        }}>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white'}}>
                {/*/!*그룹별 메타태그 설정*!/*/}
                {/*<MetaTags>*/}
                {/*    <title>{band_name}</title>*/}
                {/*    <meta name="description" content={group_intro} />*/}
                {/*    <meta property="og:title" content={band_name} />*/}
                {/*    <meta property="og:image" content={"https://sbc.smartbc.kr" + logo} />*/}
                {/*</MetaTags>*/}

                <CssBaseline/>

                {/*custom_confirm*/}
                <div>
                    <Dialog
                        open={confirmShow}
                        onClose={handleCloseN}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {confirmMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseN} color="primary">
                                {'아니오'}
                            </Button>
                            <Button onClick={handleCloseY} color="primary" autoFocus>
                                {'예'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>


                {/*main header*/}
                <AppBar>
                    <Toolbar style={{flexDirection: 'row',backgroundColor :'white'}}>
                        <div style={{margin: '0 auto', width: 430, display: 'flex'}}>
                            {/*메인 로고*/}
                            <Typography align='left' style={{width:'60%',}}>
                                {/*로고 비율  600x150, 1410x260 => 4~5:1*/}
                                {logo?
                                    <img src={"https://sbc.smartbc.kr" + logo} style={{height: 50,width: '100%',marginTop : 10}}/>
                                    :
                                    <img src={require('../resources/logo_icon_update/스마트그룹_기본_BI.png')} style={{height: 50,width: '100%',marginTop : 10}}/>
                                     //../resources/logo_icon_update/basic_logo.png
                                }
                            </Typography>
                            <Typography align='right' style={{flex: 1}}>
                                <IconButton color="primary" className={classes.listBox} style={{cursor: 'pointer', marginLeft: -15}} onClick={()=>{
                                    if(stored_uid) {
                                        history.push(match.params.JoinCode + '/MyInfo');
                                    }
                                }}>
                                    {userProfile ?
                                        <span style={{display: 'inline-block', position: 'absolute', right: 6, top: '125%', width: 45, height: 45, transform: 'translateY(-50%)', overflow: 'hidden',borderRadius: '50%'}}>
                                             <img src={"https://sbc.smartbc.kr" + userProfile} style={{width: 45,position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)'}}/>
                                         </span>
                                        // <img src={"https://sbc.smartbc.kr"+userProfile} style={{height: 45, width: 45, borderRadius:50}}/>
                                        :
                                        <img src={require('../resources/profile_icon.png')} style={{height: 45}}/>
                                    }
                                </IconButton>
                                {/*    <IconButton color="primary" className={classes.listBox} style={{cursor: 'pointer', position: 'relative'}} onClick={() => {*/}
                                {/*            history.push(match.params.JoinCode+'/Notice');*/}
                                {/*    }}>*/}
                                {/*        <Badge badgeContent={JoinStatus ? aCount : null} color="secondary" max={99} style={{position: 'absolute', top: 18, right: 18}}></Badge>*/}
                                {/*        <img src={require('../resources/main_home_icon/alarm.png')} style={{height: 32}}/>*/}
                                {/*    </IconButton>*/}
                                {/*<IconButton color="primary" className={classes.listBox} style={{cursor: 'pointer', marginLeft: -15}} onClick={()=>{*/}
                                {/*        history.push(match.params.JoinCode+'/MyInfo');*/}
                                {/*}}>*/}
                                {/*    <img src={require('../resources/main_home_icon/my_page.png')} style={{height: 32}}/>*/}
                                {/*</IconButton>*/}
                                {/*<IconButton color="primary" className={classes.listBox} style={{cursor: 'pointer', marginLeft: -15}} onClick={()=>{*/}
                                {/*        history.push(match.params.JoinCode+'/Setting');*/}
                                {/*}}>*/}
                                {/*    <img src={require('../resources/main_home_icon/setting.png')} style={{height: 32}}/>*/}
                                {/*</IconButton>*/}
                            </Typography>
                        </div>
                    </Toolbar>
                </AppBar>
                {/* 배너 */}
                <div className={classes.mainBnn}>
                    {/*배너 비율  890x475 => 2:1*/}
                    {banner?
                        <img src={"https://sbc.smartbc.kr" + banner} style={{height: 240, width: '100%', overflow: 'hidden'}} onClick={()=>{
                            if(stored_uid) {
                                if (b_link_allow == 1) {
                                    if (b_link.indexOf('http://') != -1 ||
                                        b_link.indexOf('https://') != -1) {
                                        //문자열 포함
                                        window.open('about:blank').location.href = b_link;//새창 o
                                    } else {

                                        window.open('about:blank').location.href = 'https://' + b_link;//새창 o
                                    }
                                }
                            }
                        }}/>
                        :
                        <img src={require('../resources/main_home_icon/basic_banner.jpg')} style={{height: 240, width: '100%', overflow: 'hidden'}}/>
                    }
                </div>
                {/* main content */}
                <div className={classes.paper} style={{paddingBottom: '110px', marginTop: 0}}>
                    {/* 중앙 메뉴 */}
                    <div className={classes.mainTopContent}>
                        <div>
                            <span style={{backgroundColor:'#E9E9E9',  display: 'block', width: '100%', height: 1, position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)'}}></span>
                            <span style={{backgroundColor:'#E9E9E9',  display: 'block', width: 1, height: '100%', position: 'absolute', left: '33%', top: 0}}></span>
                            <span style={{backgroundColor:'#E9E9E9',  display: 'block', width: 1, height: '100%', position: 'absolute', right: '33%', top: 0}}></span>
                        </div>

                        <Grid container spacing={0}>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem} onClick={()=>{
                                    if(stored_uid) {
                                        history.push({
                                            pathname: match.params.JoinCode + '/Members',
                                            state: {searchList: null}
                                        });
                                    }

                                }}>
                                    <Grid item xs container direction="column">
                                        {menuImage1?
                                            <img src={"https://sbc.smartbc.kr" + menuImage1} style={{height: 40, width: 40}}/>
                                            :
                                            <img src={require('../resources/main_home_icon/menu_icon1.png')} style={{height: 35, width: 55}}/>
                                        }

                                        <Typography align='left'
                                                    style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10,width:110,height:21}}
                                                    className={classes.menuText}
                                        >{menuName1}</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>{AddComma(total_member_cnt)} 명</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem} onClick={() => {
                                    if(stored_uid) {
                                        history.push({
                                            pathname: match.params.JoinCode + '/MembersCompany',
                                        });
                                    }
                                    // customAlert('준비중 입니다.');
                                        // history.push({
                                        //     pathname : match.params.JoinCode+'/LinkMember',
                                        //     state : {searchList : null}
                                        // });

                                }}>
                                    <Grid item xs container direction="column">
                                        {menuImage2?
                                            <img src={"https://sbc.smartbc.kr" + menuImage2} style={{height: 40, width: 40}}/>
                                            :
                                            <img src={require('../resources/main_home_icon/menu_icon2.png')} style={{height: 40, width: 40}}/>
                                        }

                                        <Typography align='left'
                                                    style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10,width:110,height:21}}
                                                    className={classes.menuText}
                                        >{menuName2}</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>{AddComma(company_cnt)} 社</Typography>
                                        </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem} onClick={() => {
                                     if(stored_uid) {
                                         history.push({
                                             pathname: match.params.JoinCode + '/NoticeBoard',
                                             state: {
                                                 // tabValue : 0,
                                                 table: 'band_board1',
                                             }
                                         });

                                     }
                                }}>
                                    <Grid item xs container direction="column">
                                        {menuImage3?
                                            <img src={"https://sbc.smartbc.kr" + menuImage3} style={{height: 40, width: 40}}/>
                                            :
                                            <img src={require('../resources/main_home_icon/menu_icon3.png')} style={{height: 40, width: 40}}/>
                                        }

                                        <Typography align='left'
                                                    style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10,width:110,height:21}}
                                                    className={classes.menuText}
                                        >{menuName3}</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>{news_cnt} 건</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem} onClick={() => {
                                    if(stored_uid) {
                                        history.push({
                                            pathname: match.params.JoinCode + '/NoticeBoard',
                                            state: {
                                                // tabValue : 1,
                                                table: 'band_board2',
                                            }
                                        });
                                    }

                                }}>
                                    <Grid item xs container direction="column">
                                        {menuImage4?
                                            <img src={"https://sbc.smartbc.kr" + menuImage4} style={{height: 40, width: 40}}/>
                                            :
                                            <img src={require('../resources/main_home_icon/menu_icon4.png')} style={{height: 40, width: 45}}/>
                                        }

                                        <Typography align='left'
                                                    style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10,width:110,height:21}}
                                                    className={classes.menuText}
                                        >{menuName4}</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>{cooperation_cnt} 건</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem} onClick={() => {
                                    if(stored_uid) {
                                        history.push({
                                            pathname: match.params.JoinCode + '/NoticeBoard',
                                            state: {
                                                // tabValue : 2,
                                                table: 'band_board3',
                                            }
                                        });
                                    }

                                }}>
                                    <Grid item xs container direction="column">
                                        {menuImage5?
                                            <img src={"https://sbc.smartbc.kr" + menuImage5} style={{height: 40, width: 40}}/>
                                            :
                                            <img src={require('../resources/main_home_icon/menu_icon5.png')} style={{height: 40, width: 45}}/>
                                        }

                                        <Typography align='left'
                                                    style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10,width:110,height:21}}
                                                    className={classes.menuText}
                                        >{menuName5}</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>{free_board_cnt} 건</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem} onClick={()=>{
                                    if(stored_uid) {
                                        customAlert('준비중 입니다.');
                                    }
                                    // history.push(match.params.JoinCode+'/Community');
                                }}>
                                    <Grid item xs container direction="column">
                                        {menuImage6?
                                            <img src={"https://sbc.smartbc.kr" + menuImage6} style={{height: 40, width: 40}}/>
                                            :
                                            <img src={require('../resources/main_home_icon/menu_icon6.png')} style={{height: 35, width: 55}}/>
                                        }

                                        <Typography align='left'
                                                    style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10,width:110,height:21}}
                                                    className={classes.menuText}
                                        >{menuName6}</Typography>
                                        {/*<Typography align='left'  style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10}}>복지 서비스<br/>& 커뮤니티</Typography>*/}
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>0 건</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {/* 최근소식 */}
                    {/*<Typography align='left' style={{width: '100%', fontSize: 13, fontWeight: 'bold', margin: '30px 0 20px'}}>최근소식</Typography>*/}
                    {/*<div container style={{width: '100%', border: '1px solid #E8E8E8', borderRadius: 0}}>*/}
                    {/*    <List container style={{padding: 0}}>*/}
                    {/*        <ListItem button color="primary" className={classes.newsList} style={{position: 'relative', cursor: 'pointer'}}>*/}
                    {/*            <Grid container direction="column">*/}
                    {/*                <Typography align='left' className={classes.newsListTitle}>{newsTitle}</Typography>*/}
                    {/*                <Typography align='right'  style={{fontSize: 10, color: 'rgb(181,181,181)', marginRight: 50, marginTop: 5}}>{writeDay}</Typography>*/}
                    {/*                <ListItemIcon><img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/></ListItemIcon>*/}
                    {/*            </Grid>*/}
                    {/*        </ListItem>*/}
                    {/*        <Divider />*/}
                    {/*        <ListItem button color="primary" className={classes.newsList} style={{position: 'relative', cursor: 'pointer'}}>*/}
                    {/*            <Grid container direction="column">*/}
                    {/*                <Typography align='left' className={classes.newsListTitle}>{newsTitle}</Typography>*/}
                    {/*                <Typography align='right'  style={{fontSize: 10, color: 'rgb(181,181,181)', marginRight: 50, marginTop: 5}}>{writeDay}</Typography>*/}
                    {/*                <ListItemIcon><img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/></ListItemIcon>*/}
                    {/*            </Grid>*/}
                    {/*        </ListItem>*/}
                    {/*        <Divider />*/}
                    {/*        <ListItem button color="primary" className={classes.newsList} style={{position: 'relative', cursor: 'pointer'}}>*/}
                    {/*            <Grid container direction="column">*/}
                    {/*                <Typography align='left' className={classes.newsListTitle}>{newsTitle}</Typography>*/}
                    {/*                <Typography align='right'  style={{fontSize: 10, color: 'rgb(181,181,181)', marginRight: 50, marginTop: 5}}>{writeDay}</Typography>*/}
                    {/*                <ListItemIcon><img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/></ListItemIcon>*/}
                    {/*            </Grid>*/}
                    {/*        </ListItem>*/}
                    {/*    </List>*/}
                    {/*</div>*/}


                    <Typography align='left' style={{width: '100%', fontSize: 13, fontWeight: 'bold', margin: '30px 0 20px'}}>부가서비스</Typography>
                    {/*메인홈 커뮤니티 리스트*/}
                    <Grid container direction='row' style={{width:'100%', paddingHorizontal:'5%', flexWrap : 'wrap',flexDirection:'row'}}>

                    {community_list.length > 0 ? (
                        community_list.map((value,i)=>{
                            return(

                            <Grid style={{margin:5,width:'30%',height:100}}
                                // style={{width: '100%', height: 100, padding: 10, border: '1px solid rgb(174,181,186)', borderRadius: 10, marginBottom: 20}}
                                onClick={()=>{
                                    if(stored_uid) {
                                        if (Number(community_list[i].second) && community_list[i].cm_link !== '@_professional_search') {
                                            //하위메뉴 존재
                                            // navigation.navigate('CommunitySecond', {mother_type: community_list[index].id})
                                            history.push({
                                                pathname: match.params.JoinCode + '/CommunitySecond',
                                                state: {mother_type: community_list[i].id}
                                            });
                                            return;
                                        } else if (community_list[i].cm_link == '@_professional_search') {
                                            customAlert('준비중입니다.');
                                            return;
                                        }else if (community_list[i].cm_link == '@_company_search') {
                                            history.push({
                                                pathname: match.params.JoinCode + '/Company_search',
                                            });

                                            // navigation.navigate('Company_search',{searchList: ''});
                                            return;
                                        }else {
                                            if (community_list[i].cm_link) {
                                                if (community_list[i].cm_link.indexOf('info.smartbc.kr/partner') != -1) {
                                                    if (stored_partner_id) {
                                                        history.push(match.params.JoinCode + '/PartnerMyInfo');
                                                        return;
                                                    } else {
                                                        history.push(match.params.JoinCode + '/PartnerSignUp');
                                                        return;
                                                    }

                                                } else if (community_list[i].cm_link.indexOf('info.smartbc.kr/b2c') != -1) {
                                                    window.open('about:blank').location.href = "https://play.google.com/store/apps/details?id=com.smartwel.smartbc";
                                                    return;
                                                } else if (community_list[i].cm_link.indexOf('info.smartbc.kr/b2b') != -1) {
                                                    window.open('about:blank').location.href = "https://play.google.com/store/apps/details?id=com.smartwel.smartbccom";
                                                    return;
                                                }

                                                if (community_list[i].cm_link.indexOf('http://') != -1 ||
                                                    community_list[i].cm_link.indexOf('https://') != -1) {
                                                    //문자열 포함
                                                    window.open('about:blank').location.href = community_list[i].cm_link;
                                                } else {
                                                    window.open('about:blank').location.href = 'https://' + community_list[i].cm_link;
                                                }
                                            }//주소값 여부
                                        }
                                    }
                            }}>
                                <div container alignItems='center' direction='row'  style={{width:'100%', height:'100%',border:'1px solid #aeb5ba', borderRadius:10, flexWrap : 'wrap',padding: 3, alignItems:'center'}}>
                                        <img src={"https://sbc.smartbc.kr" + community_list[i].cm_image}
                                             style={{width: '100%', height: '100%', borderRadius:10}} />
                                </div>
                            </Grid>) })
                    ) : null}
                    </Grid>

                </div> {/* main content end */}


                {/*하단메뉴*/}
                <div className={classes.bottomNavWrap}>
                    <BottomNavigation
                        iconOnly
                        className={classes.bottomNav}
                    >
                        <Grid container style={{marginTop: 45,marginLeft:-20,width:'100%'}}>
                            <Grid item color="primary" style={{width: '20%'}}>
                                <div onClick={()=>{
                                    if(stored_uid) {
                                        history.push(match.params.JoinCode + '/Notice');
                                    }
                                }}>
                                    <Badge badgeContent={JoinStatus ? aCount : null} color="secondary" max={99} style={{position: 'absolute', top: 48, right: 374}}/>
                                    <img src={require('../resources/main_home_icon/alarm.png')} style={{height: 40,marginTop:-5}}/>
                                    <Typography align='center'
                                                style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)',height:22,marginTop:-7}}
                                    >{'알림'}</Typography>
                                </div>

                            </Grid>
                            <Grid item color="primary" style={{ width: '20%',marginLeft:5}}>
                                <div onClick={()=>{
                                    if(stored_uid) {
                                        history.push(match.params.JoinCode + '/Setting');
                                    }
                                }}>
                                    <img src={require('../resources/main_home_icon/setting.png')} style={{height: 40,marginTop:-5}}/>
                                    <Typography align='center'
                                                style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)',height:22,marginTop:-7}}
                                    >{'설정'}</Typography>
                                </div>
                            </Grid>
                            <Grid item color="primary"  style={{width: '20%',marginLeft:5}}>
                                <div className={classes.bottomNavHome}>
                                    <span className={classes.homeInner}></span>
                                </div>
                                <Button style={{borderRadius: '50%', marginTop: -18}}  onClick={()=>{
                                    if(stored_uid) {
                                        history.push(match.params.JoinCode);
                                    }
                                }}>
                                    <img src={require('../resources/main_home_icon/home.png')} style={{height: 45}}/>
                                </Button>
                            </Grid>
                            <Grid item color="primary" style={{width: '15%',marginLeft:5}}>

                                <div onClick={()=>{
                                    if(stored_uid) {
                                        // history.push('/'+match.params.JoinCode+'/JoinAssociation');
                                        if (navigator.platform) {
                                            if (isMobile) {
                                                setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                setConfirmShow(true);

                                                // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                // if (answer) window.open("https://info.smartbc.kr/b2a");
                                            } else {
                                                customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                            }
                                        }
                                    }
                                }}>
                                    <img src={require('../resources/main_home_icon/joinList_icon2.png')} style={{height: 30,marginTop:-3}}/>
                                    <Typography align='center'
                                                style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)',height:22,marginTop:-1}}
                                    >{'가입모임'}</Typography>
                                </div>
                            </Grid>
                            <Grid item color="primary" style={{width: '15%',marginLeft:15}}>
                                <div onClick={()=>{
                                    if(stored_uid) {
                                        if (navigator.platform) {
                                            if (isMobile) {
                                                setConfirmMessage('해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a');
                                                setConfirmShow(true);

                                                // let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                                // if (answer) window.open("https://info.smartbc.kr/b2a");
                                            } else {
                                                customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                            }
                                        }
                                    }
                                }}>
                                    <img src={require('../resources/main_home_icon/addUser_icon.png')} style={{height: 30,marginTop:-3}}/>
                                    <Typography align='center'
                                                style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)',height:22,marginTop:-1}}
                                    >{'멤버초대'}</Typography>
                                </div>
                            </Grid>
                        </Grid>

                    </BottomNavigation>
                </div>

                {modal?
                    <>
                        <Card className={classes.popUp}>
                            <Typography align='right'>
                                <IconButton>
                                    <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                                        setModal(false);
                                    }}/>
                                </IconButton>
                            </Typography>
                            <CardContent style={{paddingTop: 5}}>
                                <Typography>
                                    <img src={require('../resources/card_icon.png')} style={{height: 85}}/>
                                </Typography>
                                <Typography style={{marginTop: 20}}>MY 모바일 명함 등록을 먼저 해주세요!</Typography>
                            </CardContent>
                            <Button
                                color="primary"
                                className={classes.groupAdd}
                                style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                                onClick={() => {
                                    history.push('/'+match.params.JoinCode+'/MyCardRegist');
                                }}>
                                MY명함 등록하기
                            </Button>
                        </Card>
                    <div className={classes.popUpBg} />
                    </>
                : null}
                {modal2?
                    <>
                        <div style={{zIndex: 1110,
                            position: 'fixed',
                            width:'80%',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%,-50%)'}}>
                            <Card className={{width: '310px',
                                backgroundColor: '#fff',
                                borderRadius: 20,}}>
                                <CardContent>
                                    <Typography>
                                        <img src={require('../resources/logo_icon_update/APP_icon.png')} style={{height: 85,}}/>
                                    </Typography>
                                    <Typography style={{marginTop: 20}}>스마트모임을 앱으로</Typography>
                                    <Typography> 이용해보세요!</Typography>
                                </CardContent>
                                <Button
                                    color="primary"
                                    className={classes.groupAdd}
                                    style={{width: '70%', backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 15}}
                                    onClick={() => {
                                        let date = new Date();
                                        date.setDate(date.getDate() + 1);
                                        document.cookie = escape("popupYN") + "=" + escape("N") + "; expires=" + date.toUTCString();
                                        setModal2(false);

                                        window.open("https://info.smartbc.kr/b2a");
                                    }}>
                                    앱 다운로드
                                    <GetAppIcon style={{color: 'white',marginLeft:5, fontSize: 30}}/>
                                </Button>
                            </Card>
                            <Typography align='center'
                                        style={{paddingTop:20,textDecorationLine: 'underline',color: 'white',width:'100%'}}
                                        onClick={()=>{
                                            let date = new Date();
                                            date.setDate(date.getDate() + 1);
                                            document.cookie = escape("popupYN") + "=" + escape("N") + "; expires=" + date.toUTCString();
                                            setModal2(false);
                                        }}>
                                괜찮습니다. 모바일웹으로 볼게요.
                                <HighlightOffIcon style={{color: 'white', marginBottom:-5,fontSize: 27}}/>
                            </Typography>
                        </div>
                        <div className={classes.popUpBg} />
                    </>
                    : null}
                {loading?
                    <>
                <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                <div className={classes.popUpBg} />
                </>
                    :null}
            </Container>
        </div>

    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        backgroundColor: 'rgb(249,250,252)',
        padding: '0 30px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBnn: {
        width: '-webkit-fill-available',
        height: 200,
        marginTop: 65,

    },
    mainTopContent: {
        position: 'relative',
        height: 210,
        borderRadius: 10,
        backgroundColor: '#fff',
        boxShadow: '0 3px 6px rgba(0,0,0,0.15)',
        width : '100%',
    },
    mainTopContentItem: {
        height: 105,
        borderRadius: 0,
        backgroundColor: 'transparent',
        padding: 10,
    },
    newsListTitle: {
        fontSize: 13,
        fontWeight: 'bold',
        color: 'rgb(51,51,51)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '80%',
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    bottomNavWrap: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        backgroundImage:`url(${navBg})`,
        backgroundRepeat : 'repeat-x',backgroundSize: "cover", backgroundPosition: 'top center', width: '100%', height: 130,
    },
    bottomNav:{
        padding:'0 20px',
        position: 'fixed',
        left: '50%',
        bottom: 0,
        transform: 'translateX(-50%)',
        width: '35%',
        minWidth: '420px',
        maxWidth: 480,
        height: '105px',
        backgroundColor: 'transparent',
    },
    bottomNavHome:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-45%)',
        zIndex: -1,
        width: '60px',
        height: '60px',
        backgroundColor: '#fff',
        borderRadius: '50%',
        boxShadow: '0 0 20px rgba(0,0,0,0.07)'
    },
    homeInner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '45px',
        height: '45px',
        backgroundColor: 'rgb(48,58,83)',
        borderRadius: '50%',
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.2,
        height: '40px',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        padding: 2.5,
    },
    menuText:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.5,
        height: '40px',
        display: '-webkit-box',
        lineClamp: 1,
        boxOrient: 'vertical',
        textTransform:'none',

    }
}));


export default MainHome;
