import React, {useEffect} from "react";
import {Button, Container, CssBaseline, IconButton, InputBase, makeStyles} from "@material-ui/core";
import PcHeader from "./PcHeader";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {shadow} from '@material-ui/system';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import RoundButton from "../component/RoundButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Axios from "axios";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { AiOutlinePaperClip } from "react-icons/ai";
import customAlert from "../component/customAlert";

function AddHyphen(num) {
    let regexp;
    if(num.length==7){
        regexp = num.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{4})/, '$1-$2');
    }else if(num.length==8){
        regexp = num.replace(/[^0-9]/g, "").replace(/(\d{4})(\d{4})/, '$1-$2');
    } else{
        regexp = num.replace(/[^0-9]/g, "").replace(/(^02|^0505|^0504|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "-");
    }
    return regexp;
}

// 이메일 체크 정규식
function isEmail(asValue) {
    var regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    // console.log(regExp.test(asValue));
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

function MyCardRegist({history,match}) {

    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [user_name, setUser_name] =  React.useState('');
    const [phone, setPhone] =  React.useState('');
    const [email, setEmail] = React.useState('');
    const [tel, setTel] = React.useState('');
    const [fax, setFax] = React.useState('');
    const [company, setCompany] = React.useState('');//소속
    const [part, setPart] =  React.useState(''); //부서,파트
    const [position, setPosition] =  React.useState('');
    const [address, setAddress] = React.useState('');
    const [homepage, setHomepage] =  React.useState('');
    const [homepage2, setHomepage2] =  React.useState('');
    const [homepage3, setHomepage3] = React.useState('');
    const [homepage4, setHomepage4] =  React.useState('');
    const [homepage5, setHomepage5] =  React.useState('');
    const [homepage6, setHomepage6] =  React.useState('');
    const [card_open, setCard_open] =  React.useState("2");
    const [file, setFile] = React.useState('');//파일이름
    const [upfile, setUpfile] = React.useState('');
    const [image, setImage] = React.useState('');
    const [fileFullName,setFileFullName] =  React.useState('');//파일이름포함한 경로
    const [introduce, setIntroduce] = React.useState('');

    const [position_type, setPosition_type] = React.useState('');
    const [location, setLocation] =  React.useState('');

    let mycard_data = {};
    let tmp_data = {};

    const [c_type_list, setC_type_list] =  React.useState([]);
    const [c_type1, setC_type1] =  React.useState('');
    const [c_type2, setC_type2] = React.useState('');
    // let item_c1 = [];
    let item_c2 = [];
    // const [opList_c1,setOpList_c1]= React.useState([]);
    const [opList_c2,setOpList_c2]= React.useState([]);

    const [p_type_list, setP_type_list] = React.useState([]);
    const [p_type1, setP_type1] =React.useState('');
    const [p_type2, setP_type2] =React.useState('');
    let item_p1 = [];
    let item_p2 = [];
    const [opList_p1,setOpList_p1]= React.useState([]);
    const [opList_p2,setOpList_p2]= React.useState([]);

    const [location_list,setLocation_list] = React.useState([]);
    const [position_list,setPosition_list] = React.useState([]);
    let item_position = [];
    let item_location = [];
    const [opList_position,setOpList_position] = React.useState([]);
    const [opList_location,setOpList_location] = React.useState([]);

    const formData = new FormData();

    let isMobile = false;
    const filter = "win16|win32|win64|mac|macintel";
    isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

    useEffect(()=>{
        Axios({
            url: 'https://sbc.smartbc.kr/sb/mycardInfo.php' ,
            method: 'get',
            params: {
                u_id:u_id,
                b_id:b_id,
            },
        })
            .then((result) => {
                console.log(result.data);
                if (result.data.status == 9001) {
                    setC_type_list(result.data.c_type_list);
                    setP_type_list(result.data.p_type_list);
                    setLocation_list(result.data.location_list);
                    setPosition_list(result.data.position_list);


                    if(result.data.c_type1){
                        Axios({
                            url: 'https://sbc.smartbc.kr/sb/getOption.php',
                            method: 'get',
                            params: {
                                table: "company_type_second",
                                mother_type: result.data.c_type1
                            }
                        })
                            .then(function (response) {
                                response.data.map((value) => {
                                    item_c2.push(<option value={value.id}>{value.name}</option>);
                                });
                                setOpList_c2(item_c2);
                                console.log(item_c2);

                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }

                    if(result.data.p_type1){
                        console.log('p_type = ' + result.data.p_type1);
                        Axios({
                            url: 'https://sbc.smartbc.kr/sb/getOption.php',
                            method: 'get',
                            params: {
                                table: "part_type_second",
                                mother_type: result.data.p_type1
                            }
                        })
                            .then(function (response) {
                                response.data.map((value) => {
                                    item_p2.push(<option value={value.id}>{value.name}</option>);
                                });
                                setOpList_p2(item_p2);
                                console.log(item_p2);

                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    }


                } else {
                    console.log(result);
                    console.log(result.data);
                }
            })
            .catch((err) => console.log(err.message));


    },[]);

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center'}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'MY 명함 등록'} back={1} history={history} match={match}/>
            {/*body*/}
            <div className={classes.paper} style={{flex: 1,}}>
                <div>
                    <img src={require('resources/mobile_card_sample.png')} style={{float: 'center', width: '90%',}}/>
                </div>
            </div>
            <Divider style={{boxShadow: 10, marginTop: '2%', marginBottom: '2%',}}/>
            {/*<Box component={'div'}*/}
            {/*     style={{border: '1px solid black', borderColor: '#e2e7eb', borderRadius: 10, minHeight: 60,}}>*/}
            {/*    <Box component={'input'} style={{border: 'none', outline: 'none', width: 'inherit'}}*/}
            {/*         placeholder={'50자 이내 소개글을 입력해 주세요 (선택)'}/>*/}
            {/*</Box>*/}

            <InputBase item xs={20} style={{ width: 'inherit',border: '1px solid black', borderColor: '#e2e7eb', borderRadius: 10, height: 100,padding:15}}
                       multiline={true}
                       rows={3}
                       placeholder={'50자 이내 소개글을 입력해 주세요 (선택)'}
                       value={introduce}
                       onChange={(event)=>{
                           if(event.target.value.length<50){
                               setIntroduce(event.target.value);
                           }
                       }}
            />

            {/*이름*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',justifyContent:'center',height:'100%',paddingTop:7}}>이름 *</Grid>
                    <InputBase item xs={20} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'이름'}
                               value={user_name}
                        // type={'text'}
                               onChange={(event)=>{
                                   // console.log('이름으으으으',event.target.value);
                                   setUser_name(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>

            {/*휴대전화*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>휴대전화 *</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'휴대전화'}
                               value={phone}
                               onChange={(event)=>{
                                   if(event.target.value.length<14){
                                       setPhone(event.target.value);
                                   }
                               }}
                    />
                    {/*<img src={require('../resources/call_icon.png')} style={{width: 35,height:35}}*/}
                    {/*     onClick={()=>{*/}
                    {/*         customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다.");*/}
                    {/*     }}/>*/}
                </Grid>
                <Divider/>
            </div>
            {/*이메일*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>이메일 *</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'이메일'}
                               value={email}
                               onChange={(event)=>{
                                   setEmail(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*유선전화*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>유선전화</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'유선전화'}
                               value={tel}
                               onChange={(event)=>{
                                   if(event.target.value.length<14){
                                       setTel(event.target.value);
                                   }
                               }}
                    />
                    {/*<img src={require('../resources/call_icon.png')} style={{width: 35,height:35}}*/}
                    {/*     onClick={()=>{*/}
                    {/*         customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다.");*/}
                    {/*     }}/>*/}
                </Grid>
                <Divider/>
            </div>
            {/*팩스번호*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>팩스번호</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'팩스번호'}
                               value={fax}
                               onChange={(event)=>{
                                   if(event.target.value.length<14){
                                       setFax(event.target.value);
                                   }
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*소속명*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>소속명 *</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'소속명'}
                               value={company}
                               onChange={(event)=>{
                                   setCompany(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*부서/파트*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>부서/파트 *</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'부서/파트'}
                               value={part}
                               onChange={(event)=>{
                                   setPart(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*직책*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>직책 *</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'직책'}
                               value={position}
                               onChange={(event)=>{
                                   setPosition(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*주소*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>주소</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'주소'}
                               value={address}
                               multiline={true}
                               onChange={(event)=>{
                                   setAddress(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*홈페이지*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>홈페이지</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'홈페이지'}
                               value={homepage}
                               onChange={(event)=>{
                                   setHomepage(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*블로그*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>블로그</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'블로그'}
                               value={homepage2}
                               onChange={(event)=>{
                                   setHomepage2(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*페이스북*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>페이스북</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'페이스북'}
                               value={homepage3}
                               onChange={(event)=>{
                                   setHomepage3(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*트위터 => 유튜브로 변경*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>유튜브</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'유튜브'}
                               value={homepage4}
                               onChange={(event)=>{
                                   setHomepage4(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*인스타그램*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>인스타그램</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'인스타그램'}
                               value={homepage5}
                               onChange={(event)=>{
                                   setHomepage5(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*운영쇼핑몰*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>운영쇼핑몰</Grid>
                    <InputBase item xs={10} style={{textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'운영쇼핑몰'}
                               value={homepage6}
                               onChange={(event)=>{
                                   setHomepage6(event.target.value);
                               }}
                    />
                </Grid>
                <Divider/>
            </div>
            {/*파일첨부*/}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={3} style={{textAlign: 'left',paddingTop:7}}>파일첨부</Grid>
                    <InputBase item xs={10} style={{width:210,textAlign :'left',paddingLeft :'5%'}}
                               placeholder={'파일첨부'}
                               value={file}
                        // disabled={true} //회색으로 보임
                               readOnly={true}
                               onChange={(event)=>{
                                   setFile(event.target.value);
                               }}
                    />
                    <Button
                        component="label" // 이거 써야 <input/> 가능?!
                        type="submit"
                        color="primary"
                        style={{backgroundColor: 'rgb(48,58,83)', color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 25,padding :10, marginLeft: 5}}
                        >
                        <AiOutlinePaperClip size={20}/>
                        첨부
                        {/*파일업로드*/}
                        <input id={"file-input"} style={{ display: 'none' }} type="file" name="file-input"
                            // ref="file"
                               onChange={(event)=>{
                                   //같은파일을 클릭해도 작동되게하지?,,,,

                                   //취소가 아닐 때 (확인 눌렀을 때)
                                   if(event.target.value!=''){
                                       // setModal(true);
                                       // console.log('파일경로',event.target.files[0]);
                                       let fileVal = event.target.files[0];
                                       let fileName = event.target.value.split('\\').pop();

                                       formData.append("before_upfile",fileVal);

                                       console.log('파일ㄹ?',fileVal);
                                       console.log('들어감?',formData);
                                       for (var key of formData.entries()) {
                                           console.log(key[0] + ', ' + key[1]);
                                       }

                                       if(formData) {
                                           Axios({
                                               method: 'POST',
                                               url: 'https://sbc.smartbc.kr/sb/before_upload_file.php',
                                               data: formData,
                                               params: {
                                                   u_id: u_id
                                               },
                                               headers: {
                                                   //'Authorization': "Bearer  "  +  YOUR_BEARER_TOKEN,
                                                   // 'Accept': 'application/json',
                                                   'Content-Type': 'multipart/form-data;'
                                               }
                                           })
                                               .then((result) => {
                                                   console.log('통신함?', result.data);

                                                   if (result.data.status == 27003) {
                                                       setFile(fileName);
                                                       setUpfile(fileVal);
                                                   }
                                                   customAlert(result.data.result_msg);
                                               })
                                               .catch((err) => console.log(err.message));
                                       }else{
                                           customAlert('파일을 다시 선택해 주세요');
                                       }//if end
                                   }


                                   console.log('파일입니다.',event.target.value);
                               }} />
                    </Button>
                </Grid>
                <Divider/>
            </div>

            {/*명함공개 설정 */}
            <div>
                <Grid container style={{padding: 15}}>
                    <Grid item xs={2.5} style={{textAlign: 'left',paddingTop:7,marginRight:18}}>명함공개 설정 *</Grid>
                    <RadioGroup row aria-label="card_open" name="card_open"  defaultValue="2" value={card_open}
                                onChange={(event)=>{
                                    setCard_open(event.target.value);
                                }}>
                        <FormControlLabel value="1" control={<Radio color="primary" />} label="전체 공개"/>
                        <FormControlLabel value="2" control={<Radio color="primary" />} label="모임만 공개" />
                    </RadioGroup>
                </Grid>
                <Divider/>
            </div>

            {/*회사 업종*/}
            <Grid container style={{padding:15,}}>
                <Grid item xs={3} style={{textAlign :'left',alignSelf:'center',paddingTop:7}}>회사 업종 *</Grid>
                <Grid item xs={8} style={{flexDirection :'row',}}>
                    <FormControl variant="outlined" className={classes.formControl} >
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,width:125,textAlign :'center'}}
                            value={c_type1}
                            onChange={(event) => {
                                console.log('선택',event.target.value);
                                setC_type1(event.target.value);
                                if(event.target.value!=0) {
                                    //2차 옵션 가져오기
                                    Axios({
                                        url: 'https://sbc.smartbc.kr/sb/getOption.php',
                                        method: 'get',
                                        params: {
                                            table: "company_type_second",
                                            mother_type: event.target.value
                                        }
                                    })
                                        .then(function (response) {
                                            response.data.map((value) => {
                                                item_c2.push(<option value={value.id}>{value.name}</option>);
                                            });
                                            setOpList_c2(item_c2);
                                            console.log(item_c2);

                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });
                                }else {
                                    item_c2=[];
                                    setOpList_c2(item_c2);
                                }
                            }}
                        >
                            <option value="0">선택</option>
                            {c_type_list.length>0?(
                                c_type_list.map((value,i)=> {
                                    return (
                                        <option value={value[0].id} >{value[0].name}</option>
                                    )
                                })):null}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl} style={{left :20}}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,width:125,textAlign :'center'}}
                            value={c_type2}
                            onChange={(event) => {
                                setC_type2(event.target.value);
                            }}
                        >
                            <option value="0">선택</option>
                            {opList_c2}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider/>
            {/*직업군*/}
            <Grid container style={{padding:15,}}>
                <Grid item xs={2} style={{textAlign :'left',alignSelf:'center',paddingTop:7}}>직업군 *</Grid>
                <Grid item xs={10} style={{flexDirection :'row',}}>
                    <FormControl variant="outlined" className={classes.formControl} >
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,width:125,textAlign :'center'}}
                            value={p_type1}
                            onChange={(event) => {
                                console.log('선택',event.target.value);
                                setP_type1(event.target.value);

                                if(event.target.value!=0) {
                                    //2차 옵션 가져오기
                                    Axios({
                                        url: 'https://sbc.smartbc.kr/sb/getOption.php',
                                        method: 'get',
                                        params: {
                                            table: "part_type_second",
                                            mother_type: event.target.value
                                        }
                                    })
                                        .then(function (response) {
                                            response.data.map((value) => {
                                                item_p2.push(<option value={value.id}>{value.name}</option>);
                                            });
                                            setOpList_p2(item_p2);
                                            console.log(item_p2);

                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });
                                }else {
                                    item_p2=[];
                                    setOpList_p2(item_p2);
                                }
                            }}
                        >
                            <option value="0">선택</option>
                            {p_type_list.length>0?(
                                p_type_list.map((value,i)=> {
                                    return (
                                        <option value={value[0].id} >{value[0].name}</option>
                                    )
                                })):null}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl} style={{left :20}}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,width:125,textAlign :'center'}}
                            value={p_type2}
                            onChange={(event) => {
                                setP_type2(event.target.value);
                            }}
                        >
                            <option value="0">선택</option>
                            {opList_p2}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider/>

            {/*직급 유형*/}
            <Grid container style={{padding:15}}>
                <Grid item xs={3} style={{textAlign :'left',alignSelf:'center',paddingTop:7}}>직급 유형 *</Grid>
                <Grid item xs={9}>
                    <FormControl variant="outlined" className={classes.formControl} style={{flexDirection :'row'}}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,marginLeft:-15,textAlign :'center',width :'36vh'}}
                            value={position_type}
                            onChange={(event) => {
                                setPosition_type(event.target.value);
                            }}
                        >
                            <option value="0">선택</option>
                            {position_list.length>0?(
                                position_list.map((value,i)=> {
                                    return (
                                        <option value={value.id} >{value.name}</option>
                                    )
                                })):null}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider/>

            {/*지역*/}
            <Grid container style={{padding:15}}>
                <Grid item xs={3} style={{textAlign :'left',alignSelf:'center',paddingTop:7}}>지역 *</Grid>
                <Grid item xs={9}>
                    <FormControl variant="outlined" className={classes.formControl} style={{flexDirection :'row'}}>
                        <Select
                            native
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                            }}
                            style={{height :30,marginLeft:-15,textAlign :'center',width :'36vh'}}
                            value={location}
                            onChange={(event) => {
                                setLocation(event.target.value);
                            }}
                        >
                            <option value="0">선택</option>
                            {location_list.length>0?(
                                location_list.map((value,i)=> {
                                    return (
                                        <option value={value.id} >{value.name}</option>
                                    )
                                })):null}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Divider/>

            {/*버튼*/}
            <div style={{margin:20}}
                 onClick={()=>{
                     // console.log('입력받은값',user_name);
                     // console.log('입력받은값',phone);
                     // console.log('입력받은값',homepage);

                     if(user_name==''){
                         customAlert("이름을 입력해주세요!");
                         return;
                     }else if(phone==''||phone.length<11){
                         customAlert('휴대폰번호를 입력해주세요.');
                         return;
                     }else if(tel.length>0&&tel.length<7) {
                         customAlert("유선전화를 입력해주세요.");
                         return;
                     }else if(fax.length>0&&fax.length<7) {
                         customAlert("팩스번호를 입력해주세요.");
                         return;
                     }else if(email =='') {
                         customAlert("이메일을 입력해주세요");
                         return;
                     }else if(!isEmail(email)){
                         customAlert('이메일을 맞게 입력했는지 확인해주세요.');
                         return;
                     }else if(company =='') {
                         customAlert("소속명을 입력해주세요");
                         return;
                     }else if(part =='') {
                         customAlert("부서/파트를 입력해주세요");
                         return;
                     }else if(position =='') {
                         customAlert("직책을 입력해주세요");
                         return;
                     }else if(c_type1 ==''||c_type1 =='0') {
                         customAlert("회사업종을 선택해주세요.");
                         return;
                     }else if(c_type2 ==''||c_type2 =='0') {
                         customAlert("회사업종을 선택해주세요.");
                         return;
                     }else if(p_type1 ==''||p_type1 =='0') {
                         customAlert("직업군을 선택해주세요.");
                         return;
                     }else if(p_type2 ==''||p_type2 =='0') {
                         customAlert("직업군을 선택해주세요.");
                         return;
                     }else if(position_type ==''||position_type =='0') {
                         customAlert("직급유형을 선택해주세요.");
                         return;
                     }else if(location ==''||location =='0') {
                         customAlert("지역을 선택해주세요");
                         return;
                     }

                     tmp_data['introduce'] = introduce;
                     tmp_data['u_id'] = u_id;
                     tmp_data['user_name'] = user_name;

                     // '-'(하이픈) 제거
                     // let phone_= phone.replace(/\-/g,'');
                     // tmp_data['phone'] = AddHyphen(phone_);
                     tmp_data['phone'] = AddHyphen(phone);

                     // '-'(하이픈) 제거
                     // let tel_= tel.replace(/\-/g,'');
                     // tmp_data['tel'] = AddHyphen(tel_);
                     tmp_data['tel'] = AddHyphen(tel);

                     // '-'(하이픈) 제거
                     // let fax_= fax.replace(/\-/g,'');
                     // tmp_data['fax'] = AddHyphen(fax_);
                     tmp_data['fax'] = AddHyphen(fax);

                     tmp_data['email'] = email;
                     tmp_data['company'] = company;
                     tmp_data['part'] = part;
                     tmp_data['position'] = position;
                     tmp_data['address'] = address;
                     tmp_data['homepage'] = homepage;
                     tmp_data['homepage2'] = homepage2;
                     tmp_data['homepage3'] = homepage3;
                     tmp_data['homepage4'] = homepage4;
                     tmp_data['homepage5'] = homepage5;
                     tmp_data['homepage6'] = homepage6;
                     tmp_data['card_open'] = card_open;
                     tmp_data['c_type1'] = c_type1;
                     tmp_data['p_type1'] = p_type1;
                     tmp_data['c_type2'] = c_type2;
                     tmp_data['p_type2'] = p_type2;
                     tmp_data['location'] = location;
                     tmp_data['position_type'] = position_type;
                     tmp_data['upfile'] = upfile;

                     mycard_data = JSON.stringify(tmp_data);
                     console.log(mycard_data);
                     if(upfile){
                         //파일업로드 하면서 명함 등록

                         formData.append("upfile", upfile);
                         //콘솔에서 formData 찍으면 이렇게 밖에 {} 안 나옴,,,(RN에서는 안에 내용 보였는데,,,)
                         console.log(upfile,'form');
                         Axios({
                             url: 'https://sbc.smartbc.kr/sb/mycard_regist.php' ,
                             method: 'POST',
                             data: formData,
                             params:{
                                 mycard_data:mycard_data
                             },
                             headers: {
                                 'Content-Type': 'multipart/form-data;'
                             }})
                             .then((result) => {
                                 console.log(result.data);
                                 customAlert(result.data.result_msg);
                                 history.replace('/'+match.params.JoinCode);
                             })
                             .catch((err) => console.log(err.message));

                     }else {
                         //파일 없이 명함 등록
                         Axios({
                             url: 'https://sbc.smartbc.kr/sb/mycard_regist.php',
                             method: 'GET',
                             params: {
                                 mycard_data: mycard_data
                             }
                         })
                             .then((result) => {
                                 console.log(result.data);
                                 customAlert(result.data.result_msg);
                                 history.replace('/'+match.params.JoinCode);
                             })
                             .catch((err) => console.log(err.message));
                     }

            }}>
            <RoundButton text={'명함 등록하기'}/>
            </div>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        }
    }
}));
export default MyCardRegist;
