import React from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Badge,
    Toolbar, IconButton, AppBar,
    List,ListItem,ListItemIcon,Divider
}from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import BottomNavi from "./BottomMenu";
function Main1() {
    const classes = useStyles();
    const newsTitle = `제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목제목`;
    const writeDay = `2020.00.00 12:00:00`;
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white'}}>
                <CssBaseline/>
                {/*main header*/}
                <AppBar>
                    <Toolbar style={{flexDirection: 'row',backgroundColor :'white'}}>
                        <div style={{margin: '0 auto', width: 430, display: 'flex'}}>
                            <Typography align='left'>
                                <img src={require('../resources/association_example2.png')} style={{height: 50}}/>
                            </Typography>
                            <Typography align='right' style={{flex: 1}}>
                                <IconButton color="primary" className={classes.listBox} style={{cursor: 'pointer', position: 'relative'}}>
                                    <Badge badgeContent={5} color="secondary" max={99} style={{position: 'absolute', top: 18, right: 18}}></Badge>
                                    <img src={require('../resources/main_home_icon/alarm.png')} style={{height: 32}}/>
                                </IconButton>
                                <IconButton color="primary" className={classes.listBox} style={{cursor: 'pointer', marginLeft: -15}}>
                                    <img src={require('../resources/main_home_icon/my_page.png')} style={{height: 32}}/>
                                </IconButton>
                                <IconButton color="primary" className={classes.listBox} style={{cursor: 'pointer', marginLeft: -15}}>
                                    <img src={require('../resources/main_home_icon/setting.png')} style={{height: 32}}/>
                                </IconButton>
                            </Typography>
                        </div>
                    </Toolbar>
                </AppBar>
                {/* 배너 */}
                <div className={classes.mainBnn}>
                    <img src={require('../resources/main_home_icon/rotc_banner.png')} style={{height: 240, width: '100%', overflow: 'hidden'}}/>
                </div>
                {/* main content */}
                <div className={classes.paper} style={{paddingBottom: '110px', marginTop: 0}}>
                    {/* 중앙 메뉴 */}
                    <div className={classes.mainTopContent}>
                        <div>
                            <span style={{backgroundColor:'#E9E9E9',  display: 'block', width: '100%', height: 1, position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)'}}></span>
                            <span style={{backgroundColor:'#E9E9E9',  display: 'block', width: 1, height: '100%', position: 'absolute', left: '33%', top: 0}}></span>
                            <span style={{backgroundColor:'#E9E9E9',  display: 'block', width: 1, height: '100%', position: 'absolute', right: '33%', top: 0}}></span>
                        </div>

                        <Grid container spacing={0}>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem}>
                                    <Grid item xs container direction="column">
                                        <img src={require('../resources/main_home_icon/total_member.png')} style={{height: 30, width: 30}}/>
                                        <Typography align='left'  style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10}}>전체회원</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>1,000명</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem}>
                                    <Grid item xs container direction="column">
                                        <img src={require('../resources/main_home_icon/connected_member.png')} style={{height: 30, width: 30}}/>
                                        <Typography align='left'  style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10}}>연결 된 회원</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>1,000명</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem}>
                                    <Grid item xs container direction="column">
                                        <img src={require('../resources/main_home_icon/news.png')} style={{height: 30, width: 35}}/>
                                        <Typography align='left'  style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10}}>NEWS</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>1,000건</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem}>
                                    <Grid item xs container direction="column">
                                        <img src={require('../resources/main_home_icon/cooperation.png')} style={{height: 30, width: 35}}/>
                                        <Typography align='left'  style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10}}>협력해요!</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>1,000건</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem}>
                                    <Grid item xs container direction="column" spacing={2} >
                                        <img src={require('../resources/main_home_icon/free_board.png')} style={{height: 30, width: 30}}/>
                                        <Typography align='left'  style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10}}>자유게시판</Typography>
                                        <Typography align='left'  style={{fontSize: 13, color: 'rgb(174,181,186)'}}>1,000건</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                            <Grid item xs={4} container direction="column">
                                <Button color='primary'  className={classes.mainTopContentItem}>
                                    <Grid item xs container direction="column">
                                        <img src={require('../resources/main_home_icon/welfare_commu.png')} style={{height: 30, width: 30}}/>
                                        <Typography align='left'  style={{fontSize: 13, fontWeight: 'bold', color: 'rgb(48,58,83)', marginTop: 10}}>복지 서비스<br/>& 커뮤니티</Typography>
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {/* 최근소식 */}
                    <Typography align='left' style={{width: '100%', fontSize: 13, fontWeight: 'bold', margin: '30px 0 20px'}}>최근소식</Typography>
                    <div container style={{width: '100%', border: '1px solid #E8E8E8', borderRadius: 0}}>
                        <List container style={{padding: 0}}>
                            <ListItem button color="primary" className={classes.newsList} style={{position: 'relative', cursor: 'pointer'}}>
                                <Grid container direction="column">
                                    <Typography align='left' className={classes.newsListTitle}>{newsTitle}</Typography>
                                    <Typography align='right'  style={{fontSize: 10, color: 'rgb(181,181,181)', marginRight: 50, marginTop: 5}}>{writeDay}</Typography>
                                    <ListItemIcon><img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/></ListItemIcon>
                                </Grid>
                            </ListItem>
                            <Divider />
                            <ListItem button color="primary" className={classes.newsList} style={{position: 'relative', cursor: 'pointer'}}>
                                <Grid container direction="column">
                                    <Typography align='left' className={classes.newsListTitle}>{newsTitle}</Typography>
                                    <Typography align='right'  style={{fontSize: 10, color: 'rgb(181,181,181)', marginRight: 50, marginTop: 5}}>{writeDay}</Typography>
                                    <ListItemIcon><img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/></ListItemIcon>
                                </Grid>
                            </ListItem>
                            <Divider />
                            <ListItem button color="primary" className={classes.newsList} style={{position: 'relative', cursor: 'pointer'}}>
                                <Grid container direction="column">
                                    <Typography align='left' className={classes.newsListTitle}>{newsTitle}</Typography>
                                    <Typography align='right'  style={{fontSize: 10, color: 'rgb(181,181,181)', marginRight: 50, marginTop: 5}}>{writeDay}</Typography>
                                    <ListItemIcon><img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/></ListItemIcon>
                                </Grid>
                            </ListItem>
                        </List>
                    </div>

                    {/*설치정보*/}
                    <div className={classes.downInfo}>
                        <img src={require('../resources/logo_1.png')} style={{height: 30}}/>
                        <Typography style={{color: '#303A53', marginTop: 15}}>
                            협회, 단체를 위한 명함 기반 회원명부 관리서비스!<br/>
                            가급적 모바일앱으로 이용해 주세요!
                        </Typography>
                        <Grid container alignItems='center' style={{marginTop: 10}}>
                            <Grid xs={6} style={{backgroundColor: '#555', height: 180, lineHeight: '180px'}}>QR코드</Grid>
                            <Grid xs={6}>
                                <Button style={{width: '100%', height: '33%'}}><img src={require('../resources/store_apple.png')} style={{width: '100%', height: '100%'}}/></Button>
                                <Button style={{width: '100%', height: '33%'}}><img src={require('../resources/store_google.png')} style={{width: '100%', height: '100%'}}/></Button>
                                <Button style={{width: '100%', height: '33%'}}><img src={require('../resources/store_one.png')} style={{width: '100%', height: '100%'}}/></Button>
                            </Grid>
                        </Grid>
                        <Typography style={{color: '#303A53', padding: 20}}>구글 PLAY, IOS, ONE스토어에서<br/>>스마트 모임 검색 설치</Typography>
                        {/* 버튼 */}
                        <Button
                            type="submit"
                            fullWidth
                            color="primary"
                            style={{backgroundColor: 'rgb(255,212,0)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                        >
                            카카오 1 : 1 문의하기
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            color="primary"
                            style={{backgroundColor: 'rgb(54,172,55)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                        >
                            앱 활용법 블로그보기
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            color="primary"
                            style={{backgroundColor: 'rgb(255,0,0)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                        >
                            앱 활용법 유투브보기
                        </Button>
                    </div>
                </div>

                <BottomNavi />
                {/* 팝업 */}
                {/*                    <Card className={classes.popUp}>
                        <Typography align='right'>
                            <IconButton>
                                <CloseIcon style={{color: 'black', fontSize: 30}}/>
                            </IconButton>
                        </Typography>
                        <CardContent style={{paddingTop: 5}}>
                            <Typography>
                                <img src={require('../resources/card_icon.png')} style={{height: 85}}/>
                            </Typography>
                            <Typography style={{marginTop: 20}}>MY 모바일 명함 등록을 먼저 해주세요!</Typography>
                        </CardContent>
                        <Button
                            color="primary"
                            className={classes.groupAdd}
                            style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                        >
                            MY명함 등록하기
                        </Button>
                    </Card>
                <div className={classes.popUpBg}></div>*/}

            </Container>
        </div>

    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        backgroundColor: 'rgb(249,250,252)',
        padding: '0 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    mainBnn: {
        width: '-webkit-fill-available',
        height: 200,
        marginTop: 55,
    },
    mainTopContent: {
        position: 'relative',
        height: 210,
        borderRadius: 10,
        backgroundColor: '#fff',
        boxShadow: '0 3px 6px rgba(0,0,0,0.15)',
    },
    mainTopContentItem: {
        height: 105,
        borderRadius: 0,
        backgroundColor: 'transparent',
        padding: 10,
    },
    newsList: {
      backgroundColor: '#fff'
    },
    newsListTitle: {
        fontSize: 13,
        fontWeight: 'bold',
        color: 'rgb(51,51,51)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '80%',
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    popUp: {
        zIndex: 1110,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    downInfo: {
        width: 450,
        backgroundColor: '#fff',
        padding: '50px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 50,
        position: 'absolute',
        top: 10, left: '50%',
        transform: 'translateX(200px)'
    }
}));

export default Main1;
