import React from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
    Avatar,
}from '@material-ui/core';
import PcHeader from "./PcHeader";

function NoticeDetail_3({history}) {
    const classes = useStyles();
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'알림 상세'} back={1}  history={history}/>

                <div className={classes.paper}>
                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        [연결요청]<br/>홍길동님이 명함교환 요청을 보내셨습니다.
                    </Typography>
                    {/* 상단 정보 */}
                    <div style={{width: '100%', padding: '20px 0', borderBottom: '1px solid rgb(226,231,235)', borderTop: '1px solid rgb(226,231,235)'}}>
                        <Grid container alignItems='center'direction='row' >
                            <Grid item xs={2} style={{marginRight: 10}}>
                                <Avatar alt="사진" src={require('../resources/example_profileimg.png')} className={classes.profileImgSize}/>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                            임재헌<span style={{fontSize: 11, color: 'rgb(181,181,181)', marginLeft: 5}}>팀장</span>
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                            주식화사 베스트 코리아
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography align='right' style={{width: '100%', fontSize: 10, color: 'rgb(181,181,181)'}}>2020-00-00 12:00:00</Typography>
                    </div>
                    <div align='left' style={{width: '100%', marginTop: 20}}>
                        <Button style={{width: '30%', padding: 10, border: '1px solid rgb(48,58,83)', borderRadius: 40, fontWeight: 'bold', marginRight: '5%'}}>명함 보기</Button>
                        <Button style={{width: '30%', padding: 10, backgroundColor: 'rgb(48,58,83)', borderRadius: 40, fontWeight: 'bold', color: '#fff', marginRight: '5%'}}>연결 수락</Button>
                        <Button style={{width: '30%', padding: 10, backgroundColor: 'rgb(239,113,113)', borderRadius: 40, fontWeight: 'bold', color: '#fff'}}>연결 거절</Button>
                    </div>
                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        MY명함 공개 전체로 설정되어 스마트명함에서 요청되었습니다. 앱을 설치하시면 연결 인맥을 확인 하실 수 있습니다.
                    </Typography>

                    <div style={{width: '100%', padding: 10, border: '2px solid #E6E6E6', borderRadius: 10}}>
                        <Grid container alignItems='center'direction='row'>
                            <Grid item xs={3} style={{marginRight: 15}}>
                                <img src={require('../resources/logo_sb.png')} style={{width: 75, height: 75}}/>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 16, color: 'rgb(48,58,83)', fontWeight: 'bold'}}>
                                            스마트 명함
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 12, color: 'rgb(92,92,92)',}}>
                                            개인을 위한 명함 관리 앱!<br/>
                                            회원가입 없이 이용 가능
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                </div>
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 60px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    profileImgSize: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },

}));


export default NoticeDetail_3;
