import React from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Grid,
    ListItem, Button, IconButton, Chip,

} from '@material-ui/core';
import PcHeader from "./PcHeader";

function SmartGroup() {
    const classes = useStyles();
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '30px'}}>
                <CssBaseline/>
                <PcHeader text={'가입 모임 목록'} />
                <div className={classes.paper}>
                    <Typography
                                style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        가입된 모임 목록을 클릭하시면 해당 모임<br/>
                        메인 페이지로 이동합니다.
                    </Typography>
                    <div style={{width: '100%'}}>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', cursor: 'pointer', width: '100%'}}>
                            <img src={require('../resources/association_example.png')} style={{height: 100, left: 20}} className={classes.yCenter}/>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                        <Button color="primary" className={classes.listBox} style={{position: 'relative', cursor: 'pointer', width: '100%'}}>
                            <img src={require('../resources/association_example.png')} style={{height: 100, left: 20}} className={classes.yCenter}/>
                            <img src={require('../resources/more_arrow.png')} style={{height: 15, padding: '0 5px', right: 10}} className={classes.yCenter}/>
                        </Button>
                    </div>
                    <Button
                        fullWidth
                        color="primary"
                        className={classes.groupAdd}
                        style={{backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10}}
                    >
                        모임 추가하기 +
                    </Button>

                </div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '0 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    /*button*/
    groupAdd: {
        margin: theme.spacing(3, 0, 2),
    },
    title: {
        fontSize: 14,
    },
    listBox: {
        height: '57px',
        border: '2px solid rgb(157,165,185)',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));


export default SmartGroup;
