
import {createAction, handleActions} from 'redux-actions';

const SETU_ID_A = 'adminSession/SETU_ID_A';
const SETB_ID_A = 'adminSession/SETB_ID_A';
const SETTOKEN_A = 'adminSession/SETTOKEN_A';

export const setu_id_a = createAction(SETU_ID_A, (input) => {
    return input;
});
export const setb_id_a = createAction(SETB_ID_A, (input) => {
    return input;
});
export const settoken_a = createAction(SETTOKEN_A, (input) => {
    return input;
});

const initialState = {
    u_id_a: undefined,
    b_id_a: undefined,
    token_a: undefined,
};

const adminSession = handleActions(
    {
        [SETU_ID_A]: (state, {payload: input}) => ({
            ...state,
            u_id_a: input,
        }),
        [SETB_ID_A]: (state, {payload: input}) => ({
            ...state,
            b_id_a: input,
        }),
        [SETTOKEN_A]: (state, {payload: input}) => ({
            ...state,
            token_a: input,
        }),
    },
    initialState,
);

export default adminSession;
