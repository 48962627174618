import React, {useEffect} from "react";
import {
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Link,
    Box,
    Typography,
    makeStyles,
    Container,
    useScrollTrigger,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import PcHeader from "./PcHeader";
import {useDispatch, useSelector} from "react-redux";
import {setu_id} from "../reducers/session";
import Axios from "axios";
import customAlert from "../component/customAlert";

//ArrowBack
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://info.smartbc.kr/">
                SmartWell
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

function PcLogin({history,match}) {


    const classes = useStyles();
    const varUA = navigator.userAgent.toLowerCase(); //platform
    const [phone,setPhone] = React.useState('');
    const [pw,setPw] = React.useState('');
    const [disabled,setDisabled] = React.useState(false);
    const [url,setUrl] = React.useState('signup');
    const [b_id,setB_id] = React.useState('');
    const [logo,setLogo] = React.useState();

    useEffect(() => {
        function firstAxios() {
            return Axios({
                url : 'https://sbc.smartbc.kr/sb/band_user_list.php',
                method : 'get',
                params : {
                    u_id : match.params.JoinCode // 초대코드가 아닌 관리자 아이디 기준으로 그룹별 url 생성
                },
            }).then(function (res) {
                console.log('route success');
                console.log(res.data);
                sessionStorage.setItem('@b_id',res.data.id);
                //return res.data;
            }).catch(function (e) {
                console.log('route fail');
                sessionStorage.setItem('@b_id', null);
            })

        }
        async function startAxios(){
            try {
                const groupInfo = await firstAxios();
                Axios({
                    url : 'https://sbc.smartbc.kr/sb/getLogo.php',
                    method : 'get',
                    params : {
                        b_id : sessionStorage.getItem('@b_id')
                    }
                }).then(function (res) {
                    console.log(res.data.li);
                    setLogo(res.data.li);
                    setB_id(sessionStorage.getItem('@b_id'));
                }).catch(function (e) {
                    customAlert('가입코드가 잘못되었습니다. 확인해주세요.');
                    console.log('null!!!');
                    setDisabled(true);
                    setUrl('#');
                })
            }catch (e) {
                console.log(e);
            }
        }
        startAxios();
    },[]);


    //const url = 'signup';

    // session
    //const b_id = sessionStorage.getItem('@b_id');

    console.log(match.params.JoinCode);

    console.log('그룹아이디',b_id)


    // const dispatch = useDispatch();//세션 상태
    // sessionStorage.setItem('@u_id',u_id);
    // // AsyncStorage.setItem('@u_id', u_id);
    // const stored_uid = useSelector((state) => state.session.u_id);
    // dispatch(setu_id(u_id));
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white'}}>
                <CssBaseline/>
                <PcHeader text={'로그인'}/>
                <div className={classes.paper} onClick={()=>{
                    if(disabled)customAlert('가입코드가 잘못되었습니다. 확인해주세요.');
                }}>
                    <img src={logo ? "https://sbc.smartbc.kr" + logo : require('../resources/logo_icon_update/스마트그룹_기본_BI.png')} style={{height: 50,maxWidth:250,marginTop : 35,marginBottom : 25 }}/>
                    <form className={classes.form} noValidate>
                        <TextField
                            disabled={disabled}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="phone"
                            label="- 없이 휴대폰 번호 입력"
                            name="phone"
                            autoComplete="email"
                            value={phone}
                            type={'numeric'}
                            autoFocus
                            onChange={(val) => {
                                let onlyNumber = val.target.value.replace(/[^0-9]/g, '');
                                setPhone(onlyNumber);
                            }}
                        />
                        <TextField
                            disabled={disabled}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="PC 로그인 패스워드 입력"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(val) => {
                                setPw(val.target.value);
                            }}
                        />

                        <Button
                            // disabled={disabled}
                            fullWidth
                            color="primary"
                            className={classes.submit}
                            style={{backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10}}
                            onClick={() => {
                                if (disabled) return;
                                // history.push({
                                //     pathname :'/',
                                //     state : {phone : '01011112222'},
                                // });
                                Axios({
                                    url : 'https://sbc.smartbc.kr/sb/pc/PcLogin.php',
                                    method :'get',
                                    params : {
                                        b_id : b_id,
                                        phone : phone,
                                        pw : pw,
                                    },
                                }).then(function (res) {
                                    console.log(res.data);
                                    // sessionStorage.setItem('@u_id',u);
                                    if(res.data == 201) customAlert('아이디 또는 비밀번호가 틀렸습니다.');
                                    //그룹정보설정여부확인,,,
                                    else if(res.data.status == 202){
                                        sessionStorage.setItem('@u_id',res.data.u_id);
                                        history.push({
                                            pathname : '/'+match.params.JoinCode+'/IcConfirm',
                                            state : {data: '110', phone: phone}
                                        })
                                    }
                                    else{
                                        sessionStorage.setItem('@u_id',res.data['u_id']);
                                        history.push({
                                            pathname :'/'+match.params.JoinCode,
                                            state : {},
                                        });
                                    }
                                }).catch(function (err) {
                                    console.log(err);
                                })
                                // Axios.post('https://sbc.smartbc.kr/sb/pc/PcLogin.php',{
                                //     b_id : b_id,
                                //     phone : phone,
                                //     pw: pw
                                // }).then(function (res) {
                                //     console.log(res.data);
                                // }).catch(function (err) {
                                //     console.log(err);
                                // })
                            }}
                        >
                            로그인
                        </Button>
                        <FormControlLabel
                            control={<Link color="inherit" href={url}>패스워드 재발급</Link>}
                            style={{float: 'right'}}
                        />
                        <Button
                            // disabled={disabled}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{backgroundColor: '#fff', color: '#303a53',fontWeight:'bold',fontSize :15,borderRadius : 25,padding :10,}}
                            onClick={() => {
                                if (disabled) return;
                                history.push('/'+match.params.JoinCode+'/signup');
                            }}
                        >
                            회원가입
                        </Button>
                    </form>
                </div>

                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}>
                    <Box mt={8} style={{position : 'fix' ,}}>
                        <Copyright/>
                    </Box>
                </div>

            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({

    paper: {
        padding: '0 30px 0 20px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


export default PcLogin;
