import React,{useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    List,ListItem,ListItemText,
    Grid,Card,
    CardContent,
    IconButton
}from '@material-ui/core';
import PcHeader from "./PcHeader";
import CloseIcon from '@material-ui/icons/Close';
import Axios from "axios";
import Moment from 'moment';

function AddComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}


export default function PartnerPointList({history,match}) {
    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');

    const [point_list,setPoint_list] = React.useState([]);
    const [point,setPoint] = React.useState('');
    let listBar = null;

    const [modal, setModal] = React.useState(false);

    useEffect(()=>{
        Axios({
            url: 'https://sbc.smartbc.kr/sb/my_point_list.php',
            method: 'get',
            params: {
                u_id: u_id,
            },
        })
            .then((result) => {
                console.log('dd',result.data);
                    // console.log('길이',point_list.length);
                    setPoint_list(result.data.point_list)
                    setPoint(result.data.point);

            })
            .catch((err) => console.log(err.message));

    },[]);

    const PointList = ({index}) => {

        //적립금액
        if(point_list[index].point>0){
            listBar =
                <ListItem>
                    <ListItemText className={classes.contentText}>{Moment(point_list[index].create_at).format('YYYY-MM-DD')}</ListItemText>
                    <ListItemText className={classes.contentText}>{AddComma(point_list[index].point)} P</ListItemText>
                    <ListItemText className={classes.contentText}></ListItemText>
                </ListItem>;
        }else{
            //사용금액
            listBar =
                <ListItem>
                    <ListItemText className={classes.contentText}>{Moment(point_list[index].create_at).format('YYYY-MM-DD')}</ListItemText>
                    <ListItemText className={classes.contentText}></ListItemText>
                    <ListItemText className={classes.contentText}>{AddComma(point_list[index].point)} P</ListItemText>
                </ListItem>;
        }

        return(listBar)
    };

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}} className='point'>
                <CssBaseline/>
                <PcHeader text={'포인트 관리'} back={1} history={history} match={match}/>
                {/* 상단 고정*/}
                <div style={{padding: '20px 25px 15px 20px', marginTop: 50, boxShadow:  '0 5px 10px rgba(0,0,0,0.15)', position: 'sticky', top: 55, backgroundColor: '#fff', zIndex: 10}} className={'allUser'}>
                    <div style={{padding: '12px 20px', width: '100%', textAlign: 'left', fontSize: 13, color: 'black', borderRadius: 10, position: 'relative', backgroundColor: '#f2f2f2',border:'1px solid #d9d9d9'}}>
                        포인트 잔액<Typography style={{marginLeft: 13, fontSize: 15, fontWeight: 'bold', color: '#000', display: 'inline-block'}}><span style={{color: 'rgb(239,113,113)',}}>{point?AddComma(point):0}</span> P</Typography>
                        <Button
                            type="submit"
                            color="primary"
                            style={{backgroundColor: 'rgb(43,66,124)', color: '#fff',
                               fontSize :13,borderRadius : 10,
                                padding: '5px 10px', boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
                                position: 'absolute', top: 6, right: 10,
                                textTransform:'none',
                            }}
                            onClick={()=>{
                                window.open('about:blank').location.href='http://sbc.smartwel.co.kr/welfarelogin';//새창 o
                                // alert('준비중 입니다.');
                                // if(point!=0){
                                //     setModal(true);
                                // }else{
                                //     alert('출금 요청할 포인트가 없습니다.');
                                // }
                            }}
                        >Point 사용
                        </Button>
                    </div>
                </div>
                {/* 리스트 */}
                <div className={classes.paper}>
                    <Grid container style={{color: 'black', fontWeight:'bold',fontSize: 13, padding: 10,borderBottom:'2px solid black'}}>
                        <Grid xs={4}>일자</Grid>
                        <Grid xs={4}>적립</Grid>
                        <Grid xs={4}>사용</Grid>
                    </Grid>
                    <List style={{width: '100%'}}>
                        {point_list.map(((value,i) => <PointList key={i} index={i} />))}

                    </List>
                </div>



            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    contentText: {
        textAlign: 'center',
        fontSize: 13,
        color: 'rgb(93,93,93)',
        width: '33%',
        display: 'inline-block'
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    '@global':{
        '.point .MuiListItemText-root':{
            flex: '1',
        },
        '.point .MuiListItem-root:nth-child(even)':{
            backgroundColor: 'rgba(0,0,0,0.1)', width: '100%', borderRadius: 10
        },
        '.point .MuiListItem-root:nth-child(odd)':{
            backgroundColor: '#fff', width: '100%', borderRadius: 10
        },
        '.point .MuiListItemText-primary': {fontSize: 13}
    }

}));


