import React, {useEffect, useState, useCallback, useRef} from "react";
import RegistCard from './Regist_card';//등록명함
import NotRegistCard from './Not_regist_card';//미등록명함
import OperatorCard from './Operator_card';//운영자
import {useDispatch, useSelector} from 'react-redux';
import {setmember_cnt} from '../reducers/session';
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Tab,
    Tabs,
    Divider,
    Grid, Box,
    InputBase, IconButton,
} from '@material-ui/core';
import PropTypes from "prop-types";
import PcHeader from "./PcHeader";
import SwipeableViews from 'react-swipeable-views';




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function Members({history,match}) {
    console.log('Members',history,match);

    const classes = useStyles();

    const [value, setValue] = React.useState(history.location.state.index ? history.location.state.index : 0);
    //const searchList = this.props.location.state.searchList
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const tabChange = (event, newValue) => {
        setValue(newValue);
    };

    const member_cnt = useSelector((state) => state.session.member_cnt);
    const member_cnt1 = useSelector((state) => state.session.member_cnt1);//등록명함 개수
    const member_cnt2 = useSelector((state) => state.session.member_cnt2);//미등록명함 개수
    const member_cnt3 = useSelector((state) => state.session.member_cnt3);//운영자 개수
    const dispatch = useDispatch();
    console.log(value);

    if(value==0){//등록명함
        dispatch(setmember_cnt(member_cnt1));
    }else if(value==1){//미등록명함
        dispatch(setmember_cnt(member_cnt2));
    }else if(value==2){//운영자
        dispatch(setmember_cnt(member_cnt3));
    }

    // if(history.location.state.index){
    //     if(history.location.state.index==0){
    //         handleChangeIndex(0);
    //         //history.location.state.index = null;
    //     }else if(history.location.state.index==1){
    //         handleChangeIndex(1);
    //         //history.location.state.index = null;
    //     }else if(history.location.state.index==2){
    //         handleChangeIndex(2);
    //         //history.location.state.index = null;
    //     }
    // }





    return (
        <>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <PcHeader text={'회원 명부'} back={1} history={history} match={match} cnt={member_cnt}/>
                {/* 상단 고정*/}
                <div style={{
                    padding: '20px 0 10px',
                    marginTop: 50,
                    boxShadow: '0 5px 10px rgba(0,0,0,0.15)',
                    position: 'sticky',
                    top: 55,
                    backgroundColor: '#fff',
                    zIndex: 10
                }} className={'Members'}>
                    <div>
                        <Tabs
                            value={value}
                            onChange={tabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{width: '100%', marginBottom: 5}}
                            classes={{ indicator: classes.indicator }}
                            variant="fullWidth"
                            aria-label="simple tabs example"
                            id={'tab'}
                        >
                            <Tab label="등록명함" {...a11yProps(0)} />
                            <Tab label="미등록명함" {...a11yProps(1)} />
                            <Tab label="운영자" {...a11yProps(2)} />
                        </Tabs>
                    </div>
                    <SwipeableViews enableMouseEvents
                        axis={'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}
                    >
                        <TabPanel value={value} index={0}>
                            <RegistCard history={history} match={match}/>
                        </TabPanel>
                        <TabPanel value={value} index={1} >
                            <NotRegistCard history={history} match={match}/>
                        </TabPanel>
                        <TabPanel value={value} index={2} >
                            <OperatorCard history={history} match={match}/>
                        </TabPanel>
                    </SwipeableViews>
                </div>
            </Container>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    indicator:{
        backgroundColor: '#303a53'
    }

}));





