import React, {useEffect} from "react";
import {
    Divider,
    Grid,
    Container, CssBaseline, makeStyles,
    Box, TextField, Typography, Button, IconButton, Avatar
} from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";
import {Link} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import customAlert from "../component/customAlert";
import customConfirm from "../component/customConfirm";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

function NoticeBoardDetail2({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    const admin = sessionStorage.getItem('@admin');

    const [boardDetail,setBoardDetail] = React.useState(new Array()); //게시글 상세
    const [r_content,setR_content] = React.useState('');// 게시글 중 url이 있을 시 자동링크된 게시글.
    const [replyList,setReplyList] = React.useState(new Array()); //댓글 리스트
    const [refresh , setRefresh] = React.useState(false);
    const [WriteReply , setWriteReply] = React.useState(false);

    const table = history.location.state.table; //소식 , 협력 , 자유게시판에 따른 게시글 테이블
    const board_id = history.location.state.board_id // 게시글 아이디

    function aa() {
        return {__html : r_content};
    }

    const ReplyList = ({id,name , company_name,profile,uid,content}) => {
        const [UpdateReply , setUpdateReply] = React.useState(false);

        let isMobile = false;
        const filter = "win16|win32|win64|mac|macintel";
        isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;

        return(
            <>
                {/*글쓴이*/}
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: '10px 30px', position: 'relative'}}>
                        <Grid container alignItems='center'direction='row'>
                            <Grid item xs={2} style={{marginRight: 10}} onClick={() => {
                                // history.push('/' );
                            }}>
                                <img alt="사진" src={profile ? 'https://sbc.smartbc.kr/'+profile : require('../resources/profile_exampleImg.png')} style={{width: 50, height: 50,borderRadius : 50}}/>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                            {name ? name : '모임관리자'}
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                            {company_name ? company_name : '스마트 모임' }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*댓글 3점버튼*/}
                        {(uid == u_id || admin == '1') ?
                            (
                                <ThreeDotsButton type={'reply'} update={setUpdateReply} reply_id={id}/>
                            ) : null}
                    </Grid>
                </div>
                {/*내용*/}
                {UpdateReply ?
                    (
                        <div style={{width: '100%',}}>
                            <div alignItems='center'  container style={{padding: '10px 30px'}}>
                                <Box component={'textarea'}
                                     id={'UpdateReply'}
                                     style={{border: 'none', outline: 'none', width: '100%', padding: 10, resize: 'none', lineHeight: 1.5, minHeight: 60}} placeholder='명예회손, 욕성 등의 악플은 경고없이 삭제됩니다.'/>
                            </div>
                            <div style={{padding: '0 30px'}}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    style={{backgroundColor: 'rgb(48,58,83)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                                    onClick={() => {
                                        Axios({
                                            url: "https://sbc.smartbc.kr/sb/reply_update.php",
                                            method: 'get',
                                            params: {
                                                reply_id : id,
                                                content : document.getElementById('UpdateReply').value,
                                            }
                                        })
                                            .then(function (res) {
                                                console.log('reply write success');
                                                console.log(res.data);
                                                if (res.data == 200) {
                                                    setRefresh(!refresh);
                                                } else {
                                                    console.log('reply error');
                                                }
                                            })
                                            .catch(function (e) {
                                                console.log('reply write fail');
                                                console.log(e);
                                            })
                                    }}
                                >
                                    수정하기
                                </Button>
                            </div>
                        </div>
                    ) :
                    (
                        <>
                            <div style={{width: '100%', padding: '10px 30px'}}>
                                <Typography align='left' style={{border: 'none', outline: 'none', width: '100%', padding: 10, fontSize: 13, lineHeight: 1.6,paddingBottom : 40}}>
                                    {content}
                                </Typography>
                            </div>
                            <Divider/>
                        </>
                    )}

            </>
        );
    }

    const ThreeDotsButton = ({type,update,reply_id}) => {

        /**
         * 3점버튼 클릭 시 이벤트
         * type = 'board' , 'reply' 게시물 상세 ,댓글
         */

        const [open,setOpen] = React.useState(false);
        const [select,setSelect] = React.useState('');
        const anchorRef = React.useRef(null);

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }
            if(type == 'board'){
                //게시물 상세 수정
                if(event.target.value == 1){
                    history.push({
                        pathname : 'NoticeBoardWrite',
                        state : {
                            table : table,
                            type : 'update',
                            board_id : board_id
                        }
                    })
                }else if(event.target.value == 2) {
                    setConfirmID(1);
                    setConfirmMessage('정말 게시물을 삭제하시겠습니까?');
                    setConfirmShow(true);
                    // let aa = customConfirm('정말 게시물을 삭제하시겠습니까?');
                    // if(aa){
                    //     Axios({
                    //         url: "https://sbc.smartbc.kr/sb/delete_board.php",
                    //         method: 'get',
                    //         params: {
                    //             board_id :board_id,
                    //             table : table
                    //         }
                    //     })
                    //         .then(function (res) {
                    //             console.log('delete board success');
                    //             if (res.data == 200) {
                    //                 customAlert('삭제 되었습니다.');
                    //                 if (table == 'band_board1') {
                    //                     history.push({
                    //                         pathname : 'NoticeBoard',
                    //                         state : {tabValue : 0}
                    //                     });
                    //                 }else if(table == 'band_board2'){
                    //                     history.push({
                    //                         pathname : 'NoticeBoard',
                    //                         state : {tabValue : 1}
                    //                     });
                    //                 }else if(table == 'band_board3'){
                    //                     history.push({
                    //                         pathname : 'NoticeBoard',
                    //                         state : {tabValue : 2}
                    //                     });
                    //                 }
                    //             } else {
                    //                 customAlert('삭제 실패');
                    //             }
                    //         })
                    //         .catch(function (e) {
                    //             console.log('delete board error');
                    //             console.log(e);
                    //         })
                    // }
                }
            }else if(type == 'reply'){
                //댓글 수정
                if(event.target.value == 1){
                    update(true);
                    console.log('update = ' + update );
                }else if(event.target.value == 2){
                    setReply_id(reply_id);
                    setConfirmID(2);
                    setConfirmMessage('정말 댓글을 삭제하시겠습니까?');
                    setConfirmShow(true);
                    // let aa = customConfirm('정말 댓글을 삭제하시겠습니까?');
                    // if(aa){
                    //     Axios({
                    //         url: "https://sbc.smartbc.kr/sb/delete_reply.php",
                    //         method: 'get',
                    //         params: {
                    //             reply_id : reply_id
                    //         }
                    //     })
                    //         .then(function (res) {
                    //             console.log('delete reply success');
                    //             if (res.data == 200) {
                    //                 customAlert('삭제 되었습니다.');
                    //                 setRefresh(!refresh);
                    //             } else {
                    //                 customAlert('삭제 실패');
                    //             }
                    //         })
                    //         .catch(function (e) {
                    //             console.log('delete board error');
                    //             console.log(e);
                    //         })
                    // }
                }
            }

                setOpen(false);
            }

        function handleListKeyDown(event) {
            if (event.key === 'Tab') {
                event.preventDefault();
                setOpen(false);
            }
        }

        return (
            <>
            <IconButton style={{position: 'absolute', right: 10, padding: '23px 8px', transform: 'rotate(90deg)'}} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                <span style={{width: 6, height: 6, borderRadius: 10, backgroundColor: '#888', margin: 2}}></span>
                <span style={{width: 6, height: 6, borderRadius: 10, backgroundColor: '#888', margin: 2}}></span>
                <span style={{width: 6, height: 6, borderRadius: 10, backgroundColor: '#888', margin: 2}}></span>
            </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleClose} value={'1'}>수정하기</MenuItem>
                                        <MenuItem onClick={handleClose} value={'2'}>삭제하기</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                </>
        );
    }



    // function down(url) {
    //     const link = document.createElement('a');
    //     link.href = 'https://sbc.smartbc.kr/'+url;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     return;
    // }

    useEffect(() => {
        Axios({
            url: "https://sbc.smartbc.kr/sb/pc/BoardDetail.php",
            method: 'get',
            params: {
                table : table,
                board_id : board_id,
                u_id : u_id,
            }
        })
            .then(function (res) {
                console.log("board_detail success");
                console.log(res.data);
                // if (res.data['profile']) setProfile({uri: "https://sbc.smartbc.kr" + res.data['profile']});
                // else setProfile(require('../resources/profile_exampleImg.png'));
                // setTitle(res.data['title']);
                // setContent(res.data['content']);
                // setName(res.data['name']);
                // setCompany(res.data['company']);
                // setDate(res.data['date']);
                // setWriter(res.data['writer']);
                // setLike_num(res.data['like_num'] ? res.data['like_num'] : 0);
                // setLike_search(res.data['like_search']);
                // setAdmin(res.data['admin']);
                // setUpfile(res.data['upfile']);
                setBoardDetail(res.data.list);
                setR_content(res.data.r_content);
                console.log('rcontent = ' + res.data.r_content);
                // res.data['content'].forEach(function (idx,ele) {
                //     let retVal = getUrl(ele.textContent);
                //     if(retVal){
                //         let newUrl = "<a href='#'><span class='autolink'>"+retVal+"</span></a>";
                //         console.log('textcontent');
                //         let textContent = this.textContent.replace(retVal, newUrl).toString();
                //         console.log(typeof(textContent));
                //         document.getElementById('boardDetailContent').innerText(textContent);
                //     }
                // })
            })
            .catch(function (e) {
                console.log("board_detail fail");
            })
        let board_type = "";
        if (table == 'band_board1') board_type = 1;
        else if (table == 'band_board2') board_type = 2;
        else if (table == 'band_board3') board_type = 3;
        Axios({
            url: "https://sbc.smartbc.kr/sb/band_reply_list.php",
            method: 'get',
            params: {
                board_type : board_type,
                board_id : board_id,
            }
        })
            .then(function (res) {
                console.log("reply list success");
                console.log(res.data);
                setReplyList(res.data);
            })
            .catch(function (e) {
                console.log("reply list fail");
            })
    }, [refresh]);

    const [reply_id , setReply_id ] = React.useState(0);
    const [confirmShow , setConfirmShow ] = React.useState(false);
    const [confirmID , setConfirmID ] = React.useState(0);
    const [confirmMessage , setConfirmMessage ] = React.useState('');

    const handleCloseN = () => {
        setConfirmShow(false);
    };

    const handleCloseY = () => {
        setConfirmShow(false);
        if(confirmID === 1) {
            Axios({
                url: "https://sbc.smartbc.kr/sb/delete_board.php",
                method: 'get',
                params: {
                    board_id :board_id,
                    table : table
                }
            })
                .then(function (res) {
                    console.log('delete board success');
                    if (res.data == 200) {
                        customAlert('삭제 되었습니다.');
                        if (table == 'band_board1') {
                            history.push({
                                pathname : 'NoticeBoard',
                                state : {tabValue : 0}
                            });
                        }else if(table == 'band_board2'){
                            history.push({
                                pathname : 'NoticeBoard',
                                state : {tabValue : 1}
                            });
                        }else if(table == 'band_board3'){
                            history.push({
                                pathname : 'NoticeBoard',
                                state : {tabValue : 2}
                            });
                        }
                    } else {
                        customAlert('삭제 실패');
                    }
                })
                .catch(function (e) {
                    console.log('delete board error');
                    console.log(e);
                })
        }else if(confirmID == 2){
            Axios({
                url: "https://sbc.smartbc.kr/sb/delete_reply.php",
                method: 'get',
                params: {
                    reply_id : reply_id
                }
            })
                .then(function (res) {
                    console.log('delete reply success');
                    if (res.data == 200) {
                        customAlert('삭제 되었습니다.');
                        setRefresh(!refresh);
                    } else {
                        customAlert('삭제 실패');
                    }
                })
                .catch(function (e) {
                    console.log('delete board error');
                    console.log(e);
                })
        } else if(confirmID == 3){
            window.open("https://info.smartbc.kr/b2a");
        }

    };

    return (
        <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', alignItems: 'center'}}>
            <CssBaseline/>
            {/*header*/}
            <PcHeader text={'게시글 상세'} back={1} history={history}/>
            {/*body*/}
            <div className={classes.paper}>

                {/*custom_confirm*/}
                <div>
                    <Dialog
                        open={confirmShow}
                        onClose={handleCloseN}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        {/*<DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>*/}
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {confirmMessage}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseN} color="primary">
                                {'아니오'}
                            </Button>
                            <Button onClick={handleCloseY} color="primary" autoFocus>
                                {'예'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                {/*제목*/}
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: '15px 30px'}}>
                        <Grid item xs={10}>
                            <Typography align='left' style={{border: 'none', outline: 'none', width: '100%', padding: 10}}>{boardDetail.title}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{textAlign :'right' ,}}>
                            {boardDetail.upfile ?
                                (
                                    <IconButton>
                                    <img src={require('resources/fileimage.png')}
                                         style={{
                                             height: 25,
                                             width: 'auto',
                                             verticalAlign: 'middle'
                                         }}
                                    onClick={() => {

                                        // down(boardDetail.upfile);
                                        // Axios({
                                        //     url : 'https://sbc.smartbc.kr'+boardDetail.upfile,
                                        //     method :'get',
                                        //
                                        // })
                                        window.location.href = 'https://sbc.smartbc.kr/sb/download_file.php?file='+boardDetail.upfile;
                                    }}/>
                                    </IconButton>
                                ) : null}
                        </Grid>
                    </Grid>
                    <Divider/>
                </div>
                {/*글쓴이*/}
                <div style={{width: '100%',}}>
                    <Grid alignItems='center'  container style={{padding: '10px 30px', position: 'relative'}}>
                        <Grid container alignItems='center'direction='row'>
                            <Grid item xs={2} style={{marginRight: 10}} onClick={() => {
                                if(boardDetail.admin != 1){
                                    let join_option = null;
                                    if(boardDetail.join_option2_val){
                                        join_option =  Object.values(JSON.parse(boardDetail.join_option2_val));
                                    }
                                    history.push({
                                        pathname : '/'+match.params.JoinCode+'/MembersCardDetail',
                                        state : {
                                            sel_u_id : boardDetail.writer, //targetId
                                            join_option:join_option ,
                                        }
                                    });
                                }
                            }}>
                                <img alt="사진" src={boardDetail.admin == 1 ? (require('../resources/profile_exampleImg.png')) : (boardDetail.profile ? 'https://sbc.smartbc.kr/'+boardDetail.profile : require('../resources/profile_exampleImg.png'))} style={{width: 50, height: 50,borderRadius : 50}}/>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                            {boardDetail.admin == 1 ? '모임관리자' : boardDetail.name}
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                            {boardDetail.admin == 1 ? '스마트 모임' : boardDetail.company}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*게시글 3점버튼*/}
                        {(boardDetail.writer == u_id || admin == '1')?
                            (
                                <ThreeDotsButton type={'board'}/>
                            ) : null}
                    </Grid>
                </div>
                {/*내용*/}
                <div style={{width: '100%', padding: '10px 30px'}}>
                        <Typography align='left' style={{border: 'none', outline: 'none', width: '100%', padding: 10, fontSize: 13, lineHeight: 1.6,wordBreak:'break-all'}} id={'boardDetailContent'}>
                            <pre style={{whiteSpace : 'pre-wrap'}}><div dangerouslySetInnerHTML={aa()}></div></pre>
                        </Typography>
                        <Typography align='right' style={{fontSize: 13, color: '#888', marginTop: 10}}>{boardDetail.date}</Typography>
                </div>
                <div style={{width: '100%'}}>
                    <div align='left' style={{width: '100%', margin: '20px 0', padding: '0 30px'}}>
                        <Button onClick={() => {
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/like_update.php',
                                method: 'get',
                                params: {
                                    u_id : u_id,
                                    table : table,
                                    board_id : board_id
                                }
                            }).then(function (res) {
                                console.log('like update success');
                                setRefresh(!refresh);
                                console.log(res.data);
                            })
                                .catch(function (e) {
                                    console.log('like update fail');
                                    console.log(e);
                                })
                        }}
                            style={boardDetail.like_search ? {width: '30%', padding: 10, borderRadius: 40, backgroundColor: '#c1dff5', fontWeight: 'bold', marginRight: '5%',fontSize : 12} : {width: '30%', padding: 10, borderRadius: 40, backgroundColor: 'rgb(249,250,252)', fontWeight: 'bold', marginRight: '5%',fontSize : 12}}>
                            <img src={require('../resources/like_icon.png')} style={{width: 15, marginRight: 10}}/><span style={{fontSize: 12}}>{boardDetail.like_num ? boardDetail.like_num : 0}</span>
                        </Button>
                        <Button onClick={() => {
                            setWriteReply(!WriteReply);
                        }}
                            style={{width: '30%', padding: 10, backgroundColor: 'rgb(249,250,252)', borderRadius: 40, fontWeight: 'bold', marginRight: '5%',fontSize : 11}}>
                            <img src={require('../resources/review_icon.png')} style={{width: 15, marginRight: 10}}/>댓글쓰기
                        </Button>
                        <Button onClick={() => {
                            let isMobile = false;

                            // PC 환경
                            const filter = "win16|win32|win64|mac|macintel";
                            const varUA = navigator.userAgent.toLowerCase();

                            if(navigator.platform){
                                isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
                                if(isMobile){
                                    let answer = customConfirm ("해당 기능은 모바일앱에서만 이용이 가능합니다. \n 앱 설치 : https://info.smartbc.kr/b2a");  //모바일
                                    if (answer) window.open("https://info.smartbc.kr/b2a");
                                }else{
                                    customAlert("해당 기능은 모바일앱에서만 이용이 가능합니다."); //pc
                                }
                            }
                        }}
                            style={{width: '30%', padding: 10, backgroundColor: 'rgb(249,250,252)', borderRadius: 40, fontWeight: 'bold',fontSize : 11}}>
                            <img src={require('../resources/share.png')} style={{width: 15, marginRight: 10}}/>공유하기
                        </Button>
                    </div>
                    <Divider/>
                </div>

                {/* 댓글쓰기 */}
                {WriteReply ?
                    (
                        <div style={{width: '100%',}}>
                            <div alignItems='center'  container style={{padding: '10px 30px'}}>
                                <Box component={'textarea'}
                                     id={'replyContents'}
                                     style={{border: 'none', outline: 'none', width: '100%', padding: 10, resize: 'none', lineHeight: 1.5, minHeight: 60}} placeholder='명예회손, 욕성 등의 악플은 경고없이 삭제됩니다.'/>
                            </div>
                            <div style={{padding: '0 30px'}}>
                                <Button
                                    fullWidth
                                    color="primary"
                                    style={{backgroundColor: 'rgb(48,58,83)', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginTop: 10}}
                                    onClick={() => {
                                        Axios({
                                            url: "https://sbc.smartbc.kr/sb/reply_write.php",
                                            method: 'get',
                                            params: {
                                                u_id : (admin == '0' ? u_id : 0),
                                                board_id : board_id,
                                                board_type : table,
                                                content : document.getElementById('replyContents').value,
                                            }
                                        })
                                            .then(function (res) {
                                                console.log('reply write success');
                                                console.log(res.data);
                                                if (res.data == 200) {
                                                    setWriteReply(!WriteReply);
                                                    setRefresh(!refresh);
                                                } else {
                                                    console.log('reply error');
                                                }
                                            })
                                            .catch(function (e) {
                                                console.log('reply write fail');
                                                console.log(e);
                                            })
                                    }}
                                >
                                    댓글쓰기
                                </Button>
                            </div>
                        </div>
                    ) : null}
                {/*댓글*/}
                <div style={{width: '100%',}}>
                    {replyList ?
                        (replyList.map((val,index) =>
                                <ReplyList key={index} id={val.id} name={val.name} company_name={val.company_name} uid={val.u_id} content={val.content} profile={val.profile}/>
                        )

                    ) : null}

                </div>
            </div>

        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 60,
        width: '100%',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    uploadName: {
        border: 0,
        width: '100%',
        height: '100%',
        background: '#fff'
    },
    fileSelectBtn: {
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: 'rgb(43,66,124)',
        display: 'inline-block',
        width : '100%',
        padding: 12,
        borderRadius: 30,
        verticalAign: 'middle',
        color: '#fff',
        fontWeight: 'bold'
    },
    fileInput: {
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        border: 0,
        opacity: 0
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        display: 'hidden',
        margin: theme.spacing(1),
        minWidth: 120,
    },

}));
export default NoticeBoardDetail2;
