import React from "react";
import {Button} from "@material-ui/core";

function RoundButton({text,startIcon,styles,classNames}) {
    return(
        <Button
            type="submit"
            fullWidth
            variant="contained"
            style={styles? styles : {backgroundColor: '#303a53',
                color: '#fff',
                fontSize: 15,
                borderRadius: 25,
                padding: 10,}}
            startIcon={startIcon}
            className={classNames}
            >{text}</Button>
    );
}

export default RoundButton;
