import React,{useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    FormControl,
    MenuItem,
    Select,
    Grid, Switch,
} from '@material-ui/core';
import PcHeader from "../PcHeader";
import Axios from "axios";
import customAlert from "../../component/customAlert";

function AdminSettingInfo({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const [opList,setOpList] = React.useState([]);
    const [is_options,setIs_options] = React.useState('');
    // let b_link_allow = '';
    const [b_link_allow,setB_link_allow]=React.useState('');
    const [isEnabled, setIsEnabled] = React.useState(false);

    const  [selectedList, setSelectedList] = React.useState({});//선택한 옵션값
    /*스위치*/
    const [state, setSwitch] = React.useState({
        communityLink: true,
    });
    const switchHandle = (event) => {
        setSwitch({ ...state, [event.target.name]: event.target.checked});
        console.log('체크',event.target.checked,);
        setIsEnabled(!isEnabled);
    };
    useEffect(()=>{

        Axios({
            url: 'https://sbc.smartbc.kr/sb/band_admin/admin_setting_info.php',
            method: 'get',
            params: {
                u_id: u_id,
                b_id: b_id,
            },
            // resultType:'text',
        })
            .then((result) => {
                console.log(result.data);
                if (result.data.status == 19001) {
                    // Toast.show(result.data.result_msg);
                    setOpList(result.data.op_list);
                    setIs_options(result.data.is_options);
                    // b_link_allow = result.data.b_link_allow;
                    setB_link_allow(result.data.b_link_allow);

                    if(result.data.b_link_allow ==1){
                        setIsEnabled(true);
                    }else{
                        setIsEnabled(false);
                    }
                }
            })
            .catch((err) => console.log(err.message));

    },[]);

    const OptionList = ({option1,option2,selList})=> {
        const [tmpValue,setTmpValue] = React.useState(selList);
        // console.log('옵션',option2);
        let items = new Array();
        option2.map((value)=> {
            return (
                items.push( <MenuItem value={value.id} >{value.name}</MenuItem>)
            );
        });
        return (
            <Grid container spacing={2}>
                <Grid item xs={3} className={classes.selectTilie}>{option1.name}</Grid>
                <Grid item xs={9}>
                    <FormControl variant="filled" className={classes.formControl}>
                        <Select
                            // displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{backgroundColor: '#fff'}}
                            // classes={{root: classes.selectInput}}
                            value={tmpValue}
                            defaultValue={0}
                            onChange={(event) => {
                                console.log('선택',event.target.value);
                                setTmpValue(event.target.value);
                                let tmp = selectedList;
                                tmp[option1.id] = event.target.value;
                                setSelectedList(tmp);
                            }}
                        >
                            <MenuItem value="0">선택</MenuItem>
                            {items}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'모임 설정 정보'} back={1} history={history} match={match}/>
                <div className={classes.paper}>
                    <Typography align= 'center'
                                style={{fontSize: '13px', backgroundColor: 'rgb(239,242,246)', padding: 10, width: '100%', borderRadius: 10}}>
                        회원가입 설정 정보
                    </Typography>
                    {is_options?
                        <form className={classes.form} noValidate style={{marginBottom: 20}} >
                            {opList.map((value)=> <OptionList key={value[0].id} option1={value[0]} option2={value[1]} selList={selectedList[value[0].id]} />)}
                        </form>
                        :
                        //협회 설정 옵션 없을 경우
                        <div style={{width: '100%',marginTop:15,marginBottom: 10}}>
                            <Grid color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}>
                                <Typography align='center' style={{textIndent: 20, color: 'rgb(51,51,51)', lineHeight: '57px'}}>설정 정보가 없습니다.</Typography>
                            </Grid>
                        </div>
                    }
                    <Typography align= 'center'
                                style={{fontSize: '13px', backgroundColor: 'rgb(239,242,246)', padding: 10, width: '100%', borderRadius: 10, marginBottom: 20}}>
                        커뮤니티 링크적용
                    </Typography>
                    <div style={{width: '100%'}}>
                        <Grid color="primary" className={classes.listBox} style={{position: 'relative', width: '100%'}}>
                            <Typography align='left' style={{textIndent: 20, color: 'rgb(51,51,51)', lineHeight: '57px'}}>사용유무</Typography>
                            <div  style={{right: 0}} className={classes.yCenter}>
                                <Switch
                                    checked={isEnabled}
                                    onChange={switchHandle}
                                    color="primary"
                                    name="communityLink"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </div>
                        </Grid>
                    </div>
                    {/* 버튼 */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.bottonBtn}
                        onClick={()=>{
                            console.log('값',isEnabled);

                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/band_admin/link_allow_update.php',
                                method: 'get',
                                params: {
                                    b_id:b_id,
                                    b_link_allow:isEnabled,
                                },
                            })
                                .then((result) => {
                                    console.log(result);
                                    customAlert(result.data.result_msg);
                                })
                                .catch((err) => console.log(err.message));
                        }}
                    >
                        회원정보 수정
                    </Button>
                </div>
            </Container>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '30px 40px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    selectInput:{
        padding: '23px 12px 20px'
    },
    formControl: {
        width: '100%',
        textAlign: 'left',
        textIndent: 10,
        marginTop: theme.spacing(3),
    },
    selectTilie: {
        fontSize: 15,
        textAlign: 'center',
        marginTop: theme.spacing(4),
        backgroundColor: 'rgb(249,250,252)',
        width: 105,
        height: 60,
        lineHeight: '60px',
        borderRadius: 10,
        marginRgiht: 10
    },
    listBox: {
        display: 'block',
        height: '57px',
        border: '2px solid rgb(189,204,240)',
        borderRadius: '10px',
        marginBottom: '10px',
    },
    yCenter: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    bottonBtn: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 60,
        background: 'rgb(43,66,124)',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight: 'bold',
        '&:hover': {
            background: 'rgb(43,66,124)',
        },
    }

}));
export default AdminSettingInfo;
