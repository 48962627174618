import React,{useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    List,ListItem,ListItemText,
    Grid,Card,
    CardContent,
    IconButton
}from '@material-ui/core';
import PcHeader from "./PcHeader";
import {BsDot} from 'react-icons/bs';
import Axios from "axios";
import Moment from 'moment';
import CloseIcon from "@material-ui/icons/Close";

function AddComma(num) {
    var regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}


export default function PartnerNonApprove({history,match}) {
    const classes = useStyles();
    const u_id = sessionStorage.getItem('@u_id_a');
    const b_id = sessionStorage.getItem('@b_id_a');

    const [point_list,setPoint_list] = React.useState([]);
    const [point,setPoint] = React.useState('');
    let listBar = null;

    const [modal, setModal] = React.useState(false);




    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}} className='point'>
                <CssBaseline/>
                <PcHeader text={'자문단 & 파트너 미 승인'} back={1} history={history} match={match} />

                <div className={classes.paper} onClick={()=>{
                    window.open('https://pf.kakao.com/_HXWYT','_blank');
                }}>

                        <div style={{padding: '15px 20px', width: '100%',  borderRadius: 10, position: 'relative', backgroundColor: '#f2f2f2',border:'1px solid #d9d9d9'}}>
                            <div style={{display:'flex',alignItems: 'center',marginBottom:7}}>
                            <BsDot style={{color: 'black', fontSize: 15}} />
                            <Typography style={{textAlign: 'left', fontSize: 14, color: 'black',}}>{'자문단 & 파트너 미 승인 상태입니다.'}</Typography>
                            </div>
                            <div style={{display:'flex',alignItems: 'center',}}>
                                <BsDot style={{color: 'black', fontSize: 15}} />
                                <Typography  style={{textAlign: 'left', fontSize: 14, color: 'black',}}>{'승인된 후에 서비스를 이용해 주세요!!!'}</Typography>
                            </div>
                        </div>

                    <Typography style={{fontSize: 14, fontWeight:'bold', backgroundColor: '#ffff4d',border:'1px solid #F6BB43', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10,color:'navy'}}>
                        파트너 승인 관련 1 : 1 문의
                    </Typography>


                </div>


            </Container>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px',
        marginTop: 70,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    contentText: {
        textAlign: 'center',
        fontSize: 13,
        color: 'rgb(93,93,93)',
        width: '33%',
        display: 'inline-block'
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    '@global':{
        '.point .MuiListItemText-root':{
            flex: '1',
        },
        '.point .MuiListItem-root:nth-child(even)':{
            backgroundColor: '#fff', width: '100%', borderRadius: 20
        },
        '.point .MuiListItem-root:nth-child(odd)':{
            backgroundColor: 'rgba(0,0,0,0.1)', width: '100%', borderRadius: 20
        },
        '.point .MuiListItemText-primary': {fontSize: 13}
    }

}));


