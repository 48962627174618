import React, {useCallback, useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Tab,
    Tabs,
    Divider,
    Grid, Box,
    InputBase, IconButton, CircularProgress, Button,
} from '@material-ui/core';
import PcHeader from "./PcHeader";
import BottomNavi from "./BottomMenu";
import PropTypes, {number} from "prop-types";
import SearchIcon from '@material-ui/icons/Search';
import Axios from "axios";
import '../css/font.css';
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import GetAppIcon from "@material-ui/icons/GetApp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";



function NoticeBoard({history, match}) {

    const classes = useStyles();

    //접속한 유저 아이디 , 그룹 아이디 세션 값
    const admin = sessionStorage.getItem('@admin');
    const u_id = sessionStorage.getItem('@u_id');
    const b_id = admin == 1 ? sessionStorage.getItem('@b_id_a') : sessionStorage.getItem('@b_id');

    const table = history.location.state.table;

    const table1 ='band_board1';
    const table2 ='band_board2';
    const table3 ='band_board3';

    //리스트
    const [NewsList, setNewsList] = React.useState(new Array());
    const [CooperList, setCooperList] = React.useState(new Array());
    const [FreeList, setFreeList] = React.useState(new Array());

    const [text, setText] = React.useState();
    const [refresh, setRefresh] = React.useState(false);

    const [search1, setSearch1] = React.useState(false);//board1 검색
    const [search2, setSearch2] = React.useState(false);//board2 검색
    const [search3, setSearch3] = React.useState(false);//board3 검색

    const [loading, setLoading] = React.useState(true);

    const[menuName3,setMenuName3] = React.useState('NEWS');
    const[menuName4,setMenuName4] = React.useState('협력해요!');
    const[menuName5,setMenuName5] = React.useState('자유게시판');
    const[menuLevel3,setMenuLevel3] = React.useState(); // 1:운영자  2:우수회원  3:운영자+우수회원  4:전체회원
    const[menuLevel4,setMenuLevel4] = React.useState();
    const[menuLevel5,setMenuLevel5] = React.useState();
    const[levelType,setLevelType] = React.useState('');
    const[userLevel,setUserLevel] = React.useState();
    const[card_regist,setCard_regist] = React.useState();
    const[modal,setModal] = React.useState(false); //등급 안내 팝업
    const[modal2,setModal2] = React.useState(false);//명함 없을 시 등록 팝업

    const [menuName, setMenuName] = React.useState('게시판');
    const [boardCnt, setBoardCnt] = React.useState(0);


    const [diff, setDiff] = React.useState(0);
    // let diff = 0 ;


    // const [value, setValue] = React.useState(history.location.state.tabValue);
    // const tabChange = (event, newValue) => {
    //     console.log('newvalue = '+newValue + ' ,value = ' );
    //     setValue(newValue);
    //     setText('');
    //     if(value == 0) setSearch1(!search1);
    //     else if(value == 1) setSearch2(!search2);
    //     else if(value == 2) setSearch3(!search3);
    // };


    useEffect(()=>{
        // console.log('순서',22222222222222222);
        // console.log('1스크롤위치?',window.pageYOffset)

        window.addEventListener("scroll", (event) => {
            // console.log('이벤트가뭐야', event);
            // console.log('이벤트가뭐야', event.target.URL);
            let str = event.target.URL;
            let arr = str.split('/');
            // console.log('확인',arr[4]);
            // console.log('검색22',search1,":",search2,":",search3,":")
            // console.log('검색어22,,,',text);
            // console.log('diff22,,,',diff);
            if(arr[4]=='NoticeBoard'){
                    if (window.pageYOffset > 0) {
                        sessionStorage.setItem("@scrollPos", window.pageYOffset);
                        // console.log('2스크롤위치?', window.pageYOffset)
                    }
            }

        });
    },[]);

    // useEffect(() => {
    //     window.scrollTo(0, 600);
    // },[]);


    useEffect(() => {
        console.log('table',table);
        console.log('admin',admin);
        Axios({
            // url: 'https://sbc.smartbc.kr/sb/band_board_list.php',
            url: 'https://sbc.smartbc.kr/sb/band_board345.php',
            method: 'get',
            params: {
                u_id : u_id,
                b_id: b_id,
                table: table,//'band_board1',
                title: text,
            }
        }).then(function (result) {
            console.log('list',result.data)


            setUserLevel(Number(result.data.user_level));
            setCard_regist( result.data.card_regist);


            if(table == table1){
                console.log("News_board list up success");

                console.log('dd', NewsList.length,  result.data.list.length);
                //검색리스트일 경우
                if(NewsList.length > result.data.list.length) {
                    setDiff(1);
                    // console.log('변경ㅎ,,,');
                }

                setNewsList(result.data.list);//search1
                setBoardCnt(result.data.list.length);
                if(result.data.menu_name.length>0){
                    setMenuName3(JSON.parse(result.data.menu_name)[2]);
                    setMenuName(JSON.parse(result.data.menu_name)[2]);
                }
                if(result.data.write_level.length>0) {
                    setMenuLevel3(JSON.parse(result.data.write_level)[0]);
                    if(JSON.parse(result.data.write_level)[0]==1){
                        setLevelType('운영자');
                    }else if(JSON.parse(result.data.write_level)[0]==2||
                        JSON.parse(result.data.write_level)[0]==3){
                        setLevelType('운영자 + 우수회원');
                    }
                }



            }else if(table == table2){
                console.log("Cooperation list up success");
                console.log('dd',CooperList.length, result.data.list.length);
                if(CooperList.length > result.data.list.length) setDiff(1);

                setCooperList(result.data.list); //search2
                setBoardCnt(result.data.list.length);
                if(result.data.menu_name.length>0){
                    setMenuName4(JSON.parse(result.data.menu_name)[3]);
                    setMenuName(JSON.parse(result.data.menu_name)[3]);
                }
                if(result.data.write_level.length>0) {
                    setMenuLevel4(JSON.parse(result.data.write_level)[0]);
                    if(JSON.parse(result.data.write_level)[1]==1){
                        setLevelType('운영자');
                    }else if(JSON.parse(result.data.write_level)[1]==2||
                        JSON.parse(result.data.write_level)[1]==3){
                        setLevelType('운영자 + 우수회원');
                    }
                }


            }else if(table == table3){
                console.log("Freeboard list up success");
                console.log('dd',FreeList.length, result.data.list.length);
                if(FreeList.length > result.data.list.length) setDiff(1);

                setFreeList(result.data.list); //search3
                setBoardCnt(result.data.list.length);
                if(result.data.menu_name.length>0){
                    setMenuName5(JSON.parse(result.data.menu_name)[4]);
                    setMenuName(JSON.parse(result.data.menu_name)[4]);
                }
                if(result.data.write_level.length>0) {
                    setMenuLevel5(JSON.parse(result.data.write_level)[0]);
                    if(JSON.parse(result.data.write_level)[2]==1){
                        setLevelType('운영자');
                    }else if(JSON.parse(result.data.write_level)[2]==2||
                        JSON.parse(result.data.write_level)[2]==3){
                        setLevelType('운영자 + 우수회원');
                    }
                }

            }
            // console.log('순서',111111111111111,);
            // console.log('검색',search1,":",search2,":",search3,":")
            // console.log('검색어,,,',text);
            // console.log('diff,,,',diff);
            // console.log('스크롤',sessionStorage.getItem('@scrollPos'))

           if (search1||search2||search3||sessionStorage.getItem('@scrollPos') == null){
                console.log('0 스크롤');
                window.scrollTo(0,0);
            }else if(sessionStorage.getItem('@scrollPos')){
                console.log('1 스크롤',);
                window.scrollTo(0, Number(sessionStorage.getItem("@scrollPos")));
            }
                // sessionStorage.removeItem('@scrollPos');
            // sessionStorage.setItem("@scrollPos",0)
            //     console.log('스크롤삭제',sessionStorage.getItem('@scrollPos'))



            setLoading(false);


        })

            .catch(function (e) {
                console.log("board list up fail : ",e);
            })
    },[refresh,search1,search2,search3]);


    const PopUp = ()=>{
        return(
                    <>
                        <Card className={classes.popUp}>
                            <Grid container alignItems='center' direction='row' style={{width: '100%',height:40,marginTop:15,marginLeft:50}}>
                                <Grid item xs={8} style={{textAlign :'center',fontWeight:'bold'}}>
                                    {'글쓰기 권한'}
                                </Grid>
                                <Grid item xs={2}>
                                    <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{setModal(false);}}/>
                                </Grid>

                            </Grid>
                            <CardContent style={{paddingTop: 5}}>
                                <div style={{width: 280, height:100, backgroundColor: 'rgb(239,242,246)',borderRadius : 10,marginTop:15, marginBottom: 5}}>
                                    <Typography style={{ color: 'black',fontWeight:'bold',fontSize : 13,padding :'20px 20px',lineHeight:'30px'}}>
                                        {/*운영자+우수회원*/}
                                        { '해당 게시판은 ' +levelType+' 등급만 '}<br/>
                                        {'글쓰기가 가능합 게시판입니다.'}
                                    </Typography>
                                </div>

                            </CardContent>

                        </Card>
                        <div className={classes.popUpBg} />
                    </>

        )
    }

    const PopUp2 = ()=>{
        return(
            <>
                <Card className={classes.popUp}>
                    <Typography align='right'>
                        <IconButton>
                            <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                                setModal2(false);
                            }}/>
                        </IconButton>
                    </Typography>
                    <CardContent style={{paddingTop: 5}}>
                        <Typography>
                            <img src={require('../resources/card_icon.png')} style={{height: 85}}/>
                        </Typography>
                        <Typography style={{marginTop: 20}}>MY 모바일 명함 등록을 먼저 해주세요!</Typography>
                    </CardContent>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                        onClick={() => {
                            history.push('/'+match.params.JoinCode+'/MyCardRegist');
                        }}>
                        MY명함 등록하기
                    </Button>
                </Card>
                <div className={classes.popUpBg} />
            </>

        )
    }


    const SearchField = () => {
        const [content, setContent] = React.useState('');
        return (
            <div style={{padding: '0 40px',}}>
                <div style={{
                    width: '100%',
                    textAlign: 'left',
                    borderRadius: '40px',
                    position: 'relative',
                    border: '1px solid #B9BFC3',
                    marginTop: 15,
                }}>
                    <IconButton edge="center" className={classes.searchIcon}>
                        <SearchIcon style={{fontSize: 35, color: 'rgb(48,58,83)'}} onClick={() => {
                            setText(content);
                            if(table == table1) setSearch1(!search1);
                            else if(table == table2) setSearch2(!search2);
                            else if(table == table3) setSearch3(!search3);
                        }}
                        />
                    </IconButton>
                    <InputBase
                        color='primary'
                        placeholder='검색어를 입력해주세요.'
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{'aria-label': 'search'}}
                        onChange={(val) => {
                            setContent(val.target.value);
                        }}
                        onKeyPress={(e) => {
                            if(e.key === 'Enter'){
                                setText(content);
                                if(table == table1) setSearch1(!search1);
                                else if(table == table2) setSearch2(!search2);
                                else if(table == table3) setSearch3(!search3);
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    const NewsBoard = () => {
        return (
            <>
                <div className={classes.paper}>
                {/*<div className={classes.paper} onScroll={()=>{*/}
                {/*    let scrollPos = sessionStorage.getItem("scrollPos");*/}
                {/*    console.log('스크롤',scrollPos)*/}
                {/*    if (scrollPos !== null) {*/}
                {/*        console.log('스크롤22222', scrollPos)*/}
                {/*        // window.onunload = function(){ window.scrollTo(0,0); }*/}
                {/*        window.scrollTo(0, Number(scrollPos));*/}
                {/*        sessionStorage.removeItem('scrollPos');*/}
                {/*        // (Number(scrollPos) + window.innerHeight) / document.body.clientHeight * 100*/}

                {/*    }*/}
                {/*}}>*/}

                    {/*리스트 시작*/}
                    {NewsList.length > 0 ?
                        (
                            NewsList.map((val) => {
                                const profile =
                                    val.admin == 1 ? require('../resources/profile_exampleImg.png') :
                                        (
                                            val.profile ? 'https://sbc.smartbc.kr' + val.profile : require('../resources/profile_exampleImg.png')
                                        );
                                const name = val.admin == 1 ? '모임관리자' : val.u_name;
                                const company = val.admin == 1 ? '스마트 모임' : val.company?val.company:'스마트 모임';
                                const position = val.admin == 1 ? null : val.position;
                                return (
                                    <Grid container style={{marginBottom: 5, position: 'relative'}} onClick={() => {
                                        console.log('diff제라바라라',diff)
                                        //검색리스트일 경우 세션스크롤값 리셋
                                        if(diff == 1){
                                            sessionStorage.setItem("@scrollPos", 0);
                                        }

                                        Axios({
                                            url: "https://sbc.smartbc.kr/sb/view_count.php",
                                            method: 'get',
                                            params: {
                                                table: 'band_board1',
                                                board_id: val.id,
                                            }
                                        })
                                            .then(function (res) {
                                                if (res.data == 200) console.log('view_count update success');
                                                else console.log('view_count update fail');
                                            })
                                            .catch(function (e) {
                                                console.log('view_count update fail');
                                                console.log(e);
                                            })
                                        history.push({
                                            pathname: 'NoticeBoardDetail',
                                            state: {board_id: val.id, table: 'band_board1'}
                                        });
                                    }}>
                                        <Divider style={{width: '100%', position: 'absolute', bottom: 80, left: 0}}/>
                                        <Grid container spacing={0} style={{
                                            padding: '15px 20px',
                                            borderRadius: 10,
                                            boxShadow: '5px 5px 10px rgba(0,0,0,0.1)',
                                            position: 'relative',
                                            marginBottom: 35
                                        }}>
                                            <Grid container direction='row'>
                                                <Grid item container direction="column">
                                                    <Typography align='left' style={{fontSize: 15, fontWeight: 700}}>
                                                        {val.title}
                                                    </Typography>
                                                    <Typography align='left' className={classes.contentText}
                                                                style={{width: '90%'}}>
                                                        <pre style={{wordBreak:'break-all',outline:'none',}}><div>{val.content}</div></pre>
                                                    </Typography>
                                                </Grid>
                                                {/* 작성자 정보 */}
                                                <Grid container alignItems='center' direction='row'>
                                                    <Grid item xs={2} style={{marginRight: 10}}>
                                                        <img alt="사진" src={profile}
                                                             style={{width: 40, height: 40, borderRadius: 50}}/>
                                                    </Grid>
                                                    <Grid item xs={9} style={{marginTop: 15}}>
                                                        <Grid item xs container direction="column">
                                                            <Grid item>
                                                                <Typography align='left' style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(51,51,51)',
                                                                    fontWeight: 'bold'
                                                                }}>
                                                                    {name}
                                                                    <span style={{
                                                                        fontSize: 11,
                                                                        color: 'rgb(181,181,181)',
                                                                        marginLeft: 5
                                                                    }}>{position}</span>
                                                                </Typography>
                                                                <Grid container>
                                                                    <Grid item xs={6}>
                                                                        <Typography align='left'
                                                                                    style={{
                                                                                        fontSize: 10,
                                                                                        color: 'rgb(181,181,181)',
                                                                                    }}>
                                                                            {company}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography align='right'
                                                                                    style={{
                                                                                        fontSize: 10,
                                                                                        color: 'rgb(181,181,181)',
                                                                                    }}>
                                                                            {val.date}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* 하단 아이콘 */}
                                                <Grid container spacing={1} style={{marginTop: 10}}>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/eye_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.view ? val.view : 0}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/review_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.reply_num ? val.reply_num : 0}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/like_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.like_num ? val.like_num : 0}</Typography>
                                                    </Grid>
                                                    {/*{val.upfile ? (*/}
                                                    {/*    <IconButton style={{position: 'relative', right: -130, top: 5}}>*/}
                                                    {/*            <img src={require('resources/fileimage.png')}*/}
                                                    {/*                 style={{*/}
                                                    {/*                     height: 18,*/}
                                                    {/*                     width: 'auto',*/}
                                                    {/*                     verticalAlign: 'middle'*/}
                                                    {/*                 }}/>*/}
                                                    {/*    </IconButton>*/}
                                                    {/*) : null}*/}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })
                        ) : null}
                </div>
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
  </>
        );
    }

    const CooperationBoard = () => {
        return (
            <>
                <div className={classes.paper}>
                    {/*리스트 시작*/}
                    {CooperList.length > 0 ?
                        (
                            CooperList.map((val) => {
                                const profile =
                                    val.admin == 1 ? require('../resources/profile_exampleImg.png') :
                                        (
                                            val.profile ? 'https://sbc.smartbc.kr' + val.profile : require('../resources/profile_exampleImg.png')
                                        );

                                const name = val.admin == 1 ? '모임관리자' : val.u_name;
                                const company = val.admin == 1 ? '스마트 모임' : val.company?val.company:'스마트 모임';
                                const position = val.admin == 1 ? null : val.position;
                                return (
                                    <Grid container style={{marginBottom: 5, position: 'relative'}} onClick={() => {
                                        //검색리스트일 경우 세션스크롤값 리셋
                                        if(diff == 1){
                                            sessionStorage.setItem("@scrollPos", 0);
                                        }
                                        Axios({
                                            url: "https://sbc.smartbc.kr/sb/view_count.php",
                                            method: 'get',
                                            params: {
                                                table: 'band_board2',
                                                board_id: val.id,
                                            }
                                        })
                                            .then(function (res) {
                                                if (res.data == 200) console.log('view_count update success');
                                                else console.log('view_count update fail');
                                            })
                                            .catch(function (e) {
                                                console.log('view_count update fail');
                                                console.log(e);
                                            })
                                        history.push({
                                            pathname: 'NoticeBoardDetail',
                                            state: {board_id: val.id, table: 'band_board2'}
                                        });
                                    }}>
                                        <Divider style={{width: '100%', position: 'absolute', bottom: 80, left: 0}}/>
                                        <Grid container spacing={0} style={{
                                            padding: '15px 20px',
                                            borderRadius: 10,
                                            boxShadow: '5px 5px 10px rgba(0,0,0,0.1)',
                                            position: 'relative',
                                            marginBottom: 35
                                        }}>
                                            <Grid container direction='row'>
                                                <Grid item container direction="column">
                                                    <Typography align='left' style={{fontSize: 15, fontWeight: 700}}>
                                                        {val.title}
                                                    </Typography>
                                                    <Typography align='left' className={classes.contentText}>
                                                        <pre style={{wordBreak:'break-all',outline:'none',}}><div>{val.content}</div></pre>
                                                    </Typography>
                                                </Grid>
                                                {/* 작성자 정보 */}
                                                <Grid container alignItems='center' direction='row'>
                                                    <Grid item xs={2} style={{marginRight: 10}}>
                                                        <img alt="사진" src={profile}
                                                             style={{width: 40, height: 40, borderRadius: 50}}/>
                                                    </Grid>
                                                    <Grid item xs={9} style={{marginTop: 15}}>
                                                        <Grid item xs container direction="column">
                                                            <Grid item>
                                                                <Typography align='left' style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(51,51,51)',
                                                                    fontWeight: 'bold'
                                                                }}>
                                                                    {name}
                                                                    <span style={{
                                                                        fontSize: 11,
                                                                        color: 'rgb(181,181,181)',
                                                                        marginLeft: 5
                                                                    }}>{position}</span>
                                                                </Typography>
                                                                <Grid container>
                                                                    <Grid item xs={6}>
                                                                        <Typography align='left'
                                                                                    style={{
                                                                                        fontSize: 10,
                                                                                        color: 'rgb(181,181,181)',
                                                                                    }}>
                                                                            {company}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography align='right'
                                                                                    style={{
                                                                                        fontSize: 10,
                                                                                        color: 'rgb(181,181,181)',
                                                                                    }}>
                                                                            {val.date}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* 하단 아이콘 */}
                                                <Grid container spacing={1} style={{marginTop: 5}}>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/eye_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.view ? val.view : 0}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/review_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.reply_num ? val.reply_num : 0}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/like_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.like_num ? val.like_num : 0}</Typography>
                                                    </Grid>
                                                    {/*{val.upfile ? (*/}
                                                    {/*    <IconButton style={{position: 'relative', right: -130, top: 5}}>*/}
                                                    {/*        <img src={require('resources/fileimage.png')}*/}
                                                    {/*             style={{*/}
                                                    {/*                 height: 18,*/}
                                                    {/*                 width: 'auto',*/}
                                                    {/*                 verticalAlign: 'middle'*/}
                                                    {/*             }}/>*/}
                                                    {/*    </IconButton>*/}
                                                    {/*) : null}*/}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })
                        ) : null}
                </div>
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
          </>
        );
    }

    const FreeBoard = () => {
        return (
            <>
                <div className={classes.paper}>
                    {/*리스트 시작*/}
                    {FreeList.length > 0 ?
                        (
                            FreeList.map((val) => {
                                const profile =
                                    val.admin == 1 ? require('../resources/profile_exampleImg.png') :
                                        (
                                            val.profile ? 'https://sbc.smartbc.kr' + val.profile : require('../resources/profile_exampleImg.png')
                                        );
                                const name = val.admin == 1 ? '모임관리자' : val.u_name;
                                const company = val.admin == 1 ? '스마트 모임' : val.company?val.company:'스마트 모임';
                                const position = val.admin == 1 ? null : val.position;
                                return (
                                    <Grid container style={{marginBottom: 5, position: 'relative'}} onClick={() => {
                                        //검색리스트일 경우 세션스크롤값 리셋
                                        if(diff == 1){
                                            sessionStorage.setItem("@scrollPos", 0);
                                        }
                                        Axios({
                                            url: "https://sbc.smartbc.kr/sb/view_count.php",
                                            method: 'get',
                                            params: {
                                                table: 'band_board3',
                                                board_id: val.id,
                                            }
                                        })
                                            .then(function (res) {
                                                if (res.data == 200) console.log('view_count update success');
                                                else console.log('view_count update fail');
                                            })
                                            .catch(function (e) {
                                                console.log('view_count update fail');
                                                console.log(e);
                                            })
                                        history.push({
                                            pathname: 'NoticeBoardDetail',
                                            state: {board_id: val.id, table: 'band_board3'}
                                        });
                                    }}>
                                        <Divider style={{width: '100%', position: 'absolute', bottom: 80, left: 0}}/>
                                        <Grid container spacing={0} style={{
                                            padding: '15px 20px',
                                            borderRadius: 10,
                                            boxShadow: '5px 5px 10px rgba(0,0,0,0.1)',
                                            position: 'relative',
                                            marginBottom: 35
                                        }}>
                                            <Grid container direction='row'>
                                                <Grid item container direction="column">
                                                    <Typography align='left' style={{fontSize: 15, fontWeight: 700}}>
                                                        {val.title}
                                                    </Typography>
                                                    <Typography align='left' className={classes.contentText}>
                                                        <pre style={{wordBreak:'break-all',outline:'none',}}><div>{val.content}</div></pre>
                                                    </Typography>
                                                </Grid>
                                                {/* 작성자 정보 */}
                                                <Grid container alignItems='center' direction='row'>
                                                    <Grid item xs={2} style={{marginRight: 10}}>
                                                        <img alt="사진" src={profile}
                                                             style={{width: 40, height: 40, borderRadius: 50}}/>
                                                    </Grid>
                                                    <Grid item xs={9} style={{marginTop: 15}}>
                                                        <Grid item xs container direction="column">
                                                            <Grid item>
                                                                <Typography align='left' style={{
                                                                    fontSize: 15,
                                                                    color: 'rgb(51,51,51)',
                                                                    fontWeight: 'bold'
                                                                }}>
                                                                    {name}
                                                                    <span style={{
                                                                        fontSize: 11,
                                                                        color: 'rgb(181,181,181)',
                                                                        marginLeft: 5
                                                                    }}>{position}</span>
                                                                </Typography>
                                                                <Grid container>
                                                                    <Grid item xs={6}>
                                                                        <Typography align='left'
                                                                                    style={{
                                                                                        fontSize: 10,
                                                                                        color: 'rgb(181,181,181)',
                                                                                    }}>
                                                                            {company}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Typography align='right'
                                                                                    style={{
                                                                                        fontSize: 10,
                                                                                        color: 'rgb(181,181,181)',
                                                                                    }}>
                                                                            {val.date}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* 하단 아이콘 */}
                                                <Grid container spacing={1} style={{marginTop: 5}}>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/eye_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.view ? val.view : 0}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/review_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.reply_num ? val.reply_num : 0}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{marginTop :10}}>
                                                        <img src={require('resources/like_icon.png')}
                                                             style={{
                                                                 height: 18,
                                                                 width: 'auto',
                                                                 verticalAlign: 'middle'
                                                             }}/>
                                                        <Typography style={{
                                                            fontSize: 11,
                                                            display: 'inline-block',
                                                            height: 18,
                                                            lineHeight: '18px',
                                                            marginLeft: 5
                                                        }}>{val.like_num ? val.like_num : 0}</Typography>
                                                    </Grid>
                                                    {/*{val.upfile ? (*/}
                                                    {/*    <IconButton style={{position: 'relative', right: -130, top: 5}}>*/}
                                                    {/*        <img src={require('resources/fileimage.png')}*/}
                                                    {/*             style={{*/}
                                                    {/*                 height: 18,*/}
                                                    {/*                 width: 'auto',*/}
                                                    {/*                 verticalAlign: 'middle'*/}
                                                    {/*             }}/>*/}
                                                    {/*    </IconButton>*/}
                                                    {/*) : null}*/}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })
                        ) : null}

                </div>
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
            </>
        );
    }

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={menuName+'('+boardCnt+')'} back={1} main={admin=='1'?2:1} history={history} match={match}/>
                {/* 상단 고정*/}
                <div style={{
                    padding: '20px 0 10px',
                    marginTop: 50,
                    boxShadow: '0 5px 10px rgba(0,0,0,0.15)',
                    position: 'sticky',
                    top: 55,
                    backgroundColor: '#fff',
                    zIndex: 10
                }} className={'MnNoticeBoard'}>
                    <SearchField/>
                </div>

                {/*<div onClick={()=>{*/}
                {/*    window.scrollTo(0,600)*/}
                {/*    console.log('스크롤 이동')*/}
                {/*}}>{'스크롤 이동'}</div>*/}

                {/* 게시판 리스트 */}

                {/* 그룹소식 */}
                {NewsList.length > 0 ?
                <NewsBoard/>:null}

                {/* 협력해요 */}
                {CooperList.length > 0 ?
                    <CooperationBoard/>:null}

                {/*자유게시판*/}
                {FreeList.length > 0 ?
                    <FreeBoard/>:null}

                {/* 추가 버튼 (글쓰기) */}
                {/*{!(value == 0 && admin == 0) ?*/}
                        <IconButton style={{
                            zIndex: 999,
                            color: '#fff',
                            padding: '0 15px',
                            fontSize: 35,
                            backgroundColor: 'rgb(48,58,83)',
                            position: 'fixed',
                            left: '50%',
                            bottom: 80,
                            transform: 'translateX(130px)'
                        }}
                                    onClick={() => {
                                        //검색리스트일 경우 세션스크롤값 리셋
                                        if(diff == 1){
                                            sessionStorage.setItem("@scrollPos", 0);
                                        }

                                        setText('');
                                        if(admin==1){
                                            history.push({
                                                pathname: 'NoticeBoardWrite',
                                                state: {
                                                    table: table ,
                                                    type: 'write'
                                                }
                                            })
                                        }

                                        console.log('보드레벨',menuLevel4)
                                        console.log('유저레벨',userLevel)
                                        if(card_regist=='Y') {
                                            if (userLevel == 1) {
                                                history.push({
                                                    pathname: 'NoticeBoardWrite',
                                                    state: {
                                                        table: table ,
                                                        type: 'write'
                                                    }
                                                })
                                            } else if (userLevel == 2 || userLevel == 3) {
                                                if (menuLevel3 == 1) {
                                                    setModal(true);
                                                } else {
                                                    history.push({
                                                        pathname: 'NoticeBoardWrite',
                                                        state: {
                                                            table: table ,
                                                            type: 'write'
                                                        }
                                                    })
                                                }

                                            } else {
                                                //일반회원
                                                if (menuLevel3 == 1 || menuLevel3 == 2 || menuLevel3 == 3) {
                                                    setModal(true);
                                                } else {
                                                    history.push({
                                                        pathname: 'NoticeBoardWrite',
                                                        state: {
                                                            table: table ,
                                                            type: 'write'
                                                        }
                                                    })
                                                }
                                            }//회원등급 끝
                                        }else{
                                            //명함 없으면 등록 팝업
                                            setModal2(true);
                                        }

                                    }}>
                            +
                        </IconButton>


                {/*<BottomNavi/>*/}
                {modal?<PopUp/>:null}
                {modal2?<PopUp2/>:null}
                {loading?
                    <>
                        <CircularProgress style={{zIndex: 1200,position: 'fixed', top: '50%', left: '50%'}}/>
                        <div className={classes.popUpBg} />
                    </>
                    :null}
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '30px 30px 70px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // overflowY:'scroll'
    },
    allUser: {},
    searchIcon: {
        position: 'absolute',
        right: -3, top: '50%',
        transform: 'translateY(-50%)'
    },
    inputRoot: {
        color: 'inherit',
        width: '90%',
    },
    inputInput: {
        margin: 3,
        padding: 10,
    },
    contentText: {
        fontSize: 13,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.5,
        height: '50px',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        width: '90%'
    },
    '@global': {
        '.MuiBox-root': {
            padding: 5
        }
    },
    numIcon : {
        marginTop :10,
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },

}));


export default NoticeBoard;
