import React, {useEffect, useState, useCallback, useRef} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Paper,
    FormControl,
    MenuItem,
    Select,
    Grid, Chip,
    InputBase, TextareaAutosize, IconButton, Box, FormControlLabel, Checkbox, Avatar,
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import SearchIcon from '@material-ui/icons/Search';
import Axios from "axios";
import Card from "@material-ui/core/Card";
import CloseIcon from "@material-ui/icons/Close";
import CardContent from "@material-ui/core/CardContent";
import CheckBoxIcon from "@material-ui/icons/CheckCircleOutline";
import CheckBoxCheckedIcon from "@material-ui/icons/CheckCircle";
import {useDispatch} from "react-redux";
import {setmember_cnt3} from "../reducers/session";
import customAlert from "../component/customAlert";

function Operator_card({history,match}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');
    const b_id = sessionStorage.getItem('@b_id');
    // 간편 검색
    const [text,setText] = React.useState('');
    const [sub,setSub] = React.useState('');
    const [sort,setSort] = React.useState('');
    // 전체회원 리스트
    const [list,setList] = React.useState(new Array());
    //간편 검색
    const [search, setSearch] = React.useState(false);

    const [modal , setModal] = React.useState(false);  //연결 요청 팝업
    const [target_id,setTarget_id] = React.useState('');

    const [refresh, setRefresh] = React.useState(false);

    const [preItems,setPreItems]=React.useState(0);
    const [items,setItems]=React.useState([]);

    const [modal2,setModal2] = React.useState(false); //명함 없을 시 등록 팝업
    const [card_regist, setCard_regist] = React.useState('N');

    const [modal3, setModal3] = useState(false);//정보공개 요청 팝업

    const [member_open, setMember_open]= useState('N');//전체회원열람 여부

    const [u_allow_list,setU_allow_list] = useState([]);
    const [u_allow_list_email,setU_allow_list_email] = useState([]);

    const dispatch = useDispatch();


    //검색창
    const Inputtext = () => {
        const [content, setContent] = React.useState('');

        return (
            <>
                <IconButton edge="center" className={classes.searchIcon} onClick={() => {
                    setText(content);
                    setSearch(!search);
                }}>
                    <SearchIcon style={{fontSize: 30, color: 'rgb(48,58,83)'}}/>
                </IconButton>
                <InputBase
                    color='primary'
                    placeholder='이름,회사명 입력'
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{'aria-label': 'search'}}
                    onChange={(val) => {
                        setContent(val.target.value);
                    }}
                />
            </>
        )
    }

    const PopUp = ({}) => {
        const [check, setCheck] = useState(false);
        if (check) document.getElementById('msg').value = '명함 교환을 요청합니다.';
        return(
            <>
                <Card className={classes.popUp}>
                    <div style={{position: 'relative', padding: '15px 0'}}>
                        <Typography align='center' style={{fontWeight: 'bold'}}>연결 요청</Typography>
                        <IconButton style={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}} onClick={() => {
                            setModal(false);
                        }}>
                            <CloseIcon style={{color: 'black', fontSize: 30}}/>
                        </IconButton>
                    </div>
                    <CardContent style={{paddingTop: 5}}>
                        <Box component={'div'}
                             style={{border: '1px solid #e2e7eb', borderRadius: 10, minHeight: 80, padding: 10,}}>
                            <Box component={'textarea'} style={{border: 'none', outline: 'none', width: '100%', height: '100%', minHeight: 80, resize: 'none', lineHeight: 1.3}}
                                 id={'msg'}
                                 placeholder={'자신을 소개하면서 인맥 연결을 요청을 해보세요!'}
                            />
                        </Box>
                        <FormControlLabel
                            onClick={() => {
                                setCheck(!check);
                            }}
                            style={{width: '100%'}}
                            control={
                                <Checkbox
                                    icon={<CheckBoxIcon fontSize="inherit"/>}
                                    checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                />
                            }
                            label={<Typography style={{fontSize: 13}}>명함 교환을 요청합니다.</Typography>}
                            checked={check}
                        />
                    </CardContent>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                        onClick={() => {
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/card_link_request.php',
                                method: 'get',
                                params: {
                                    u_id : u_id,
                                    target_id : target_id,
                                    b_id : b_id,
                                    requestMeg : (check ? '명환 교환을 요청합니다.':document.getElementById('msg').value)
                                },
                            })
                                .then((result) => {
                                    console.log(result.data);
                                    setModal(false);
                                    setRefresh(!refresh);
                                    customAlert("연결요청이 되었습니다.");
                                })
                                .catch((err) => console.log(err.message));
                        }}>
                        연결 요청하기
                    </Button>
                </Card>
                <div className={classes.popUpBg} />
            </>
        )
    }
    const PopUp2 = ()=>{
        return(
            <>
                <Card className={classes.popUp}>
                    <Typography align='right'>
                        <IconButton>
                            <CloseIcon style={{color: 'black', fontSize: 30}} onClick={()=>{
                                setModal2(false);
                            }}/>
                        </IconButton>
                    </Typography>
                    <CardContent style={{paddingTop: 5}}>
                        <Typography>
                            <img src={require('../resources/card_icon.png')} style={{height: 85}}/>
                        </Typography>
                        <Typography style={{marginTop: 20}}>MY 모바일 명함 등록을 먼저 해주세요!</Typography>
                    </CardContent>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                        onClick={() => {
                            history.push('/'+match.params.JoinCode+'/MyCardRegist');
                            setModal2(false);
                        }}>
                        MY명함 등록하기
                    </Button>
                </Card>
                <div className={classes.popUpBg} />
            </>
        )
    }

    const PopUp3 = ({}) => {
        const [check, setCheck] = useState(false);
        if (check) document.getElementById('msg').value = '연락처,이메일 공개를 요청합니다.';
        return(
            <>
                <Card className={classes.popUp}>
                    <div style={{position: 'relative', padding: '15px 0'}}>
                        <Typography align='center' style={{fontWeight: 'bold'}}>개인정보 공개요청</Typography>
                        <IconButton style={{position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)'}} onClick={() => {
                            setModal3(false);
                        }}>
                            <CloseIcon style={{color: 'black', fontSize: 30}}/>
                        </IconButton>
                    </div>
                    <CardContent style={{paddingTop: 5}}>
                        <Box component={'div'}
                             style={{border: '1px solid #e2e7eb', borderRadius: 10, minHeight: 80, padding: 10,}}>
                            <Box component={'textarea'} style={{border: 'none', outline: 'none', width: '100%', height: '100%', minHeight: 80, resize: 'none', lineHeight: 1.3}}
                                 id={'msg'}
                                 placeholder={'공개요청 사유를 입력해 주세요.'}
                            />
                        </Box>
                        <FormControlLabel
                            onClick={() => {
                                setCheck(!check);
                            }}
                            style={{width: '100%'}}
                            control={
                                <Checkbox
                                    icon={<CheckBoxIcon fontSize="inherit"/>}
                                    checkedIcon={<CheckBoxCheckedIcon fontSize="inherit"/>}
                                />
                            }
                            label={<Typography style={{fontSize: 13}}>연락처, 이메일 공개를 요청합니다.</Typography>}
                            checked={check}
                        />
                    </CardContent>
                    <Button
                        color="primary"
                        className={classes.groupAdd}
                        style={{width: 200, backgroundColor: '#303a53', color: 'white',fontWeight:'bold',fontSize : 15,borderRadius : 25,padding :10, marginBottom: 28}}
                        onClick={() => {
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/card_link_request.php',
                                method: 'get',
                                params: {
                                    u_id : u_id,
                                    target_id : target_id,
                                    b_id : b_id,
                                    requestMeg : (check ? '연락처,이메일 공개를 요청합니다.':document.getElementById('msg').value)
                                },
                            })
                                .then((result) => {
                                    console.log('info_open_request success');
                                    console.log(result.data);
                                    if(result.data == "30") customAlert("개인정보 공개 요청은 요청 후 30일뒤 \n다시 요청이 가능합니다.");
                                    setModal(false);
                                })
                                .catch((err) => console.log(err.message));
                        }}>
                        공개 요청하기
                    </Button>
                </Card>
                <div className={classes.popUpBg} />
            </>
        )
    }

    // const index = useRef(0);
    //useCallback 상태값 변하는 것들 []안에 포함시키기
    const _infiniteScroll = useCallback(()=>{
        // console.log('무한스크롤높이');
        let scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
        let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
        let clientHeight = document.documentElement.clientHeight;
        if(scrollTop+clientHeight >= scrollHeight -100){
            // console.log('스크롤높이',scrollHeight);
            // console.log('스크롤탑',scrollTop);
            // console.log('클라이언트높이',clientHeight);

            // console.log('----------높이 같을 때----------');
            // console.log('preItems',preItems);
            // console.log('asdf',items)
            // console.log(JSON.parse(JSON.stringify(list)).concat(items.slice(preItems+10,preItems+20)));
            // console.log('--------------------');
            setPreItems(preItems+10);
            setList(JSON.parse(JSON.stringify(list)).concat(items.slice(preItems+10,preItems+20)));

        }
    },[preItems,list,items]);

    useEffect(() => {
        // console.log('무한스크롤');
        window.addEventListener('scroll', _infiniteScroll, true);
        return () => window.removeEventListener('scroll', _infiniteScroll, true);
    }, [_infiniteScroll]);

    useEffect(() => {

        if(!history.location.state.searchList||sort||sub) {
            Axios({
                url: "https://sbc.smartbc.kr/sb/total_member_info3.php", //리뉴얼
                // url: "https://sbc.smartbc.kr/sb/total_member_info.php", //원본
                // url: "https://sbc.smartbc.kr/sb/total_member_info_test.php", //무한스크롤 테스트
                method: 'get',
                params: {
                    u_id : u_id,
                    b_id : b_id,
                    search_text : text,
                    tab : 'operator_card'
                }
            })
                .then((result) => {
                    console.log(result.data);
                    if (result.data.status == 12001) {
                        console.log('카드등록여부',result.data.card_regist);
                        setCard_regist(result.data.card_regist);
                        console.log('member_list');
                        // setList(result.data.member_list);
                        setList(result.data.member_list.slice(0,10));
                        setItems(result.data.member_list);
                        setMember_open(result.data.member_open);
                        dispatch(setmember_cnt3(result.data.member_list.length));
                    }

                })
                .catch((err) => console.log(err.message));
            // 로그인 되어 있는 계정 id로 연결된 회원 리스트 가져오기
            Axios({
                url: 'https://sbc.smartbc.kr/sb/link_members_list.php',
                method: 'get',
                params: {
                    id : u_id,
                    b_id : b_id,
                }
            })
                .then(function (response) {

                    setU_allow_list(JSON.parse(response.data['allow_list']));
                    setU_allow_list_email(JSON.parse(response.data['allow_list_email']));
                    sessionStorage.setItem('allow_list',JSON.parse(response.data['allow_list']));
                    sessionStorage.setItem('allow_list_email',JSON.parse(response.data['allow_list_email']));
                    console.log(JSON.parse(response.data['allow_list']));
                    console.log(u_allow_list_email);
                    console.log(typeof (u_allow_list));
                    console.log(typeof (response.data['allow_list']));
                    //setList(response.data['list']);
                })
                .catch(function (error) {
                    console.log('!!!!!!!!!!!!!!!!!!!' + error);
                })

        }
        else{
            console.log('searchlist');
            // console.log(history.state.searchList);
            //     setList(history.location.state.searchList);
            setList(history.location.state.searchList.slice(0,10));
            setItems(history.location.state.searchList);
            history.location.state.searchList = null;
            history.location.state.index = null;

        }

        // }
    },[search,refresh]);
    let linkReq = null;
    const MemberList = ({val,index})=> {
        // const [userLevel,setUserLevel] = React.useState('일반회원');
        let userLevel='';
        if(val.user_level==1){
            // setUserLevel('운영자');
            userLevel='운영자';
        }else if(val.user_level==2){
            // setUserLevel('우수회원');
            userLevel='우수회원';
        }
        // console.log('옵션아이ㅏ',val.join_option2_val);
        if((val.join_option2_val != null)){
            const string1 = Object.values(JSON.parse(val.join_option2_val))+ "";
            // console.log('스트링?',typeof  string1);
            Axios({
                url : 'https://sbc.smartbc.kr/sb/get_option2_name.php',
                method :'get',
                params : {
                    op2_val : string1,
                },
            })
                .then(function (res) {

                    // op2 = res.data.join(', ');
                    // console.log('join val',op2);
                    // console.log(res.data);
                    if(res.data.length>0){
                        document.getElementById('groupOption'+index).value = '모임 옵션 :' + res.data.join(', ') + '\n' + val.u_company2 + ',' + val.u_part2 + ',' + val.u_area;
                    }else{
                        document.getElementById('groupOption'+index).value = '모임 옵션 : 미설정 '   + '\n' + val.u_company2 + ',' + val.u_part2 + ',' + val.u_area;
                    }

                    // console.log('asd = ' + document.getElementById('groupOption').value); //value 남

                })
                .catch(function (e) {
                    console.log('fail' + e);
                });
        }


        if(val.response_type==0){
            linkReq =
                <Button type="submit" color="primary"
                        style={{backgroundColor: '#fff', color: '#303a53',fontWeight:'bold',fontSize :13,borderRadius : 5,padding :5, width: '95%',border: '1px solid #303a53'}}>연결 중</Button>;

        }else if(val.response_type==2){
            linkReq =
                <Button
                    type="submit"
                    color="primary"
                    style={{backgroundColor: '#fff', color: '#303a53',fontWeight:'bold',fontSize :13,borderRadius : 5,padding :5, width: '95%',border: '1px solid #303a53'}}>연결 거절</Button>;
        }else if(val.linked_id){
            if(u_allow_list.find(element => element === Number(val.u_id)) && u_allow_list_email.find(element => element === Number(val.u_id))){
                linkReq =
                    <Button
                        type="submit"
                        color="primary"
                        style={{backgroundColor: '#fff', color: '#303a53',fontWeight:'bold',fontSize :13,borderRadius : 5,padding :5, width: '95%',border: '1px solid #303a53'}}>정보공개</Button>;
            }else{
                linkReq =
                    <Button
                        type="submit"
                        color="primary"
                        style={{backgroundColor: '#303a53', color: '#fff',fontWeight:'bold',fontSize :13,borderRadius : 5,padding :5, width: '95%',}}
                        onClick={()=>{
                            console.log('클릭',val.id);
                            if(card_regist=='Y'){
                                setTarget_id(val.id);
                                Axios({
                                    url: 'https://sbc.smartbc.kr/sb/today_link_req_count.php',
                                    method: 'get',
                                    params: {
                                        u_id: u_id,
                                        b_id: b_id,
                                    },
                                })
                                    .then((result) => {
                                        if(result.data.status == 31001){
                                            setModal3(true);
                                        }
                                        // else{alert(result.data.result_msg);}
                                    })
                                    .catch((err) => console.log(err.message));
                            }else{
                                setModal2(true);
                            }
                        }}>정보공개 요청</Button>;

            }
            /* linkReq =
                 <Button
                     type="submit"
                     color="primary"
                     style={{backgroundColor: '#fff', color: '#303a53',fontWeight:'bold',fontSize :13,borderRadius : 5,padding :5, width: '95%',border: '1px solid #303a53'}}>연결</Button>;*/
        }else if(val.id==u_id){
            linkReq =
                <Button
                    type="submit"
                    color="primary"
                    style={{backgroundColor: '#ff6666', color: 'white',fontWeight:'bold',fontSize :13,borderRadius : 5,padding :5, width: '95%'}}>MY</Button>;
        }
        else{
            //연결요청
            linkReq =
                <Button
                    type="submit"
                    color="primary"
                    style={{backgroundColor: '#303a53', color: '#fff',fontWeight:'bold',fontSize :13,borderRadius : 5,padding :5, width: '95%',}}
                    onClick={()=>{
                        console.log('클릭',val.id);
                        if(card_regist=='Y'){
                            setTarget_id(val.id);
                            Axios({
                                url: 'https://sbc.smartbc.kr/sb/today_link_req_count.php',
                                method: 'get',
                                params: {
                                    u_id: u_id,
                                    b_id: b_id,
                                },
                            })
                                .then((result) => {
                                    if(result.data.status == 31001){
                                        console.log('today_count',Number (result.data.today_link_cnt));
                                        if(Number(result.data.today_link_cnt)>30){
                                            customAlert('하루 연결 요청은 30건을 넘을 수 없습니다.');
                                            setModal(false);
                                            return;
                                        }else{
                                            setModal(true);
                                        }
                                    }
                                    // else{alert(result.data.result_msg);}
                                })
                                .catch((err) => console.log(err.message));
                        }else{
                            setModal2(true);
                        }
                    }}>연결 요청</Button>;

        }
        return (
            <div className={classes.paper}>
                <Grid  container spacing={3} style={{marginBottom: 5}}>
                    <Grid container spacing={0} style={{padding: '15px 20px', borderRadius: 10, boxShadow: '2px 2px 10px rgba(0,0,0,0.2)', position: 'relative', marginBottom: 0}}>
                        <Grid container direction='row'
                              style={{cursor: 'pointer'}}
                              onClick={() => {
                                  if(val.id==u_id){
                                      if(card_regist=='Y') {
                                          if(val.business_regist_num != null){
                                              history.push({
                                                  pathname: '/' + match.params.JoinCode + '/MyCardDetailTab',
                                                  state: {
                                                      ecredible_id: val.ecredible_id, //ecredible_id
                                                      tab : true,
                                                  }
                                              });
                                          }else{
                                              history.push('/' + match.params.JoinCode + '/MyCardDetail');//탭X
                                          }
                                      }else{
                                          history.push('/'+match.params.JoinCode+'/MyCardRegist');
                                      }
                                  }else {

                                      let join_option = null;
                                      if (val.join_option2_val) {
                                          join_option = Object.values(JSON.parse(val.join_option2_val));
                                      }
                                      console.log('ss', join_option);
                                      if(card_regist=='Y'){
                                          if(val.business_regist_num != null){
                                              history.push({
                                                  pathname: '/' + match.params.JoinCode + '/MembersCardDetailTab',
                                                  state: {
                                                      sel_u_id: val.id, //targetId
                                                      join_option: join_option,
                                                      ecredible_id: val.ecredible_id, //ecredible_id
                                                      tab : true,
                                                  }
                                              });
                                          }else{
                                              history.push({
                                                  pathname: '/' + match.params.JoinCode + '/MembersCardDetail',
                                                  state: {
                                                      sel_u_id: val.id, //targetId
                                                      join_option: join_option,
                                                  }
                                              });//탭X
                                          }
                                      }else{
                                          setModal2(true);
                                      }

                                  }
                              }}>
                            <Grid item xs={8} container direction="column">
                                <Typography align='left' style={{fontSize: 13, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                    {val.u_name} {userLevel? '('+userLevel+')': null}
                                </Typography>
                                <Typography align='left' style={{fontSize: 13, color: 'rgb(181,181,181)'}}>
                                    {/*<span>{val.mc_position?val.mc_position:'직책'}</span> | <span>{val.part?val.part:'부서'}</span>*/}
                                    <span>{val.mc_position?val.mc_position+' | ':null}</span>  <span>{val.part?val.part:null}</span>
                                </Typography>
                                <Typography align='left' style={{fontSize: 13, color: 'rgb(181,181,181)'}}>
                                    {/*{val.company?val.company:'소속'}*/}
                                    {val.company?val.company:null}
                                </Typography>
                            </Grid>
                            {/*<Grid item xs={4} style={{borderRadius: 5, overflow: 'hidden', width: '100%', height: 54}}>*/}
                            {/*<img alt="사진" src={require('../resources/5eaa37f13138d.png')} style={{width: '90%', height: '100%'}}/>*/}
                            {/*</Grid>*/}
                            {/*{val.image?*/}
                            {/*    <Grid item xs={4}*/}
                            {/*          onContextMenu={*/}
                            {/*              (e) => {*/}
                            {/*                  //우클릭 방지*/}
                            {/*                  e.preventDefault();*/}
                            {/*                  console.log('우클!');*/}
                            {/*              }}*/}
                            {/*          onDragStart={*/}
                            {/*              (e) => {*/}
                            {/*                  //드래그 방지*/}
                            {/*                  e.preventDefault();*/}
                            {/*                  console.log('드래그방지!');*/}
                            {/*              }}*/}
                            {/*          style={{*/}
                            {/*              borderRadius: 5,*/}
                            {/*              overflow: 'hidden',*/}
                            {/*              width: '100%',*/}
                            {/*              height: 54*/}
                            {/*          }}>*/}
                            {/*        <img alt="사진" src={'https://sbc.smartbc.kr/' + val.image}*/}
                            {/*             style={{width: '90%', height: '100%'}}/>*/}
                            {/*    </Grid>*/}

                            {/*    :*/}
                            {/*    <Grid item xs={4} style={{*/}
                            {/*        borderRadius: 5,*/}
                            {/*        overflow: 'hidden',*/}
                            {/*        width: '100%',*/}
                            {/*        height: 57*/}
                            {/*    }}>*/}
                            {/*        <img alt="사진"  src={require('../resources/card_icon.png')}*/}
                            {/*             style={{width: '80%', height: '100%'}}/>*/}
                            {/*    </Grid>*/}
                            {/*}*/}
                            {val.profile?
                                <Grid item xs={4}
                                      onContextMenu={
                                          (e) => {
                                              //우클릭 방지
                                              e.preventDefault();
                                              console.log('우클!');
                                          }}
                                      onDragStart={
                                          (e) => {
                                              //드래그 방지
                                              e.preventDefault();
                                              console.log('드래그방지!');
                                          }}
                                >
                                    <Avatar alt="사진" src={"https://sbc.smartbc.kr"+val.profile} className={classes.large}/>
                                </Grid>

                                :
                                <Grid item xs={4}>
                                    <Avatar alt="사진" src={require('../resources/profile_icon.png')} className={classes.large}/>
                                </Grid>

                            }
                        </Grid>
                        { member_open === 'N'?
                            <Grid container direction='row' style={{marginTop: 3}}>
                                <Grid item xs={2} style={{textAlign :'left'}}>
                                    <img src={require('resources/eye_icon.png')}
                                         style={{
                                             height: 18,
                                             width: 'auto',
                                             verticalAlign: 'middle'
                                         }}/>
                                    <Typography style={{
                                        fontSize: 11,
                                        display: 'inline-block',
                                        height: 18,
                                        lineHeight: '18px',
                                        marginLeft: 5
                                    }}>{val.view_count ? val.view_count : 0}</Typography>
                                </Grid>
                                <Grid item xs={2} style={{textAlign :'left'}}>
                                    <img src={require('resources/link.png')}
                                         style={{
                                             height: 18,
                                             width: 'auto',
                                             verticalAlign: 'middle'
                                         }}/>
                                    <Typography style={{
                                        fontSize: 11,
                                        display: 'inline-block',
                                        height: 18,
                                        lineHeight: '18px',
                                        marginLeft: 5
                                    }}>{val.link_count ? val.link_count : 0}</Typography>
                                </Grid>
                                <Grid item xs={2} style={{textAlign :'left'}}>
                                    <img src={require('resources/write_icon.png')}
                                         style={{
                                             height: 18,
                                             width: 'auto',
                                             verticalAlign: 'middle'
                                         }}/>
                                    <Typography style={{
                                        fontSize: 11,
                                        display: 'inline-block',
                                        height: 18,
                                        lineHeight: '18px',
                                        marginLeft: 5
                                    }}>{val.b1_cnt||val.b2_cnt||val.b3_cnt ? Number(val.b1_cnt)+Number(val.b2_cnt)+Number(val.b3_cnt) : 0}</Typography>
                                </Grid>
                                <Grid item xs={2}/>


                                <Grid item xs={4} style={{borderRadius: 5, overflow: 'hidden', width: '100%'}}>
                                    {linkReq}
                                </Grid>


                            </Grid> : null}
                        {val.introduce ?
                            (
                                <div  direction='row' style={{marginTop: 25, backgroundColor: 'rgb(249,250,252)', borderRadius: 10, padding: 5, width:'100%',cursor: 'pointer'}} className='text-input'
                                      onClick={() => {
                                          if(val.id==u_id) {
                                              if(card_regist=='Y') {
                                                  if(val.business_regist_num != null){
                                                      history.push({
                                                          pathname: '/' + match.params.JoinCode + '/MyCardDetailTab',
                                                          state: {
                                                              ecredible_id: val.ecredible_id, //ecredible_id
                                                              tab : true,
                                                          }
                                                      });
                                                  }else{
                                                      history.push('/' + match.params.JoinCode + '/MyCardDetail');//탭X
                                                  }
                                              }else{
                                                  history.push('/'+match.params.JoinCode+'/MyCardRegist');
                                              }
                                          }else {
                                              let join_option = null;
                                              if (val.join_option2_val) {
                                                  join_option = Object.values(JSON.parse(val.join_option2_val));
                                              }
                                              console.log('ss', join_option);
                                              if(card_regist=='Y'){
                                                  if(val.business_regist_num != null){
                                                      history.push({
                                                          pathname: '/' + match.params.JoinCode + '/MembersCardDetailTab',
                                                          state: {
                                                              sel_u_id: val.id, //targetId
                                                              join_option: join_option,
                                                              ecredible_id: val.ecredible_id, //ecredible_id
                                                              tab : true,
                                                          }
                                                      });
                                                  }else{
                                                      history.push({
                                                          pathname: '/' + match.params.JoinCode + '/MembersCardDetail',
                                                          state: {
                                                              sel_u_id: val.id, //targetId
                                                              join_option: join_option,
                                                          }
                                                      });//탭X
                                                  }
                                              }else{
                                                  setModal2(true);
                                              }

                                          }
                                      }}>
                                    <Typography
                                        align='left'
                                        style={{width: '100%', border: 0, backgroundColor: 'transparent', resize: 'none', fontSize: 13}}
                                        className={classes.textEllipsis}>
                                        {val.introduce}
                                    </Typography>
                                    {/*<InputBase*/}
                                    {/*    // aria-label="minimum height"*/}
                                    {/*    rowsMin={1} rowsMax={2}*/}
                                    {/*    value={val.introduce}*/}
                                    {/*    readOnly={true}*/}
                                    {/*    multiline={true}*/}
                                    {/*    rows={2}*/}
                                    {/*    // className={classes.textEllipsis}*/}
                                    {/*    style={{width: '100%', border: 0, backgroundColor: 'transparent', resize: 'none', fontSize: 13}}/>*/}
                                </div>
                            ) : null}
                        <div  direction='row' style={{marginTop:val.introduce ? 5: 25, backgroundColor: 'rgb(249,250,252)', borderRadius: 10, padding: 5, width:'100%',cursor: 'pointer'}} className='text-input'
                              onClick={() => {
                                  if(val.id==u_id) {
                                      if(card_regist=='Y') {
                                          if(val.business_regist_num != null){
                                              history.push({
                                                  pathname: '/' + match.params.JoinCode + '/MyCardDetailTab',
                                                  state: {
                                                      ecredible_id: val.ecredible_id, //ecredible_id
                                                      tab : true,
                                                  }
                                              });
                                          }else{
                                              history.push('/' + match.params.JoinCode + '/MyCardDetail');//탭X
                                          }
                                      }else{
                                          history.push('/'+match.params.JoinCode+'/MyCardRegist');
                                      }
                                  }else {
                                      let join_option = null;
                                      if (val.join_option2_val) {
                                          join_option = Object.values(JSON.parse(val.join_option2_val));
                                      }
                                      console.log('ss', join_option);
                                      if(card_regist=='Y'){
                                          if(val.business_regist_num != null){
                                              history.push({
                                                  pathname: '/' + match.params.JoinCode + '/MembersCardDetailTab',
                                                  state: {
                                                      sel_u_id: val.id, //targetId
                                                      join_option: join_option,
                                                      ecredible_id: val.ecredible_id, //ecredible_id
                                                      tab : true,
                                                  }
                                              });
                                          }else{
                                              history.push({
                                                  pathname: '/' + match.params.JoinCode + '/MembersCardDetail',
                                                  state: {
                                                      sel_u_id: val.id, //targetId
                                                      join_option: join_option,
                                                  }
                                              });//탭X
                                          }
                                      }else{
                                          setModal2(true);
                                      }

                                  }
                              }}>
                            {(val.u_company2 && val.u_part2 && val.u_area) ?
                                (
                                    <InputBase
                                        id={'groupOption'+index}
                                        defaultValue={'모임 옵션 : 미설정 \n'+val.u_company2+','+val.u_part2+','+val.u_area}
                                        rowsMin={2} rowsMax={3}
                                        readOnly={true}
                                        multiline={true}
                                        rows={2}
                                        style={{width: '100%',height:'100%', border: 0, backgroundColor: 'transparent', resize: 'none', fontSize: 13,}}/>
                                ) :
                                (
                                    <InputBase
                                        id={'groupOption'+index}
                                        defaultValue={'모임 옵션 : 미설정 '}
                                        rowsMin={2} rowsMax={3}
                                        readOnly={true}
                                        multiline={true}
                                        rows={1}
                                        style={{width: '100%',height:'100%', border: 0, backgroundColor: 'transparent', resize: 'none', fontSize: 13,}}/>
                                )
                            }
                        </div>
                        {/*기업정보*/}
                        {val.business_regist_num ?
                            (
                                <div  direction='row' style={{marginTop: 5, backgroundColor: 'rgb(249,250,252)', borderRadius: 10, padding: 5, width:'100%',cursor: 'pointer'}} className='text-input'
                                      onClick={() => {
                                          if(val.id==u_id) {
                                              if(card_regist=='Y') {
                                                  if(val.business_regist_num != null){
                                                      history.push({
                                                          pathname: '/' + match.params.JoinCode + '/MyCardDetailTab',
                                                          state: {
                                                              ecredible_id: val.ecredible_id, //ecredible_id
                                                              tab : true,
                                                          }
                                                      });
                                                  }else{
                                                      history.push('/' + match.params.JoinCode + '/MyCardDetail');//탭X
                                                  }
                                              }else{
                                                  history.push('/'+match.params.JoinCode+'/MyCardRegist');
                                              }
                                          }else {
                                              let join_option = null;
                                              if (val.join_option2_val) {
                                                  join_option = Object.values(JSON.parse(val.join_option2_val));
                                              }
                                              console.log('ss', join_option);
                                              if(card_regist=='Y'){
                                                  if(val.business_regist_num != null){
                                                      history.push({
                                                          pathname: '/' + match.params.JoinCode + '/MembersCardDetailTab',
                                                          state: {
                                                              sel_u_id: val.id, //targetId
                                                              join_option: join_option,
                                                              ecredible_id: val.ecredible_id, //ecredible_id
                                                              tab : true,
                                                          }
                                                      });
                                                  }else{
                                                      history.push({
                                                          pathname: '/' + match.params.JoinCode + '/MembersCardDetail',
                                                          state: {
                                                              sel_u_id: val.id, //targetId
                                                              join_option: join_option,
                                                          }
                                                      });//탭X
                                                  }
                                              }else{
                                                  setModal2(true);
                                              }

                                          }
                                      }}>
                                    <Typography
                                        align='left'
                                        style={{width: '100%', border: 0, backgroundColor: 'transparent', resize: 'none', fontSize: 13}}
                                        className={classes.textEllipsis}>
                                        {val.company_closing=='O' ? val.AI_ranking +','+ val.company_classification+','+ val.standard_industry_classification : val.company_classification+','+val.standard_industry_classification}
                                    </Typography>
                                </div>
                            ) : null}

                    </Grid>

                </Grid>
            </div>

        );
    }

    // const MemberListUM = React.useMemo(()=> list.map((value, i) =>
    //     <MemberList key={i} val ={value} index={i}/>),[list]);

    return (
        <div style={{padding: 0,top:10}}>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                {/*<PcHeader text={'전체 회원'} back={1} history={history} match={match} cnt={items.length}/>*/}
                {/* 상단 고정*/}
                <div style={{padding: '20px 25px 15px 20px', marginTop: -20, boxShadow:  '0 5px 10px rgba(0,0,0,0.15)', position: 'sticky', top: -15, backgroundColor: '#fff', zIndex: 10}} className={'allUser'}>
                    <div style={{width: '85%', height: 45, textAlign: 'left', borderRadius: '40px', position: 'relative', border: '1px solid #B9BFC3', display: 'inline-block', marginRight: 3}}>
                        <Inputtext/>
                    </div>
                    <div style={{width: '12%', textAlign: 'left', borderRadius: '40px', position: 'relative', display: 'inline-block',}}>
                        <Button
                            type="submit"
                            color="primary"
                            style={{backgroundColor: 'rgb(48,58,83)', color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 40, padding: '10px 8px', height: 43, marginTop: -5}}
                            onClick={()=>{
                                history.push({
                                    pathname :'/'+ match.params.JoinCode+'/MembersDetailSearch',
                                    state : {tab : 'operator_card'}
                                });
                                // history.push('/MembersDetailSearch');
                            }}>상세</Button>
                    </div>
                </div>
                {/*<div style={{padding: '30px 40px 20px',marginTop: 0, position: 'sticky', top: 130, backgroundColor: '#fff', zIndex: 9}}>*/}
                {/*    <Typography align='left' style={{backgroundColor: 'rgb(235,235,235)', padding: 10, textIndent: 10, fontSize: 13, borderRadius: 10, fontWeight: 500}}>*/}
                {/*        현재 회원 - <span style={{color: 'rgb(239,113,113)'}}>{items.length}</span> 명</Typography>*/}
                {/*</div>*/}

                {/* 회원 리스트 */}
                {list.map(((value,i) => <MemberList key={i} val ={value} index={i}/>))}
                {/*{MemberListUM}*/}

                {/*연결 요청 팝업*/}
                {modal ? (<PopUp/>) : null}

                {/*명함 등록 팝업*/}
                {modal2? (<PopUp2/>) : null}

                {/*개인정보  공개요청 팝업*/}
                {modal3 ? (<PopUp3/>) : null}

            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 20px',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    allUser: {

    },
    searchIcon: {
        zIndex: 10,
        position: 'absolute',
        right: -3, top: '50%',
        transform: 'translateY(-50%)'
    },
    inputRoot: {
        color: 'inherit',
        width: '75%',
    },
    inputInput: {
        margin: 3,
        padding: '10px 5px 13px 10px'
    },
    '@global':{
        '.text-input .MuiInputBase-input': {
            height: 'auto !important',
            fontSize: 13,
            lineHeight: 1.5
        },
        '.allUser .MuiInputBase-formControl':{
            height: 45
        },
        '.allUser .makeStyles-inputInput-5':{
            margin: 0
        },
        '.MuiBox-root': {
            padding: 5
        }
    },
    popUp: {
        zIndex: 1110,
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '310px',
        backgroundColor: '#fff',
        borderRadius: 10,
    },
    popUpBg: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        backgroundColor: 'rgba(0,0,0,0.25)',
        width: '100%',
        height: '100%'
    },
    textEllipsis:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: 1.5,
        height: '40px',
        display: '-webkit-box',
        lineClamp: 2,
        boxOrient: 'vertical',
        padding: 5,
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginLeft: 26
    },


}));


export default Operator_card;
