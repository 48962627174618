import React from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Box
}
    from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";
import customAlert from "../component/customAlert";

function Advertige({history}) {
    const classes = useStyles();

    const u_id = sessionStorage.getItem('@u_id');

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'제휴 & 광고 문의'} back={1} history={history}/>
                <div className={classes.paper}>
                    <Typography align= 'center'
                                style={{fontSize: '13px', backgroundColor: 'rgb(239,242,246)', padding: 10, width: '100%', borderRadius: 10}}>
                        제휴 및 제안, 광고 문의를 해주시면<br />담당자가 72시간내에 답변을 드립니다.
                    </Typography>
                    <Box component={'div'}
                         style={{border: '1px solid #000', borderRadius: 10, minHeight: 200, padding: 10, width: '100%', height: '100%', marginTop: 20}}>
                        <Box id={'contents'} component={'textarea'} style={{border: 'none', outline: 'none', width: '100%', height: '100%', minHeight: 200, resize: 'none', lineHeight: 1.3}}
                             placeholder={'문의내용을 입력해 주세요.'}/>
                    </Box>
                    <Button
                        onClick={() => {
                            if(!document.getElementById('contents').value){
                                customAlert('문의 내용을 입력해주세요');
                            }else{
                                Axios({
                                    url: 'https://sbc.smartbc.kr/sb/request_sponsor_quote.php',
                                    method: 'get',
                                    params : {
                                        u_id : u_id,
                                        content : document.getElementById('contents').value
                                    }
                                }).then(function (res) {
                                    console.log('request_sponsor_quote success');
                                    if (res.data == 200) {
                                        customAlert('제휴 & 광고 문의내용이 정상적으로 접수 되었습니다.');
                                        history.goBack();
                                    }else{
                                        customAlert('제휴 & 광고 문의가 접수를 실패하였습니다.\n관리자에게 문의부탁드립니다.');
                                    }
                                }).catch(function (e) {
                                    console.log('request_sponsor_quote fail');
                                })
                            }
                        }}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                        style={{color: '#fff',fontWeight:'bold',fontSize :15,borderRadius : 25,padding :10, backgroundColor: '#303a53', marginTop: 20}}
                    >
                        관리자에게 문의하기
                    </Button>
                    <Button
                        onClick={() => {
                            window.location.href = 'https://sbc.smartbc.kr/ssbc/download.php';
                        }}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        className={classes.submit}
                        style={{color: '#303a53',fontWeight:'bold',fontSize :15,borderRadius : 25,padding :10, border: '1px solid #303a53', marginTop: 10}}
                    >
                        동영상 광고 소개서 다운로드
                    </Button>

                </div>
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '30px 40px 60px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    title: {
        fontSize: 14,
    },
    formControl: {
        width: '100%',
        textAlign: 'left',
        textIndent: 10,
        marginTop: theme.spacing(3),
    },
    selectTilie: {
        fontSize: 15,
        textAlign: 'center',
        marginTop: theme.spacing(4),
        backgroundColor: 'rgb(249,250,252)',
        width: 105,
        height: 60,
        lineHeight: '60px',
        borderRadius: 10,
        marginRgiht: 10
    },
    selectInput:{
        padding: '23px 12px 20px'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    bottonBtn: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 60,
        background: '#303a53',
        borderRadius: 0,
        color: '#fff',
        fontSize: 15,
        fontWeight:'bold',
        '&:hover': {
            background: '#303a53',
        },

    },

}));


export default Advertige;
