import {createAction, handleActions} from 'redux-actions';

const SETLOCATION = 'location/SETLOCATION';
const SETRECENTLOC = 'location/SETRECENTLOC';
const SETSUBLOCATION = 'location/SETSUBLOCATION';

export const setLocation = createAction(SETLOCATION, (input) => {
    return input;
});
export const setSubLocation = createAction(SETSUBLOCATION, (input) => {
    return input;
});
// export const setRecentLoc = createAction(SETRECENTLOC,());

const initialState = {
    location: undefined,
    subLocation: undefined,
    recentLoc: undefined,
};

const location = handleActions(
    {
        [SETLOCATION]: (state, {payload: input}) => ({
            ...state,
            location: input,
        }),
        [SETSUBLOCATION]: (state, {payload: input}) => ({
            ...state,
            subLocation: input,
        }),
    },
    initialState,
);

export default location;
