import React, {useEffect} from "react";
import {
    CssBaseline,
    makeStyles,
    Container,
    Typography,
    Button,
    Grid,
    Avatar,
}from '@material-ui/core';
import PcHeader from "./PcHeader";
import Axios from "axios";

function NoticeDetail_0({history,match}) {

    const [notice,setNotice] = React.useState(new Array());

    const classes = useStyles();

    useEffect(() => {
        Axios({
            url: "https://sbc.smartbc.kr/sb/notification_detail.php",
            method: 'get',
            params: {
                type : '0',
                n_id : history.location.state.n_id,
            }
        })
            .then(function (res) {
                //     console.log(res.data['msg'].contain('https://'));
                // if (res.data['msg'].contain('https://')) {
                //     setHref(res.data['msg'].contain('https://'));
                //     // setContent(res.data['msg'].replace('https://',""));
                // }
                // else setContent(res.data['msg']);
                // setContent(res.data['msg']);
                // setTitle(res.data['title']);
                // setDate(res.data['create_at']);
                setNotice(res.data);
                // .replace(/(<([^>]+)>)/ig,"")
                console.log("알림 상세0 list up success");
            })
            .catch(function (e) {
                console.log("알림 상세0 list up fail");
            })
    },[]);

    function aa() {
        return {__html : notice.msg};
    }

    return (
        <div>
            <Container component="main" maxWidth="xs" style={{backgroundColor: 'white', paddingBottom: '70px'}}>
                <CssBaseline/>
                <PcHeader text={'알림 상세'} back={1}  history={history}/>

                <div className={classes.paper}>
                    <Typography
                        style={{fontSize: '13px', backgroundColor: 'rgb(249,250,252)', padding: 10, width: '100%', margin: '30px 0 20px', borderRadius: 10}}>
                        {notice.title}
                    </Typography>
                    {/* 상단 정보 */}
                    <div style={{width: '100%', padding: '20px 0', borderBottom: '1px solid rgb(226,231,235)', borderTop: '1px solid rgb(226,231,235)'}}>
                        <Grid container alignItems='center'direction='row' >
                            <Grid item xs={2} style={{marginRight: 10}}>
                                <Avatar alt="사진" src={require('../resources/example_profileimg.png')} className={classes.profileImgSize}/>
                            </Grid>
                            <Grid item xs={7}>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography align='left' style={{fontSize: 17, color: 'rgb(51,51,51)', fontWeight: 'bold'}}>
                                            임재헌<span style={{fontSize: 11, color: 'rgb(181,181,181)', marginLeft: 5}}>팀장</span>
                                        </Typography>
                                        <Typography align='left' style={{fontSize: 11, color: 'rgb(181,181,181)',}}>
                                            주식화사 베스트 코리아
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/*<div style={{backgroundColor :'red'}}>*/}
                        {/*    <a href={'https://www.naver.com'}>asddasd</a>*/}
                        {/*</div>*/}
                        <div style={{margin : 10,textAlign:'left',marginTop :20}} dangerouslySetInnerHTML={aa()}></div>
                        <Typography align='right' style={{width: '100%', fontSize: 10, color: 'rgb(181,181,181)'}}>{notice.create_at}</Typography>
                    </div>

                </div>
                <div style={{
                    minHeight: 700,
                    height: '100%',
                    width: '444px',
                    backgroundColor: '#fff',
                    position: 'fixed',
                    left: '50%',
                    transform: 'translateX(-50%)'
                }}></div>
            </Container>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    /*innerbody*/
    paper: {
        padding: '10px 40px 60px',
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    profileImgSize: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },

}));


export default NoticeDetail_0;
